import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StateContext } from "../../context/Context";
import CourseItem from "../CourseItem/CourseItem";
import NavbarDemo from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import SidebarActive from "../Sidebar/SidebarActive";
import { Popover } from "@mui/material";
import "./searchPage.css";
import axios from "../../Apis/api";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Navigation } from "swiper";
import Img1 from "../../assets/images/ChosenCourse/img1.png";
import { Divider } from "@mui/material";
import Footer from "../Footers/Footer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavbarSm from "../Navbar/NavbarSm";
import { refresh } from "../../Apis/RefreshToken/RefreshToken";
import {
  price,
  price_title,
  pTitle_typography,
  filter_title,
  input,
  label,
  svg,
} from "../Navbar/NavbarStyles";

export default function SearchPage(props) {
  const { navStretch, addedToCart, addedToFav, loggedIn, boughtCourses, filterPriceValue, setFilterPriceValue } =
    useContext(StateContext);
  const { value } = useParams();
  const [courseResponse, setCourseResponse] = useState([]);
  const [speakerResponse, setspeakerResponse] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const [favItems, setFavItems] = useState([]);
  const [favData, setFavData] = useState([]);
  const idFilter = openFilter ? "simple-popover-filter" : undefined;
  const openFilter = Boolean(anchorElFilter);

  useEffect(() => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_KEY}/api/v1/courses/search/`, {
          word: value,
        })
        .then((res) => {
          setCourseResponse(res.data.found_courses);
          setspeakerResponse(res.data.found_speakers);
        })
        .catch((err) => {
          refresh(err.response.status, err.response.status.text);
        });
    } catch (error) { }
  }, [value]);

  useEffect(() => {
    try {
      loggedIn &&
        axios
          .get(`${process.env.REACT_APP_API_KEY}/api/v1/orders/cart`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          })
          .then((res) => {
            setCartData(res.data.items);
            setCartItems(res.data.items.map((item) => item.course.id));
          })
          .catch((err) => {
            refresh(err.response.status, err.response.status.text);
          });
    } catch (error) { }
  }, [addedToCart]);

  useEffect(() => {
    try {
      loggedIn &&
        axios
          .get(
            `${process.env.REACT_APP_API_KEY}/api/v1/courses/list-fav-courses/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            }
          )
          .then((res) => {
            setFavData(res.data);
            setFavItems(res.data.map((item) => item.id));
          })
          .catch((err) => {
            refresh(err.response.status, err.response.status.text);
          });
    } catch (error) { }
  }, [addedToFav]);

  //   const [courseData, setCourseData] = useState(searchResponse?searchResponse.found_courses:[]);
  //   const [speakerData, setSpeakerData] = useState(searchResponse?searchResponse.found_courses:[]);

  for (let i = 0; i < courseResponse.length; i++) {
    if (window.innerWidth > 1320) {
      if ((i + 1) % 4 === 0) {
        courseResponse[i].className = "cards-left";
      } else {
        courseResponse[i].className = "cards-right";
      }
    } else if (window.innerWidth < 1320) {
      if ((i + 1) % 3 === 0) {
        courseResponse[i].className = "cards-left";
      } else {
        courseResponse[i].className = "cards-right";
      }
    }
  }

  const search = (e) => {
    e.preventDefault();
    navigate(`/search/${searchValue}`);
    // setSearchToggle()
  };
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const handleClickFilter = (event) => {
    event.preventDefault();
    setAnchorElFilter(event.currentTarget);
    console.log("hello")
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const navigateToFilter = (e) => {
    e.preventDefault()
    setFilterPriceValue(e.target.value);
    navigate(`/courses/filter/course ${e.target.value}`);
  };

  useEffect(() => {
    const searchInp = document.getElementById('searchch')
    console.log(searchInp, searchInp.value, 'sasasa');
    searchInp.addEventListener('keypress', (e) => {
      console.log(e.keyCode);
      if(e.keyCode == 13) {
        e.preventDefault()
      }
    })
  }, [])
  
  return (
    <div className="searchPageComp">
      <NavbarDemo />
      <NavbarSm />
      <Sidebar />
      <div className={navStretch ? "courses ml-240" : "courses ml-100"}>
        <div className="container d-sm-block pt-100">
          <div className="rowGrid">
            <div className="col-sm-block col-sm-24">
              <form onSubmit={search} id="searchBox">
                <div className="search">
                  <label onClick={(e) => search(e)} htmlFor="searchch" className="pointer searchIcon">
                    <svg width="24" height="24" fill="none">
                      <path
                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                        stroke="#999999"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 22L20 20"
                        stroke="#999999"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </label>

                  <button
                    aria-describedby={idFilter}
                    onClick={(event) => handleClickFilter(event)}
                    style={{
                      zIndex: "5",
                      position: "absolute",
                      right: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <svg
                      // className="filterButton"
                      width="24"
                      height="24"
                      fill="none"
                    >
                      <path
                        d="M3 7H21"
                        stroke="#1C1C1C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6 12H18"
                        stroke="#1C1C1C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M10 17H14"
                        stroke="#1C1C1C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                  <Popover
                    id={idFilter}
                    open={openFilter}
                    anchorEl={anchorElFilter}
                    onClose={handleCloseFilter}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    disableScrollLock={true}
                    sx={{
                      "& .MuiPaper-root": {
                        background: "#fcfcfc",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                        borderRadius: "15px",
                        width: "100%",
                        padding: "30px",
                        marginTop: "20px",
                        overflowY: "visible",
                        height: "auto",
                      },
                    }}
                  >
                    <div style={price}>
                      <h1 style={filter_title}>
                        Narx
                        <svg width="18" height="9" fill="none">
                          <path
                            d="M16.9201 0.949997L10.4001 7.47C9.63008 8.24 8.37008 8.24 7.60008 7.47L1.08008 0.949997"
                            stroke="#999999"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </h1>
                      <div style={price_title}>
                        <label for="increment">
                          <p style={pTitle_typography}>
                            Narx yuqorilab borish bo'yicha
                          </p>
                        </label>
                        <div>
                          <label style={label} for="increment">
                            {" "}
                            {filterPriceValue === "+absolut_price" && (
                              <svg style={svg} width="10" height="8" fill="none">
                                <path
                                  d="M0.75 4L3.58 6.83L9.25 1.17"
                                  stroke="#006AFF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </label>
                          <input
                            style={input}
                            type="radio"
                            name="price"
                            id="increment"
                            value="+absolut_price"
                            onChange={(e) => {
                              navigateToFilter(e);
                            }}
                          />
                        </div>
                      </div>
                      <div style={price_title}>
                        <label for="decrement">
                          <p style={pTitle_typography}>
                            Narx pastlab borish bo’yicha
                          </p>
                        </label>
                        <div>
                          <label style={label} for="decrement">
                            {filterPriceValue === "-absolut_price" && (
                              <svg style={svg} width="10" height="8" fill="none">
                                <path
                                  d="M0.75 4L3.58 6.83L9.25 1.17"
                                  stroke="#006AFF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </label>
                          <input
                            style={input}
                            type="radio"
                            name="price"
                            id="decrement"
                            value="-absolut_price"
                            onChange={(e) => {
                              navigateToFilter(e);
                            }}
                          />
                        </div>
                      </div>
                      <div style={price_title}>
                        <label for="free">
                          <p style={pTitle_typography}>Bepul kurslar</p>
                        </label>
                        <div>
                          <label style={label} for="free">
                            {filterPriceValue === "free" && (
                              <svg style={svg} width="10" height="8" fill="none" onClick={() => console.log('ss')}>
                                <path
                                  d="M0.75 4L3.58 6.83L9.25 1.17"
                                  stroke="#006AFF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </label>
                          <input
                            style={input}
                            type="radio"
                            name="price"
                            id="free"
                            value="free"
                            onChange={(e) => {
                              navigateToFilter(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    id="searchch"
                    type="text"
                    placeholder="Qidirish..."
                    value={searchValue}
                  />
                </div>

                {/* {searchValue && searchResponse.length != 0 && (
                  <div className="searchResponse">
                    {searchResponse.map((item) => (
                      <div
                        onClick={() => navigate(`/chosenCourse/${item.id}`)}
                        className="rowGrid pointer"
                      >
                        <img
                          className="col-8"
                          src={`${process.env.REACT_APP_API_KEY}${item.cover_img}`}
                          alt=""
                        />
                        <div className="col-16">
                          <p className="name">
                            {item.name.length > 25
                              ? item.name.slice(0, 24) + "..."
                              : item.name}
                          </p>
                          <p className="teacher">
                            {item.course_owner.full_name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )} */}
              </form>
            </div>
          </div>
        </div>
        <div className="container searchPage">
          {courseResponse.length != 0 ? (
            <h1 className="headerTitle">
              "{value}" - bo'yicha topilgan kurslar!
            </h1>
          ) : (
            <h1 className="headerTitle">
              "{value}" - bo'yicha kurslar topilmadi!
            </h1>
          )}
          <div className="rowGrid">
            {courseResponse.length != 0
              ? courseResponse.map((course, index) => (
                <div key={index} className="col-6 col-lg-8 col-sm-24">
                  <CourseItem
                    key={index}
                    id={course.id}
                    video={course.video}
                    label={null}
                    title={course.name}
                    trainer={course.course_owner.full_name}
                    trainerId={course.course_owner.id}
                    rating={course.null}
                    ratersNumber={null}
                    graduates={course.enrolled_students}
                    priceLine={course.discount_price}
                    price={course.price}
                    dicountAvailable={null}
                    updated_at={course.updated_at}
                    level={course.level}
                    about={course.short_descr}
                    coverImg={course.cover_img}
                    recommendation={course.recommendation}
                    exchange_url={course.exchange_url}
                    ratings={course.course_rating}
                    key_words={course.key_words}
                    trailer_url={course.trailer_url}
                    category={course.category}
                    subcategory={course.subcategory}
                    type={course.type}
                    lang={course.lang}
                    class={course.className}
                    cartItemsIds={cartItems}
                    isAddedToCart={cartItems.some(
                      (item) => item == course.id
                    )}
                    isAddedToFav={favItems.some((item) => item == course.id)}
                    cartData={cartData}
                    favData={favData}
                    boughtCourses={boughtCourses}
                  />
                </div>
              ))
              : null}
          </div>
          <Divider />
        </div>
      </div>
      <Footer />
    </div>
  );
}
