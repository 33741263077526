import React from "react";
import "./CourseAbout.css";
import ReactHtmlParser from 'react-html-parser';

export default function CourseAbout(props) {
  return (
    <div className="course-about">
      {/* <p>{props.about}</p> */}
      <p>{ ReactHtmlParser(props.resData.short_descr) }</p>
      {props.resData.whom_this_course && (
        <div>
          <br />
          <h4 className="headerText" style={{textDecoration: "underline"}}>Kurs kimlar uchun</h4>
          <p>{ReactHtmlParser(props.resData.whom_this_course)}</p>
        </div>
      )}{" "}
      {props.resData.what_to_learn && (
        <div>
          <h4 className="headerText" style={{textDecoration: "underline"}}>Kursda nimalar o'rganiladi</h4>
          <p>{ReactHtmlParser(props.resData.what_to_learn)}</p>
        </div>
      )}{" "}
    </div>
  );
}