import React, { useState, useContext, useEffect } from 'react'
import TextField from "@mui/material/TextField";
import NavbarSm from '../../components/Navbar/NavbarSm';
import NavbarDemo from '../../components/Navbar/Navbar';
import Footer from '../../components/Footers/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarSm from '../../components/Sidebar/SidebarSm';
import { StateContext } from '../../context/Context';
import PhoneInput from "react-phone-input-2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "./Rassrochka.css"
import axios from 'axios';
import { refresh } from '../../Apis/RefreshToken/RefreshToken';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from "react-spinners";


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SyncLoader
from "react-spinners/SyncLoader";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Rassrochka() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [region, setRrgion] = React.useState('');

    const handleRegionChange = (event) => {
        setCodeReg(event.target.value)
    };
    const handleTumanChange = (event) => {
        setCodeTuman(event.target.value)
    };
    const headers = {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
    };
    const maxLength = 14
    const { navStretch, setTabPage } = useContext(StateContext);
    const [pasSeriya, setPasSeriya] = useState("")
    const [birthDay, setBirthDay] = useState(new Date())
    const [dataIssue, setDataIssue] = useState(new Date())
    const [cardNum, setCardNum] = useState("")
    const [cardNumSec, setCardNumSec] = useState("")
    const [codeReg, setCodeReg] = useState("")
    const [codeTuman, setCodeTuman] = useState("")
    const [tuman, setTuman] = useState([])
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [midleName, setMidleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [expire, setExpire] = useState("")
    const [pinFl, setPinFl] = useState("")
    const [docType, setDocType] = useState("I")
    const [mobile, setMobile] = useState("")
    const [regions, setRegions] = useState([])
    const [otpNum, setOtpNum] = useState("")
    const [otpToken, setOtpToken] = useState("")
    const [disableOtp, setDisableOtp] = useState(true)
    const [disable, setDisable] = useState(false)
    const [loader, setLoader] = useState(false);
    const [cardCheckErrMessage, setCardCheckErrMessage] = useState()
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const otpTost = () => toast.error("Otp xato!")
    const AlredyReg = () => toast.error("Siz oldin ham bo'lib to'lashga kurs sotib olgansiz.")
    const SucBuy = () => toast.success("Tabriklaymiz siz kursni sotib oldingiz")
    const CheckCard = () => toast.success("Karta ma`lumotlari tekshirilmoqda.")
    const allErrCart = () => toast.error(cardCheckErrMessage)
    const sendErr = () => toast.error('Iltimos karta ma`lumotlarini to`ldiring')

    useEffect(() => {
        try {
            axios.get(`${process.env.REACT_APP_API_KEY}/api/v2/orders/check-indent-and-enroll`,
                {
                    headers,
                }).then((res) => res.data.status === false ? setLoader(false) : sucBuyNavigate())
        } catch (error) {

        }
    }, [])


    const sucBuyNavigate = () => {
        SucBuy();
        setTimeout(() => {
            navigate('/userAbout')
        }, 2000)
    }


    const handleSubmitCardInfo = async (e) => {
        e.preventDefault()
        CheckCard()
        try {
            await axios
                .post(
                    `${process.env.REACT_APP_API_KEY}/api/v2/orders/card-scoring`,
                    {
                        card_number: cardNum.replace(/ /g, ""),
                        card_expire: expire.split("/").reverse("").join("")
                    },
                    {
                        headers,
                    }
                )
                .then((res) => {
                    setOtpToken(res.data.otp_generated)
                    setDisableOtp(false)
                    setDisable(true)
                    setValue(1)
                })
                .catch(err => {
                    err.response.data.error.message && setCardCheckErrMessage(err.response.data.error.message)
                    err.response.status && refresh(err.response.status, err.response.status.text);
                })
        } catch (error) {

        }
    }

    const handleSendOtp = async (e) => {
        e.preventDefault()
        try {
            if (otpNum && otpToken) {
                setLoading(true)
                await axios
                    .post(
                        `${process.env.REACT_APP_API_KEY}/api/v2/orders/card-check-enroll`,
                        {
                            otp_token: otpToken,
                            otp: otpNum.replace(/ /g, "")
                        },
                        {
                            headers,
                        }
                    )
                    .then((res) => {
                        sucBuyNavigate()
                         setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                        err.response.data.message && setCardCheckErrMessage(err.response.data.message)
                        if (err.response.data.error.message.slice(0, 64) === "Undefined error: {\"message\":\"Application was there before!\",") {
                            AlredyReg();
                        }
                        err.response.data.error.message == "Invalid otp or expire" && otpTost()
                        err.response.status && refresh(err.response.status, err.response.status.text);
                    })
            }else {
                sendErr()
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        try {
            window.location.search && axios.post(`${process.env.REACT_APP_API_KEY}/api/v2/orders/check-passport`,
                {
                    code: window.location.search.replace('?code=', '').replace('&state=xyzABC123', '')
                },
                {
                    headers,
                }).then((res) => console.log(res.data))
        } catch (error) {

        }
    }, [])


    useEffect(() => {
        if (cardCheckErrMessage !== '') {
            allErrCart()
            setCardCheckErrMessage('')
        }
    }, [cardCheckErrMessage])

    return (
        <>
            <NavbarDemo />
            <NavbarSm />
            <Sidebar active={3} />
            <SidebarSm active={4} />
            <div className={navStretch ? "courses ml-100" : "courses ml-100"}>

                <div className="rassrochka">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Karta ma'lumotlari" {...a11yProps(0)} />
                                <Tab label="Sms xabar" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        <CustomTabPanel value={value} index={0}>
                            <form action="" onSubmit={(e) => handleSubmitCardInfo(e)}>
                                <div className="container">
                                    <div className="rowGrid">
                                        <div className="col-24">
                                            <h2>Karta ma’lumotlarini kiriting:</h2>
                                        </div>
                                        <div style={{ marginBottom: "15px" }} className="col-12 col-md-24">
                                            <TextField

                                                className="inputs"
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderRadius: "15px",
                                                    },
                                                }}
                                                type="text"
                                                required
                                                placeholder='0000 0000 0000 0000'
                                                label="Karta raqami"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    let res = e.target.value
                                                        .replace(/[^\dA-Z]/g, "")
                                                        .replace(/(.{4})/g, "$1 ").trim()
                                                    res.length > 20 ? e.preventDefault() : setCardNum(res);
                                                }}
                                                value={cardNum}
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }} className="col-12 col-md-24">
                                            <TextField

                                                className="inputs"
                                                label="Kartaning amal qilish muddati"
                                                variant="outlined"
                                                placeholder="MM/YY"
                                                required
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderRadius: "15px",
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let res = e.target.value
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(/^([2-9])$/g, "0$1")
                                                        .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
                                                        .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
                                                    setExpire(res);
                                                }}
                                                value={expire}
                                                type="text"
                                            />
                                        </div>
                                        <div style={{ margin: "0 auto 0 auto" }} className="col-16 col-md-24">
                                            <button type='submit' className='pointer cartaBtn'>
                                                Davom etish
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <form onSubmit={(e) => handleSendOtp(e)}>
                                <div className="container">
                                    <div className="rowGrid">
                                        <div className="col-24">
                                            <h2>Sms dan kelgan kodni kirgazing:</h2>
                                        </div>

                                        <div style={{ marginBottom: "15px" }} className="col-24">
                                            <TextField
                                                className="inputs"
                                                disabled={disableOtp}
                                                focused={disable}
                                                required={
                                                    disable ? true : false
                                                }
                                                label="Raqamga yuborilgan parolni kiriting"
                                                variant="outlined"
                                                placeholder="X X X X X"
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderRadius: "15px",
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let res = e.target.value
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(/(\d)(?=(\d{1})+$)/g, '$1 ');
                                                    setOtpNum(res.slice(0, 9))
                                                }}
                                                value={otpNum}
                                                type="text"
                                            />
                                        </div>
                                        <div style={{ margin: "0 auto 0 auto" }} className="col-16 col-md-24">
                                            <button type='submit' className='pointer cartaBtn flex justify-center align-center'>
                                                Sms xabarni jo'natish
                                                <SyncLoader
                                                    color={'#fff'}
                                                    loading={loading}
                                                    cssOverride={{  display: "block",
                                                    margin: "0 0 0 10px",
                                                    borderColor: "red",

                                                    }}
                                                    className='smsLoading'
                                                    size={10}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CustomTabPanel>
                    </Box>
                </div>
            </div >

            {loader && (
                <div className="loader">
                    <BounceLoader color="#006AFF" speedMultiplier={1.2} />
                </div>
            )
            }
            <ToastContainer />
            <Footer />
        </>
    )
}

export default Rassrochka