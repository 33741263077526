export const taqvimApi =  [
    {
        date: "23-03-2023",
        week: "Payshanba",
       morning: "05:03" ,
       exaggeration: "18:40" 
    }, 
    {
        date: "24-03-2023" ,
        week: "Juma" ,
       morning: "05:01" ,
       exaggeration: "18:41"
    },
    {
        date: "25-03-2023" ,
        week: "Shanba" ,
       morning: "05:00" ,
       exaggeration: "18:42" 
    },
    {
        date: "26-03-2023" ,
        week: "Yakshanba" ,
       morning: "04:58" ,
       exaggeration: "18:43" 
    },
    {
        date: "27-03-2023" ,
        week: "Dushanba" ,
       morning: "04:56" ,
       exaggeration: "18:44" 
    },
    {
        date: "28-03-2023" ,
        week: "Seshanba" ,
       morning: "04:54" ,
       exaggeration: "18:45" 
    },
    {
        date: "29-03-2023" ,
        week: "Chorshanba",
       morning: "04:53" ,
       exaggeration: "18:46" 
    },
    {
        date: "30-03-2023" ,
        week: "Payshanba",
       morning: "04:51" ,
       exaggeration: "18:47" 
    },
    {
        date: "31-03-2023" ,
        week: "Juma", 
       morning: "04:49" ,
       exaggeration: "18:48" 
    },
    {
        date: "01-04-2023" ,
        week: "Shanba" ,
       morning: "04:47" ,
       exaggeration: "18:49" 
    },
    {
        date: "02-04-2023" ,
        week: "Yakshanba", 
       morning: "04:45" ,
       exaggeration: "18:50" 
    },
    {
        date: "03-04-2023", 
        week: "Dushanba" ,
       morning: "04:43" ,
       exaggeration: "18:52" 
    },
    {
        date: "04-04-2023" ,
        week: "Seshanba" ,
       morning: "04:42" ,
       exaggeration: "18:53" 
    },
    {
        date: "05-04-2023" ,
        week: "Chorshanba",
       morning: "04:40" ,
       exaggeration: "18:54" 
    },
    {
        date: "06-04-2023" ,
        week: "Payshanba",
       morning: "04:38" ,
       exaggeration: "18:55" 
    },
    {
        date: "07-04-2023" ,
                           week: "Juma" ,
                          morning: "04:36" ,
                          exaggeration: "18:56"
    },
    {
        date: "08-04-2023" ,
        week: "Shanba" ,
       morning: "04:34" ,
       exaggeration: "18:57" 
    },
    {
        date: "09-04-2023" ,
        week: "Yakshanba" ,
       morning: "04:32" ,
       exaggeration: "18:58" 
    },
    {
        date: "10-04-2023" ,
        week: "Dushanba" ,
       morning: "04:30" ,
       exaggeration: "18:59" 
    },
    {
        date: "11-04-2023", 
        week: "Seshanba" ,
       morning: "04:28" ,
       exaggeration: "19:00" 
    },
    {
        date: "12-04-2023" ,
        week: "Chorshanba",
       morning: "04:37" ,
       exaggeration: "19:01" 
    },
    {
        date: "13-04-2023" ,
        week: "Payshanba",
       morning: "04:25" ,
       exaggeration: "19:02" 
    },
    {
        date: "14-04-2023" ,
        week: "Juma" ,
       morning: "04:23" ,
       exaggeration: "19:03" 
    },
    {
        date: "15-04-2023" ,
        week: "Shanba" ,
       morning: "04:21" ,
       exaggeration: "19:04" 
    },
    {
        date: "16-04-2023" ,
        week: "Yakshanba" ,
       morning: "04:19" ,
       exaggeration: "19:05" 
    },
    {
        date: "17-04-2023" ,
        week: "Dushanba" ,
       morning: "04:17" ,
       exaggeration: "19:07" 
    },
    {
        date: "18-04-2023" ,
        week: "Seshanba" ,
       morning: "04:15" ,
       exaggeration: "19:08" 
    },
    {
        date: "19-04-2023" ,
        week: "Chorshanba",
       morning: "04:13" ,
       exaggeration: "19:09" 
    },
    {
        date: "20-04-2023" ,
        week: "Payshanba",
       morning: "04:11" ,
       exaggeration: "19:10" 
    },
    {
        date: "21-04-2023", 
        week: "Juma" ,
       morning: "04:10" ,
       exaggeration: "19:11"  
    },
   ]