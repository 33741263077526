import React, { useEffect } from 'react';
import { io } from 'socket.io-client';

export default function TestAdminPanel() {
    // useEffect(() => {
    //     let socket = io('https://192.168.42.163:3000')

    //     socket.emit('messagesent', 'salom')
    // }, [])
  return (
    <div>
        salom
    </div>
  )
}
