import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import "./ProfilePrivacy.css";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function ProfilePrivacy() {
  return (
    <div className="privacy">
      <h1 className="privTitle">Maxfiylik sozlamalari</h1>
      <div className="rowGrid">
        <div className="col-12 col-lg-16 col-sm-24">
          <div className="privacy-item">
            <p>Tizimdagi foydalanuvchilarga profilingizni ko'rsating</p>
            <FormControlLabel
              control={<Android12Switch defaultChecked />}
              sx={{
                "& .css-j204z7-MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              }}
            />
          </div>
          <div className="privacy-item">
            <p>Foydalanuvchi o'tayotgan kurslarni ko'rsatish</p>
            <FormControlLabel
              control={<Android12Switch defaultChecked />}
              sx={{
                "& .css-j204z7-MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              }}
            />
          </div>
          <div className="privacy-item">
            <p>Elektron pochtani ko'rsatish</p>
            <FormControlLabel
              control={<Android12Switch defaultChecked />}
              sx={{
                "& .css-j204z7-MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              }}
            />
          </div>
          <div className="privacy-item">
            <p>Telefon raqamini ko'rsatish</p>
            <FormControlLabel
              control={<Android12Switch defaultChecked />}
              sx={{
                "& .css-j204z7-MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              }}
            />
          </div>
          <div className="privacy-item">
            <p>Sevimli kurslarni ko'rsatish</p>
            <FormControlLabel
              control={<Android12Switch defaultChecked />}
              sx={{
                "& .css-j204z7-MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              }}
            />
          </div>
        </div>
      </div>
      <Button
        sx={{
          width: "180px",
          height: "55px",
          borderRadius: "15px",
          backgroundColor: "#80B5FF;",
          fontFamily: "sans-serif",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "29px",
          textTransform: "none",
          marginTop: "30px",
        }}
        variant="contained"
        className="btn"
      >
        Saqlash
      </Button>
    </div>
  );
}
