import React, { useState } from 'react'
import "./RassrochkaMapCourse.css"
import Modal from "react-modal";
import CourseImage from "../../../assets/images/Rectangle 7.png";
// import CourseImage from "../../../assets";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  

function RassrochkaMapCourse({ item, graph }) {
    const [expanded, setExpanded] = useState('panel1');

    const [showTransVoucherModal, setShowTransVoucherModal] = useState(false);
    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "15px",
            zIndex: "2",
            minWidth: "300px",
            width: '500px',
            maxWidth: '100%',
            height: '70%',
            overflowY: 'scroll'
        },
    };
    
    
    const graphAmountCheck = (element) => {
        return element.paid_amount > 0
    }


    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    return (
        <div className='RassrochkaMapCourse'>
            <div className="rasCourse">
                {item && item.cover_img ? (
                    <img src={`${process.env.REACT_APP_API_KEY}${item.cover_img}`} alt="" />
                ) : <img src={CourseImage} />}
                <div className="rasCourseInfo">
                    <h4>{item.name}</h4>

                    <span>{item.course_owner.full_name}</span>
                    <div className="mar">
                        <p> <span>Kurs narxi:</span> {currency(item.price, "UZS").replace("UZS", "").replace("soʻm", "")} so’m va
                            <span> 12 oyga {currency(item.price / 12, "UZS").replace("UZS", "").replace("soʻm", "")}so’m</span> etib berildi</p>
                    </div>
                    <button onClick={() => setShowTransVoucherModal(true)}>To’lov grafigini ko’rish</button>
                </div>
            </div>

            <Modal
                isOpen={showTransVoucherModal}
                onRequestClose={() => setShowTransVoucherModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="title">
                    <h1 style={{ fontSize: "45px", fontWeight: "500", textAlign: "center", color: "#1C1C1C" }}>To’lov grafigi:</h1>
                </div>
                <div className="info">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }} className="date ">
                        <h5 style={{ fontSize: "24px", fontWeight: "500", textAlign: "left", color: "#1C1C1C" }}>Sana</h5>
                        <h5 style={{ fontSize: "24px", fontWeight: "500", textAlign: "left", color: "#1C1C1C" }}>To’lash uchun (UZS)</h5>
                    </div>
                   
                   <div className='mt-30'>
                    {
                        item.graph.length !== 0 && 
                            item.graph.map((item, index) => (
                                <Accordion style={{backgroundColor: 'transparent', borderRadius: '10px'}} expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                  <Typography className='flex justify-between align-center' style={{width: '100%'}}><p>{item.date_transaction}</p> <p style={{margin: '0'}}> {item.amount} </p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography className='flex justify-between align-center' style={{width: '100%'}}>
                                    <p>To'langan</p> <p>{item.paid_amount}</p>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            ))
                    }
                   </div>
                </div>
            </Modal>
        </div>
    )
}

export default RassrochkaMapCourse