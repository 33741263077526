import React, { useState, useEffect } from 'react'
import "./RassrochkaInfo.css"
import axios from 'axios';
import { refresh } from '../../Apis/RefreshToken/RefreshToken';
import RassrochkaMapCourse from './RassrochksMapCourse/RassrochkaMapCourse';
import { BounceLoader } from "react-spinners";


function RassrochkaInfo() {

  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [graph, setGraph] = useState([])
  const [loader, setLoader] = useState(true);

  const infoSum = [
    {
      date: "10.01.2023",
      sum: "84 000"
    },
    {
      date: "90.02.2023",
      sum: "84 000"
    },
    {
      date: "11.03.2023",
      sum: "84 000"
    },
    {
      date: "07.04.2023",
      sum: "84 000"
    },
  ]

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    };
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/api/v2/courses/autopay-with`,
          {
            headers,
          }
        )
        .then((res) => {
          setEnrolledCourses(res.data.result)
          setLoader(false)
        })
        .catch(err => {
          err.response.status && refresh(err.response.status, err.response.status.text);
        })
    } catch (error) {
    }

  }, [])


  return (
    <>
    <div className='rassrochkaInfo'>
      {
        enrolledCourses && enrolledCourses.length == 0 ?
          <div className="col-24">
            <p className="alertMessage">
              Sizda mudatli tolovga harid qilingan kurslar mavjud emas
            </p>
          </div> :
          enrolledCourses && enrolledCourses.map((item, index) => (
            <RassrochkaMapCourse key={index} item={item} graph={graph} />
          ))
      }
    </div>

    {loader && (
        <div className="loader">
          <BounceLoader color="#006AFF" speedMultiplier={1.2} />
        </div>
      )}
    </>
  )
}

export default RassrochkaInfo