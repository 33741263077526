export const AllDistrict = [
    {
      id: "1",
      district_id: "31",
      name_uz: "Ayrilish",
      name_oz: "Ŕéđčëčř",
      name_ru: "Ŕéđčëčř",
    },
    {
      id: "2",
      district_id: "31",
      name_uz: "Butaqora",
      name_oz: "Áóňŕ?îđŕ",
      name_ru: "Áóňŕ?îđŕ",
    },
    {
      id: "3",
      district_id: "31",
      name_uz: "Bo‘taqora",
      name_oz: "Á˘ňŕ?îđŕ",
      name_ru: "Á˘ňŕ?îđŕ",
    },
    {
      id: "4",
      district_id: "31",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "5",
      district_id: "31",
      name_uz: "Gulobod",
      name_oz: "Ăóëîáîä",
      name_ru: "Ăóëîáîä",
    },
    {
      id: "6",
      district_id: "31",
      name_uz: "Gumbaz",
      name_oz: "Ăóěáŕç",
      name_ru: "Ăóěáŕç",
    },
    {
      id: "7",
      district_id: "31",
      name_uz: "Yorboshi",
      name_oz: "¨đáîřč",
      name_ru: "¨đáîřč",
    },
    {
      id: "8",
      district_id: "31",
      name_uz: "Zavroq",
      name_oz: "Çŕâđî?",
      name_ru: "Çŕâđî?",
    },
    {
      id: "9",
      district_id: "31",
      name_uz: "Kuyganyor",
      name_oz: "Ęóéăŕí¸đ",
      name_ru: "Ęóéăŕí¸đ",
    },
    {
      id: "10",
      district_id: "31",
      name_uz: "Kunji",
      name_oz: "Ęóíćč",
      name_ru: "Ęóíćč",
    },
    {
      id: "11",
      district_id: "31",
      name_uz: "Qoraqalpoq",
      name_oz: "?îđŕ?ŕëďî?",
      name_ru: "?îđŕ?ŕëďî?",
    },
    {
      id: "12",
      district_id: "31",
      name_uz: "Qo‘nji",
      name_oz: "?˘íćč",
      name_ru: "?˘íćč",
    },
    {
      id: "13",
      district_id: "31",
      name_uz: "Qo‘shariq",
      name_oz: "?˘řŕđč?",
      name_ru: "?˘řŕđč?",
    },
    {
      id: "14",
      district_id: "31",
      name_uz: "Nayman",
      name_oz: "Íŕéěŕí",
      name_ru: "Íŕéěŕí",
    },
    {
      id: "15",
      district_id: "31",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "16",
      district_id: "31",
      name_uz: "Og‘ullik",
      name_oz: "Î?óëëčę",
      name_ru: "Î?óëëčę",
    },
    {
      id: "17",
      district_id: "31",
      name_uz: "Oq-yor",
      name_oz: "Î?-¸đ",
      name_ru: "Î?-¸đ",
    },
    {
      id: "18",
      district_id: "31",
      name_uz: "Oq-yor",
      name_oz: "Î?-¸đ",
      name_ru: "Î?-¸đ",
    },
    {
      id: "19",
      district_id: "31",
      name_uz: "Orol",
      name_oz: "Îđîë",
      name_ru: "Îđîë",
    },
    {
      id: "20",
      district_id: "31",
      name_uz: "Rovvot",
      name_oz: "Đîââîň",
      name_ru: "Đîââîň",
    },
    {
      id: "21",
      district_id: "31",
      name_uz: "Xakan",
      name_oz: "Őŕęŕí",
      name_ru: "Őŕęŕí",
    },
    {
      id: "22",
      district_id: "31",
      name_uz: "Xartum",
      name_oz: "Őŕđňóě",
      name_ru: "Őŕđňóě",
    },
    {
      id: "23",
      district_id: "31",
      name_uz: "Xartum",
      name_oz: "Őŕđňóě",
      name_ru: "Őŕđňóě",
    },
    {
      id: "24",
      district_id: "31",
      name_uz: "Xrabek",
      name_oz: "Őđŕáĺę",
      name_ru: "Őđŕáĺę",
    },
    {
      id: "25",
      district_id: "31",
      name_uz: "Chilon",
      name_oz: "×čëîí",
      name_ru: "×čëîí",
    },
    {
      id: "26",
      district_id: "31",
      name_uz: "Chumbog‘ich",
      name_oz: "×óěáî?č÷",
      name_ru: "×óěáî?č÷",
    },
    {
      id: "27",
      district_id: "31",
      name_uz: "Ekin tikin",
      name_oz: "Ýęčí ňčęčí",
      name_ru: "Ýęčí ňčęčí",
    },
    {
      id: "28",
      district_id: "31",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "29",
      district_id: "32",
      name_uz: "Asaka",
      name_oz: "Ŕńŕęŕ",
      name_ru: "Ŕńŕęŕ",
    },
    {
      id: "30",
      district_id: "32",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "31",
      district_id: "32",
      name_uz: "Ilg‘or",
      name_oz: "Čë?îđ",
      name_ru: "Čë?îđ",
    },
    {
      id: "32",
      district_id: "32",
      name_uz: "Kujgan",
      name_oz: "Ęóćăŕí",
      name_ru: "Ęóćăŕí",
    },
    {
      id: "33",
      district_id: "32",
      name_uz: "Kujgan",
      name_oz: "Ęóćăŕí",
      name_ru: "Ęóćăŕí",
    },
    {
      id: "34",
      district_id: "32",
      name_uz: "Qadim",
      name_oz: "?ŕäčě",
      name_ru: "?ŕäčě",
    },
    {
      id: "35",
      district_id: "32",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "?îđŕňĺďŕ",
    },
    {
      id: "36",
      district_id: "32",
      name_uz: "Mustahkam\r\n\r\nMustahkam\r\nMustahkam",
      name_oz: "Ěóńňŕ?ęŕě",
      name_ru: "Ěóńňŕ?ęŕě",
    },
    {
      id: "37",
      district_id: "32",
      name_uz: "Navkan",
      name_oz: "Íŕâęŕí",
      name_ru: "Íŕâęŕí",
    },
    {
      id: "38",
      district_id: "32",
      name_uz: "Niyozbotir",
      name_oz: "Íč¸çáîňčđ",
      name_ru: "Íč¸çáîňčđ",
    },
    {
      id: "39",
      district_id: "32",
      name_uz: "Oqbo‘yra",
      name_oz: "Î?á˘éđŕ",
      name_ru: "Î?á˘éđŕ",
    },
    {
      id: "40",
      district_id: "32",
      name_uz: "T.Aliyev",
      name_oz: "Ň.Ŕëčĺâ",
      name_ru: "Ň.Ŕëčĺâ",
    },
    {
      id: "41",
      district_id: "32",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "42",
      district_id: "33",
      name_uz: "Baliqchi",
      name_oz: "Áŕëč?÷č",
      name_ru: "Áŕëč?÷č",
    },
    {
      id: "43",
      district_id: "33",
      name_uz: "Baliqchi",
      name_oz: "Áŕëč?÷č",
      name_ru: "Áŕëč?÷č",
    },
    {
      id: "44",
      district_id: "33",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "45",
      district_id: "33",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "46",
      district_id: "33",
      name_uz: "Olimbek",
      name_oz: "Îëčěáĺę",
      name_ru: "Îëčěáĺę",
    },
    {
      id: "47",
      district_id: "33",
      name_uz: "Oxunboboyev nomli",
      name_oz: "Îőóíáîáîĺâ íîěëč",
      name_ru: "Îőóíáîáîĺâ íîěëč",
    },
    {
      id: "48",
      district_id: "33",
      name_uz: "Siza",
      name_oz: "Ńčçŕ",
      name_ru: "Ńčçŕ",
    },
    {
      id: "49",
      district_id: "33",
      name_uz: "O‘rmonbek",
      name_oz: "ˇđěîíáĺę",
      name_ru: "ˇđěîíáĺę",
    },
    {
      id: "50",
      district_id: "33",
      name_uz: "Xo‘jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "51",
      district_id: "33",
      name_uz: "Xo‘jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "52",
      district_id: "33",
      name_uz: "Chinobod markaz",
      name_oz: "×číîáîä ěŕđęŕç",
      name_ru: "×číîáîä ěŕđęŕç",
    },
    {
      id: "53",
      district_id: "33",
      name_uz: "Eskixaqqulobod",
      name_oz: "Ýńęčőŕ??óëîáîä",
      name_ru: "Ýńęčőŕ??óëîáîä",
    },
    {
      id: "54",
      district_id: "35",
      name_uz: "Bo‘z",
      name_oz: "Á˘ç",
      name_ru: "Á˘ç",
    },
    {
      id: "55",
      district_id: "35",
      name_uz: "M.Jalolov nomli",
      name_oz: "Ě.Ćŕëîëîâ íîěëč",
      name_ru: "Ě.Ćŕëîëîâ íîěëč",
    },
    {
      id: "56",
      district_id: "35",
      name_uz: "M.Jalolov nomli",
      name_oz: "Ě.Ćŕëîëîâ íîěëč",
      name_ru: "Ě.Ćŕëîëîâ íîěëč",
    },
    {
      id: "57",
      district_id: "35",
      name_uz: "Xovos",
      name_oz: "Őîâîń",
      name_ru: "Őîâîń",
    },
    {
      id: "58",
      district_id: "35",
      name_uz: "Xoldevonbek",
      name_oz: "Őîëäĺâîíáĺę",
      name_ru: "Őîëäĺâîíáĺę",
    },
    {
      id: "59",
      district_id: "35",
      name_uz: "Xoldevonbek",
      name_oz: "Őîëäĺâîíáĺę",
      name_ru: "Őîëäĺâîíáĺę",
    },
    {
      id: "60",
      district_id: "34",
      name_uz: "Andijon",
      name_oz: "Ŕíäčćîí",
      name_ru: "Ŕíäčćîí",
    },
    {
      id: "61",
      district_id: "34",
      name_uz: "Buloqboshi",
      name_oz: "Áóëî?áîřč",
      name_ru: "Áóëî?áîřč",
    },
    {
      id: "62",
      district_id: "34",
      name_uz: "Buloqboshi",
      name_oz: "Áóëî?áîřč",
      name_ru: "Áóëî?áîřč",
    },
    {
      id: "63",
      district_id: "34",
      name_uz: "Kulla",
      name_oz: "Ęóëëŕ",
      name_ru: "Ęóëëŕ",
    },
    {
      id: "64",
      district_id: "34",
      name_uz: "Mayariq",
      name_oz: "Ěŕ˙đč?",
      name_ru: "Ěŕ˙đč?",
    },
    {
      id: "65",
      district_id: "34",
      name_uz: "Nayman",
      name_oz: "Íŕéěŕí",
      name_ru: "Íŕéěŕí",
    },
    {
      id: "66",
      district_id: "34",
      name_uz: "Uchtepa",
      name_oz: "Ó÷ňĺďŕ",
      name_ru: "Ó÷ňĺďŕ",
    },
    {
      id: "67",
      district_id: "34",
      name_uz: "Shirmonbuloq",
      name_oz: "Řčđěîíáóëî?",
      name_ru: "Řčđěîíáóëî?",
    },
    {
      id: "68",
      district_id: "34",
      name_uz: "Shirmonbuloq",
      name_oz: "Řčđěîíáóëî?",
      name_ru: "Řčđěîíáóëî?",
    },
    {
      id: "69",
      district_id: "36",
      name_uz: "Abdullabiy",
      name_oz: "Ŕáäóëëŕáčé",
      name_ru: "Ŕáäóëëŕáčé",
    },
    {
      id: "70",
      district_id: "36",
      name_uz: "Beshtal",
      name_oz: "Áĺřňŕë",
      name_ru: "Áĺřňŕë",
    },
    {
      id: "71",
      district_id: "36",
      name_uz: "Beshtol",
      name_oz: "Áĺřňîë",
      name_ru: "Áĺřňîë",
    },
    {
      id: "72",
      district_id: "36",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "73",
      district_id: "36",
      name_uz: "Yorqishloq",
      name_oz: "¨đ?čřëî?",
      name_ru: "¨đ?čřëî?",
    },
    {
      id: "74",
      district_id: "36",
      name_uz: "Yorqishloq",
      name_oz: "¨đ?čřëî?",
      name_ru: "¨đ?čřëî?",
    },
    {
      id: "75",
      district_id: "36",
      name_uz: "Jalaquduq",
      name_oz: "Ćŕëŕ?óäó?",
      name_ru: "Ćŕëŕ?óäó?",
    },
    {
      id: "76",
      district_id: "36",
      name_uz: "Jalaquduq",
      name_oz: "Ćŕëŕ?óäó?",
      name_ru: "Ćŕëŕ?óäó?",
    },
    {
      id: "77",
      district_id: "36",
      name_uz: "Jalolquduq",
      name_oz: "Ćŕëîë?óäó?",
      name_ru: "Ćŕëîë?óäó?",
    },
    {
      id: "78",
      district_id: "36",
      name_uz: "Janubiy olamushuk",
      name_oz: "Ćŕíóáčé îëŕěóřóę",
      name_ru: "Ćŕíóáčé îëŕěóřóę",
    },
    {
      id: "79",
      district_id: "36",
      name_uz: "Ko‘kalam",
      name_oz: "Ę˘ęŕëŕě",
      name_ru: "Ę˘ęŕëŕě",
    },
    {
      id: "80",
      district_id: "36",
      name_uz: "Qatortol",
      name_oz: "?ŕňîđňîë",
      name_ru: "?ŕňîđňîë",
    },
    {
      id: "81",
      district_id: "36",
      name_uz: "Qo‘shtepa",
      name_oz: "?˘řňĺďŕ",
      name_ru: "?˘řňĺďŕ",
    },
    {
      id: "82",
      district_id: "36",
      name_uz: "Oyim",
      name_oz: "Îéčě",
      name_ru: "Îéčě",
    },
    {
      id: "83",
      district_id: "36",
      name_uz: "Teshiktosh",
      name_oz: "Ňĺřčęňîř",
      name_ru: "Ňĺřčęňîř",
    },
    {
      id: "84",
      district_id: "37",
      name_uz: "Gurkirov",
      name_oz: "Ăóđęčđîâ",
      name_ru: "Ăóđęčđîâ",
    },
    {
      id: "85",
      district_id: "37",
      name_uz: "Izboskan",
      name_oz: "Čçáîńęŕí",
      name_ru: "Čçáîńęŕí",
    },
    {
      id: "86",
      district_id: "37",
      name_uz: "Maygir",
      name_oz: "Ěŕéăčđ",
      name_ru: "Ěŕéăčđ",
    },
    {
      id: "87",
      district_id: "37",
      name_uz: "Maygir",
      name_oz: "Ěŕéăčđ",
      name_ru: "Ěŕéăčđ",
    },
    {
      id: "88",
      district_id: "37",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "89",
      district_id: "37",
      name_uz: "Paytug",
      name_oz: "Ďŕéňóă",
      name_ru: "Ďŕéňóă",
    },
    {
      id: "90",
      district_id: "37",
      name_uz: "To‘rtko‘l",
      name_oz: "Ň˘đňę˘ë",
      name_ru: "Ň˘đňę˘ë",
    },
    {
      id: "91",
      district_id: "37",
      name_uz: "Uzun ko‘cha",
      name_oz: "Óçóí ę˘÷ŕ",
      name_ru: "Óçóí ę˘÷ŕ",
    },
    {
      id: "92",
      district_id: "37",
      name_uz: "O‘rtoqlar",
      name_oz: "ˇđňî?ëŕđ",
      name_ru: "ˇđňî?ëŕđ",
    },
    {
      id: "93",
      district_id: "37",
      name_uz: "Shermatobod",
      name_oz: "Řĺđěŕňîáîä",
      name_ru: "Řĺđěŕňîáîä",
    },
    {
      id: "94",
      district_id: "37",
      name_uz: "Erkin",
      name_oz: "Ýđęčí",
      name_ru: "Ýđęčí",
    },
    {
      id: "95",
      district_id: "37",
      name_uz: "Yakkatut",
      name_oz: "ßęęŕňóň",
      name_ru: "ßęęŕňóň",
    },
    {
      id: "96",
      district_id: "37",
      name_uz: "Yangi qishloq",
      name_oz: "ßíăč ?čřëî?",
      name_ru: "ßíăč ?čřëî?",
    },
    {
      id: "97",
      district_id: "37",
      name_uz: "Yangizamon",
      name_oz: "ßíăčçŕěîí",
      name_ru: "ßíăčçŕěîí",
    },
    {
      id: "98",
      district_id: "39",
      name_uz: "Dardok",
      name_oz: "Äŕđäîę",
      name_ru: "Äŕđäîę",
    },
    {
      id: "99",
      district_id: "39",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "100",
      district_id: "39",
      name_uz: "Qo‘rg‘ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "?˘đ?îíňĺďŕ",
    },
    {
      id: "101",
      district_id: "39",
      name_uz: "Qo‘rg‘ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "?˘đ?îíňĺďŕ",
    },
    {
      id: "102",
      district_id: "39",
      name_uz: "Savay",
      name_oz: "Ńŕâŕé",
      name_ru: "Ńŕâŕé",
    },
    {
      id: "103",
      district_id: "39",
      name_uz: "Sultonobod",
      name_oz: "Ńóëňîíîáîä",
      name_ru: "Ńóëňîíîáîä",
    },
    {
      id: "104",
      district_id: "39",
      name_uz: "Sultonobod",
      name_oz: "Ńóëňîíîáîä",
      name_ru: "Ńóëňîíîáîä",
    },
    {
      id: "105",
      district_id: "39",
      name_uz: "Chimyon",
      name_oz: "×čě¸í",
      name_ru: "×čě¸í",
    },
    {
      id: "106",
      district_id: "40",
      name_uz: "Boboxuroson",
      name_oz: "Áîáîőóđîńîí",
      name_ru: "Áîáîőóđîńîí",
    },
    {
      id: "107",
      district_id: "40",
      name_uz: "Ko‘tarma",
      name_oz: "Ę˘ňŕđěŕ",
      name_ru: "Ę˘ňŕđěŕ",
    },
    {
      id: "108",
      district_id: "40",
      name_uz: "Ko‘tarma",
      name_oz: "Ę˘ňŕđěŕ",
      name_ru: "Ę˘ňŕđěŕ",
    },
    {
      id: "109",
      district_id: "40",
      name_uz: "Qorabog‘ish",
      name_oz: "?îđŕáî?čř",
      name_ru: "?îđŕáî?čř",
    },
    {
      id: "110",
      district_id: "40",
      name_uz: "Qorabog‘ish",
      name_oz: "?îđŕáî?čř",
      name_ru: "?îđŕáî?čř",
    },
    {
      id: "111",
      district_id: "40",
      name_uz: "Qoraqo‘rg‘on",
      name_oz: "?îđŕ?˘đ?îí",
      name_ru: "?îđŕ?˘đ?îí",
    },
    {
      id: "112",
      district_id: "40",
      name_uz: "Qoraqo‘rg‘on",
      name_oz: "?îđŕ?˘đ?îí",
      name_ru: "?îđŕ?˘đ?îí",
    },
    {
      id: "113",
      district_id: "40",
      name_uz: "Marxamat",
      name_oz: "Ěŕđőŕěŕň",
      name_ru: "Ěŕđőŕěŕň",
    },
    {
      id: "114",
      district_id: "40",
      name_uz: "Marxamat",
      name_oz: "Ěŕđőŕěŕň",
      name_ru: "Ěŕđőŕěŕň",
    },
    {
      id: "115",
      district_id: "40",
      name_uz: "Marxamat",
      name_oz: "Ěŕđőŕěŕň",
      name_ru: "Ěŕđőŕěŕň",
    },
    {
      id: "116",
      district_id: "40",
      name_uz: "Polvontosh",
      name_oz: "Ďîëâîíňîř",
      name_ru: "Ďîëâîíňîř",
    },
    {
      id: "117",
      district_id: "40",
      name_uz: "Rovot",
      name_oz: "Đîâîň",
      name_ru: "Đîâîň",
    },
    {
      id: "118",
      district_id: "40",
      name_uz: "O‘qchi",
      name_oz: "ˇ?÷č",
      name_ru: "ˇ?÷č",
    },
    {
      id: "119",
      district_id: "40",
      name_uz: "Xakka",
      name_oz: "Őŕęęŕ",
      name_ru: "Őŕęęŕ",
    },
    {
      id: "120",
      district_id: "40",
      name_uz: "Xo‘jaariq",
      name_oz: "Ő˘ćŕŕđč?",
      name_ru: "Ő˘ćŕŕđč?",
    },
    {
      id: "121",
      district_id: "40",
      name_uz: "Shukurmergan",
      name_oz: "Řóęóđěĺđăŕí",
      name_ru: "Řóęóđěĺđăŕí",
    },
    {
      id: "122",
      district_id: "41",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "123",
      district_id: "41",
      name_uz: "Dalvarzin",
      name_oz: "Äŕëâŕđçčí",
      name_ru: "Äŕëâŕđçčí",
    },
    {
      id: "124",
      district_id: "41",
      name_uz: "Jalabek",
      name_oz: "Ćŕëŕáĺę",
      name_ru: "Ćŕëŕáĺę",
    },
    {
      id: "125",
      district_id: "41",
      name_uz: "Jalabek",
      name_oz: "Ćŕëŕáĺę",
      name_ru: "Ćŕëŕáĺę",
    },
    {
      id: "126",
      district_id: "41",
      name_uz: "Ijtimoiyat",
      name_oz: "Čćňčěîč˙ň",
      name_ru: "Čćňčěîč˙ň",
    },
    {
      id: "127",
      district_id: "41",
      name_uz: "Kumakay",
      name_oz: "Ęóěŕęŕé",
      name_ru: "Ęóěŕęŕé",
    },
    {
      id: "128",
      district_id: "41",
      name_uz: "Kumakay",
      name_oz: "Ęóěŕęŕé",
      name_ru: "Ęóěŕęŕé",
    },
    {
      id: "129",
      district_id: "41",
      name_uz: "Qo‘shtepa",
      name_oz: "?˘řňĺďŕ",
      name_ru: "?˘řňĺďŕ",
    },
    {
      id: "130",
      district_id: "41",
      name_uz: "Qo‘shtepasaroy",
      name_oz: "?˘řňĺďŕńŕđîé",
      name_ru: "?˘řňĺďŕńŕđîé",
    },
    {
      id: "131",
      district_id: "41",
      name_uz: "Madaniy mehnat",
      name_oz: "Ěŕäŕíčé ěĺ?íŕň",
      name_ru: "Ěŕäŕíčé ěĺ?íŕň",
    },
    {
      id: "132",
      district_id: "41",
      name_uz: "Markaz",
      name_oz: "Ěŕđęŕç",
      name_ru: "Ěŕđęŕç",
    },
    {
      id: "133",
      district_id: "41",
      name_uz: "Maslahat",
      name_oz: "Ěŕńëŕ?ŕň",
      name_ru: "Ěŕńëŕ?ŕň",
    },
    {
      id: "134",
      district_id: "41",
      name_uz: "Maslahat",
      name_oz: "Ěŕńëŕ?ŕň",
      name_ru: "Ěŕńëŕ?ŕň",
    },
    {
      id: "135",
      district_id: "41",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "136",
      district_id: "41",
      name_uz: "Oltinko‘l",
      name_oz: "Îëňčíę˘ë",
      name_ru: "Îëňčíę˘ë",
    },
    {
      id: "137",
      district_id: "41",
      name_uz: "Oraziy",
      name_oz: "Îđŕçčé",
      name_ru: "Îđŕçčé",
    },
    {
      id: "138",
      district_id: "41",
      name_uz: "Oxunboboyev nomli",
      name_oz: "Îőóíáîáîĺâ íîěëč",
      name_ru: "Îőóíáîáîĺâ íîěëč",
    },
    {
      id: "139",
      district_id: "41",
      name_uz: "Suvyulduz",
      name_oz: "Ńóâţëäóç",
      name_ru: "Ńóâţëäóç",
    },
    {
      id: "140",
      district_id: "41",
      name_uz: "Xondibog‘i",
      name_oz: "Őîíäčáî?č",
      name_ru: "Őîíäčáî?č",
    },
    {
      id: "141",
      district_id: "42",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "142",
      district_id: "42",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "143",
      district_id: "42",
      name_uz: "Izboskan",
      name_oz: "Čçáîńęŕí",
      name_ru: "Čçáîńęŕí",
    },
    {
      id: "144",
      district_id: "42",
      name_uz: "Ittifoq",
      name_oz: "Čňňčôî?",
      name_ru: "Čňňčôî?",
    },
    {
      id: "145",
      district_id: "42",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "146",
      district_id: "42",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "147",
      district_id: "42",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "148",
      district_id: "42",
      name_uz: "Pushmon",
      name_oz: "Ďóřěîí",
      name_ru: "Ďóřěîí",
    },
    {
      id: "149",
      district_id: "42",
      name_uz: "Uyg‘ur",
      name_oz: "Óé?óđ",
      name_ru: "Óé?óđ",
    },
    {
      id: "150",
      district_id: "43",
      name_uz: "Mingbuloq",
      name_oz: "Ěčíăáóëî?",
      name_ru: "Ěčíăáóëî?",
    },
    {
      id: "151",
      district_id: "43",
      name_uz: "Mingchinor",
      name_oz: "Ěčíă÷číîđ",
      name_ru: "Ěčíă÷číîđ",
    },
    {
      id: "152",
      district_id: "43",
      name_uz: "Navoiy nomli",
      name_oz: "Íŕâîčé íîěëč",
      name_ru: "Íŕâîčé íîěëč",
    },
    {
      id: "153",
      district_id: "43",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "154",
      district_id: "43",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "155",
      district_id: "44",
      name_uz: "Xonobod",
      name_oz: "Őîíîáîä",
      name_ru: "Őîíîáîä",
    },
    {
      id: "156",
      district_id: "44",
      name_uz: "Xonobod",
      name_oz: "Őîíîáîä",
      name_ru: "Őîíîáîä",
    },
    {
      id: "157",
      district_id: "45",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "158",
      district_id: "45",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "159",
      district_id: "45",
      name_uz: "Dilkushod",
      name_oz: "Äčëęóřîä",
      name_ru: "Äčëęóřîä",
    },
    {
      id: "160",
      district_id: "45",
      name_uz: "Ko‘tarma",
      name_oz: "Ę˘ňŕđěŕ",
      name_ru: "Ę˘ňŕđěŕ",
    },
    {
      id: "161",
      district_id: "45",
      name_uz: "Manak",
      name_oz: "Ěŕíŕę",
      name_ru: "Ěŕíŕę",
    },
    {
      id: "162",
      district_id: "45",
      name_uz: "Manak",
      name_oz: "Ěŕíŕę",
      name_ru: "Ěŕíŕę",
    },
    {
      id: "163",
      district_id: "45",
      name_uz: "Oltin vodiy",
      name_oz: "Îëňčí âîäčé",
      name_ru: "Îëňčí âîäčé",
    },
    {
      id: "164",
      district_id: "45",
      name_uz: "Xidirsha",
      name_oz: "Őčäčđřŕ",
      name_ru: "Őčäčđřŕ",
    },
    {
      id: "165",
      district_id: "45",
      name_uz: "Xo‘jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "166",
      district_id: "45",
      name_uz: "Xo‘jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "167",
      district_id: "46",
      name_uz: "Abdubiy",
      name_oz: "Ŕáäóáčé",
      name_ru: "Ŕáäóáčé",
    },
    {
      id: "168",
      district_id: "46",
      name_uz: "Vaxim",
      name_oz: "Âŕőčě",
      name_ru: "Âŕőčě",
    },
    {
      id: "169",
      district_id: "46",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "170",
      district_id: "46",
      name_uz: "Nazarmaxram",
      name_oz: "Íŕçŕđěŕőđŕě",
      name_ru: "Íŕçŕđěŕőđŕě",
    },
    {
      id: "171",
      district_id: "46",
      name_uz: "Naynavo",
      name_oz: "Íŕéíŕâî",
      name_ru: "Íŕéíŕâî",
    },
    {
      id: "172",
      district_id: "46",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "173",
      district_id: "46",
      name_uz: "Segaza kum",
      name_oz: "Ńĺăŕçŕ ęóě",
      name_ru: "Ńĺăŕçŕ ęóě",
    },
    {
      id: "174",
      district_id: "46",
      name_uz: "Toshtepa",
      name_oz: "Ňîřňĺďŕ",
      name_ru: "Ňîřňĺďŕ",
    },
    {
      id: "175",
      district_id: "46",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "176",
      district_id: "46",
      name_uz: "O‘rta Shaxrixon",
      name_oz: "ˇđňŕ Řŕőđčőîí",
      name_ru: "ˇđňŕ Řŕőđčőîí",
    },
    {
      id: "177",
      district_id: "46",
      name_uz: "Xaqiqat",
      name_oz: "Őŕ?č?ŕň",
      name_ru: "Őŕ?č?ŕň",
    },
    {
      id: "178",
      district_id: "46",
      name_uz: "Cho‘ja",
      name_oz: "×˘ćŕ",
      name_ru: "×˘ćŕ",
    },
    {
      id: "179",
      district_id: "46",
      name_uz: "Cho‘ja",
      name_oz: "×˘ćŕ",
      name_ru: "×˘ćŕ",
    },
    {
      id: "180",
      district_id: "46",
      name_uz: "Shaxrixon",
      name_oz: "Řŕőđčőîí",
      name_ru: "Řŕőđčőîí",
    },
    {
      id: "181",
      district_id: "46",
      name_uz: "Yuqori Shaxrixon",
      name_oz: "Ţ?îđč Řŕőđčőîí",
      name_ru: "Ţ?îđč Řŕőđčőîí",
    },
    {
      id: "182",
      district_id: "46",
      name_uz: "Yangi yo‘l",
      name_oz: "ßíăč é˘ë",
      name_ru: "ßíăč é˘ë",
    },
    {
      id: "183",
      district_id: "48",
      name_uz: "Arabxona",
      name_oz: "Ŕđŕáőîíŕ",
      name_ru: "Ŕđŕáőîíŕ",
    },
    {
      id: "184",
      district_id: "48",
      name_uz: "Bog‘ikalon",
      name_oz: "Áî?čęŕëîí",
      name_ru: "Áî?čęŕëîí",
    },
    {
      id: "185",
      district_id: "48",
      name_uz: "Gala Osiyo",
      name_oz: "Ăŕëŕ Îńč¸",
      name_ru: "Ăŕëŕ Îńč¸",
    },
    {
      id: "186",
      district_id: "48",
      name_uz: "Gulshanobod",
      name_oz: "Ăóëřŕíîáîä",
      name_ru: "Ăóëřŕíîáîä",
    },
    {
      id: "187",
      district_id: "48",
      name_uz: "Dexcha",
      name_oz: "Äĺő÷ŕ",
      name_ru: "Äĺő÷ŕ",
    },
    {
      id: "188",
      district_id: "48",
      name_uz: "Istiqbol",
      name_oz: "Čńňč?áîë",
      name_ru: "Čńňč?áîë",
    },
    {
      id: "189",
      district_id: "48",
      name_uz: "Kunjiqal?a",
      name_oz: "Ęóíćč?ŕëúŕ",
      name_ru: "Ęóíćč?ŕëúŕ",
    },
    {
      id: "190",
      district_id: "48",
      name_uz: "Ko‘chko‘mar",
      name_oz: "Ę˘÷ę˘ěŕđ",
      name_ru: "Ę˘÷ę˘ěŕđ",
    },
    {
      id: "191",
      district_id: "48",
      name_uz: "Qavola Maxmud",
      name_oz: "?ŕâîëŕ Ěŕőěóä",
      name_ru: "?ŕâîëŕ Ěŕőěóä",
    },
    {
      id: "192",
      district_id: "48",
      name_uz: "Podshoyi",
      name_oz: "Ďîäřîéč",
      name_ru: "Ďîäřîéč",
    },
    {
      id: "193",
      district_id: "48",
      name_uz: "Rabotak",
      name_oz: "Đŕáîňŕę",
      name_ru: "Đŕáîňŕę",
    },
    {
      id: "194",
      district_id: "48",
      name_uz: "Rabotiqalmoq",
      name_oz: "Đŕáîňč?ŕëěî?",
      name_ru: "Đŕáîňč?ŕëěî?",
    },
    {
      id: "195",
      district_id: "48",
      name_uz: "Saxovat",
      name_oz: "Ńŕőîâŕň",
      name_ru: "Ńŕőîâŕň",
    },
    {
      id: "196",
      district_id: "48",
      name_uz: "Sohibkor",
      name_oz: "Ńî?čáęîđ",
      name_ru: "Ńî?čáęîđ",
    },
    {
      id: "197",
      district_id: "48",
      name_uz: "So‘fikorgar",
      name_oz: "Ń˘ôčęîđăŕđ",
      name_ru: "Ń˘ôčęîđăŕđ",
    },
    {
      id: "198",
      district_id: "48",
      name_uz: "O‘rta Novmetan",
      name_oz: "ˇđňŕ Íîâěĺňŕí",
      name_ru: "ˇđňŕ Íîâěĺňŕí",
    },
    {
      id: "199",
      district_id: "48",
      name_uz: "Xumini bolo",
      name_oz: "Őóěčíč áîëî",
      name_ru: "Őóěčíč áîëî",
    },
    {
      id: "200",
      district_id: "48",
      name_uz: "Shergiron",
      name_oz: "Řĺđăčđîí",
      name_ru: "Řĺđăčđîí",
    },
    {
      id: "201",
      district_id: "48",
      name_uz: "Shexoncha",
      name_oz: "Řĺőîí÷ŕ",
      name_ru: "Řĺőîí÷ŕ",
    },
    {
      id: "202",
      district_id: "48",
      name_uz: "Yangi Turmush",
      name_oz: "ßíăč Ňóđěóř",
      name_ru: "ßíăč Ňóđěóř",
    },
    {
      id: "203",
      district_id: "48",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "204",
      district_id: "49",
      name_uz: "Vobkent",
      name_oz: "Âîáęĺíň",
      name_ru: "Âîáęĺíň",
    },
    {
      id: "205",
      district_id: "49",
      name_uz: "Imomqazixon",
      name_oz: "Čěîě?ŕçčőîí",
      name_ru: "Čěîě?ŕçčőîí",
    },
    {
      id: "206",
      district_id: "49",
      name_uz: "Kosari",
      name_oz: "Ęîńŕđč",
      name_ru: "Ęîńŕđč",
    },
    {
      id: "207",
      district_id: "49",
      name_uz: "Kumushkent",
      name_oz: "Ęóěóřęĺíň",
      name_ru: "Ęóěóřęĺíň",
    },
    {
      id: "208",
      district_id: "49",
      name_uz: "Qipchoq",
      name_oz: "?čď÷î?",
      name_ru: "?čď÷î?",
    },
    {
      id: "209",
      district_id: "49",
      name_uz: "Qo‘ng‘irot",
      name_oz: "?˘í?čđîň",
      name_ru: "?˘í?čđîň",
    },
    {
      id: "210",
      district_id: "49",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "211",
      district_id: "49",
      name_uz: "Pirmast",
      name_oz: "Ďčđěŕńň",
      name_ru: "Ďčđěŕńň",
    },
    {
      id: "212",
      district_id: "49",
      name_uz: "Roxkent",
      name_oz: "Đîőęĺíň",
      name_ru: "Đîőęĺíň",
    },
    {
      id: "213",
      district_id: "49",
      name_uz: "Xayrobotcha",
      name_oz: "Őŕéđîáîň÷ŕ",
      name_ru: "Őŕéđîáîň÷ŕ",
    },
    {
      id: "214",
      district_id: "49",
      name_uz: "Xayrxush",
      name_oz: "Őŕéđőóř",
      name_ru: "Őŕéđőóř",
    },
    {
      id: "215",
      district_id: "49",
      name_uz: "Xalach",
      name_oz: "Őŕëŕ÷",
      name_ru: "Őŕëŕ÷",
    },
    {
      id: "216",
      district_id: "49",
      name_uz: "Shirin",
      name_oz: "Řčđčí",
      name_ru: "Řčđčí",
    },
    {
      id: "217",
      district_id: "49",
      name_uz: "Exson",
      name_oz: "Ýőńîí",
      name_ru: "Ýőńîí",
    },
    {
      id: "218",
      district_id: "49",
      name_uz: "Yangikent",
      name_oz: "ßíăčęĺíň",
      name_ru: "ßíăčęĺíň",
    },
    {
      id: "219",
      district_id: "50",
      name_uz: "Abadi",
      name_oz: "Ŕáŕäč",
      name_ru: "Ŕáŕäč",
    },
    {
      id: "220",
      district_id: "50",
      name_uz: "Armechan",
      name_oz: "Ŕđěĺ÷ŕí",
      name_ru: "Ŕđěĺ÷ŕí",
    },
    {
      id: "221",
      district_id: "50",
      name_uz: "Beshtuvo",
      name_oz: "Áĺřňóâî",
      name_ru: "Áĺřňóâî",
    },
    {
      id: "222",
      district_id: "50",
      name_uz: "Buktaroy",
      name_oz: "Áóęňŕđîé",
      name_ru: "Áóęňŕđîé",
    },
    {
      id: "223",
      district_id: "50",
      name_uz: "G.Yunusov nomli",
      name_oz: "Ă.Ţíóńîâ íîěëč",
      name_ru: "Ă.Ţíóńîâ íîěëč",
    },
    {
      id: "224",
      district_id: "50",
      name_uz: "Gajdumak",
      name_oz: "Ăŕćäóěŕę",
      name_ru: "Ăŕćäóěŕę",
    },
    {
      id: "225",
      district_id: "50",
      name_uz: "G‘ijduvon",
      name_oz: "?čćäóâîí",
      name_ru: "?čćäóâîí",
    },
    {
      id: "226",
      district_id: "50",
      name_uz: "G‘ovshun",
      name_oz: "?îâřóí",
      name_ru: "?îâřóí",
    },
    {
      id: "227",
      district_id: "50",
      name_uz: "Dodarak",
      name_oz: "Äîäŕđŕę",
      name_ru: "Äîäŕđŕę",
    },
    {
      id: "228",
      district_id: "50",
      name_uz: "Jovgari",
      name_oz: "Ćîâăŕđč",
      name_ru: "Ćîâăŕđč",
    },
    {
      id: "229",
      district_id: "50",
      name_uz: "Zarangari",
      name_oz: "Çŕđŕíăŕđč",
      name_ru: "Çŕđŕíăŕđč",
    },
    {
      id: "230",
      district_id: "50",
      name_uz: "Ko‘kcha",
      name_oz: "Ę˘ę÷ŕ",
      name_ru: "Ę˘ę÷ŕ",
    },
    {
      id: "231",
      district_id: "50",
      name_uz: "Ko‘lijabbor",
      name_oz: "Ę˘ëčćŕááîđ",
      name_ru: "Ę˘ëčćŕááîđ",
    },
    {
      id: "232",
      district_id: "50",
      name_uz: "Qaraxoni",
      name_oz: "?ŕđŕőîíč",
      name_ru: "?ŕđŕőîíč",
    },
    {
      id: "233",
      district_id: "50",
      name_uz: "Mazragan",
      name_oz: "Ěŕçđŕăŕí",
      name_ru: "Ěŕçđŕăŕí",
    },
    {
      id: "234",
      district_id: "50",
      name_uz: "Namatgaron",
      name_oz: "Íŕěŕňăŕđîí",
      name_ru: "Íŕěŕňăŕđîí",
    },
    {
      id: "235",
      district_id: "50",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "236",
      district_id: "50",
      name_uz: "Pozagari",
      name_oz: "Ďîçŕăŕđč",
      name_ru: "Ďîçŕăŕđč",
    },
    {
      id: "237",
      district_id: "50",
      name_uz: "Sarvari",
      name_oz: "Ńŕđâŕđč",
      name_ru: "Ńŕđâŕđč",
    },
    {
      id: "238",
      district_id: "50",
      name_uz: "Sarmijon",
      name_oz: "Ńŕđěčćîí",
      name_ru: "Ńŕđěčćîí",
    },
    {
      id: "239",
      district_id: "50",
      name_uz: "Soktari",
      name_oz: "Ńîęňŕđč",
      name_ru: "Ńîęňŕđč",
    },
    {
      id: "240",
      district_id: "50",
      name_uz: "O‘zanon",
      name_oz: "ˇçŕíîí",
      name_ru: "ˇçŕíîí",
    },
    {
      id: "241",
      district_id: "50",
      name_uz: "Ulfatbibi",
      name_oz: "Óëôŕňáčáč",
      name_ru: "Óëôŕňáčáč",
    },
    {
      id: "242",
      district_id: "50",
      name_uz: "Firishkent",
      name_oz: "Ôčđčřęĺíň",
      name_ru: "Ôčđčřęĺíň",
    },
    {
      id: "243",
      district_id: "50",
      name_uz: "Xatcha",
      name_oz: "Őŕň÷ŕ",
      name_ru: "Őŕň÷ŕ",
    },
    {
      id: "244",
      district_id: "50",
      name_uz: "Chag‘dari",
      name_oz: "×ŕ?äŕđč",
      name_ru: "×ŕ?äŕđč",
    },
    {
      id: "245",
      district_id: "50",
      name_uz: "Yuqori Qumoq",
      name_oz: "Ţ?îđč ?óěî?",
      name_ru: "Ţ?îđč ?óěî?",
    },
    {
      id: "246",
      district_id: "50",
      name_uz: "Yuqori Rostgo‘y",
      name_oz: "Ţ?îđč Đîńňă˘é",
      name_ru: "Ţ?îđč Đîńňă˘é",
    },
    {
      id: "247",
      district_id: "51",
      name_uz: "Aleli",
      name_oz: "Ŕëĺëč",
      name_ru: "Ŕëĺëč",
    },
    {
      id: "248",
      district_id: "51",
      name_uz: "Dalmun",
      name_oz: "Äŕëěóí",
      name_ru: "Äŕëěóí",
    },
    {
      id: "249",
      district_id: "51",
      name_uz: "Dalmun",
      name_oz: "Äŕëěóí",
      name_ru: "Äŕëěóí",
    },
    {
      id: "250",
      district_id: "51",
      name_uz: "Jondor",
      name_oz: "Ćîíäîđ",
      name_ru: "Ćîíäîđ",
    },
    {
      id: "251",
      district_id: "51",
      name_uz: "Zarafshon",
      name_oz: "Çŕđŕôřîí",
      name_ru: "Çŕđŕôřîí",
    },
    {
      id: "252",
      district_id: "51",
      name_uz: "Ko‘liyon",
      name_oz: "Ę˘ëč¸í",
      name_ru: "Ę˘ëč¸í",
    },
    {
      id: "253",
      district_id: "51",
      name_uz: "Qaroli",
      name_oz: "?ŕđîëč",
      name_ru: "?ŕđîëč",
    },
    {
      id: "254",
      district_id: "51",
      name_uz: "Lolo",
      name_oz: "Ëîëî",
      name_ru: "Ëîëî",
    },
    {
      id: "255",
      district_id: "51",
      name_uz: "Mirzayon",
      name_oz: "Ěčđçŕ¸í",
      name_ru: "Ěčđçŕ¸í",
    },
    {
      id: "256",
      district_id: "51",
      name_uz: "Mustaqillik",
      name_oz: "Ěóńňŕ?čëëčę",
      name_ru: "Ěóńňŕ?čëëčę",
    },
    {
      id: "257",
      district_id: "51",
      name_uz: "Oxshix",
      name_oz: "Îőřčő",
      name_ru: "Îőřčő",
    },
    {
      id: "258",
      district_id: "51",
      name_uz: "Paxlavon",
      name_oz: "Ďŕőëŕâîí",
      name_ru: "Ďŕőëŕâîí",
    },
    {
      id: "259",
      district_id: "51",
      name_uz: "Po‘loti",
      name_oz: "Ď˘ëîňč",
      name_ru: "Ď˘ëîňč",
    },
    {
      id: "260",
      district_id: "51",
      name_uz: "Romish",
      name_oz: "Đîěčř",
      name_ru: "Đîěčř",
    },
    {
      id: "261",
      district_id: "51",
      name_uz: "Samonchuq",
      name_oz: "Ńŕěîí÷ó?",
      name_ru: "Ńŕěîí÷ó?",
    },
    {
      id: "262",
      district_id: "51",
      name_uz: "Samonchuq",
      name_oz: "Ńŕěîí÷ó?",
      name_ru: "Ńŕěîí÷ó?",
    },
    {
      id: "263",
      district_id: "51",
      name_uz: "Tobagar",
      name_oz: "Ňîáŕăŕđ",
      name_ru: "Ňîáŕăŕđ",
    },
    {
      id: "264",
      district_id: "51",
      name_uz: "Ushot",
      name_oz: "Óřîň",
      name_ru: "Óřîň",
    },
    {
      id: "265",
      district_id: "51",
      name_uz: "Xazorman",
      name_oz: "Őŕçîđěŕí",
      name_ru: "Őŕçîđěŕí",
    },
    {
      id: "266",
      district_id: "51",
      name_uz: "Xumdonak",
      name_oz: "Őóěäîíŕę",
      name_ru: "Őóěäîíŕę",
    },
    {
      id: "267",
      district_id: "51",
      name_uz: "Xumini bolo",
      name_oz: "Őóěčíč áîëî",
      name_ru: "Őóěčíč áîëî",
    },
    {
      id: "268",
      district_id: "52",
      name_uz: "Chorzona",
      name_oz: "×îđçîíŕ",
      name_ru: "×îđçîíŕ",
    },
    {
      id: "269",
      district_id: "52",
      name_uz: "B.Naqshband",
      name_oz: "Á.Íŕ?řáŕíä",
      name_ru: "Á.Íŕ?řáŕíä",
    },
    {
      id: "270",
      district_id: "52",
      name_uz: "Beklar",
      name_oz: "Áĺęëŕđ",
      name_ru: "Áĺęëŕđ",
    },
    {
      id: "271",
      district_id: "52",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "272",
      district_id: "52",
      name_uz: "Kogon",
      name_oz: "Ęîăîí",
      name_ru: "Ęîăîí",
    },
    {
      id: "273",
      district_id: "52",
      name_uz: "Niyozhoji",
      name_oz: "Íč¸ç?îćč",
      name_ru: "Íč¸ç?îćč",
    },
    {
      id: "274",
      district_id: "52",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "275",
      district_id: "52",
      name_uz: "Sarayon",
      name_oz: "Ńŕđŕ¸í",
      name_ru: "Ńŕđŕ¸í",
    },
    {
      id: "276",
      district_id: "52",
      name_uz: "Sarayonobod",
      name_oz: "Ńŕđŕ¸íîáîä",
      name_ru: "Ńŕđŕ¸íîáîä",
    },
    {
      id: "277",
      district_id: "52",
      name_uz: "Tutikunda",
      name_oz: "Ňóňčęóíäŕ",
      name_ru: "Ňóňčęóíäŕ",
    },
    {
      id: "278",
      district_id: "52",
      name_uz: "Xo‘ja Yakshaba",
      name_oz: "Ő˘ćŕ ßęřŕáŕ",
      name_ru: "Ő˘ćŕ ßęřŕáŕ",
    },
    {
      id: "279",
      district_id: "52",
      name_uz: "Yangi xayot",
      name_oz: "ßíăč őŕ¸ň",
      name_ru: "ßíăč őŕ¸ň",
    },
    {
      id: "280",
      district_id: "54",
      name_uz: "Bandboshi",
      name_oz: "Áŕíäáîřč",
      name_ru: "Áŕíäáîřč",
    },
    {
      id: "281",
      district_id: "54",
      name_uz: "Bandboshi",
      name_oz: "Áŕíäáîřč",
      name_ru: "Áŕíäáîřč",
    },
    {
      id: "282",
      district_id: "54",
      name_uz: "Darg‘abog‘i",
      name_oz: "Äŕđ?ŕáî?č",
      name_ru: "Äŕđ?ŕáî?č",
    },
    {
      id: "283",
      district_id: "54",
      name_uz: "Darg‘ali",
      name_oz: "Äŕđ?ŕëč",
      name_ru: "Äŕđ?ŕëč",
    },
    {
      id: "284",
      district_id: "54",
      name_uz: "Jig‘achi",
      name_oz: "Ćč?ŕ÷č",
      name_ru: "Ćč?ŕ÷č",
    },
    {
      id: "285",
      district_id: "54",
      name_uz: "Jig‘achi",
      name_oz: "Ćč?ŕ÷č",
      name_ru: "Ćč?ŕ÷č",
    },
    {
      id: "286",
      district_id: "54",
      name_uz: "Ziyorat",
      name_oz: "Çč¸đŕň",
      name_ru: "Çč¸đŕň",
    },
    {
      id: "287",
      district_id: "54",
      name_uz: "Kulonchi",
      name_oz: "Ęóëîí÷č",
      name_ru: "Ęóëîí÷č",
    },
    {
      id: "288",
      district_id: "54",
      name_uz: "Qozon",
      name_oz: "?îçîí",
      name_ru: "?îçîí",
    },
    {
      id: "289",
      district_id: "54",
      name_uz: "Qorako‘l",
      name_oz: "?îđŕę˘ë",
      name_ru: "?îđŕę˘ë",
    },
    {
      id: "290",
      district_id: "54",
      name_uz: "Qorako‘l",
      name_oz: "?îđŕę˘ë",
      name_ru: "?îđŕę˘ë",
    },
    {
      id: "291",
      district_id: "54",
      name_uz: "Qoraun",
      name_oz: "?îđŕóí",
      name_ru: "?îđŕóí",
    },
    {
      id: "292",
      district_id: "54",
      name_uz: "Qorahoji",
      name_oz: "?îđŕ?îćč",
      name_ru: "?îđŕ?îćč",
    },
    {
      id: "293",
      district_id: "54",
      name_uz: "Qorahoji",
      name_oz: "?îđŕ?îćč",
      name_ru: "?îđŕ?îćč",
    },
    {
      id: "294",
      district_id: "54",
      name_uz: "Quvvacha",
      name_oz: "?óââŕ÷ŕ",
      name_ru: "?óââŕ÷ŕ",
    },
    {
      id: "295",
      district_id: "54",
      name_uz: "Quvvacha",
      name_oz: "?óââŕ÷ŕ",
      name_ru: "?óââŕ÷ŕ",
    },
    {
      id: "296",
      district_id: "54",
      name_uz: "Quyi Yangibozor",
      name_oz: "?óéč ßíăčáîçîđ",
      name_ru: "?óéč ßíăčáîçîđ",
    },
    {
      id: "297",
      district_id: "54",
      name_uz: "Mallaishayx",
      name_oz: "Ěŕëëŕčřŕéő",
      name_ru: "Ěŕëëŕčřŕéő",
    },
    {
      id: "298",
      district_id: "54",
      name_uz: "Mirzaqal?a",
      name_oz: "Ěčđçŕ?ŕëúŕ",
      name_ru: "Ěčđçŕ?ŕëúŕ",
    },
    {
      id: "299",
      district_id: "54",
      name_uz: "Sayyod",
      name_oz: "Ńŕé¸ä",
      name_ru: "Ńŕé¸ä",
    },
    {
      id: "300",
      district_id: "54",
      name_uz: "Sayyot",
      name_oz: "Ńŕé¸ň",
      name_ru: "Ńŕé¸ň",
    },
    {
      id: "301",
      district_id: "54",
      name_uz: "Solur",
      name_oz: "Ńîëóđ",
      name_ru: "Ńîëóđ",
    },
    {
      id: "302",
      district_id: "54",
      name_uz: "Chandir",
      name_oz: "×ŕíäčđ",
      name_ru: "×ŕíäčđ",
    },
    {
      id: "303",
      district_id: "54",
      name_uz: "Chandirobod",
      name_oz: "×ŕíäčđîáîä",
      name_ru: "×ŕíäčđîáîä",
    },
    {
      id: "304",
      district_id: "54",
      name_uz: "Chovli",
      name_oz: "×îâëč",
      name_ru: "×îâëč",
    },
    {
      id: "305",
      district_id: "54",
      name_uz: "Sho‘rabot",
      name_oz: "Ř˘đŕáîň",
      name_ru: "Ř˘đŕáîň",
    },
    {
      id: "306",
      district_id: "54",
      name_uz: "Sho‘rrabot",
      name_oz: "Ř˘đđŕáîň",
      name_ru: "Ř˘đđŕáîň",
    },
    {
      id: "307",
      district_id: "54",
      name_uz: "Yakka A?lam",
      name_oz: "ßęęŕ Ŕúëŕě",
      name_ru: "ßęęŕ Ŕúëŕě",
    },
    {
      id: "308",
      district_id: "54",
      name_uz: "Yangiqal?a",
      name_oz: "ßíăč?ŕëúŕ",
      name_ru: "ßíăč?ŕëúŕ",
    },
    {
      id: "309",
      district_id: "55",
      name_uz: "Buzachi",
      name_oz: "Áóçŕ÷č",
      name_ru: "Áóçŕ÷č",
    },
    {
      id: "310",
      district_id: "55",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "311",
      district_id: "55",
      name_uz: "Jarqoq",
      name_oz: "Ćŕđ?î?",
      name_ru: "Ćŕđ?î?",
    },
    {
      id: "312",
      district_id: "55",
      name_uz: "Qorovulbozor",
      name_oz: "?îđîâóëáîçîđ",
      name_ru: "?îđîâóëáîçîđ",
    },
    {
      id: "313",
      district_id: "55",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "314",
      district_id: "56",
      name_uz: "Bahoriston",
      name_oz: "Áŕ?îđčńňîí",
      name_ru: "Áŕ?îđčńňîí",
    },
    {
      id: "315",
      district_id: "56",
      name_uz: "Bo‘ribek Chandir",
      name_oz: "Á˘đčáĺę ×ŕíäčđ",
      name_ru: "Á˘đčáĺę ×ŕíäčđ",
    },
    {
      id: "316",
      district_id: "56",
      name_uz: "Ganchi Chandir",
      name_oz: "Ăŕí÷č ×ŕíäčđ",
      name_ru: "Ăŕí÷č ×ŕíäčđ",
    },
    {
      id: "317",
      district_id: "56",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "318",
      district_id: "56",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "319",
      district_id: "56",
      name_uz: "Jayxunobod",
      name_oz: "Ćŕéőóíîáîä",
      name_ru: "Ćŕéőóíîáîä",
    },
    {
      id: "320",
      district_id: "56",
      name_uz: "Jumabozor",
      name_oz: "Ćóěŕáîçîđ",
      name_ru: "Ćóěŕáîçîđ",
    },
    {
      id: "321",
      district_id: "56",
      name_uz: "Kesakli",
      name_oz: "Ęĺńŕęëč",
      name_ru: "Ęĺńŕęëč",
    },
    {
      id: "322",
      district_id: "56",
      name_uz: "Qirlishon",
      name_oz: "?čđëčřîí",
      name_ru: "?čđëčřîí",
    },
    {
      id: "323",
      district_id: "56",
      name_uz: "Qirtay",
      name_oz: "?čđňŕé",
      name_ru: "?čđňŕé",
    },
    {
      id: "324",
      district_id: "56",
      name_uz: "Olot",
      name_oz: "Îëîň",
      name_ru: "Îëîň",
    },
    {
      id: "325",
      district_id: "56",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "326",
      district_id: "56",
      name_uz: "Soyin-qorovul",
      name_oz: "Ńîéčí-?îđîâóë",
      name_ru: "Ńîéčí-?îđîâóë",
    },
    {
      id: "327",
      district_id: "56",
      name_uz: "Sola qorovul",
      name_oz: "Ńîëŕ ?îđîâóë",
      name_ru: "Ńîëŕ ?îđîâóë",
    },
    {
      id: "328",
      district_id: "56",
      name_uz: "Talqon-sayyot",
      name_oz: "Ňŕë?îí-ńŕé¸ň",
      name_ru: "Ňŕë?îí-ńŕé¸ň",
    },
    {
      id: "329",
      district_id: "56",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "330",
      district_id: "56",
      name_uz: "Chandir",
      name_oz: "×ŕíäčđ",
      name_ru: "×ŕíäčđ",
    },
    {
      id: "331",
      district_id: "56",
      name_uz: "Chovdur",
      name_oz: "×îâäóđ",
      name_ru: "×îâäóđ",
    },
    {
      id: "332",
      district_id: "56",
      name_uz: "Chorbog‘",
      name_oz: "×îđáî?",
      name_ru: "×îđáî?",
    },
    {
      id: "333",
      district_id: "57",
      name_uz: "Bog‘imuso",
      name_oz: "Áî?čěóńî",
      name_ru: "Áî?čěóńî",
    },
    {
      id: "334",
      district_id: "57",
      name_uz: "Varaxsho",
      name_oz: "Âŕđŕőřî",
      name_ru: "Âŕđŕőřî",
    },
    {
      id: "335",
      district_id: "57",
      name_uz: "Jonkeldi",
      name_oz: "Ćîíęĺëäč",
      name_ru: "Ćîíęĺëäč",
    },
    {
      id: "336",
      district_id: "57",
      name_uz: "Zandani",
      name_oz: "Çŕíäŕíč",
      name_ru: "Çŕíäŕíč",
    },
    {
      id: "337",
      district_id: "57",
      name_uz: "Ibn Sino nomli",
      name_oz: "Čáí Ńčíî íîěëč",
      name_ru: "Čáí Ńčíî íîěëč",
    },
    {
      id: "338",
      district_id: "57",
      name_uz: "Qal?ai Mirishkor",
      name_oz: "?ŕëúŕč Ěčđčřęîđ",
      name_ru: "?ŕëúŕč Ěčđčřęîđ",
    },
    {
      id: "339",
      district_id: "57",
      name_uz: "Qoraqalpoq",
      name_oz: "?îđŕ?ŕëďî?",
      name_ru: "?îđŕ?ŕëďî?",
    },
    {
      id: "340",
      district_id: "57",
      name_uz: "Mahallai-Mirishkor",
      name_oz: "Ěŕ?ŕëëŕč-Ěčđčřęîđ",
      name_ru: "Ěŕ?ŕëëŕč-Ěčđčřęîđ",
    },
    {
      id: "341",
      district_id: "57",
      name_uz: "Peshku",
      name_oz: "Ďĺřęó",
      name_ru: "Ďĺřęó",
    },
    {
      id: "342",
      district_id: "57",
      name_uz: "Peshku",
      name_oz: "Ďĺřęó",
      name_ru: "Ďĺřęó",
    },
    {
      id: "343",
      district_id: "57",
      name_uz: "Chibog‘oni",
      name_oz: "×čáî?îíč",
      name_ru: "×čáî?îíč",
    },
    {
      id: "344",
      district_id: "57",
      name_uz: "Shavgon",
      name_oz: "Řŕâăîí",
      name_ru: "Řŕâăîí",
    },
    {
      id: "345",
      district_id: "57",
      name_uz: "Yangibozor",
      name_oz: "ßíăčáîçîđ",
      name_ru: "ßíăčáîçîđ",
    },
    {
      id: "346",
      district_id: "57",
      name_uz: "Yangibozor",
      name_oz: "ßíăčáîçîđ",
      name_ru: "ßíăčáîçîđ",
    },
    {
      id: "347",
      district_id: "58",
      name_uz: "Bog‘iturkon",
      name_oz: "Áî?čňóđęîí",
      name_ru: "Áî?čňóđęîí",
    },
    {
      id: "348",
      district_id: "58",
      name_uz: "Gazli",
      name_oz: "Ăŕçëč",
      name_ru: "Ăŕçëč",
    },
    {
      id: "349",
      district_id: "58",
      name_uz: "Qizil Ravot",
      name_oz: "?čçčë Đŕâîň",
      name_ru: "?čçčë Đŕâîň",
    },
    {
      id: "350",
      district_id: "58",
      name_uz: "Qoqishtuvon",
      name_oz: "?î?čřňóâîí",
      name_ru: "?î?čřňóâîí",
    },
    {
      id: "351",
      district_id: "58",
      name_uz: "Qo‘rg‘on",
      name_oz: "?˘đ?îí",
      name_ru: "?˘đ?îí",
    },
    {
      id: "352",
      district_id: "58",
      name_uz: "Romitan",
      name_oz: "Đîěčňŕí",
      name_ru: "Đîěčňŕí",
    },
    {
      id: "353",
      district_id: "58",
      name_uz: "Romitan",
      name_oz: "Đîěčňŕí",
      name_ru: "Đîěčňŕí",
    },
    {
      id: "354",
      district_id: "58",
      name_uz: "Xosa",
      name_oz: "Őîńŕ",
      name_ru: "Őîńŕ",
    },
    {
      id: "355",
      district_id: "58",
      name_uz: "Chelong‘u",
      name_oz: "×ĺëîí?ó",
      name_ru: "×ĺëîí?ó",
    },
    {
      id: "356",
      district_id: "58",
      name_uz: "Sho‘rcha",
      name_oz: "Ř˘đ÷ŕ",
      name_ru: "Ř˘đ÷ŕ",
    },
    {
      id: "357",
      district_id: "58",
      name_uz: "Yuqori G‘azberon",
      name_oz: "Ţ?îđč ?ŕçáĺđîí",
      name_ru: "Ţ?îđč ?ŕçáĺđîí",
    },
    {
      id: "358",
      district_id: "59",
      name_uz: "Bog‘iafzal",
      name_oz: "Áî?čŕôçŕë",
      name_ru: "Áî?čŕôçŕë",
    },
    {
      id: "359",
      district_id: "59",
      name_uz: "Vargonze",
      name_oz: "Âŕđăîíçĺ",
      name_ru: "Âŕđăîíçĺ",
    },
    {
      id: "360",
      district_id: "59",
      name_uz: "G‘ulomte",
      name_oz: "?óëîěňĺ",
      name_ru: "?óëîěňĺ",
    },
    {
      id: "361",
      district_id: "59",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "362",
      district_id: "59",
      name_uz: "Do‘rmon",
      name_oz: "Ä˘đěîí",
      name_ru: "Ä˘đěîí",
    },
    {
      id: "363",
      district_id: "59",
      name_uz: "Jo‘ynav",
      name_oz: "Ć˘éíŕâ",
      name_ru: "Ć˘éíŕâ",
    },
    {
      id: "364",
      district_id: "59",
      name_uz: "Jo‘yrabot",
      name_oz: "Ć˘éđŕáîň",
      name_ru: "Ć˘éđŕáîň",
    },
    {
      id: "365",
      district_id: "59",
      name_uz: "Jo‘yrabot",
      name_oz: "Ć˘éđŕáîň",
      name_ru: "Ć˘éđŕáîň",
    },
    {
      id: "366",
      district_id: "59",
      name_uz: "Iskogare",
      name_oz: "Čńęîăŕđĺ",
      name_ru: "Čńęîăŕđĺ",
    },
    {
      id: "367",
      district_id: "59",
      name_uz: "Iskogare",
      name_oz: "Čńęîăŕđĺ",
      name_ru: "Čńęîăŕđĺ",
    },
    {
      id: "368",
      district_id: "59",
      name_uz: "Quyi Chuqurak",
      name_oz: "?óéč ×ó?óđŕę",
      name_ru: "?óéč ×ó?óđŕę",
    },
    {
      id: "369",
      district_id: "59",
      name_uz: "Mazlaxon chandir",
      name_oz: "Ěŕçëŕőîí ÷ŕíäčđ",
      name_ru: "Ěŕçëŕőîí ÷ŕíäčđ",
    },
    {
      id: "370",
      district_id: "59",
      name_uz: "Mirzoqul",
      name_oz: "Ěčđçî?óë",
      name_ru: "Ěčđçî?óë",
    },
    {
      id: "371",
      district_id: "59",
      name_uz: "Savrak",
      name_oz: "Ńŕâđŕę",
      name_ru: "Ńŕâđŕę",
    },
    {
      id: "372",
      district_id: "59",
      name_uz: "Sulton Jo‘ra",
      name_oz: "Ńóëňîí Ć˘đŕ",
      name_ru: "Ńóëňîí Ć˘đŕ",
    },
    {
      id: "373",
      district_id: "59",
      name_uz: "Talisafed",
      name_oz: "Ňŕëčńŕôĺä",
      name_ru: "Ňŕëčńŕôĺä",
    },
    {
      id: "374",
      district_id: "59",
      name_uz: "Tezguzar",
      name_oz: "Ňĺçăóçŕđ",
      name_ru: "Ňĺçăóçŕđ",
    },
    {
      id: "375",
      district_id: "59",
      name_uz: "Undare",
      name_oz: "Óíäŕđĺ",
      name_ru: "Óíäŕđĺ",
    },
    {
      id: "376",
      district_id: "59",
      name_uz: "Chandir",
      name_oz: "×ŕíäčđ",
      name_ru: "×ŕíäčđ",
    },
    {
      id: "377",
      district_id: "59",
      name_uz: "Sh.Hamroyev nomli",
      name_oz: "Ř.?ŕěđîĺâ íîěëč",
      name_ru: "Ř.?ŕěđîĺâ íîěëč",
    },
    {
      id: "378",
      district_id: "59",
      name_uz: "Shofirkon",
      name_oz: "Řîôčđęîí",
      name_ru: "Řîôčđęîí",
    },
    {
      id: "379",
      district_id: "60",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "380",
      district_id: "60",
      name_uz: "Gulbahor",
      name_oz: "Ăóëáŕ?îđ",
      name_ru: "Ăóëáŕ?îđ",
    },
    {
      id: "381",
      district_id: "60",
      name_uz: "G‘oliblar",
      name_oz: "?îëčáëŕđ",
      name_ru: "?îëčáëŕđ",
    },
    {
      id: "382",
      district_id: "60",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "383",
      district_id: "60",
      name_uz: "Zarafshon",
      name_oz: "Çŕđŕôřîí",
      name_ru: "Çŕđŕôřîí",
    },
    {
      id: "384",
      district_id: "60",
      name_uz: "Oltin vodiy",
      name_oz: "Îëňčí âîäčé",
      name_ru: "Îëňčí âîäčé",
    },
    {
      id: "385",
      district_id: "60",
      name_uz: "Cho‘lquvar",
      name_oz: "×˘ë?óâŕđ",
      name_ru: "×˘ë?óâŕđ",
    },
    {
      id: "386",
      district_id: "60",
      name_uz: "Yangibo‘ston",
      name_oz: "ßíăčá˘ńňîí",
      name_ru: "ßíăčá˘ńňîí",
    },
    {
      id: "387",
      district_id: "61",
      name_uz: "Alamli",
      name_oz: "Ŕëŕěëč",
      name_ru: "Ŕëŕěëč",
    },
    {
      id: "388",
      district_id: "61",
      name_uz: "Barlos",
      name_oz: "Áŕđëîń",
      name_ru: "Áŕđëîń",
    },
    {
      id: "389",
      district_id: "61",
      name_uz: "Baxmal",
      name_oz: "Áŕőěŕë",
      name_ru: "Áŕőěŕë",
    },
    {
      id: "390",
      district_id: "61",
      name_uz: "Baxmal",
      name_oz: "Áŕőěŕë",
      name_ru: "Áŕőěŕë",
    },
    {
      id: "391",
      district_id: "61",
      name_uz: "Bog‘ishamol",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Áî?čřŕěîë",
    },
    {
      id: "392",
      district_id: "61",
      name_uz: "Gulbuloq",
      name_oz: "Ăóëáóëî?",
      name_ru: "Ăóëáóëî?",
    },
    {
      id: "393",
      district_id: "61",
      name_uz: "Mo‘g‘ol",
      name_oz: "Ě˘?îë",
      name_ru: "Ě˘?îë",
    },
    {
      id: "394",
      district_id: "61",
      name_uz: "Mo‘g‘ol",
      name_oz: "Ě˘?îë",
      name_ru: "Ě˘?îë",
    },
    {
      id: "395",
      district_id: "61",
      name_uz: "Novqa",
      name_oz: "Íîâ?ŕ",
      name_ru: "Íîâ?ŕ",
    },
    {
      id: "396",
      district_id: "61",
      name_uz: "Oyqor",
      name_oz: "Îé?îđ",
      name_ru: "Îé?îđ",
    },
    {
      id: "397",
      district_id: "61",
      name_uz: "Oqtosh",
      name_oz: "Î?ňîř",
      name_ru: "Î?ňîř",
    },
    {
      id: "398",
      district_id: "61",
      name_uz: "Oqtosh",
      name_oz: "Î?ňîř",
      name_ru: "Î?ňîř",
    },
    {
      id: "399",
      district_id: "61",
      name_uz: "Sangzor",
      name_oz: "Ńŕíăçîđ",
      name_ru: "Ńŕíăçîđ",
    },
    {
      id: "400",
      district_id: "61",
      name_uz: "Tongotar",
      name_oz: "Ňîíăîňŕđ",
      name_ru: "Ňîíăîňŕđ",
    },
    {
      id: "401",
      district_id: "61",
      name_uz: "Tongotar",
      name_oz: "Ňîíăîňŕđ",
      name_ru: "Ňîíăîňŕđ",
    },
    {
      id: "402",
      district_id: "61",
      name_uz: "Uzunbuloq",
      name_oz: "Óçóíáóëî?",
      name_ru: "Óçóíáóëî?",
    },
    {
      id: "403",
      district_id: "61",
      name_uz: "O‘smat",
      name_oz: "ˇńěŕň",
      name_ru: "ˇńěŕň",
    },
    {
      id: "404",
      district_id: "62",
      name_uz: "Abdukarim",
      name_oz: "Ŕáäóęŕđčě",
      name_ru: "Ŕáäóęŕđčě",
    },
    {
      id: "405",
      district_id: "62",
      name_uz: "Buloqboshi",
      name_oz: "Áóëî?áîřč",
      name_ru: "Áóëî?áîřč",
    },
    {
      id: "406",
      district_id: "62",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "407",
      district_id: "62",
      name_uz: "Gulchambar",
      name_oz: "Ăóë÷ŕěáŕđ",
      name_ru: "Ăóë÷ŕěáŕđ",
    },
    {
      id: "408",
      district_id: "62",
      name_uz: "G‘allaorol",
      name_oz: "?ŕëëŕîđîë",
      name_ru: "?ŕëëŕîđîë",
    },
    {
      id: "409",
      district_id: "62",
      name_uz: "G‘ubdin",
      name_oz: "?óáäčí",
      name_ru: "?óáäčí",
    },
    {
      id: "410",
      district_id: "62",
      name_uz: "Ittifoq",
      name_oz: "Čňňčôî?",
      name_ru: "Čňňčôî?",
    },
    {
      id: "411",
      district_id: "62",
      name_uz: "Korizquduq",
      name_oz: "Ęîđčç?óäó?",
      name_ru: "Ęîđčç?óäó?",
    },
    {
      id: "412",
      district_id: "62",
      name_uz: "Ko‘kbuloq",
      name_oz: "Ę˘ęáóëî?",
      name_ru: "Ę˘ęáóëî?",
    },
    {
      id: "413",
      district_id: "62",
      name_uz: "Qangliobod",
      name_oz: "?ŕíăëčîáîä",
      name_ru: "?ŕíăëčîáîä",
    },
    {
      id: "414",
      district_id: "62",
      name_uz: "Qipchoqsuv",
      name_oz: "?čď÷î?ńóâ",
      name_ru: "?čď÷î?ńóâ",
    },
    {
      id: "415",
      district_id: "62",
      name_uz: "Qo‘ytosh",
      name_oz: "?˘éňîř",
      name_ru: "?˘éňîř",
    },
    {
      id: "416",
      district_id: "62",
      name_uz: "Lalmikor",
      name_oz: "Ëŕëěčęîđ",
      name_ru: "Ëŕëěčęîđ",
    },
    {
      id: "417",
      district_id: "62",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "418",
      district_id: "62",
      name_uz: "Marjonbuloq",
      name_oz: "Ěŕđćîíáóëî?",
      name_ru: "Ěŕđćîíáóëî?",
    },
    {
      id: "419",
      district_id: "62",
      name_uz: "Mirzabuloq",
      name_oz: "Ěčđçŕáóëî?",
      name_ru: "Ěčđçŕáóëî?",
    },
    {
      id: "420",
      district_id: "62",
      name_uz: "Moltob",
      name_oz: "Ěîëňîá",
      name_ru: "Ěîëňîá",
    },
    {
      id: "421",
      district_id: "62",
      name_uz: "Mulkush",
      name_oz: "Ěóëęóř",
      name_ru: "Ěóëęóř",
    },
    {
      id: "422",
      district_id: "62",
      name_uz: "Tozaurug‘",
      name_oz: "Ňîçŕóđó?",
      name_ru: "Ňîçŕóđó?",
    },
    {
      id: "423",
      district_id: "62",
      name_uz: "Xonimqo‘rg‘on",
      name_oz: "Őîíčě?˘đ?îí",
      name_ru: "Őîíčě?˘đ?îí",
    },
    {
      id: "424",
      district_id: "62",
      name_uz: "Chuvilloq",
      name_oz: "×óâčëëî?",
      name_ru: "×óâčëëî?",
    },
    {
      id: "425",
      district_id: "63",
      name_uz: "Bog‘zor",
      name_oz: "Áî?çîđ",
      name_ru: "Áî?çîđ",
    },
    {
      id: "426",
      district_id: "63",
      name_uz: "Bunyodkor",
      name_oz: "Áóí¸äęîđ",
      name_ru: "Áóí¸äęîđ",
    },
    {
      id: "427",
      district_id: "63",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "428",
      district_id: "63",
      name_uz: "Qahramon",
      name_oz: "?ŕ?đŕěîí",
      name_ru: "?ŕ?đŕěîí",
    },
    {
      id: "429",
      district_id: "63",
      name_uz: "Manas",
      name_oz: "Ěŕíŕń",
      name_ru: "Ěŕíŕń",
    },
    {
      id: "430",
      district_id: "63",
      name_uz: "Mevazor",
      name_oz: "Ěĺâŕçîđ",
      name_ru: "Ěĺâŕçîđ",
    },
    {
      id: "431",
      district_id: "63",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "432",
      district_id: "63",
      name_uz: "Oltin vodiy",
      name_oz: "Îëňčí âîäčé",
      name_ru: "Îëňčí âîäčé",
    },
    {
      id: "433",
      district_id: "63",
      name_uz: "Saritepa",
      name_oz: "Ńŕđčňĺďŕ",
      name_ru: "Ńŕđčňĺďŕ",
    },
    {
      id: "434",
      district_id: "66",
      name_uz: "Adirobod",
      name_oz: "Ŕäčđîáîä",
      name_ru: "Ŕäčđîáîä",
    },
    {
      id: "435",
      district_id: "66",
      name_uz: "Andijon",
      name_oz: "Ŕíäčćîí",
      name_ru: "Ŕíäčćîí",
    },
    {
      id: "436",
      district_id: "66",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "437",
      district_id: "66",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "438",
      district_id: "66",
      name_uz: "Lalmikor",
      name_oz: "Ëŕëěčęîđ",
      name_ru: "Ëŕëěčęîđ",
    },
    {
      id: "439",
      district_id: "66",
      name_uz: "Oqar",
      name_oz: "Î?ŕđ",
      name_ru: "Î?ŕđ",
    },
    {
      id: "440",
      district_id: "66",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "441",
      district_id: "66",
      name_uz: "Toshkesgan",
      name_oz: "Ňîřęĺńăŕí",
      name_ru: "Ňîřęĺńăŕí",
    },
    {
      id: "442",
      district_id: "66",
      name_uz: "Fayzobod",
      name_oz: "Ôŕéçîáîä",
      name_ru: "Ôŕéçîáîä",
    },
    {
      id: "443",
      district_id: "66",
      name_uz: "Sharq yulduzi",
      name_oz: "Řŕđ? ţëäóçč",
      name_ru: "Řŕđ? ţëäóçč",
    },
    {
      id: "444",
      district_id: "66",
      name_uz: "Sharq yulduzi",
      name_oz: "Řŕđ? ţëäóçč",
      name_ru: "Řŕđ? ţëäóçč",
    },
    {
      id: "445",
      district_id: "66",
      name_uz: "Yangikent",
      name_oz: "ßíăčęĺíň",
      name_ru: "ßíăčęĺíň",
    },
    {
      id: "446",
      district_id: "67",
      name_uz: "Birlik",
      name_oz: "Áčđëčę",
      name_ru: "Áčđëčę",
    },
    {
      id: "447",
      district_id: "67",
      name_uz: "Yorqin",
      name_oz: "¨đ?čí",
      name_ru: "¨đ?čí",
    },
    {
      id: "448",
      district_id: "67",
      name_uz: "Yorqin",
      name_oz: "¨đ?čí",
      name_ru: "¨đ?čí",
    },
    {
      id: "449",
      district_id: "67",
      name_uz: "Zafarobod",
      name_oz: "Çŕôŕđîáîä",
      name_ru: "Çŕôŕđîáîä",
    },
    {
      id: "450",
      district_id: "67",
      name_uz: "Lolazor",
      name_oz: "Ëîëŕçîđ",
      name_ru: "Ëîëŕçîđ",
    },
    {
      id: "451",
      district_id: "67",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "452",
      district_id: "67",
      name_uz: "Pistalikent",
      name_oz: "Ďčńňŕëčęĺíň",
      name_ru: "Ďčńňŕëčęĺíň",
    },
    {
      id: "453",
      district_id: "67",
      name_uz: "Uzun",
      name_oz: "Óçóí",
      name_ru: "Óçóí",
    },
    {
      id: "454",
      district_id: "67",
      name_uz: "Xulkar",
      name_oz: "Őóëęŕđ",
      name_ru: "Őóëęŕđ",
    },
    {
      id: "455",
      district_id: "67",
      name_uz: "Chimqo‘rg‘on",
      name_oz: "×čě?˘đ?îí",
      name_ru: "×čě?˘đ?îí",
    },
    {
      id: "456",
      district_id: "68",
      name_uz: "A.Navoiy",
      name_oz: "Ŕ.Íŕâîčé",
      name_ru: "Ŕ.Íŕâîčé",
    },
    {
      id: "457",
      district_id: "68",
      name_uz: "Beshkubi",
      name_oz: "Áĺřęóáč",
      name_ru: "Áĺřęóáč",
    },
    {
      id: "458",
      district_id: "68",
      name_uz: "Gulshan",
      name_oz: "Ăóëřŕí",
      name_ru: "Ăóëřŕí",
    },
    {
      id: "459",
      district_id: "68",
      name_uz: "G‘allakor",
      name_oz: "?ŕëëŕęîđ",
      name_ru: "?ŕëëŕęîđ",
    },
    {
      id: "460",
      district_id: "68",
      name_uz: "Dashtobod",
      name_oz: "Äŕřňîáîä",
      name_ru: "Äŕřňîáîä",
    },
    {
      id: "461",
      district_id: "68",
      name_uz: "Duoba",
      name_oz: "Äóîáŕ",
      name_ru: "Äóîáŕ",
    },
    {
      id: "462",
      district_id: "68",
      name_uz: "Yom",
      name_oz: "¨ě",
      name_ru: "¨ě",
    },
    {
      id: "463",
      district_id: "68",
      name_uz: "Zomin",
      name_oz: "Çîěčí",
      name_ru: "Çîěčí",
    },
    {
      id: "464",
      district_id: "68",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "465",
      district_id: "68",
      name_uz: "Obi hayot",
      name_oz: "Îáč ?ŕ¸ň",
      name_ru: "Îáč ?ŕ¸ň",
    },
    {
      id: "466",
      district_id: "68",
      name_uz: "Pshag‘or",
      name_oz: "Ďřŕ?îđ",
      name_ru: "Ďřŕ?îđ",
    },
    {
      id: "467",
      district_id: "68",
      name_uz: "Sirg‘ali",
      name_oz: "Ńčđ?ŕëč",
      name_ru: "Ńčđ?ŕëč",
    },
    {
      id: "468",
      district_id: "68",
      name_uz: "Chorvador",
      name_oz: "×îđâŕäîđ",
      name_ru: "×îđâŕäîđ",
    },
    {
      id: "469",
      district_id: "68",
      name_uz: "Shirin",
      name_oz: "Řčđčí",
      name_ru: "Řčđčí",
    },
    {
      id: "470",
      district_id: "69",
      name_uz: "Bog‘bon",
      name_oz: "Áî?áîí",
      name_ru: "Áî?áîí",
    },
    {
      id: "471",
      district_id: "69",
      name_uz: "Gagarin",
      name_oz: "Ăŕăŕđčí",
      name_ru: "Ăŕăŕđčí",
    },
    {
      id: "472",
      district_id: "69",
      name_uz: "Gulzor",
      name_oz: "Ăóëçîđ",
      name_ru: "Ăóëçîđ",
    },
    {
      id: "473",
      district_id: "69",
      name_uz: "Jibek-jo‘li",
      name_oz: "Ćčáĺę-ć˘ëč",
      name_ru: "Ćčáĺę-ć˘ëč",
    },
    {
      id: "474",
      district_id: "69",
      name_uz: "Mirzadala",
      name_oz: "Ěčđçŕäŕëŕ",
      name_ru: "Ěčđçŕäŕëŕ",
    },
    {
      id: "475",
      district_id: "69",
      name_uz: "Paxtazor",
      name_oz: "Ďŕőňŕçîđ",
      name_ru: "Ďŕőňŕçîđ",
    },
    {
      id: "476",
      district_id: "69",
      name_uz: "Toshkent",
      name_oz: "Ňîřęĺíň",
      name_ru: "Ňîřęĺíň",
    },
    {
      id: "477",
      district_id: "69",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "478",
      district_id: "69",
      name_uz: "Yangidala",
      name_oz: "ßíăčäŕëŕ",
      name_ru: "ßíăčäŕëŕ",
    },
    {
      id: "479",
      district_id: "70",
      name_uz: "Bog‘ishamol",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Áî?čřŕěîë",
    },
    {
      id: "480",
      district_id: "70",
      name_uz: "Buyuk Ipak yo‘li",
      name_oz: "Áóţę Čďŕę é˘ëč",
      name_ru: "Áóţę Čďŕę é˘ëč",
    },
    {
      id: "481",
      district_id: "70",
      name_uz: "Gulzor",
      name_oz: "Ăóëçîđ",
      name_ru: "Ăóëçîđ",
    },
    {
      id: "482",
      district_id: "70",
      name_uz: "Mingchinor",
      name_oz: "Ěčíă÷číîđ",
      name_ru: "Ěčíă÷číîđ",
    },
    {
      id: "483",
      district_id: "70",
      name_uz: "Oq buloq",
      name_oz: "Î? áóëî?",
      name_ru: "Î? áóëî?",
    },
    {
      id: "484",
      district_id: "70",
      name_uz: "Olmazor",
      name_oz: "Îëěŕçîđ",
      name_ru: "Îëěŕçîđ",
    },
    {
      id: "485",
      district_id: "70",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "486",
      district_id: "70",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "487",
      district_id: "70",
      name_uz: "Chamanzor",
      name_oz: "×ŕěŕíçîđ",
      name_ru: "×ŕěŕíçîđ",
    },
    {
      id: "488",
      district_id: "71",
      name_uz: "Arnasoy",
      name_oz: "Ŕđíŕńîé",
      name_ru: "Ŕđíŕńîé",
    },
    {
      id: "489",
      district_id: "71",
      name_uz: "Bog‘don",
      name_oz: "Áî?äîí",
      name_ru: "Áî?äîí",
    },
    {
      id: "490",
      district_id: "71",
      name_uz: "Garasha",
      name_oz: "Ăŕđŕřŕ",
      name_ru: "Ăŕđŕřŕ",
    },
    {
      id: "491",
      district_id: "71",
      name_uz: "Darvoza",
      name_oz: "Äŕđâîçŕ",
      name_ru: "Äŕđâîçŕ",
    },
    {
      id: "492",
      district_id: "71",
      name_uz: "Qizilqum",
      name_oz: "?čçčë?óě",
      name_ru: "?čçčë?óě",
    },
    {
      id: "493",
      district_id: "71",
      name_uz: "Qoraabdol",
      name_oz: "?îđŕŕáäîë",
      name_ru: "?îđŕŕáäîë",
    },
    {
      id: "494",
      district_id: "71",
      name_uz: "Omonkeldi",
      name_oz: "Îěîíęĺëäč",
      name_ru: "Îěîíęĺëäč",
    },
    {
      id: "495",
      district_id: "71",
      name_uz: "Osmonsoy",
      name_oz: "Îńěîíńîé",
      name_ru: "Îńěîíńîé",
    },
    {
      id: "496",
      district_id: "71",
      name_uz: "Uxum",
      name_oz: "Óőóě",
      name_ru: "Óőóě",
    },
    {
      id: "497",
      district_id: "71",
      name_uz: "Uchquloch",
      name_oz: "Ó÷?óëî÷",
      name_ru: "Ó÷?óëî÷",
    },
    {
      id: "498",
      district_id: "71",
      name_uz: "Forish",
      name_oz: "Ôîđčř",
      name_ru: "Ôîđčř",
    },
    {
      id: "499",
      district_id: "71",
      name_uz: "Egizbuloq",
      name_oz: "Ýăčçáóëî?",
      name_ru: "Ýăčçáóëî?",
    },
    {
      id: "500",
      district_id: "64",
      name_uz: "Gandumtosh",
      name_oz: "Ăŕíäóěňîř",
      name_ru: "Ăŕíäóěňîř",
    },
    {
      id: "501",
      district_id: "64",
      name_uz: "Jizzaxlik",
      name_oz: "Ćčççŕőëčę",
      name_ru: "Ćčççŕőëčę",
    },
    {
      id: "502",
      district_id: "64",
      name_uz: "Ziyokor",
      name_oz: "Çč¸ęîđ",
      name_ru: "Çč¸ęîđ",
    },
    {
      id: "503",
      district_id: "64",
      name_uz: "Kuyovboshi",
      name_oz: "Ęó¸âáîřč",
      name_ru: "Ęó¸âáîřč",
    },
    {
      id: "504",
      district_id: "64",
      name_uz: "Qang‘li",
      name_oz: "?ŕí?ëč",
      name_ru: "?ŕí?ëč",
    },
    {
      id: "505",
      district_id: "64",
      name_uz: "Qang‘li",
      name_oz: "?ŕí?ëč",
      name_ru: "?ŕí?ëč",
    },
    {
      id: "506",
      district_id: "64",
      name_uz: "Qorayantoq",
      name_oz: "?îđŕ˙íňî?",
      name_ru: "?îđŕ˙íňî?",
    },
    {
      id: "507",
      district_id: "64",
      name_uz: "Qulama",
      name_oz: "?óëŕěŕ",
      name_ru: "?óëŕěŕ",
    },
    {
      id: "508",
      district_id: "64",
      name_uz: "Qo‘shbarmoq",
      name_oz: "?˘řáŕđěî?",
      name_ru: "?˘řáŕđěî?",
    },
    {
      id: "509",
      district_id: "64",
      name_uz: "Mulkanlik",
      name_oz: "Ěóëęŕíëčę",
      name_ru: "Ěóëęŕíëčę",
    },
    {
      id: "510",
      district_id: "64",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "511",
      district_id: "64",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "512",
      district_id: "64",
      name_uz: "Rovat",
      name_oz: "Đîâŕň",
      name_ru: "Đîâŕň",
    },
    {
      id: "513",
      district_id: "64",
      name_uz: "Samarqand quduq",
      name_oz: "Ńŕěŕđ?ŕíä ?óäó?",
      name_ru: "Ńŕěŕđ?ŕíä ?óäó?",
    },
    {
      id: "514",
      district_id: "64",
      name_uz: "Toqchiliq",
      name_oz: "Ňî?÷čëč?",
      name_ru: "Ňî?÷čëč?",
    },
    {
      id: "515",
      district_id: "64",
      name_uz: "Uch-tepa",
      name_oz: "Ó÷-ňĺďŕ",
      name_ru: "Ó÷-ňĺďŕ",
    },
    {
      id: "516",
      district_id: "64",
      name_uz: "Uchtepa",
      name_oz: "Ó÷ňĺďŕ",
      name_ru: "Ó÷ňĺďŕ",
    },
    {
      id: "517",
      district_id: "64",
      name_uz: "Fayzobod",
      name_oz: "Ôŕéçîáîä",
      name_ru: "Ôŕéçîáîä",
    },
    {
      id: "518",
      district_id: "64",
      name_uz: "Xayrobod",
      name_oz: "Őŕéđîáîä",
      name_ru: "Őŕéđîáîä",
    },
    {
      id: "519",
      district_id: "72",
      name_uz: "Savot",
      name_oz: "Ńŕâîň",
      name_ru: "Ńŕâîň",
    },
    {
      id: "520",
      district_id: "72",
      name_uz: "Savot",
      name_oz: "Ńŕâîň",
      name_ru: "Ńŕâîň",
    },
    {
      id: "521",
      district_id: "72",
      name_uz: "Sarmich",
      name_oz: "Ńŕđěč÷",
      name_ru: "Ńŕđěč÷",
    },
    {
      id: "522",
      district_id: "72",
      name_uz: "Xovos",
      name_oz: "Őîâîń",
      name_ru: "Őîâîń",
    },
    {
      id: "523",
      district_id: "72",
      name_uz: "Xo‘jamushkent",
      name_oz: "Ő˘ćŕěóřęĺíň",
      name_ru: "Ő˘ćŕěóřęĺíň",
    },
    {
      id: "524",
      district_id: "72",
      name_uz: "Havotog‘",
      name_oz: "?ŕâîňî?",
      name_ru: "?ŕâîňî?",
    },
    {
      id: "525",
      district_id: "72",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "526",
      district_id: "73",
      name_uz: "Batosh",
      name_oz: "Áŕňîř",
      name_ru: "Áŕňîř",
    },
    {
      id: "527",
      district_id: "73",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "528",
      district_id: "73",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "529",
      district_id: "73",
      name_uz: "Gulshan",
      name_oz: "Ăóëřŕí",
      name_ru: "Ăóëřŕí",
    },
    {
      id: "530",
      district_id: "73",
      name_uz: "G‘uzor",
      name_oz: "?óçîđ",
      name_ru: "?óçîđ",
    },
    {
      id: "531",
      district_id: "73",
      name_uz: "Jarariq",
      name_oz: "Ćŕđŕđč?",
      name_ru: "Ćŕđŕđč?",
    },
    {
      id: "532",
      district_id: "73",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "533",
      district_id: "73",
      name_uz: "Qorako‘l",
      name_oz: "?îđŕę˘ë",
      name_ru: "?îđŕę˘ë",
    },
    {
      id: "534",
      district_id: "73",
      name_uz: "Qo‘shtepa",
      name_oz: "?˘řňĺďŕ",
      name_ru: "?˘řňĺďŕ",
    },
    {
      id: "535",
      district_id: "73",
      name_uz: "Mash?al",
      name_oz: "Ěŕřúŕë",
      name_ru: "Ěŕřúŕë",
    },
    {
      id: "536",
      district_id: "73",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "537",
      district_id: "73",
      name_uz: "Obihayot",
      name_oz: "Îáč?ŕ¸ň",
      name_ru: "Îáč?ŕ¸ň",
    },
    {
      id: "538",
      district_id: "73",
      name_uz: "Pachkamar",
      name_oz: "Ďŕ÷ęŕěŕđ",
      name_ru: "Ďŕ÷ęŕěŕđ",
    },
    {
      id: "539",
      district_id: "73",
      name_uz: "Xalqabod",
      name_oz: "Őŕë?ŕáîä",
      name_ru: "Őŕë?ŕáîä",
    },
    {
      id: "540",
      district_id: "73",
      name_uz: "Shakarbuloq",
      name_oz: "Řŕęŕđáóëî?",
      name_ru: "Řŕęŕđáóëî?",
    },
    {
      id: "541",
      district_id: "73",
      name_uz: "Sherali",
      name_oz: "Řĺđŕëč",
      name_ru: "Řĺđŕëč",
    },
    {
      id: "542",
      district_id: "73",
      name_uz: "Sherali",
      name_oz: "Řĺđŕëč",
      name_ru: "Řĺđŕëč",
    },
    {
      id: "543",
      district_id: "73",
      name_uz: "Yangikent",
      name_oz: "ßíăčęĺíň",
      name_ru: "ßíăčęĺíň",
    },
    {
      id: "544",
      district_id: "74",
      name_uz: "Beshbuloq",
      name_oz: "Áĺřáóëî?",
      name_ru: "Áĺřáóëî?",
    },
    {
      id: "545",
      district_id: "74",
      name_uz: "Beshqo‘ton",
      name_oz: "Áĺř?˘ňîí",
      name_ru: "Áĺř?˘ňîí",
    },
    {
      id: "546",
      district_id: "74",
      name_uz: "Bibiqorasoch",
      name_oz: "Áčáč?îđŕńî÷",
      name_ru: "Áčáč?îđŕńî÷",
    },
    {
      id: "547",
      district_id: "74",
      name_uz: "Bozortepa",
      name_oz: "Áîçîđňĺďŕ",
      name_ru: "Áîçîđňĺďŕ",
    },
    {
      id: "548",
      district_id: "74",
      name_uz: "Boshchorbog‘",
      name_oz: "Áîř÷îđáî?",
      name_ru: "Áîř÷îđáî?",
    },
    {
      id: "549",
      district_id: "74",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "550",
      district_id: "74",
      name_uz: "Duob",
      name_oz: "Äóîá",
      name_ru: "Äóîá",
    },
    {
      id: "551",
      district_id: "74",
      name_uz: "Karashina",
      name_oz: "Ęŕđŕřčíŕ",
      name_ru: "Ęŕđŕřčíŕ",
    },
    {
      id: "552",
      district_id: "74",
      name_uz: "Qizilcha",
      name_oz: "?čçčë÷ŕ",
      name_ru: "?čçčë÷ŕ",
    },
    {
      id: "553",
      district_id: "74",
      name_uz: "Qirqquloch",
      name_oz: "?čđ??óëî÷",
      name_ru: "?čđ??óëî÷",
    },
    {
      id: "554",
      district_id: "74",
      name_uz: "Qo‘rg‘ontosh",
      name_oz: "?˘đ?îíňîř",
      name_ru: "?˘đ?îíňîř",
    },
    {
      id: "555",
      district_id: "74",
      name_uz: "Obiravon",
      name_oz: "Îáčđŕâîí",
      name_ru: "Îáčđŕâîí",
    },
    {
      id: "556",
      district_id: "74",
      name_uz: "Oqirtma",
      name_oz: "Î?čđňěŕ",
      name_ru: "Î?čđňěŕ",
    },
    {
      id: "557",
      district_id: "74",
      name_uz: "Oqqishloq",
      name_oz: "Î??čřëî?",
      name_ru: "Î??čřëî?",
    },
    {
      id: "558",
      district_id: "74",
      name_uz: "Oqrabod",
      name_oz: "Î?đŕáîä",
      name_ru: "Î?đŕáîä",
    },
    {
      id: "559",
      district_id: "74",
      name_uz: "Oqtosh",
      name_oz: "Î?ňîř",
      name_ru: "Î?ňîř",
    },
    {
      id: "560",
      district_id: "74",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "561",
      district_id: "78",
      name_uz: "G‘alaba",
      name_oz: "?ŕëŕáŕ",
      name_ru: "?ŕëŕáŕ",
    },
    {
      id: "562",
      district_id: "78",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "563",
      district_id: "78",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "564",
      district_id: "78",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "565",
      district_id: "78",
      name_uz: "Kasbi",
      name_oz: "Ęŕńáč",
      name_ru: "Ęŕńáč",
    },
    {
      id: "566",
      district_id: "78",
      name_uz: "Komilon",
      name_oz: "Ęîěčëîí",
      name_ru: "Ęîěčëîí",
    },
    {
      id: "567",
      district_id: "78",
      name_uz: "Qamashi",
      name_oz: "?ŕěŕřč",
      name_ru: "?ŕěŕřč",
    },
    {
      id: "568",
      district_id: "78",
      name_uz: "Qatag‘an",
      name_oz: "?ŕňŕ?ŕí",
      name_ru: "?ŕňŕ?ŕí",
    },
    {
      id: "569",
      district_id: "78",
      name_uz: "Qatag‘on",
      name_oz: "?ŕňŕ?îí",
      name_ru: "?ŕňŕ?îí",
    },
    {
      id: "570",
      district_id: "78",
      name_uz: "Qoraqo‘ng‘irot",
      name_oz: "?îđŕ?˘í?čđîň",
      name_ru: "?îđŕ?˘í?čđîň",
    },
    {
      id: "571",
      district_id: "78",
      name_uz: "Maymanoq",
      name_oz: "Ěŕéěŕíî?",
      name_ru: "Ěŕéěŕíî?",
    },
    {
      id: "572",
      district_id: "78",
      name_uz: "Mug‘lon",
      name_oz: "Ěó?ëîí",
      name_ru: "Ěó?ëîí",
    },
    {
      id: "573",
      district_id: "78",
      name_uz: "Mug‘lon",
      name_oz: "Ěó?ëîí",
      name_ru: "Ěó?ëîí",
    },
    {
      id: "574",
      district_id: "78",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "575",
      district_id: "78",
      name_uz: "Fazli",
      name_oz: "Ôŕçëč",
      name_ru: "Ôŕçëč",
    },
    {
      id: "576",
      district_id: "78",
      name_uz: "Xo‘jakasbi",
      name_oz: "Ő˘ćŕęŕńáč",
      name_ru: "Ő˘ćŕęŕńáč",
    },
    {
      id: "577",
      district_id: "78",
      name_uz: "Cho‘lquvar",
      name_oz: "×˘ë?óâŕđ",
      name_ru: "×˘ë?óâŕđ",
    },
    {
      id: "578",
      district_id: "78",
      name_uz: "Yuksalish",
      name_oz: "Ţęńŕëčř",
      name_ru: "Ţęńŕëčř",
    },
    {
      id: "579",
      district_id: "78",
      name_uz: "Yangi qishloq",
      name_oz: "ßíăč ?čřëî?",
      name_ru: "ßíăč ?čřëî?",
    },
    {
      id: "580",
      district_id: "79",
      name_uz: "Alaqo‘yliq",
      name_oz: "Ŕëŕ?˘éëč?",
      name_ru: "Ŕëŕ?˘éëč?",
    },
    {
      id: "581",
      district_id: "79",
      name_uz: "Baxtdarvozasi",
      name_oz: "Áŕőňäŕđâîçŕńč",
      name_ru: "Áŕőňäŕđâîçŕńč",
    },
    {
      id: "582",
      district_id: "79",
      name_uz: "Bektemir",
      name_oz: "Áĺęňĺěčđ",
      name_ru: "Áĺęňĺěčđ",
    },
    {
      id: "583",
      district_id: "79",
      name_uz: "Bektemir",
      name_oz: "Áĺęňĺěčđ",
      name_ru: "Áĺęňĺěčđ",
    },
    {
      id: "584",
      district_id: "79",
      name_uz: "Beshterak",
      name_oz: "Áĺřňĺđŕę",
      name_ru: "Áĺřňĺđŕę",
    },
    {
      id: "585",
      district_id: "79",
      name_uz: "Beshterak",
      name_oz: "Áĺřňĺđŕę",
      name_ru: "Áĺřňĺđŕę",
    },
    {
      id: "586",
      district_id: "79",
      name_uz: "Bog‘bon",
      name_oz: "Áî?áîí",
      name_ru: "Áî?áîí",
    },
    {
      id: "587",
      district_id: "79",
      name_uz: "Varganza",
      name_oz: "Âŕđăŕíçŕ",
      name_ru: "Âŕđăŕíçŕ",
    },
    {
      id: "588",
      district_id: "79",
      name_uz: "Jilisuv",
      name_oz: "Ćčëčńóâ",
      name_ru: "Ćčëčńóâ",
    },
    {
      id: "589",
      district_id: "79",
      name_uz: "Kitob",
      name_oz: "Ęčňîá",
      name_ru: "Ęčňîá",
    },
    {
      id: "590",
      district_id: "79",
      name_uz: "Qaynarbuloq",
      name_oz: "?ŕéíŕđáóëî?",
      name_ru: "?ŕéíŕđáóëî?",
    },
    {
      id: "591",
      district_id: "79",
      name_uz: "Qatorbog‘",
      name_oz: "?ŕňîđáî?",
      name_ru: "?ŕňîđáî?",
    },
    {
      id: "592",
      district_id: "79",
      name_uz: "Qo‘yioqboy",
      name_oz: "?˘éčî?áîé",
      name_ru: "?˘éčî?áîé",
    },
    {
      id: "593",
      district_id: "79",
      name_uz: "Makrid",
      name_oz: "Ěŕęđčä",
      name_ru: "Ěŕęđčä",
    },
    {
      id: "594",
      district_id: "79",
      name_uz: "Obikanda",
      name_oz: "Îáčęŕíäŕ",
      name_ru: "Îáčęŕíäŕ",
    },
    {
      id: "595",
      district_id: "79",
      name_uz: "Panji",
      name_oz: "Ďŕíćč",
      name_ru: "Ďŕíćč",
    },
    {
      id: "596",
      district_id: "79",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "597",
      district_id: "79",
      name_uz: "Rus qishloq",
      name_oz: "Đóń ?čřëî?",
      name_ru: "Đóń ?čřëî?",
    },
    {
      id: "598",
      district_id: "79",
      name_uz: "Sariosiyo",
      name_oz: "Ńŕđčîńč¸",
      name_ru: "Ńŕđčîńč¸",
    },
    {
      id: "599",
      district_id: "79",
      name_uz: "Sevaz",
      name_oz: "Ńĺâŕç",
      name_ru: "Ńĺâŕç",
    },
    {
      id: "600",
      district_id: "79",
      name_uz: "Sevaz",
      name_oz: "Ńĺâŕç",
      name_ru: "Ńĺâŕç",
    },
    {
      id: "601",
      district_id: "79",
      name_uz: "Tagob",
      name_oz: "Ňŕăîá",
      name_ru: "Ňŕăîá",
    },
    {
      id: "602",
      district_id: "79",
      name_uz: "Tupchoq",
      name_oz: "Ňóď÷î?",
      name_ru: "Ňóď÷î?",
    },
    {
      id: "603",
      district_id: "79",
      name_uz: "Xoji",
      name_oz: "Őîćč",
      name_ru: "Őîćč",
    },
    {
      id: "604",
      district_id: "79",
      name_uz: "Yakkatut",
      name_oz: "ßęęŕňóň",
      name_ru: "ßęęŕňóň",
    },
    {
      id: "605",
      district_id: "79",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "606",
      district_id: "80",
      name_uz: "Alachabob",
      name_oz: "Ŕëŕ÷ŕáîá",
      name_ru: "Ŕëŕ÷ŕáîá",
    },
    {
      id: "607",
      district_id: "80",
      name_uz: "Boyg‘undi",
      name_oz: "Áîé?óíäč",
      name_ru: "Áîé?óíäč",
    },
    {
      id: "608",
      district_id: "80",
      name_uz: "Boyterak",
      name_oz: "Áîéňĺđŕę",
      name_ru: "Áîéňĺđŕę",
    },
    {
      id: "609",
      district_id: "80",
      name_uz: "Guvalak",
      name_oz: "Ăóâŕëŕę",
      name_ru: "Ăóâŕëŕę",
    },
    {
      id: "610",
      district_id: "80",
      name_uz: "Gulbog‘",
      name_oz: "Ăóëáî?",
      name_ru: "Ăóëáî?",
    },
    {
      id: "611",
      district_id: "80",
      name_uz: "Gulobod",
      name_oz: "Ăóëîáîä",
      name_ru: "Ăóëîáîä",
    },
    {
      id: "612",
      district_id: "80",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "613",
      district_id: "80",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "614",
      district_id: "80",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "615",
      district_id: "80",
      name_uz: "Koson",
      name_oz: "Ęîńîí",
      name_ru: "Ęîńîí",
    },
    {
      id: "616",
      district_id: "80",
      name_uz: "Koson",
      name_oz: "Ęîńîí",
      name_ru: "Ęîńîí",
    },
    {
      id: "617",
      district_id: "80",
      name_uz: "Qo‘yi Obron",
      name_oz: "?˘éč Îáđîí",
      name_ru: "?˘éč Îáđîí",
    },
    {
      id: "618",
      district_id: "80",
      name_uz: "Qo‘ng‘irtog‘",
      name_oz: "?˘í?čđňî?",
      name_ru: "?˘í?čđňî?",
    },
    {
      id: "619",
      district_id: "80",
      name_uz: "Mudin",
      name_oz: "Ěóäčí",
      name_ru: "Ěóäčí",
    },
    {
      id: "620",
      district_id: "80",
      name_uz: "Obod",
      name_oz: "Îáîä",
      name_ru: "Îáîä",
    },
    {
      id: "621",
      district_id: "80",
      name_uz: "Oqtepa",
      name_oz: "Î?ňĺďŕ",
      name_ru: "Î?ňĺďŕ",
    },
    {
      id: "622",
      district_id: "80",
      name_uz: "Pudina",
      name_oz: "Ďóäčíŕ",
      name_ru: "Ďóäčíŕ",
    },
    {
      id: "623",
      district_id: "80",
      name_uz: "Po‘lati",
      name_oz: "Ď˘ëŕňč",
      name_ru: "Ď˘ëŕňč",
    },
    {
      id: "624",
      district_id: "80",
      name_uz: "Rahimso‘fi",
      name_oz: "Đŕ?čěń˘ôč",
      name_ru: "Đŕ?čěń˘ôč",
    },
    {
      id: "625",
      district_id: "80",
      name_uz: "Rudaksoy",
      name_oz: "Đóäŕęńîé",
      name_ru: "Đóäŕęńîé",
    },
    {
      id: "626",
      district_id: "80",
      name_uz: "Surhon",
      name_oz: "Ńóđ?îí",
      name_ru: "Ńóđ?îí",
    },
    {
      id: "627",
      district_id: "80",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "628",
      district_id: "80",
      name_uz: "To‘lg‘a",
      name_oz: "Ň˘ë?ŕ",
      name_ru: "Ň˘ë?ŕ",
    },
    {
      id: "629",
      district_id: "80",
      name_uz: "Esaboy",
      name_oz: "Ýńŕáîé",
      name_ru: "Ýńŕáîé",
    },
    {
      id: "630",
      district_id: "75",
      name_uz: "Badahshon",
      name_oz: "Áŕäŕ?řîí",
      name_ru: "Áŕäŕ?řîí",
    },
    {
      id: "631",
      district_id: "75",
      name_uz: "Balandchayla",
      name_oz: "Áŕëŕíä÷ŕéëŕ",
      name_ru: "Áŕëŕíä÷ŕéëŕ",
    },
    {
      id: "632",
      district_id: "75",
      name_uz: "Yortepa",
      name_oz: "¨đňĺďŕ",
      name_ru: "¨đňĺďŕ",
    },
    {
      id: "633",
      district_id: "75",
      name_uz: "Jonbo‘zsoy",
      name_oz: "Ćîíá˘çńîé",
      name_ru: "Ćîíá˘çńîé",
    },
    {
      id: "634",
      district_id: "75",
      name_uz: "Ko‘kbuloq",
      name_oz: "Ę˘ęáóëî?",
      name_ru: "Ę˘ęáóëî?",
    },
    {
      id: "635",
      district_id: "75",
      name_uz: "Qamay",
      name_oz: "?ŕěŕé",
      name_ru: "?ŕěŕé",
    },
    {
      id: "636",
      district_id: "75",
      name_uz: "Qamashi",
      name_oz: "?ŕěŕřč",
      name_ru: "?ŕěŕřč",
    },
    {
      id: "637",
      district_id: "75",
      name_uz: "Qiziltepa",
      name_oz: "?čçčëňĺďŕ",
      name_ru: "?čçčëňĺďŕ",
    },
    {
      id: "638",
      district_id: "75",
      name_uz: "Qiziltepa",
      name_oz: "?čçčëňĺďŕ",
      name_ru: "?čçčëňĺďŕ",
    },
    {
      id: "639",
      district_id: "75",
      name_uz: "Qorabog‘",
      name_oz: "?îđŕáî?",
      name_ru: "?îđŕáî?",
    },
    {
      id: "640",
      district_id: "75",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "?îđŕňĺďŕ",
    },
    {
      id: "641",
      district_id: "75",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "?îđŕňĺďŕ",
    },
    {
      id: "642",
      district_id: "75",
      name_uz: "Loyqasoy",
      name_oz: "Ëîé?ŕńîé",
      name_ru: "Ëîé?ŕńîé",
    },
    {
      id: "643",
      district_id: "75",
      name_uz: "Rabod",
      name_oz: "Đŕáîä",
      name_ru: "Đŕáîä",
    },
    {
      id: "644",
      district_id: "75",
      name_uz: "Sarbozor",
      name_oz: "Ńŕđáîçîđ",
      name_ru: "Ńŕđáîçîđ",
    },
    {
      id: "645",
      district_id: "75",
      name_uz: "To‘qboy",
      name_oz: "Ň˘?áîé",
      name_ru: "Ň˘?áîé",
    },
    {
      id: "646",
      district_id: "75",
      name_uz: "Chim",
      name_oz: "×čě",
      name_ru: "×čě",
    },
    {
      id: "647",
      district_id: "76",
      name_uz: "Beshkent",
      name_oz: "Áĺřęĺíň",
      name_ru: "Áĺřęĺíň",
    },
    {
      id: "648",
      district_id: "76",
      name_uz: "Bog‘obod",
      name_oz: "Áî?îáîä",
      name_ru: "Áî?îáîä",
    },
    {
      id: "649",
      district_id: "76",
      name_uz: "G‘ubdin",
      name_oz: "?óáäčí",
      name_ru: "?óáäčí",
    },
    {
      id: "650",
      district_id: "76",
      name_uz: "Dasht",
      name_oz: "Äŕřň",
      name_ru: "Äŕřň",
    },
    {
      id: "651",
      district_id: "76",
      name_uz: "Yertepa",
      name_oz: "Ĺđňĺďŕ",
      name_ru: "Ĺđňĺďŕ",
    },
    {
      id: "652",
      district_id: "76",
      name_uz: "Yertepa",
      name_oz: "Ĺđňĺďŕ",
      name_ru: "Ĺđňĺďŕ",
    },
    {
      id: "653",
      district_id: "76",
      name_uz: "Jumabozor",
      name_oz: "Ćóěŕáîçîđ",
      name_ru: "Ćóěŕáîçîđ",
    },
    {
      id: "654",
      district_id: "76",
      name_uz: "Kat",
      name_oz: "Ęŕň",
      name_ru: "Ęŕň",
    },
    {
      id: "655",
      district_id: "76",
      name_uz: "Kuchkak",
      name_oz: "Ęó÷ęŕę",
      name_ru: "Ęó÷ęŕę",
    },
    {
      id: "656",
      district_id: "76",
      name_uz: "Qovchin",
      name_oz: "?îâ÷čí",
      name_ru: "?îâ÷čí",
    },
    {
      id: "657",
      district_id: "76",
      name_uz: "Qovchin",
      name_oz: "?îâ÷čí",
      name_ru: "?îâ÷čí",
    },
    {
      id: "658",
      district_id: "76",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "?îđŕňĺďŕ",
    },
    {
      id: "659",
      district_id: "76",
      name_uz: "Lag‘mon",
      name_oz: "Ëŕ?ěîí",
      name_ru: "Ëŕ?ěîí",
    },
    {
      id: "660",
      district_id: "76",
      name_uz: "Mirmiron",
      name_oz: "Ěčđěčđîí",
      name_ru: "Ěčđěčđîí",
    },
    {
      id: "661",
      district_id: "76",
      name_uz: "Mustaqillik",
      name_oz: "Ěóńňŕ?čëëčę",
      name_ru: "Ěóńňŕ?čëëčę",
    },
    {
      id: "662",
      district_id: "76",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "663",
      district_id: "76",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "664",
      district_id: "76",
      name_uz: "Nuqrabod",
      name_oz: "Íó?đŕáîä",
      name_ru: "Íó?đŕáîä",
    },
    {
      id: "665",
      district_id: "76",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "666",
      district_id: "76",
      name_uz: "Poshton",
      name_oz: "Ďîřňîí",
      name_ru: "Ďîřňîí",
    },
    {
      id: "667",
      district_id: "76",
      name_uz: "Saroy",
      name_oz: "Ńŕđîé",
      name_ru: "Ńŕđîé",
    },
    {
      id: "668",
      district_id: "76",
      name_uz: "Tallikuron",
      name_oz: "Ňŕëëčęóđîí",
      name_ru: "Ňŕëëčęóđîí",
    },
    {
      id: "669",
      district_id: "76",
      name_uz: "O‘zbekiston mustaqilligi",
      name_oz: "ˇçáĺęčńňîí ěóńňŕ?čëëčăč",
      name_ru: "ˇçáĺęčńňîí ěóńňŕ?čëëčăč",
    },
    {
      id: "670",
      district_id: "76",
      name_uz: "Fayzobod",
      name_oz: "Ôŕéçîáîä",
      name_ru: "Ôŕéçîáîä",
    },
    {
      id: "671",
      district_id: "76",
      name_uz: "Xonobod",
      name_oz: "Őîíîáîä",
      name_ru: "Őîíîáîä",
    },
    {
      id: "672",
      district_id: "76",
      name_uz: "Hilol",
      name_oz: "?čëîë",
      name_ru: "?čëîë",
    },
    {
      id: "673",
      district_id: "76",
      name_uz: "Chaman",
      name_oz: "×ŕěŕí",
      name_ru: "×ŕěŕí",
    },
    {
      id: "674",
      district_id: "76",
      name_uz: "Charag‘il",
      name_oz: "×ŕđŕ?čë",
      name_ru: "×ŕđŕ?čë",
    },
    {
      id: "675",
      district_id: "76",
      name_uz: "Cho‘libo‘ston",
      name_oz: "×˘ëčá˘ńňîí",
      name_ru: "×˘ëčá˘ńňîí",
    },
    {
      id: "676",
      district_id: "76",
      name_uz: "Shilvi",
      name_oz: "Řčëâč",
      name_ru: "Řčëâč",
    },
    {
      id: "677",
      district_id: "76",
      name_uz: "Yangi xayot",
      name_oz: "ßíăč őŕ¸ň",
      name_ru: "ßíăč őŕ¸ň",
    },
    {
      id: "678",
      district_id: "81",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "679",
      district_id: "81",
      name_uz: "Vori",
      name_oz: "Âîđč",
      name_ru: "Âîđč",
    },
    {
      id: "680",
      district_id: "81",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "681",
      district_id: "81",
      name_uz: "Gulshanbog‘",
      name_oz: "Ăóëřŕíáî?",
      name_ru: "Ăóëřŕíáî?",
    },
    {
      id: "682",
      district_id: "81",
      name_uz: "Jeynov",
      name_oz: "Ćĺéíîâ",
      name_ru: "Ćĺéíîâ",
    },
    {
      id: "683",
      district_id: "81",
      name_uz: "Jeynov",
      name_oz: "Ćĺéíîâ",
      name_ru: "Ćĺéíîâ",
    },
    {
      id: "684",
      district_id: "81",
      name_uz: "Mirishkor",
      name_oz: "Ěčđčřęîđ",
      name_ru: "Ěčđčřęîđ",
    },
    {
      id: "685",
      district_id: "81",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "686",
      district_id: "81",
      name_uz: "Obod",
      name_oz: "Îáîä",
      name_ru: "Îáîä",
    },
    {
      id: "687",
      district_id: "81",
      name_uz: "Pomuq",
      name_oz: "Ďîěó?",
      name_ru: "Ďîěó?",
    },
    {
      id: "688",
      district_id: "81",
      name_uz: "Pomuq",
      name_oz: "Ďîěó?",
      name_ru: "Ďîěó?",
    },
    {
      id: "689",
      district_id: "81",
      name_uz: "Chamanzor",
      name_oz: "×ŕěŕíçîđ",
      name_ru: "×ŕěŕíçîđ",
    },
    {
      id: "690",
      district_id: "81",
      name_uz: "Chandir",
      name_oz: "×ŕíäčđ",
      name_ru: "×ŕíäčđ",
    },
    {
      id: "691",
      district_id: "81",
      name_uz: "Yangi Mirishkor",
      name_oz: "ßíăč Ěčđčřęîđ",
      name_ru: "ßíăč Ěčđčřęîđ",
    },
    {
      id: "692",
      district_id: "81",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "693",
      district_id: "82",
      name_uz: "Baxt",
      name_oz: "Áŕőň",
      name_ru: "Áŕőň",
    },
    {
      id: "694",
      district_id: "82",
      name_uz: "Diyonat",
      name_oz: "Äč¸íŕň",
      name_ru: "Äč¸íŕň",
    },
    {
      id: "695",
      district_id: "82",
      name_uz: "Qarliq",
      name_oz: "?ŕđëč?",
      name_ru: "?ŕđëč?",
    },
    {
      id: "696",
      district_id: "82",
      name_uz: "Qarliq",
      name_oz: "?ŕđëč?",
      name_ru: "?ŕđëč?",
    },
    {
      id: "697",
      district_id: "82",
      name_uz: "Qoraqum",
      name_oz: "?îđŕ?óě",
      name_ru: "?îđŕ?óě",
    },
    {
      id: "698",
      district_id: "82",
      name_uz: "Qoraqum",
      name_oz: "?îđŕ?óě",
      name_ru: "?îđŕ?óě",
    },
    {
      id: "699",
      district_id: "82",
      name_uz: "Muborak",
      name_oz: "Ěóáîđŕę",
      name_ru: "Ěóáîđŕę",
    },
    {
      id: "700",
      district_id: "82",
      name_uz: "Muborak",
      name_oz: "Ěóáîđŕę",
      name_ru: "Ěóáîđŕę",
    },
    {
      id: "701",
      district_id: "82",
      name_uz: "Sariq",
      name_oz: "Ńŕđč?",
      name_ru: "Ńŕđč?",
    },
    {
      id: "702",
      district_id: "82",
      name_uz: "Shayx",
      name_oz: "Řŕéő",
      name_ru: "Řŕéő",
    },
    {
      id: "703",
      district_id: "83",
      name_uz: "Balxiyak",
      name_oz: "Áŕëőč˙ę",
      name_ru: "Áŕëőč˙ę",
    },
    {
      id: "704",
      district_id: "83",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "705",
      district_id: "83",
      name_uz: "Qirqquloch",
      name_oz: "?čđ??óëî÷",
      name_ru: "?čđ??óëî÷",
    },
    {
      id: "706",
      district_id: "83",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "707",
      district_id: "83",
      name_uz: "Nishon",
      name_oz: "Íčřîí",
      name_ru: "Íčřîí",
    },
    {
      id: "708",
      district_id: "83",
      name_uz: "Nishon",
      name_oz: "Íčřîí",
      name_ru: "Íčřîí",
    },
    {
      id: "709",
      district_id: "83",
      name_uz: "Nuriston",
      name_oz: "Íóđčńňîí",
      name_ru: "Íóđčńňîí",
    },
    {
      id: "710",
      district_id: "83",
      name_uz: "Oydin",
      name_oz: "Îéäčí",
      name_ru: "Îéäčí",
    },
    {
      id: "711",
      district_id: "83",
      name_uz: "Oydinlik",
      name_oz: "Îéäčíëčę",
      name_ru: "Îéäčíëčę",
    },
    {
      id: "712",
      district_id: "83",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "713",
      district_id: "83",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "714",
      district_id: "83",
      name_uz: "Paxtazor",
      name_oz: "Ďŕőňŕçîđ",
      name_ru: "Ďŕőňŕçîđ",
    },
    {
      id: "715",
      district_id: "83",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "716",
      district_id: "83",
      name_uz: "Paxtachi",
      name_oz: "Ďŕőňŕ÷č",
      name_ru: "Ďŕőňŕ÷č",
    },
    {
      id: "717",
      district_id: "83",
      name_uz: "Samarqand",
      name_oz: "Ńŕěŕđ?ŕíä",
      name_ru: "Ńŕěŕđ?ŕíä",
    },
    {
      id: "718",
      district_id: "83",
      name_uz: "Sardoba",
      name_oz: "Ńŕđäîáŕ",
      name_ru: "Ńŕđäîáŕ",
    },
    {
      id: "719",
      district_id: "83",
      name_uz: "Talimarjon",
      name_oz: "Ňŕëčěŕđćîí",
      name_ru: "Ňŕëčěŕđćîí",
    },
    {
      id: "720",
      district_id: "83",
      name_uz: "Shirinobod",
      name_oz: "Řčđčíîáîä",
      name_ru: "Řčđčíîáîä",
    },
    {
      id: "721",
      district_id: "83",
      name_uz: "Yangi Nishon",
      name_oz: "ßíăč Íčřîí",
      name_ru: "ßíăč Íčřîí",
    },
    {
      id: "722",
      district_id: "84",
      name_uz: "Ayritom",
      name_oz: "Ŕéđčňîě",
      name_ru: "Ŕéđčňîě",
    },
    {
      id: "723",
      district_id: "84",
      name_uz: "Dam",
      name_oz: "Äŕě",
      name_ru: "Äŕě",
    },
    {
      id: "724",
      district_id: "84",
      name_uz: "Dodiq",
      name_oz: "Äîäč?",
      name_ru: "Äîäč?",
    },
    {
      id: "725",
      district_id: "84",
      name_uz: "Jar",
      name_oz: "Ćŕđ",
      name_ru: "Ćŕđ",
    },
    {
      id: "726",
      district_id: "84",
      name_uz: "Jar",
      name_oz: "Ćŕđ",
      name_ru: "Ćŕđ",
    },
    {
      id: "727",
      district_id: "84",
      name_uz: "Ko‘kdala",
      name_oz: "Ę˘ęäŕëŕ",
      name_ru: "Ę˘ęäŕëŕ",
    },
    {
      id: "728",
      district_id: "84",
      name_uz: "Ko‘kdala",
      name_oz: "Ę˘ęäŕëŕ",
      name_ru: "Ę˘ęäŕëŕ",
    },
    {
      id: "729",
      district_id: "84",
      name_uz: "Qalqama",
      name_oz: "?ŕë?ŕěŕ",
      name_ru: "?ŕë?ŕěŕ",
    },
    {
      id: "730",
      district_id: "84",
      name_uz: "Qahramon",
      name_oz: "?ŕ?đŕěîí",
      name_ru: "?ŕ?đŕěîí",
    },
    {
      id: "731",
      district_id: "84",
      name_uz: "Qumdaryo",
      name_oz: "?óěäŕđ¸",
      name_ru: "?óěäŕđ¸",
    },
    {
      id: "732",
      district_id: "84",
      name_uz: "Langar",
      name_oz: "Ëŕíăŕđ",
      name_ru: "Ëŕíăŕđ",
    },
    {
      id: "733",
      district_id: "84",
      name_uz: "Mirzato‘p",
      name_oz: "Ěčđçŕň˘ď",
      name_ru: "Ěčđçŕň˘ď",
    },
    {
      id: "734",
      district_id: "84",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "735",
      district_id: "84",
      name_uz: "Olmazor",
      name_oz: "Îëěŕçîđ",
      name_ru: "Îëěŕçîđ",
    },
    {
      id: "736",
      district_id: "84",
      name_uz: "Pakandi",
      name_oz: "Ďŕęŕíäč",
      name_ru: "Ďŕęŕíäč",
    },
    {
      id: "737",
      district_id: "84",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "738",
      district_id: "84",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "739",
      district_id: "84",
      name_uz: "Taraqqiyot",
      name_oz: "Ňŕđŕ??č¸ň",
      name_ru: "Ňŕđŕ??č¸ň",
    },
    {
      id: "740",
      district_id: "84",
      name_uz: "Torjilg‘a",
      name_oz: "Ňîđćčë?ŕ",
      name_ru: "Ňîđćčë?ŕ",
    },
    {
      id: "741",
      district_id: "84",
      name_uz: "Uymovut",
      name_oz: "Óéěîâóň",
      name_ru: "Óéěîâóň",
    },
    {
      id: "742",
      district_id: "84",
      name_uz: "O‘ymovut",
      name_oz: "ˇéěîâóň",
      name_ru: "ˇéěîâóň",
    },
    {
      id: "743",
      district_id: "84",
      name_uz: "Uyshun",
      name_oz: "Óéřóí",
      name_ru: "Óéřóí",
    },
    {
      id: "744",
      district_id: "84",
      name_uz: "Xumo",
      name_oz: "Őóěî",
      name_ru: "Őóěî",
    },
    {
      id: "745",
      district_id: "84",
      name_uz: "Chiroqchi",
      name_oz: "×čđî?÷č",
      name_ru: "×čđî?÷č",
    },
    {
      id: "746",
      district_id: "84",
      name_uz: "Chiyal",
      name_oz: "×č˙ë",
      name_ru: "×č˙ë",
    },
    {
      id: "747",
      district_id: "84",
      name_uz: "Chiyal",
      name_oz: "×č˙ë",
      name_ru: "×č˙ë",
    },
    {
      id: "748",
      district_id: "84",
      name_uz: "Sho‘rquduq",
      name_oz: "Ř˘đ?óäó?",
      name_ru: "Ř˘đ?óäó?",
    },
    {
      id: "749",
      district_id: "84",
      name_uz: "Eski Anxor",
      name_oz: "Ýńęč Ŕíőîđ",
      name_ru: "Ýńęč Ŕíőîđ",
    },
    {
      id: "750",
      district_id: "84",
      name_uz: "Yangihayot",
      name_oz: "ßíăč?ŕ¸ň",
      name_ru: "ßíăč?ŕ¸ň",
    },
    {
      id: "751",
      district_id: "85",
      name_uz: "Ammog‘on-1",
      name_oz: "Ŕěěî?îí-1",
      name_ru: "Ŕěěî?îí-1",
    },
    {
      id: "752",
      district_id: "85",
      name_uz: "Anday",
      name_oz: "Ŕíäŕé",
      name_ru: "Ŕíäŕé",
    },
    {
      id: "753",
      district_id: "85",
      name_uz: "Do‘xchi",
      name_oz: "Ä˘ő÷č",
      name_ru: "Ä˘ő÷č",
    },
    {
      id: "754",
      district_id: "85",
      name_uz: "Keldihayot",
      name_oz: "Ęĺëäč?ŕ¸ň",
      name_ru: "Ęĺëäč?ŕ¸ň",
    },
    {
      id: "755",
      district_id: "85",
      name_uz: "Kunchiqar",
      name_oz: "Ęóí÷č?ŕđ",
      name_ru: "Ęóí÷č?ŕđ",
    },
    {
      id: "756",
      district_id: "85",
      name_uz: "Qumqishloq",
      name_oz: "?óě?čřëî?",
      name_ru: "?óě?čřëî?",
    },
    {
      id: "757",
      district_id: "85",
      name_uz: "Qutchi",
      name_oz: "?óň÷č",
      name_ru: "?óň÷č",
    },
    {
      id: "758",
      district_id: "85",
      name_uz: "Qutchi",
      name_oz: "?óň÷č",
      name_ru: "?óň÷č",
    },
    {
      id: "759",
      district_id: "85",
      name_uz: "Qo‘shqanot",
      name_oz: "?˘ř?ŕíîň",
      name_ru: "?˘ř?ŕíîň",
    },
    {
      id: "760",
      district_id: "85",
      name_uz: "Miraki",
      name_oz: "Ěčđŕęč",
      name_ru: "Ěčđŕęč",
    },
    {
      id: "761",
      district_id: "85",
      name_uz: "Mo‘minobod",
      name_oz: "Ě˘ěčíîáîä",
      name_ru: "Ě˘ěčíîáîä",
    },
    {
      id: "762",
      district_id: "85",
      name_uz: "Namaton",
      name_oz: "Íŕěŕňîí",
      name_ru: "Íŕěŕňîí",
    },
    {
      id: "763",
      district_id: "85",
      name_uz: "Oq suv",
      name_oz: "Î? ńóâ",
      name_ru: "Î? ńóâ",
    },
    {
      id: "764",
      district_id: "85",
      name_uz: "Temirchi",
      name_oz: "Ňĺěčđ÷č",
      name_ru: "Ňĺěčđ÷č",
    },
    {
      id: "765",
      district_id: "85",
      name_uz: "To‘damaydon",
      name_oz: "Ň˘äŕěŕéäîí",
      name_ru: "Ň˘äŕěŕéäîí",
    },
    {
      id: "766",
      district_id: "85",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "767",
      district_id: "85",
      name_uz: "O‘rtaqo‘rg‘on",
      name_oz: "ˇđňŕ?˘đ?îí",
      name_ru: "ˇđňŕ?˘đ?îí",
    },
    {
      id: "768",
      district_id: "85",
      name_uz: "Xitoy",
      name_oz: "Őčňîé",
      name_ru: "Őčňîé",
    },
    {
      id: "769",
      district_id: "85",
      name_uz: "Xo‘jaxuroson",
      name_oz: "Ő˘ćŕőóđîńîí",
      name_ru: "Ő˘ćŕőóđîńîí",
    },
    {
      id: "770",
      district_id: "85",
      name_uz: "Hisorak",
      name_oz: "?čńîđŕę",
      name_ru: "?čńîđŕę",
    },
    {
      id: "771",
      district_id: "85",
      name_uz: "Chorshanbe",
      name_oz: "×îđřŕíáĺ",
      name_ru: "×îđřŕíáĺ",
    },
    {
      id: "772",
      district_id: "85",
      name_uz: "Shakarteri",
      name_oz: "Řŕęŕđňĺđč",
      name_ru: "Řŕęŕđňĺđč",
    },
    {
      id: "773",
      district_id: "85",
      name_uz: "Shamaton",
      name_oz: "Řŕěŕňîí",
      name_ru: "Řŕěŕňîí",
    },
    {
      id: "774",
      district_id: "85",
      name_uz: "Shamaton",
      name_oz: "Řŕěŕňîí",
      name_ru: "Řŕěŕňîí",
    },
    {
      id: "775",
      district_id: "85",
      name_uz: "Yangiqishloq",
      name_oz: "ßíăč?čřëî?",
      name_ru: "ßíăč?čřëî?",
    },
    {
      id: "776",
      district_id: "86",
      name_uz: "Alako‘ylak",
      name_oz: "Ŕëŕę˘éëŕę",
      name_ru: "Ŕëŕę˘éëŕę",
    },
    {
      id: "777",
      district_id: "86",
      name_uz: "Alaqarg‘a",
      name_oz: "Ŕëŕ?ŕđ?ŕ",
      name_ru: "Ŕëŕ?ŕđ?ŕ",
    },
    {
      id: "778",
      district_id: "86",
      name_uz: "Jarqirg‘iz",
      name_oz: "Ćŕđ?čđ?čç",
      name_ru: "Ćŕđ?čđ?čç",
    },
    {
      id: "779",
      district_id: "86",
      name_uz: "Kattabog‘",
      name_oz: "Ęŕňňŕáî?",
      name_ru: "Ęŕňňŕáî?",
    },
    {
      id: "780",
      district_id: "86",
      name_uz: "Qayrag‘och",
      name_oz: "?ŕéđŕ?î÷",
      name_ru: "?ŕéđŕ?î÷",
    },
    {
      id: "781",
      district_id: "86",
      name_uz: "Qayrag‘och",
      name_oz: "?ŕéđŕ?î÷",
      name_ru: "?ŕéđŕ?î÷",
    },
    {
      id: "782",
      district_id: "86",
      name_uz: "Qatag‘on",
      name_oz: "?ŕňŕ?îí",
      name_ru: "?ŕňŕ?îí",
    },
    {
      id: "783",
      district_id: "86",
      name_uz: "Qishliq",
      name_oz: "?čřëč?",
      name_ru: "?čřëč?",
    },
    {
      id: "784",
      district_id: "86",
      name_uz: "Qo‘shchinor",
      name_oz: "?˘ř÷číîđ",
      name_ru: "?˘ř÷číîđ",
    },
    {
      id: "785",
      district_id: "86",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "786",
      district_id: "86",
      name_uz: "Mevazor",
      name_oz: "Ěĺâŕçîđ",
      name_ru: "Ěĺâŕçîđ",
    },
    {
      id: "787",
      district_id: "86",
      name_uz: "Samoq",
      name_oz: "Ńŕěî?",
      name_ru: "Ńŕěî?",
    },
    {
      id: "788",
      district_id: "86",
      name_uz: "Samoq",
      name_oz: "Ńŕěî?",
      name_ru: "Ńŕěî?",
    },
    {
      id: "789",
      district_id: "86",
      name_uz: "Sandal",
      name_oz: "Ńŕíäŕë",
      name_ru: "Ńŕíäŕë",
    },
    {
      id: "790",
      district_id: "86",
      name_uz: "Turon",
      name_oz: "Ňóđîí",
      name_ru: "Ňóđîí",
    },
    {
      id: "791",
      district_id: "86",
      name_uz: "O‘z",
      name_oz: "ˇç",
      name_ru: "ˇç",
    },
    {
      id: "792",
      district_id: "86",
      name_uz: "O‘rta",
      name_oz: "ˇđňŕ",
      name_ru: "ˇđňŕ",
    },
    {
      id: "793",
      district_id: "86",
      name_uz: "Hiyobon",
      name_oz: "?č¸áîí",
      name_ru: "?č¸áîí",
    },
    {
      id: "794",
      district_id: "86",
      name_uz: "Chaydori",
      name_oz: "×ŕéäîđč",
      name_ru: "×ŕéäîđč",
    },
    {
      id: "795",
      district_id: "86",
      name_uz: "Chubron",
      name_oz: "×óáđîí",
      name_ru: "×óáđîí",
    },
    {
      id: "796",
      district_id: "86",
      name_uz: "Edilbek",
      name_oz: "Ýäčëáĺę",
      name_ru: "Ýäčëáĺę",
    },
    {
      id: "797",
      district_id: "86",
      name_uz: "Esat",
      name_oz: "Ýńŕň",
      name_ru: "Ýńŕň",
    },
    {
      id: "798",
      district_id: "86",
      name_uz: "Eski Yakkabog‘",
      name_oz: "Ýńęč ßęęŕáî?",
      name_ru: "Ýńęč ßęęŕáî?",
    },
    {
      id: "799",
      district_id: "86",
      name_uz: "Yakkabog‘",
      name_oz: "ßęęŕáî?",
      name_ru: "ßęęŕáî?",
    },
    {
      id: "800",
      district_id: "88",
      name_uz: "Do‘rmon",
      name_oz: "Ä˘đěîí",
      name_ru: "Ä˘đěîí",
    },
    {
      id: "801",
      district_id: "88",
      name_uz: "Yoshlik",
      name_oz: "¨řëčę",
      name_ru: "¨řëčę",
    },
    {
      id: "802",
      district_id: "88",
      name_uz: "Jaloyir",
      name_oz: "Ćŕëîéčđ",
      name_ru: "Ćŕëîéčđ",
    },
    {
      id: "803",
      district_id: "88",
      name_uz: "Kamolot",
      name_oz: "Ęŕěîëîň",
      name_ru: "Ęŕěîëîň",
    },
    {
      id: "804",
      district_id: "88",
      name_uz: "Karmana",
      name_oz: "Ęŕđěŕíŕ",
      name_ru: "Ęŕđěŕíŕ",
    },
    {
      id: "805",
      district_id: "88",
      name_uz: "Malikrabot",
      name_oz: "Ěŕëčęđŕáîň",
      name_ru: "Ěŕëčęđŕáîň",
    },
    {
      id: "806",
      district_id: "88",
      name_uz: "Narpay",
      name_oz: "Íŕđďŕé",
      name_ru: "Íŕđďŕé",
    },
    {
      id: "807",
      district_id: "88",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "808",
      district_id: "88",
      name_uz: "Podkoron",
      name_oz: "Ďîäęîđîí",
      name_ru: "Ďîäęîđîí",
    },
    {
      id: "809",
      district_id: "88",
      name_uz: "Uyrot",
      name_oz: "Óéđîň",
      name_ru: "Óéđîň",
    },
    {
      id: "810",
      district_id: "88",
      name_uz: "Xazora",
      name_oz: "Őŕçîđŕ",
      name_ru: "Őŕçîđŕ",
    },
    {
      id: "811",
      district_id: "88",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđč?",
    },
    {
      id: "812",
      district_id: "90",
      name_uz: "Balaqaraq",
      name_oz: "Áŕëŕ?ŕđŕ?",
      name_ru: "Áŕëŕ?ŕđŕ?",
    },
    {
      id: "813",
      district_id: "90",
      name_uz: "Boymurot",
      name_oz: "Áîéěóđîň",
      name_ru: "Áîéěóđîň",
    },
    {
      id: "814",
      district_id: "90",
      name_uz: "Zafarobod",
      name_oz: "Çŕôŕđîáîä",
      name_ru: "Çŕôŕđîáîä",
    },
    {
      id: "815",
      district_id: "90",
      name_uz: "Karakata",
      name_oz: "Ęŕđŕęŕňŕ",
      name_ru: "Ęŕđŕęŕňŕ",
    },
    {
      id: "816",
      district_id: "90",
      name_uz: "Konimex",
      name_oz: "Ęîíčěĺő",
      name_ru: "Ęîíčěĺő",
    },
    {
      id: "817",
      district_id: "90",
      name_uz: "Mamiqchi",
      name_oz: "Ěŕěč?÷č",
      name_ru: "Ěŕěč?÷č",
    },
    {
      id: "818",
      district_id: "90",
      name_uz: "Sarajal",
      name_oz: "Ńŕđŕćŕë",
      name_ru: "Ńŕđŕćŕë",
    },
    {
      id: "819",
      district_id: "90",
      name_uz: "Uchtobe",
      name_oz: "Ó÷ňîáĺ",
      name_ru: "Ó÷ňîáĺ",
    },
    {
      id: "820",
      district_id: "90",
      name_uz: "Chordara",
      name_oz: "×îđäŕđŕ",
      name_ru: "×îđäŕđŕ",
    },
    {
      id: "821",
      district_id: "90",
      name_uz: "Sho‘rtepa",
      name_oz: "Ř˘đňĺďŕ",
      name_ru: "Ř˘đňĺďŕ",
    },
    {
      id: "822",
      district_id: "90",
      name_uz: "Yangig‘ozg‘on",
      name_oz: "ßíăč?îç?îí",
      name_ru: "ßíăč?îç?îí",
    },
    {
      id: "823",
      district_id: "90",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "824",
      district_id: "89",
      name_uz: "Arabon",
      name_oz: "Ŕđŕáîí",
      name_ru: "Ŕđŕáîí",
    },
    {
      id: "825",
      district_id: "89",
      name_uz: "Baland G‘ardiyon",
      name_oz: "Áŕëŕíä ?ŕđäč¸í",
      name_ru: "Áŕëŕíä ?ŕđäč¸í",
    },
    {
      id: "826",
      district_id: "89",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "827",
      district_id: "89",
      name_uz: "Vang‘ozi",
      name_oz: "Âŕí?îçč",
      name_ru: "Âŕí?îçč",
    },
    {
      id: "828",
      district_id: "89",
      name_uz: "Vang‘ozi",
      name_oz: "Âŕí?îçč",
      name_ru: "Âŕí?îçč",
    },
    {
      id: "829",
      district_id: "89",
      name_uz: "G‘amxo‘r",
      name_oz: "?ŕěő˘đ",
      name_ru: "?ŕěő˘đ",
    },
    {
      id: "830",
      district_id: "89",
      name_uz: "G‘ardiyon",
      name_oz: "?ŕđäč¸í",
      name_ru: "?ŕđäč¸í",
    },
    {
      id: "831",
      district_id: "89",
      name_uz: "G‘oyibon",
      name_oz: "?îéčáîí",
      name_ru: "?îéčáîí",
    },
    {
      id: "832",
      district_id: "89",
      name_uz: "Zarmetan",
      name_oz: "Çŕđěĺňŕí",
      name_ru: "Çŕđěĺňŕí",
    },
    {
      id: "833",
      district_id: "89",
      name_uz: "Zarmitan",
      name_oz: "Çŕđěčňŕí",
      name_ru: "Çŕđěčňŕí",
    },
    {
      id: "834",
      district_id: "89",
      name_uz: "Qalayn-Azizon",
      name_oz: "?ŕëŕéí-Ŕçčçîí",
      name_ru: "?ŕëŕéí-Ŕçčçîí",
    },
    {
      id: "835",
      district_id: "89",
      name_uz: "Qiziltepa",
      name_oz: "?čçčëňĺďŕ",
      name_ru: "?čçčëňĺďŕ",
    },
    {
      id: "836",
      district_id: "89",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "837",
      district_id: "89",
      name_uz: "Oq soch",
      name_oz: "Î? ńî÷",
      name_ru: "Î? ńî÷",
    },
    {
      id: "838",
      district_id: "89",
      name_uz: "Oqmachit",
      name_oz: "Î?ěŕ÷čň",
      name_ru: "Î?ěŕ÷čň",
    },
    {
      id: "839",
      district_id: "89",
      name_uz: "Uzilishkent",
      name_oz: "Óçčëčřęĺíň",
      name_ru: "Óçčëčřęĺíň",
    },
    {
      id: "840",
      district_id: "89",
      name_uz: "O‘rtaqo‘rg‘on",
      name_oz: "ˇđňŕ?˘đ?îí",
      name_ru: "ˇđňŕ?˘đ?îí",
    },
    {
      id: "841",
      district_id: "89",
      name_uz: "Xo‘ja-Hasan",
      name_oz: "Ő˘ćŕ-?ŕńŕí",
      name_ru: "Ő˘ćŕ-?ŕńŕí",
    },
    {
      id: "842",
      district_id: "89",
      name_uz: "Xo‘jaqo‘rg‘on",
      name_oz: "Ő˘ćŕ?˘đ?îí",
      name_ru: "Ő˘ćŕ?˘đ?îí",
    },
    {
      id: "843",
      district_id: "89",
      name_uz: "Husbuddin",
      name_oz: "?óńáóääčí",
      name_ru: "?óńáóääčí",
    },
    {
      id: "844",
      district_id: "89",
      name_uz: "Yangi Hayot",
      name_oz: "ßíăč ?ŕ¸ň",
      name_ru: "ßíăč ?ŕ¸ň",
    },
    {
      id: "845",
      district_id: "91",
      name_uz: "Arabsaroy",
      name_oz: "Ŕđŕáńŕđîé",
      name_ru: "Ŕđŕáńŕđîé",
    },
    {
      id: "846",
      district_id: "91",
      name_uz: "Beshrabot",
      name_oz: "Áĺřđŕáîň",
      name_ru: "Áĺřđŕáîň",
    },
    {
      id: "847",
      district_id: "91",
      name_uz: "Ijant",
      name_oz: "Čćŕíň",
      name_ru: "Čćŕíň",
    },
    {
      id: "848",
      district_id: "91",
      name_uz: "Kalkonota",
      name_oz: "Ęŕëęîíîňŕ",
      name_ru: "Ęŕëęîíîňŕ",
    },
    {
      id: "849",
      district_id: "91",
      name_uz: "Keskanterak",
      name_oz: "Ęĺńęŕíňĺđŕę",
      name_ru: "Ęĺńęŕíňĺđŕę",
    },
    {
      id: "850",
      district_id: "91",
      name_uz: "Keskanterak",
      name_oz: "Ęĺńęŕíňĺđŕę",
      name_ru: "Ęĺńęŕíňĺđŕę",
    },
    {
      id: "851",
      district_id: "91",
      name_uz: "Quyi Beshrabot",
      name_oz: "?óéč Áĺřđŕáîň",
      name_ru: "?óéč Áĺřđŕáîň",
    },
    {
      id: "852",
      district_id: "91",
      name_uz: "Olchin",
      name_oz: "Îë÷čí",
      name_ru: "Îë÷čí",
    },
    {
      id: "853",
      district_id: "91",
      name_uz: "Saroy",
      name_oz: "Ńŕđîé",
      name_ru: "Ńŕđîé",
    },
    {
      id: "854",
      district_id: "91",
      name_uz: "Turkiston",
      name_oz: "Ňóđęčńňîí",
      name_ru: "Ňóđęčńňîí",
    },
    {
      id: "855",
      district_id: "91",
      name_uz: "Yangi-yo‘l",
      name_oz: "ßíăč-é˘ë",
      name_ru: "ßíăč-é˘ë",
    },
    {
      id: "856",
      district_id: "91",
      name_uz: "Yangiqo‘rg‘on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăč?˘đ?îí",
    },
    {
      id: "857",
      district_id: "92",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "858",
      district_id: "93",
      name_uz: "Gum",
      name_oz: "Ăóě",
      name_ru: "Ăóě",
    },
    {
      id: "859",
      district_id: "93",
      name_uz: "G‘ozg‘on",
      name_oz: "?îç?îí",
      name_ru: "?îç?îí",
    },
    {
      id: "860",
      district_id: "93",
      name_uz: "Dehibaland",
      name_oz: "Äĺ?čáŕëŕíä",
      name_ru: "Äĺ?čáŕëŕíä",
    },
    {
      id: "861",
      district_id: "93",
      name_uz: "Qizilcha",
      name_oz: "?čçčë÷ŕ",
      name_ru: "?čçčë÷ŕ",
    },
    {
      id: "862",
      district_id: "93",
      name_uz: "Qizilcha",
      name_oz: "?čçčë÷ŕ",
      name_ru: "?čçčë÷ŕ",
    },
    {
      id: "863",
      district_id: "93",
      name_uz: "Nurota",
      name_oz: "Íóđîňŕ",
      name_ru: "Íóđîňŕ",
    },
    {
      id: "864",
      district_id: "93",
      name_uz: "Nurota",
      name_oz: "Íóđîňŕ",
      name_ru: "Íóđîňŕ",
    },
    {
      id: "865",
      district_id: "93",
      name_uz: "Sentob",
      name_oz: "Ńĺíňîá",
      name_ru: "Ńĺíňîá",
    },
    {
      id: "866",
      district_id: "93",
      name_uz: "Temurqovuq",
      name_oz: "Ňĺěóđ?îâó?",
      name_ru: "Ňĺěóđ?îâó?",
    },
    {
      id: "867",
      district_id: "93",
      name_uz: "Chuya",
      name_oz: "×ó˙",
      name_ru: "×ó˙",
    },
    {
      id: "868",
      district_id: "93",
      name_uz: "Chuya",
      name_oz: "×ó˙",
      name_ru: "×ó˙",
    },
    {
      id: "869",
      district_id: "93",
      name_uz: "Yangibino",
      name_oz: "ßíăčáčíî",
      name_ru: "ßíăčáčíî",
    },
    {
      id: "870",
      district_id: "94",
      name_uz: "Ayaqquduq",
      name_oz: "Ŕ˙??óäó?",
      name_ru: "Ŕ˙??óäó?",
    },
    {
      id: "871",
      district_id: "94",
      name_uz: "Keregetau",
      name_oz: "Ęĺđĺăĺňŕó",
      name_ru: "Ęĺđĺăĺňŕó",
    },
    {
      id: "872",
      district_id: "94",
      name_uz: "Keriz",
      name_oz: "Ęĺđčç",
      name_ru: "Ęĺđčç",
    },
    {
      id: "873",
      district_id: "94",
      name_uz: "Oktau",
      name_oz: "Îęňŕó",
      name_ru: "Îęňŕó",
    },
    {
      id: "874",
      district_id: "94",
      name_uz: "Suketti",
      name_oz: "Ńóęĺňňč",
      name_ru: "Ńóęĺňňč",
    },
    {
      id: "875",
      district_id: "94",
      name_uz: "Tomdibuloq",
      name_oz: "Ňîěäčáóëî?",
      name_ru: "Ňîěäčáóëî?",
    },
    {
      id: "876",
      district_id: "94",
      name_uz: "Tomdibuloq",
      name_oz: "Ňîěäčáóëî?",
      name_ru: "Ňîěäčáóëî?",
    },
    {
      id: "877",
      district_id: "94",
      name_uz: "Shiyeli",
      name_oz: "Řčĺëč",
      name_ru: "Řčĺëč",
    },
    {
      id: "878",
      district_id: "95",
      name_uz: "Altintov",
      name_oz: "Ŕëňčíňîâ",
      name_ru: "Ŕëňčíňîâ",
    },
    {
      id: "879",
      district_id: "95",
      name_uz: "Bozdun",
      name_oz: "Áîçäóí",
      name_ru: "Áîçäóí",
    },
    {
      id: "880",
      district_id: "95",
      name_uz: "Ko'kayaz",
      name_oz: "Ę˘ęŕ˙ç",
      name_ru: "Ę˘ęŕ˙ç",
    },
    {
      id: "881",
      district_id: "95",
      name_uz: "Mingbuloq",
      name_oz: "Ěčíăáóëî?",
      name_ru: "Ěčíăáóëî?",
    },
    {
      id: "882",
      district_id: "95",
      name_uz: "Shalxar",
      name_oz: "Óçóí?óäó?",
      name_ru: "Óçóí?óäó?",
    },
    {
      id: "883",
      district_id: "95",
      name_uz: "Uchquduq",
      name_oz: "Ó÷?óäó?",
      name_ru: "Ó÷?óäó?",
    },
    {
      id: "884",
      district_id: "95",
      name_uz: "Uzunquduq",
      name_oz: "Řŕëőŕđ",
      name_ru: "Řŕëőŕđ",
    },
    {
      id: "885",
      district_id: "96",
      name_uz: "Bog'chakalon",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Ŕë÷číŕáŕä",
    },
    {
      id: "886",
      district_id: "96",
      name_uz: "Bog'ishamol",
      name_oz: "Áî?÷ŕęŕëîí",
      name_ru: "Áŕő÷ŕęŕëŕí",
    },
    {
      id: "887",
      district_id: "96",
      name_uz: "G'alabek",
      name_oz: "?ŕëŕáĺę",
      name_ru: "Áîăčřŕěîë",
    },
    {
      id: "888",
      district_id: "96",
      name_uz: "Jaloyir",
      name_oz: "Ćŕëîéčđ",
      name_ru: "Ăŕëŕáĺę",
    },
    {
      id: "889",
      district_id: "96",
      name_uz: "Ko'ksaroy",
      name_oz: "Ę˘ęńŕđîé",
      name_ru: "Ăîpîäŕ pŕéîííîăî ďîä÷. Őŕňűp÷číńęîăî pŕéîíŕ",
    },
    {
      id: "890",
      district_id: "96",
      name_uz: "Langar",
      name_oz: "?îđŕ÷ŕ",
      name_ru: "Äćŕëîéčđ",
    },
    {
      id: "891",
      district_id: "96",
      name_uz: "O'zbekiston",
      name_oz: "?˘đ?îí",
      name_ru: "čě. Ďóëęŕí řŕčđŕ",
    },
    {
      id: "892",
      district_id: "96",
      name_uz: "Olchinobod",
      name_oz: "?˘ř÷číîđ",
      name_ru: "Ęŕpŕ÷ŕ",
    },
    {
      id: "893",
      district_id: "96",
      name_uz: "Paxtakor",
      name_oz: "Ëŕíăŕđ",
      name_ru: "Ęóęńŕđîé",
    },
    {
      id: "894",
      district_id: "96",
      name_uz: "Polvontepa",
      name_oz: "Îë÷číîáîä",
      name_ru: "Ęóđăîí",
    },
    {
      id: "895",
      district_id: "96",
      name_uz: "Pulkan shoir",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ęóř÷číîđ",
    },
    {
      id: "896",
      district_id: "96",
      name_uz: "Qo'rg'on",
      name_oz: "Ďîëâîíňĺďŕ",
      name_ru: "Ë˙íăŕđ",
    },
    {
      id: "897",
      district_id: "96",
      name_uz: "Qo'shchinor",
      name_oz: "Ďóëęŕí řîčđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "898",
      district_id: "96",
      name_uz: "Qoracha",
      name_oz: "Ńŕ?îâŕň",
      name_ru: "Ďîëâîíňĺďŕ",
    },
    {
      id: "899",
      district_id: "96",
      name_uz: "Sahovat",
      name_oz: "Ňŕńěŕ÷č",
      name_ru: "Ńŕőîâŕň",
    },
    {
      id: "900",
      district_id: "96",
      name_uz: "Tasmachi",
      name_oz: "Ňóđęěŕí",
      name_ru: "Ňŕńěŕ÷č",
    },
    {
      id: "901",
      district_id: "96",
      name_uz: "Turkman",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "Ňóđęěŕí",
    },
    {
      id: "902",
      district_id: "96",
      name_uz: "Xonaqa",
      name_oz: "Őîíŕ?ŕ",
      name_ru: "Óçáĺęčńňîí",
    },
    {
      id: "903",
      district_id: "96",
      name_uz: "Yangi qurilish",
      name_oz: "ßíăč ?óđčëčř",
      name_ru: "Őŕíŕęŕ",
    },
    {
      id: "904",
      district_id: "96",
      name_uz: "Yangirabod",
      name_oz: "ßíăčđŕáîä",
      name_ru: "ßíăč ęóđčëčř",
    },
    {
      id: "905",
      district_id: "96",
      name_uz: "Yangirabod",
      name_oz: "ßíăčđŕáîä",
      name_ru: "ßíăčpŕáîä",
    },
    {
      id: "906",
      district_id: "87",
      name_uz: "Nuruntau",
      name_oz: "Ěóđóíňŕó",
      name_ru: "Ěóđóíňŕó",
    },
    {
      id: "907",
      district_id: "97",
      name_uz: "Bog'ishamol",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Áîăčřŕěîë",
    },
    {
      id: "908",
      district_id: "97",
      name_uz: "Chindavul",
      name_oz: "¨řëčę",
      name_ru: "Ăîđîäŕ đŕéîííîăî ďîä÷číĺíč˙ Ęŕńŕíńŕéńęîăî đŕéîíŕ",
    },
    {
      id: "909",
      district_id: "97",
      name_uz: "Chindovul",
      name_oz: "Čńňč?ëîë",
      name_ru: "Ĺřëčę",
    },
    {
      id: "910",
      district_id: "97",
      name_uz: "Chust ko'cha",
      name_oz: "Ęîńîí",
      name_ru: "Čńňčęëîë",
    },
    {
      id: "911",
      district_id: "97",
      name_uz: "Istiqlol",
      name_oz: "Ęîńîí",
      name_ru: "Ęŕđŕńóâ",
    },
    {
      id: "912",
      district_id: "97",
      name_uz: "Koson",
      name_oz: "Ęîńîíńîé",
      name_ru: "Ęŕńŕí",
    },
    {
      id: "913",
      district_id: "97",
      name_uz: "Koson",
      name_oz: "?îđŕńóâ",
      name_ru: "Ęŕńŕíńŕé",
    },
    {
      id: "914",
      district_id: "97",
      name_uz: "Kosonsoy",
      name_oz: "?ó?óěáîé",
      name_ru: "Ęîńîí",
    },
    {
      id: "915",
      district_id: "97",
      name_uz: "Ozod",
      name_oz: "?ó?óěáîé",
      name_ru: "Ęóęóěáŕé",
    },
    {
      id: "916",
      district_id: "97",
      name_uz: "Qorasuv",
      name_oz: "Îçîä",
      name_ru: "Ęóęóěáîé",
    },
    {
      id: "917",
      district_id: "97",
      name_uz: "Ququmboy",
      name_oz: "Ňĺđăŕ÷č",
      name_ru: "Îçîä",
    },
    {
      id: "918",
      district_id: "97",
      name_uz: "Ququmboy",
      name_oz: "Ňĺđăŕ÷č",
      name_ru: "Ňĺđăŕ÷č",
    },
    {
      id: "919",
      district_id: "97",
      name_uz: "Shirin",
      name_oz: "×číäŕâóë",
      name_ru: "Ňĺđăŕ÷č",
    },
    {
      id: "920",
      district_id: "97",
      name_uz: "Tergachi",
      name_oz: "×číäîâóë",
      name_ru: "×číäŕâŕë",
    },
    {
      id: "921",
      district_id: "97",
      name_uz: "Tergachi",
      name_oz: "×óńň ę˘÷ŕ",
      name_ru: "×číäîâóë",
    },
    {
      id: "922",
      district_id: "97",
      name_uz: "Yangi shahar",
      name_oz: "Řčđčí",
      name_ru: "×óńň ęó÷ŕ",
    },
    {
      id: "923",
      district_id: "97",
      name_uz: "Yangiyo'l",
      name_oz: "ßíăč řŕ?ŕđ",
      name_ru: "Řčpčí",
    },
    {
      id: "924",
      district_id: "97",
      name_uz: "Yoshlik",
      name_oz: "ßíăčé˘ë",
      name_ru: "ßíăčéóë",
    },
    {
      id: "925",
      district_id: "97",
      name_uz: "Yangishahar",
      name_oz: "ßíăčřŕőŕđ",
      name_ru: "ßíăčřŕőŕđ",
    },
    {
      id: "926",
      district_id: "98",
      name_uz: "Bo'ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "927",
      district_id: "98",
      name_uz: "Dovduq",
      name_oz: "Ăóëáî?",
      name_ru: "Ăóëáî?",
    },
    {
      id: "928",
      district_id: "98",
      name_uz: "Dovduq",
      name_oz: "Ă˘đňĺďŕ",
      name_ru: "Ă˘đňĺďŕ",
    },
    {
      id: "929",
      district_id: "98",
      name_uz: "Go'rtepa",
      name_oz: "Ă˘đňĺďŕ",
      name_ru: "Ă˘đňĺďŕ",
    },
    {
      id: "930",
      district_id: "98",
      name_uz: "Go'rtepa",
      name_oz: "Äîâäó?",
      name_ru: "Äîâäó?",
    },
    {
      id: "931",
      district_id: "98",
      name_uz: "Gulbog'",
      name_oz: "Äîâäó?",
      name_ru: "Äîâäó?",
    },
    {
      id: "932",
      district_id: "98",
      name_uz: "Jo'masho'y",
      name_oz: "Ć˘ěŕř˘é",
      name_ru: "Ć˘ěŕř˘é",
    },
    {
      id: "933",
      district_id: "98",
      name_uz: "Kugolikul",
      name_oz: "Ęóăîëčęóë",
      name_ru: "Ęóăîëčęóë",
    },
    {
      id: "934",
      district_id: "98",
      name_uz: "Madyarovul",
      name_oz: "Ěŕä˙đîâóë",
      name_ru: "Ěŕä˙đîâóë",
    },
    {
      id: "935",
      district_id: "98",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "936",
      district_id: "98",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "937",
      district_id: "98",
      name_uz: "Momoxon",
      name_oz: "Ěîěîőîí",
      name_ru: "Ěîěîőîí",
    },
    {
      id: "938",
      district_id: "98",
      name_uz: "O'zgarish",
      name_oz: "Îëňčíę˘ë",
      name_ru: "Îëňčíę˘ë",
    },
    {
      id: "939",
      district_id: "98",
      name_uz: "Oltinko'l",
      name_oz: "ˇçăŕđčř",
      name_ru: "ˇçăŕđčř",
    },
    {
      id: "940",
      district_id: "100",
      name_uz: "Davlatobod tumani",
      name_oz: "Äŕâëŕňîáîä ňóěŕíč",
      name_ru: "Äŕâëŕňîáîä ňóěŕíč",
    },
    {
      id: "941",
      district_id: "100",
      name_uz: "Namangan shahrining tumanlari",
      name_oz: "Íŕěŕíăŕí řŕ?đčíčíă ňóěŕíëŕđč",
      name_ru: "Íŕěŕíăŕí řŕ?đčíčíă ňóěŕíëŕđč",
    },
    {
      id: "942",
      district_id: "100",
      name_uz: "Yangi Namangan tumani",
      name_oz: "ßíăč Íŕěŕíăŕí ňóěŕíč",
      name_ru: "ßíăč Íŕěŕíăŕí ňóěŕíč",
    },
    {
      id: "943",
      district_id: "99",
      name_uz: "Bog'ishamol",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Áî?čřŕěîë",
    },
    {
      id: "944",
      district_id: "99",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "945",
      district_id: "99",
      name_uz: "Mirishkor",
      name_oz: "?óě?˘đ?îí",
      name_ru: "?óě?˘đ?îí",
    },
    {
      id: "946",
      district_id: "99",
      name_uz: "Mirishkor",
      name_oz: "?óě?˘đ?îí",
      name_ru: "?óě?˘đ?îí",
    },
    {
      id: "947",
      district_id: "99",
      name_uz: "Navbahor",
      name_oz: "Ěčđčřęîđ",
      name_ru: "Ěčđčřęîđ",
    },
    {
      id: "948",
      district_id: "99",
      name_uz: "Navbahor",
      name_oz: "Ěčđčřęîđ",
      name_ru: "Ěčđčřęîđ",
    },
    {
      id: "949",
      district_id: "99",
      name_uz: "Nurafshon",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "950",
      district_id: "99",
      name_uz: "O'zbekiston",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "951",
      district_id: "99",
      name_uz: "Qumqo'rg'on",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "952",
      district_id: "99",
      name_uz: "Qumqo'rg'on",
      name_oz: "Ňĺďŕ?˘đ?îí",
      name_ru: "Ňĺďŕ?˘đ?îí",
    },
    {
      id: "953",
      district_id: "99",
      name_uz: "Sho'rqishloq",
      name_oz: "Ňîřáóëî?",
      name_ru: "Ňîřáóëî?",
    },
    {
      id: "954",
      district_id: "99",
      name_uz: "Sho'rqo'rg'on",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "955",
      district_id: "99",
      name_uz: "Tepaqo'rg'on",
      name_oz: "Őîíŕáîä",
      name_ru: "Őîíŕáîä",
    },
    {
      id: "956",
      district_id: "99",
      name_uz: "Toshbuloq",
      name_oz: "Ř˘đ?čřëî?",
      name_ru: "Ř˘đ?čřëî?",
    },
    {
      id: "957",
      district_id: "99",
      name_uz: "Xonabod",
      name_oz: "Ř˘đ?˘đ?îí",
      name_ru: "Ř˘đ?˘đ?îí",
    },
    {
      id: "958",
      district_id: "101",
      name_uz: "Chambil",
      name_oz: "×ŕěáčë",
      name_ru: "×ŕěáčë",
    },
    {
      id: "959",
      district_id: "101",
      name_uz: "Marg'izor",
      name_oz: "Ěŕđ?čçîđ",
      name_ru: "Ěŕđ?čçîđ",
    },
    {
      id: "960",
      district_id: "101",
      name_uz: "Marg'uzar",
      name_oz: "Ěŕđ?óçŕđ",
      name_ru: "Ěŕđ?óçŕđ",
    },
    {
      id: "961",
      district_id: "101",
      name_uz: "Norinkapa",
      name_oz: "Íîđčíęŕďŕ",
      name_ru: "Íîđčíęŕďŕ",
    },
    {
      id: "962",
      district_id: "101",
      name_uz: "Norinkapa",
      name_oz: "Íîđčíęŕďŕ",
      name_ru: "Íîđčíęŕďŕ",
    },
    {
      id: "963",
      district_id: "101",
      name_uz: "Pastki cho'ja",
      name_oz: "Ďŕńňęč ÷˘ćŕ",
      name_ru: "Ďŕńňęč ÷˘ćŕ",
    },
    {
      id: "964",
      district_id: "101",
      name_uz: "Paxtaqishloq",
      name_oz: "Ďŕőňŕ?čřëî?",
      name_ru: "Ďŕőňŕ?čřëî?",
    },
    {
      id: "965",
      district_id: "101",
      name_uz: "Qo'rg'ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "?˘đ?îíňĺďŕ",
    },
    {
      id: "966",
      district_id: "101",
      name_uz: "Qorateri",
      name_oz: "?îđŕňĺđč",
      name_ru: "?îđŕňĺđč",
    },
    {
      id: "967",
      district_id: "101",
      name_uz: "Sho'ra",
      name_oz: "Ř˘đŕ",
      name_ru: "Ř˘đŕ",
    },
    {
      id: "968",
      district_id: "101",
      name_uz: "To'da",
      name_oz: "Ň˘äŕ",
      name_ru: "Ň˘äŕ",
    },
    {
      id: "969",
      district_id: "101",
      name_uz: "Toshloq",
      name_oz: "Ňîřëî?",
      name_ru: "Ňîřëî?",
    },
    {
      id: "970",
      district_id: "101",
      name_uz: "Uchtepa",
      name_oz: "Ó÷ňĺďŕ",
      name_ru: "Ó÷ňĺďŕ",
    },
    {
      id: "971",
      district_id: "101",
      name_uz: "Uchtepa",
      name_oz: "Ó÷ňĺďŕ",
      name_ru: "Ó÷ňĺďŕ",
    },
    {
      id: "972",
      district_id: "101",
      name_uz: "Xaqqulobod",
      name_oz: "Őŕ??óëîáîä",
      name_ru: "Őŕ??óëîáîä",
    },
    {
      id: "973",
      district_id: "101",
      name_uz: "Xo'jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "974",
      district_id: "101",
      name_uz: "Xo'jaobod",
      name_oz: "Ő˘ćŕîáîä",
      name_ru: "Ő˘ćŕîáîä",
    },
    {
      id: "975",
      district_id: "102",
      name_uz: "Chodak",
      name_oz: "×îäŕę",
      name_ru: "×îäŕę",
    },
    {
      id: "976",
      district_id: "102",
      name_uz: "Chodak",
      name_oz: "×îäŕę",
      name_ru: "×îäŕę",
    },
    {
      id: "977",
      district_id: "102",
      name_uz: "Chorkesar",
      name_oz: "×îđęĺńŕđ",
      name_ru: "×îđęĺńŕđ",
    },
    {
      id: "978",
      district_id: "102",
      name_uz: "Chorkesar-2",
      name_oz: "×îđęĺńŕđ-2",
      name_ru: "×îđęĺńŕđ-2",
    },
    {
      id: "979",
      district_id: "102",
      name_uz: "G'urumsaroy",
      name_oz: "?óđóěńŕđîé",
      name_ru: "?óđóěńŕđîé",
    },
    {
      id: "980",
      district_id: "102",
      name_uz: "G'urumsaroy",
      name_oz: "?óđóěńŕđîé",
      name_ru: "?óđóěńŕđîé",
    },
    {
      id: "981",
      district_id: "102",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "982",
      district_id: "102",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "983",
      district_id: "102",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "984",
      district_id: "102",
      name_uz: "Nayman",
      name_oz: "Íŕéěŕí",
      name_ru: "Íŕéěŕí",
    },
    {
      id: "985",
      district_id: "102",
      name_uz: "Oltinkon",
      name_oz: "Îëňčíęîí",
      name_ru: "Îëňčíęîí",
    },
    {
      id: "986",
      district_id: "102",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "987",
      district_id: "102",
      name_uz: "Pop",
      name_oz: "Ďîď",
      name_ru: "Ďîď",
    },
    {
      id: "988",
      district_id: "102",
      name_uz: "Pop",
      name_oz: "Ďîď",
      name_ru: "Ďîď",
    },
    {
      id: "989",
      district_id: "102",
      name_uz: "Pungon",
      name_oz: "Ďóíăîí",
      name_ru: "Ďóíăîí",
    },
    {
      id: "990",
      district_id: "102",
      name_uz: "Pungon",
      name_oz: "Ďóíăîí",
      name_ru: "Ďóíăîí",
    },
    {
      id: "991",
      district_id: "102",
      name_uz: "Qandig'on",
      name_oz: "?ŕíäč?îí",
      name_ru: "?ŕíäč?îí",
    },
    {
      id: "992",
      district_id: "102",
      name_uz: "Qurg'onobod",
      name_oz: "?óđ?îíîáîä",
      name_ru: "?óđ?îíîáîä",
    },
    {
      id: "993",
      district_id: "102",
      name_uz: "Sang",
      name_oz: "Ńŕíă",
      name_ru: "Ńŕíă",
    },
    {
      id: "994",
      district_id: "102",
      name_uz: "Sirdaryo",
      name_oz: "Ńčđäŕđ¸",
      name_ru: "Ńčđäŕđ¸",
    },
    {
      id: "995",
      district_id: "102",
      name_uz: "Uyg'ur",
      name_oz: "Óé?óđ",
      name_ru: "Óé?óđ",
    },
    {
      id: "996",
      district_id: "102",
      name_uz: "Uyg'ur",
      name_oz: "Óé?óđ",
      name_ru: "Óé?óđ",
    },
    {
      id: "997",
      district_id: "102",
      name_uz: "Uyg'ursoy",
      name_oz: "Óé?óđńîé",
      name_ru: "Óé?óđńîé",
    },
    {
      id: "998",
      district_id: "102",
      name_uz: "Xalqobod",
      name_oz: "Őŕë?îáîä",
      name_ru: "Őŕë?îáîä",
    },
    {
      id: "999",
      district_id: "102",
      name_uz: "Yangi hayot",
      name_oz: "ßíăč ?ŕ¸ň",
      name_ru: "ßíăč ?ŕ¸ň",
    },
    {
      id: "1000",
      district_id: "102",
      name_uz: "Yangi Xo'jaobod",
      name_oz: "ßíăč Ő˘ćŕîáîä",
      name_ru: "ßíăč Ő˘ćŕîáîä",
    },
    {
      id: "1001",
      district_id: "103",
      name_uz: "Axsi",
      name_oz: "Ŕőńč",
      name_ru: "Ŕőńč",
    },
    {
      id: "1002",
      district_id: "103",
      name_uz: "Axsi",
      name_oz: "Ŕőńč",
      name_ru: "Ŕőńč",
    },
    {
      id: "1003",
      district_id: "103",
      name_uz: "Buramatut",
      name_oz: "Áóđŕěŕňóň",
      name_ru: "Áóđŕěŕňóň",
    },
    {
      id: "1004",
      district_id: "103",
      name_uz: "Buramatut",
      name_oz: "Áóđŕěŕňóň",
      name_ru: "Áóđŕěŕňóň",
    },
    {
      id: "1005",
      district_id: "103",
      name_uz: "Kalvak",
      name_oz: "Ęŕëâŕę",
      name_ru: "Ęŕëâŕę",
    },
    {
      id: "1006",
      district_id: "103",
      name_uz: "Katagansaroy",
      name_oz: "Ęŕňŕăŕíńŕđîé",
      name_ru: "Ęŕňŕăŕíńŕđîé",
    },
    {
      id: "1007",
      district_id: "103",
      name_uz: "Katagon",
      name_oz: "Ęŕňŕăîí",
      name_ru: "Ęŕňŕăîí",
    },
    {
      id: "1008",
      district_id: "103",
      name_uz: "Kichikqurama",
      name_oz: "Ęč÷čę?óđŕěŕ",
      name_ru: "Ęč÷čę?óđŕěŕ",
    },
    {
      id: "1009",
      district_id: "103",
      name_uz: "Mozorko'xna",
      name_oz: "Ěîçîđę˘őíŕ",
      name_ru: "Ěîçîđę˘őíŕ",
    },
    {
      id: "1010",
      district_id: "103",
      name_uz: "Namdon",
      name_oz: "Íŕěäîí",
      name_ru: "Íŕěäîí",
    },
    {
      id: "1011",
      district_id: "103",
      name_uz: "Olchin",
      name_oz: "Îë÷čí",
      name_ru: "Îë÷čí",
    },
    {
      id: "1012",
      district_id: "103",
      name_uz: "Oqtosh",
      name_oz: "Î?ňîř",
      name_ru: "Î?ňîř",
    },
    {
      id: "1013",
      district_id: "103",
      name_uz: "Saroy",
      name_oz: "Ńŕđîé",
      name_ru: "Ńŕđîé",
    },
    {
      id: "1014",
      district_id: "103",
      name_uz: "Sayram",
      name_oz: "Ńŕéđŕě",
      name_ru: "Ńŕéđŕě",
    },
    {
      id: "1015",
      district_id: "103",
      name_uz: "Shaxand",
      name_oz: "Řŕőŕíä",
      name_ru: "Řŕőŕíä",
    },
    {
      id: "1016",
      district_id: "103",
      name_uz: "Shaxand",
      name_oz: "Řŕőŕíä",
      name_ru: "Řŕőŕíä",
    },
    {
      id: "1017",
      district_id: "103",
      name_uz: "To'raqo'rg'on",
      name_oz: "Ň˘đŕ?˘đ?îí",
      name_ru: "Ň˘đŕ?˘đ?îí",
    },
    {
      id: "1018",
      district_id: "103",
      name_uz: "Xo'jand",
      name_oz: "Ő˘ćŕíä",
      name_ru: "Ő˘ćŕíä",
    },
    {
      id: "1019",
      district_id: "103",
      name_uz: "Yandama",
      name_oz: "ßíäŕěŕ",
      name_ru: "ßíäŕěŕ",
    },
    {
      id: "1020",
      district_id: "103",
      name_uz: "Yandama",
      name_oz: "ßíäŕěŕ",
      name_ru: "ßíäŕěŕ",
    },
    {
      id: "1021",
      district_id: "103",
      name_uz: "Yettikon",
      name_oz: "Ĺňňčęîí",
      name_ru: "Ĺňňčęîí",
    },
    {
      id: "1022",
      district_id: "103",
      name_uz: "Yortepa",
      name_oz: "¨đňĺďŕ",
      name_ru: "¨đňĺďŕ",
    },
    {
      id: "1023",
      district_id: "105",
      name_uz: "Baxt",
      name_oz: "Áŕőň",
      name_ru: "Áŕőň",
    },
    {
      id: "1024",
      district_id: "105",
      name_uz: "O'zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "1025",
      district_id: "105",
      name_uz: "Qayqi",
      name_oz: "?ŕé?č",
      name_ru: "?ŕé?č",
    },
    {
      id: "1026",
      district_id: "105",
      name_uz: "Qayqi",
      name_oz: "?ŕé?č",
      name_ru: "?ŕé?č",
    },
    {
      id: "1027",
      district_id: "105",
      name_uz: "Qo'g'ay",
      name_oz: "?˘?ŕé",
      name_ru: "?˘?ŕé",
    },
    {
      id: "1028",
      district_id: "105",
      name_uz: "Qo'g'ay",
      name_oz: "?˘?ŕé",
      name_ru: "?˘?ŕé",
    },
    {
      id: "1029",
      district_id: "105",
      name_uz: "Qo'g'ay-o'lmas",
      name_oz: "?˘?ŕé-˘ëěŕń",
      name_ru: "?˘?ŕé-˘ëěŕń",
    },
    {
      id: "1030",
      district_id: "105",
      name_uz: "Uchqo'rg'on",
      name_oz: "Ó÷?˘đ?îí",
      name_ru: "Ó÷?˘đ?îí",
    },
    {
      id: "1031",
      district_id: "105",
      name_uz: "Uchyog'och",
      name_oz: "Ó÷¸?î÷",
      name_ru: "Ó÷¸?î÷",
    },
    {
      id: "1032",
      district_id: "105",
      name_uz: "Yangi yor",
      name_oz: "ßíăč ¸đ",
      name_ru: "ßíăč ¸đ",
    },
    {
      id: "1033",
      district_id: "105",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1034",
      district_id: "105",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1035",
      district_id: "105",
      name_uz: "Yashiq",
      name_oz: "ßřč?",
      name_ru: "ßřč?",
    },
    {
      id: "1036",
      district_id: "104",
      name_uz: "Axsi",
      name_oz: "Ŕőńč",
      name_ru: "Ŕőńč",
    },
    {
      id: "1037",
      district_id: "104",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "1038",
      district_id: "104",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "1039",
      district_id: "104",
      name_uz: "Boyog'on",
      name_oz: "Áî¸?îí",
      name_ru: "Áî¸?îí",
    },
    {
      id: "1040",
      district_id: "104",
      name_uz: "Churtuk",
      name_oz: "×óđňóę",
      name_ru: "×óđňóę",
    },
    {
      id: "1041",
      district_id: "104",
      name_uz: "Fayziobod",
      name_oz: "Ôŕéçčîáîä",
      name_ru: "Ôŕéçčîáîä",
    },
    {
      id: "1042",
      district_id: "104",
      name_uz: "G'ayrat",
      name_oz: "?ŕéđŕň",
      name_ru: "?ŕéđŕň",
    },
    {
      id: "1043",
      district_id: "104",
      name_uz: "G'ayrat",
      name_oz: "?ŕéđŕň",
      name_ru: "?ŕéđŕň",
    },
    {
      id: "1044",
      district_id: "104",
      name_uz: "Jiydakapa",
      name_oz: "Ćčéäŕęŕďŕ",
      name_ru: "Ćčéäŕęŕďŕ",
    },
    {
      id: "1045",
      district_id: "104",
      name_uz: "Jiydakapa",
      name_oz: "Ćčéäŕęŕďŕ",
      name_ru: "Ćčéäŕęŕďŕ",
    },
    {
      id: "1046",
      district_id: "104",
      name_uz: "Kichik toshloq",
      name_oz: "Ęč÷čę ňîřëî?",
      name_ru: "Ęč÷čę ňîřëî?",
    },
    {
      id: "1047",
      district_id: "104",
      name_uz: "Mashad",
      name_oz: "Ěŕřŕä",
      name_ru: "Ěŕřŕä",
    },
    {
      id: "1048",
      district_id: "104",
      name_uz: "Mashad",
      name_oz: "Ěŕřŕä",
      name_ru: "Ěŕřŕä",
    },
    {
      id: "1049",
      district_id: "104",
      name_uz: "O'nxayat",
      name_oz: "ˇíőŕ˙ň",
      name_ru: "ˇíőŕ˙ň",
    },
    {
      id: "1050",
      district_id: "104",
      name_uz: "Soku",
      name_oz: "Ńîęó",
      name_ru: "Ńîęó",
    },
    {
      id: "1051",
      district_id: "104",
      name_uz: "Teshiktosh",
      name_oz: "Ňĺřčęňîř",
      name_ru: "Ňĺřčęňîř",
    },
    {
      id: "1052",
      district_id: "104",
      name_uz: "Uychi",
      name_oz: "Óé÷č",
      name_ru: "Óé÷č",
    },
    {
      id: "1053",
      district_id: "104",
      name_uz: "Uychi",
      name_oz: "Óé÷č",
      name_ru: "Óé÷č",
    },
    {
      id: "1054",
      district_id: "104",
      name_uz: "Yorkatay",
      name_oz: "¨đęŕňŕé",
      name_ru: "¨đęŕňŕé",
    },
    {
      id: "1055",
      district_id: "104",
      name_uz: "Yorqo'rg'on",
      name_oz: "¨đ?˘đ?îí",
      name_ru: "¨đ?˘đ?îí",
    },
    {
      id: "1056",
      district_id: "104",
      name_uz: "Ziyokor",
      name_oz: "Çč¸ęîđ",
      name_ru: "Çč¸ęîđ",
    },
    {
      id: "1057",
      district_id: "108",
      name_uz: "Bekobod",
      name_oz: "Áĺęîáîä",
      name_ru: "Áĺęîáîä",
    },
    {
      id: "1058",
      district_id: "108",
      name_uz: "Bekobod",
      name_oz: "Áĺęîáîä",
      name_ru: "Áĺęîáîä",
    },
    {
      id: "1059",
      district_id: "108",
      name_uz: "Birlashkan",
      name_oz: "Áčđëŕřęŕí",
      name_ru: "Áčđëŕřęŕí",
    },
    {
      id: "1060",
      district_id: "108",
      name_uz: "G'ovazon",
      name_oz: "?îâŕçîí",
      name_ru: "?îâŕçîí",
    },
    {
      id: "1061",
      district_id: "108",
      name_uz: "Iskavot",
      name_oz: "Čńęŕâîň",
      name_ru: "Čńęŕâîň",
    },
    {
      id: "1062",
      district_id: "108",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "1063",
      district_id: "108",
      name_uz: "Kalisho",
      name_oz: "Ęŕëčřî",
      name_ru: "Ęŕëčřî",
    },
    {
      id: "1064",
      district_id: "108",
      name_uz: "Ko'kyor",
      name_oz: "Ę˘ę¸đ",
      name_ru: "Ę˘ę¸đ",
    },
    {
      id: "1065",
      district_id: "108",
      name_uz: "Nanay",
      name_oz: "Íŕíŕé",
      name_ru: "Íŕíŕé",
    },
    {
      id: "1066",
      district_id: "108",
      name_uz: "Nanay",
      name_oz: "Íŕíŕé",
      name_ru: "Íŕíŕé",
    },
    {
      id: "1067",
      district_id: "108",
      name_uz: "Navkent",
      name_oz: "Íŕâęĺíň",
      name_ru: "Íŕâęĺíň",
    },
    {
      id: "1068",
      district_id: "108",
      name_uz: "Navkent",
      name_oz: "Íŕâęĺíň",
      name_ru: "Íŕâęĺíň",
    },
    {
      id: "1069",
      district_id: "108",
      name_uz: "Navro'zobod",
      name_oz: "Íŕâđ˘çîáîä",
      name_ru: "Íŕâđ˘çîáîä",
    },
    {
      id: "1070",
      district_id: "108",
      name_uz: "Poromon",
      name_oz: "Ďîđîěîí",
      name_ru: "Ďîđîěîí",
    },
    {
      id: "1071",
      district_id: "108",
      name_uz: "Poromon",
      name_oz: "Ďîđîěîí",
      name_ru: "Ďîđîěîí",
    },
    {
      id: "1072",
      district_id: "108",
      name_uz: "Qizil qiyoq",
      name_oz: "?čçčë ?č¸?",
      name_ru: "?čçčë ?č¸?",
    },
    {
      id: "1073",
      district_id: "108",
      name_uz: "Qorachasho'rkent",
      name_oz: "?îđŕ÷ŕř˘đęĺíň",
      name_ru: "?îđŕ÷ŕř˘đęĺíň",
    },
    {
      id: "1074",
      district_id: "108",
      name_uz: "Qorapolvon",
      name_oz: "?îđŕďîëâîí",
      name_ru: "?îđŕďîëâîí",
    },
    {
      id: "1075",
      district_id: "108",
      name_uz: "Qorapolvon",
      name_oz: "?îđŕďîëâîí",
      name_ru: "?îđŕďîëâîí",
    },
    {
      id: "1076",
      district_id: "108",
      name_uz: "Qorayong'oq",
      name_oz: "?îđŕ¸í?î?",
      name_ru: "?îđŕ¸í?î?",
    },
    {
      id: "1077",
      district_id: "108",
      name_uz: "Rovot",
      name_oz: "Đîâîň",
      name_ru: "Đîâîň",
    },
    {
      id: "1078",
      district_id: "108",
      name_uz: "Salmon",
      name_oz: "Ńŕëěîí",
      name_ru: "Ńŕëěîí",
    },
    {
      id: "1079",
      district_id: "108",
      name_uz: "Sangiston",
      name_oz: "Ńŕíăčńňîí",
      name_ru: "Ńŕíăčńňîí",
    },
    {
      id: "1080",
      district_id: "108",
      name_uz: "Sharq yulduzi",
      name_oz: "Řŕđ? ţëäóçč",
      name_ru: "Řŕđ? ţëäóçč",
    },
    {
      id: "1081",
      district_id: "108",
      name_uz: "Xo'jasho'rkent",
      name_oz: "Ő˘ćŕř˘đęĺíň",
      name_ru: "Ő˘ćŕř˘đęĺíň",
    },
    {
      id: "1082",
      district_id: "108",
      name_uz: "Yangiqo'rg'on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăč?˘đ?îí",
    },
    {
      id: "1083",
      district_id: "108",
      name_uz: "Yumaloqtepa",
      name_oz: "Ţěŕëî?ňĺďŕ",
      name_ru: "Ţěŕëî?ňĺďŕ",
    },
    {
      id: "1084",
      district_id: "108",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "1085",
      district_id: "108",
      name_uz: "Zarkent",
      name_oz: "Çŕđęĺíň",
      name_ru: "Çŕđęĺíň",
    },
    {
      id: "1086",
      district_id: "108",
      name_uz: "Zarkent",
      name_oz: "Çŕđęĺíň",
      name_ru: "Çŕđęĺíň",
    },
    {
      id: "1087",
      district_id: "107",
      name_uz: "Axcha",
      name_oz: "Ŕő÷ŕ",
      name_ru: "Ŕő÷ŕ",
    },
    {
      id: "1088",
      district_id: "107",
      name_uz: "Axcha",
      name_oz: "Ŕő÷ŕ",
      name_ru: "Ŕő÷ŕ",
    },
    {
      id: "1089",
      district_id: "107",
      name_uz: "Baymoq",
      name_oz: "Áŕéěî?",
      name_ru: "Áŕéěî?",
    },
    {
      id: "1090",
      district_id: "107",
      name_uz: "Chust",
      name_oz: "×óńň",
      name_ru: "×óńň",
    },
    {
      id: "1091",
      district_id: "107",
      name_uz: "G'ova",
      name_oz: "?îâŕ",
      name_ru: "?îâŕ",
    },
    {
      id: "1092",
      district_id: "107",
      name_uz: "G'ova",
      name_oz: "?îâŕ",
      name_ru: "?îâŕ",
    },
    {
      id: "1093",
      district_id: "107",
      name_uz: "Karkidon",
      name_oz: "Ęŕđęčäîí",
      name_ru: "Ęŕđęčäîí",
    },
    {
      id: "1094",
      district_id: "107",
      name_uz: "Karkidon",
      name_oz: "Ęŕđęčäîí",
      name_ru: "Ęŕđęčäîí",
    },
    {
      id: "1095",
      district_id: "107",
      name_uz: "Karnon",
      name_oz: "Ęŕđíîí",
      name_ru: "Ęŕđíîí",
    },
    {
      id: "1096",
      district_id: "107",
      name_uz: "Karnon",
      name_oz: "Ęŕđíîí",
      name_ru: "Ęŕđíîí",
    },
    {
      id: "1097",
      district_id: "107",
      name_uz: "Og'asaray",
      name_oz: "Î?ŕńŕđŕé",
      name_ru: "Î?ŕńŕđŕé",
    },
    {
      id: "1098",
      district_id: "107",
      name_uz: "Olmos",
      name_oz: "Îëěîń",
      name_ru: "Îëěîń",
    },
    {
      id: "1099",
      district_id: "107",
      name_uz: "Olmos",
      name_oz: "Îëěîń",
      name_ru: "Îëěîń",
    },
    {
      id: "1100",
      district_id: "107",
      name_uz: "Qoraqo'rg'on",
      name_oz: "?îđŕ?˘đ?îí",
      name_ru: "?îđŕ?˘đ?îí",
    },
    {
      id: "1101",
      district_id: "107",
      name_uz: "Sarimsoqtepa",
      name_oz: "Ńŕđčěńî?ňĺďŕ",
      name_ru: "Ńŕđčěńî?ňĺďŕ",
    },
    {
      id: "1102",
      district_id: "107",
      name_uz: "Sho'rkent",
      name_oz: "Ř˘đęĺíň",
      name_ru: "Ř˘đęĺíň",
    },
    {
      id: "1103",
      district_id: "107",
      name_uz: "Shoyon",
      name_oz: "Řî¸í",
      name_ru: "Řî¸í",
    },
    {
      id: "1104",
      district_id: "107",
      name_uz: "Shoyon",
      name_oz: "Řî¸í",
      name_ru: "Řî¸í",
    },
    {
      id: "1105",
      district_id: "107",
      name_uz: "Varzik",
      name_oz: "Âŕđçčę",
      name_ru: "Âŕđçčę",
    },
    {
      id: "1106",
      district_id: "107",
      name_uz: "Varzik",
      name_oz: "Âŕđçčę",
      name_ru: "Âŕđçčę",
    },
    {
      id: "1107",
      district_id: "107",
      name_uz: "Xisorak",
      name_oz: "Őčńîđŕę",
      name_ru: "Őčńîđŕę",
    },
    {
      id: "1108",
      district_id: "107",
      name_uz: "Xisorak",
      name_oz: "Őčńîđŕę",
      name_ru: "Őčńîđŕę",
    },
    {
      id: "1109",
      district_id: "107",
      name_uz: "Yorqishloq",
      name_oz: "¨đ?čřëî?",
      name_ru: "¨đ?čřëî?",
    },
    {
      id: "1110",
      district_id: "106",
      name_uz: "Alixon",
      name_oz: "Ŕëčőîí",
      name_ru: "Ŕëčőîí",
    },
    {
      id: "1111",
      district_id: "106",
      name_uz: "Alixon",
      name_oz: "Ŕëčőîí",
      name_ru: "Ŕëčőîí",
    },
    {
      id: "1112",
      district_id: "106",
      name_uz: "Ayqiron",
      name_oz: "Ŕé?čđîí",
      name_ru: "Ŕé?čđîí",
    },
    {
      id: "1113",
      district_id: "106",
      name_uz: "Ayqiron",
      name_oz: "Ŕé?čđîí",
      name_ru: "Ŕé?čđîí",
    },
    {
      id: "1114",
      district_id: "106",
      name_uz: "Baliqli ko'l",
      name_oz: "Áŕëč?ëč ę˘ë",
      name_ru: "Áŕëč?ëč ę˘ë",
    },
    {
      id: "1115",
      district_id: "106",
      name_uz: "Bog'iston",
      name_oz: "Áî?čńňîí",
      name_ru: "Áî?čńňîí",
    },
    {
      id: "1116",
      district_id: "106",
      name_uz: "Chortoq",
      name_oz: "×îđňî?",
      name_ru: "×îđňî?",
    },
    {
      id: "1117",
      district_id: "106",
      name_uz: "Karaskan",
      name_oz: "Ęŕđŕńęŕí",
      name_ru: "Ęŕđŕńęŕí",
    },
    {
      id: "1118",
      district_id: "106",
      name_uz: "Ko'shan",
      name_oz: "Ę˘řŕí",
      name_ru: "Ę˘řŕí",
    },
    {
      id: "1119",
      district_id: "106",
      name_uz: "Koroskon",
      name_oz: "Ęîđîńęîí",
      name_ru: "Ęîđîńęîí",
    },
    {
      id: "1120",
      district_id: "106",
      name_uz: "Muchum",
      name_oz: "Ěó÷óě",
      name_ru: "Ěó÷óě",
    },
    {
      id: "1121",
      district_id: "106",
      name_uz: "Muchum",
      name_oz: "Ěó÷óě",
      name_ru: "Ěó÷óě",
    },
    {
      id: "1122",
      district_id: "106",
      name_uz: "Ora ariq",
      name_oz: "Îđŕ ŕđč?",
      name_ru: "Îđŕ ŕđč?",
    },
    {
      id: "1123",
      district_id: "106",
      name_uz: "Pastki Peshqo'rg'on",
      name_oz: "Ďŕńňęč Ďĺř?˘đ?îí",
      name_ru: "Ďŕńňęč Ďĺř?˘đ?îí",
    },
    {
      id: "1124",
      district_id: "106",
      name_uz: "Peshqo'rg'on",
      name_oz: "Ďĺř?˘đ?îí",
      name_ru: "Ďĺř?˘đ?îí",
    },
    {
      id: "1125",
      district_id: "106",
      name_uz: "Saroy",
      name_oz: "Ńŕđîé",
      name_ru: "Ńŕđîé",
    },
    {
      id: "1126",
      district_id: "106",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "1127",
      district_id: "106",
      name_uz: "Xazratishox",
      name_oz: "Őŕçđŕňčřîő",
      name_ru: "Őŕçđŕňčřîő",
    },
    {
      id: "1128",
      district_id: "106",
      name_uz: "Xazratishox",
      name_oz: "Őŕçđŕňčřîő",
      name_ru: "Őŕçđŕňčřîő",
    },
    {
      id: "1129",
      district_id: "106",
      name_uz: "Yuqori Peshqo'rg'on",
      name_oz: "Ţ?îđč Ďĺř?˘đ?îí",
      name_ru: "Ţ?îđč Ďĺř?˘đ?îí",
    },
    {
      id: "1130",
      district_id: "109",
      name_uz: "Beshqo'ton",
      name_oz: "Áĺř?˘ňîí",
      name_ru: "Áĺř?˘ňîí",
    },
    {
      id: "1131",
      district_id: "109",
      name_uz: "Bog'bon",
      name_oz: "Áî?áîí",
      name_ru: "Áî?áîí",
    },
    {
      id: "1132",
      district_id: "109",
      name_uz: "Bulung'ur",
      name_oz: "Áóëóí?óđ",
      name_ru: "Áóëóí?óđ",
    },
    {
      id: "1133",
      district_id: "109",
      name_uz: "F.Yo'ldoshev nomli",
      name_oz: "F.Yo'ldoshev íîěëč",
      name_ru: "F.Yo'ldoshev íîěëč",
    },
    {
      id: "1134",
      district_id: "109",
      name_uz: "Kildon",
      name_oz: "Ęčëäîí",
      name_ru: "Ęčëäîí",
    },
    {
      id: "1135",
      district_id: "109",
      name_uz: "Kildon",
      name_oz: "Ęčëäîí",
      name_ru: "Ęčëäîí",
    },
    {
      id: "1136",
      district_id: "109",
      name_uz: "Kulchabiy",
      name_oz: "Ęóë÷ŕáčé",
      name_ru: "Ęóë÷ŕáčé",
    },
    {
      id: "1137",
      district_id: "109",
      name_uz: "Navoiy nomli",
      name_oz: "Íŕâîčé íîěëč",
      name_ru: "Íŕâîčé íîěëč",
    },
    {
      id: "1138",
      district_id: "109",
      name_uz: "O'rtabuloq",
      name_oz: "ˇđňŕáóëî?",
      name_ru: "ˇđňŕáóëî?",
    },
    {
      id: "1139",
      district_id: "109",
      name_uz: "Soxibkor",
      name_oz: "Ńîőčáęîđ",
      name_ru: "Ńîőčáęîđ",
    },
    {
      id: "1140",
      district_id: "109",
      name_uz: "Soxibkor",
      name_oz: "Ńîőčáęîđ",
      name_ru: "Ńîőčáęîđ",
    },
    {
      id: "1141",
      district_id: "111",
      name_uz: "Azamat",
      name_oz: "Ŕçŕěŕň",
      name_ru: "Ŕçŕěŕň",
    },
    {
      id: "1142",
      district_id: "111",
      name_uz: "Azamat",
      name_oz: "Ŕçŕěŕň",
      name_ru: "Ŕçŕěŕň",
    },
    {
      id: "1143",
      district_id: "111",
      name_uz: "Bahrin",
      name_oz: "Áŕ?đčí",
      name_ru: "Áŕ?đčí",
    },
    {
      id: "1144",
      district_id: "111",
      name_uz: "Chordara",
      name_oz: "×îđäŕđŕ",
      name_ru: "×îđäŕđŕ",
    },
    {
      id: "1145",
      district_id: "111",
      name_uz: "Damariq",
      name_oz: "Äŕěŕđč?",
      name_ru: "Äŕěŕđč?",
    },
    {
      id: "1146",
      district_id: "111",
      name_uz: "Fayziobod",
      name_oz: "Ôŕéçčîáîä",
      name_ru: "Ôŕéçčîáîä",
    },
    {
      id: "1147",
      district_id: "111",
      name_uz: "Halqabod",
      name_oz: "?ŕë?ŕáîä",
      name_ru: "?ŕë?ŕáîä",
    },
    {
      id: "1148",
      district_id: "111",
      name_uz: "Haqiqat",
      name_oz: "?ŕ?č?ŕň",
      name_ru: "?ŕ?č?ŕň",
    },
    {
      id: "1149",
      district_id: "111",
      name_uz: "Ishtixon",
      name_oz: "Čřňčőîí",
      name_ru: "Čřňčőîí",
    },
    {
      id: "1150",
      district_id: "111",
      name_uz: "Mitan",
      name_oz: "Ěčňŕí",
      name_ru: "Ěčňŕí",
    },
    {
      id: "1151",
      district_id: "111",
      name_uz: "O'rtaqishloq",
      name_oz: "ˇđňŕ?čřëî?",
      name_ru: "ˇđňŕ?čřëî?",
    },
    {
      id: "1152",
      district_id: "111",
      name_uz: "Odil",
      name_oz: "Îäčë",
      name_ru: "Îäčë",
    },
    {
      id: "1153",
      district_id: "111",
      name_uz: "Qirqyigit",
      name_oz: "?čđ?éčăčň",
      name_ru: "?čđ?éčăčň",
    },
    {
      id: "1154",
      district_id: "111",
      name_uz: "Qurli",
      name_oz: "?óđëč",
      name_ru: "?óđëč",
    },
    {
      id: "1155",
      district_id: "111",
      name_uz: "Ravot",
      name_oz: "Đŕâîň",
      name_ru: "Đŕâîň",
    },
    {
      id: "1156",
      district_id: "111",
      name_uz: "Shayxislom",
      name_oz: "Řŕéőčńëîě",
      name_ru: "Řŕéőčńëîě",
    },
    {
      id: "1157",
      district_id: "111",
      name_uz: "Sheyxlar",
      name_oz: "Řĺéőëŕđ",
      name_ru: "Řĺéőëŕđ",
    },
    {
      id: "1158",
      district_id: "111",
      name_uz: "Sug'ot",
      name_oz: "Ńó?îň",
      name_ru: "Ńó?îň",
    },
    {
      id: "1159",
      district_id: "111",
      name_uz: "Xalqobod",
      name_oz: "Őŕë?îáîä",
      name_ru: "Őŕë?îáîä",
    },
    {
      id: "1160",
      district_id: "111",
      name_uz: "Yangikent",
      name_oz: "ßíăčęĺíň",
      name_ru: "ßíăčęĺíň",
    },
    {
      id: "1161",
      district_id: "111",
      name_uz: "Yangirabot",
      name_oz: "ßíăčđŕáîň",
      name_ru: "ßíăčđŕáîň",
    },
    {
      id: "1162",
      district_id: "111",
      name_uz: "Zarband",
      name_oz: "Çŕđáŕíä",
      name_ru: "Çŕđáŕíä",
    },
    {
      id: "1163",
      district_id: "110",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "1164",
      district_id: "110",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "1165",
      district_id: "110",
      name_uz: "Eski Jomboy",
      name_oz: "Ýńęč Ćîěáîé",
      name_ru: "Ýńęč Ćîěáîé",
    },
    {
      id: "1166",
      district_id: "110",
      name_uz: "G'azira",
      name_oz: "?ŕçčđŕ",
      name_ru: "?ŕçčđŕ",
    },
    {
      id: "1167",
      district_id: "110",
      name_uz: "Jomboy",
      name_oz: "Ćîěáîé",
      name_ru: "Ćîěáîé",
    },
    {
      id: "1168",
      district_id: "110",
      name_uz: "Jomboy",
      name_oz: "Ćîěáîé",
      name_ru: "Ćîěáîé",
    },
    {
      id: "1169",
      district_id: "110",
      name_uz: "Juriyat",
      name_oz: "Ćóđč˙ň",
      name_ru: "Ćóđč˙ň",
    },
    {
      id: "1170",
      district_id: "110",
      name_uz: "Kattaqishloq",
      name_oz: "Ęŕňňŕ?čřëî?",
      name_ru: "Ęŕňňŕ?čřëî?",
    },
    {
      id: "1171",
      district_id: "110",
      name_uz: "Qangli",
      name_oz: "?ŕíăëč",
      name_ru: "?ŕíăëč",
    },
    {
      id: "1172",
      district_id: "110",
      name_uz: "Qo'ng'irot",
      name_oz: "?˘í?čđîň",
      name_ru: "?˘í?čđîň",
    },
    {
      id: "1173",
      district_id: "110",
      name_uz: "Qoramuyin",
      name_oz: "?îđŕěóéčí",
      name_ru: "?îđŕěóéčí",
    },
    {
      id: "1174",
      district_id: "110",
      name_uz: "Sherqo'rg'on",
      name_oz: "Řĺđ?˘đ?îí",
      name_ru: "Řĺđ?˘đ?îí",
    },
    {
      id: "1175",
      district_id: "110",
      name_uz: "Xo'ja",
      name_oz: "Ő˘ćŕ",
      name_ru: "Ő˘ćŕ",
    },
    {
      id: "1176",
      district_id: "110",
      name_uz: "Xolvoyi",
      name_oz: "Őîëâîéč",
      name_ru: "Őîëâîéč",
    },
    {
      id: "1177",
      district_id: "113",
      name_uz: "Ingichka",
      name_oz: "Číăč÷ęŕ",
      name_ru: "Číăč÷ęŕ",
    },
    {
      id: "1178",
      district_id: "112",
      name_uz: "Durbesh",
      name_oz: "Äóđáĺř",
      name_ru: "Äóđáĺř",
    },
    {
      id: "1179",
      district_id: "112",
      name_uz: "Girdiqo'rg'on",
      name_oz: "Ăčđäč?˘đ?îí",
      name_ru: "Ăčđäč?˘đ?îí",
    },
    {
      id: "1180",
      district_id: "112",
      name_uz: "Jumaboy",
      name_oz: "Ćóěŕáîé",
      name_ru: "Ćóěŕáîé",
    },
    {
      id: "1181",
      district_id: "112",
      name_uz: "Kattako'rpa",
      name_oz: "Ęŕňňŕę˘đďŕ",
      name_ru: "Ęŕňňŕę˘đďŕ",
    },
    {
      id: "1182",
      district_id: "112",
      name_uz: "Kattaming",
      name_oz: "Ęŕňňŕěčíă",
      name_ru: "Ęŕňňŕěčíă",
    },
    {
      id: "1183",
      district_id: "112",
      name_uz: "Kattaming",
      name_oz: "Ęŕňňŕěčíă",
      name_ru: "Ęŕňňŕěčíă",
    },
    {
      id: "1184",
      district_id: "112",
      name_uz: "Kichikmundiyon",
      name_oz: "Ęč÷čęěóíäč¸í",
      name_ru: "Ęč÷čęěóíäč¸í",
    },
    {
      id: "1185",
      district_id: "112",
      name_uz: "Moybuloq",
      name_oz: "Ěîéáóëî?",
      name_ru: "Ěîéáóëî?",
    },
    {
      id: "1186",
      district_id: "112",
      name_uz: "Mundiyon",
      name_oz: "Ěóíäč¸í",
      name_ru: "Ěóíäč¸í",
    },
    {
      id: "1187",
      district_id: "112",
      name_uz: "Omonboy",
      name_oz: "Îěîíáîé",
      name_ru: "Îěîíáîé",
    },
    {
      id: "1188",
      district_id: "112",
      name_uz: "Payshanba",
      name_oz: "Ďŕéřŕíáŕ",
      name_ru: "Ďŕéřŕíáŕ",
    },
    {
      id: "1189",
      district_id: "112",
      name_uz: "Polvontepa",
      name_oz: "Ďîëâîíňĺďŕ",
      name_ru: "Ďîëâîíňĺďŕ",
    },
    {
      id: "1190",
      district_id: "112",
      name_uz: "Qo'shtepa",
      name_oz: "?˘řňĺďŕ",
      name_ru: "?˘řňĺďŕ",
    },
    {
      id: "1191",
      district_id: "112",
      name_uz: "Qoradaryo",
      name_oz: "?îđŕäŕđ¸",
      name_ru: "?îđŕäŕđ¸",
    },
    {
      id: "1192",
      district_id: "112",
      name_uz: "Saroyqo'rg'on",
      name_oz: "Ńŕđîé?˘đ?îí",
      name_ru: "Ńŕđîé?˘đ?îí",
    },
    {
      id: "1193",
      district_id: "112",
      name_uz: "Suv xovuzi",
      name_oz: "Ńóâ őîâóçč",
      name_ru: "Ńóâ őîâóçč",
    },
    {
      id: "1194",
      district_id: "112",
      name_uz: "Vayrat",
      name_oz: "Âŕéđŕň",
      name_ru: "Âŕéđŕň",
    },
    {
      id: "1195",
      district_id: "112",
      name_uz: "Yangiqo'rg'oncha",
      name_oz: "ßíăč?˘đ?îí÷ŕ",
      name_ru: "ßíăč?˘đ?îí÷ŕ",
    },
    {
      id: "1196",
      district_id: "112",
      name_uz: "Yangiqo'rg'oncha",
      name_oz: "ßíăč?˘đ?îí÷ŕ",
      name_ru: "ßíăč?˘đ?îí÷ŕ",
    },
    {
      id: "1197",
      district_id: "115",
      name_uz: "Balandqo'rg'on",
      name_oz: "Áŕëŕíä?˘đ?îí",
      name_ru: "Áŕëŕíä?˘đ?îí",
    },
    {
      id: "1198",
      district_id: "115",
      name_uz: "Chaqar",
      name_oz: "×ŕ?ŕđ",
      name_ru: "×ŕ?ŕđ",
    },
    {
      id: "1199",
      district_id: "115",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1200",
      district_id: "115",
      name_uz: "Islom Shoir",
      name_oz: "Čńëîě Řîčđ",
      name_ru: "Čńëîě Řîčđ",
    },
    {
      id: "1201",
      district_id: "115",
      name_uz: "Kosogoron",
      name_oz: "Ęîńîăîđîí",
      name_ru: "Ęîńîăîđîí",
    },
    {
      id: "1202",
      district_id: "115",
      name_uz: "Mirbozor",
      name_oz: "Ěčđáîçîđ",
      name_ru: "Ěčđáîçîđ",
    },
    {
      id: "1203",
      district_id: "115",
      name_uz: "Oltio'g'il",
      name_oz: "Îëňč˘?čë",
      name_ru: "Îëňč˘?čë",
    },
    {
      id: "1204",
      district_id: "115",
      name_uz: "Oqtosh",
      name_oz: "Î?ňîř",
      name_ru: "Î?ňîř",
    },
    {
      id: "1205",
      district_id: "115",
      name_uz: "Qadim",
      name_oz: "?ŕäčě",
      name_ru: "?ŕäčě",
    },
    {
      id: "1206",
      district_id: "115",
      name_uz: "Qo'yi Charxin",
      name_oz: "?˘éč ×ŕđőčí",
      name_ru: "?˘éč ×ŕđőčí",
    },
    {
      id: "1207",
      district_id: "115",
      name_uz: "Qorako'l",
      name_oz: "?îđŕę˘ë",
      name_ru: "?îđŕę˘ë",
    },
    {
      id: "1208",
      district_id: "115",
      name_uz: "Qorasiyrak",
      name_oz: "?îđŕńčéđŕę",
      name_ru: "?îđŕńčéđŕę",
    },
    {
      id: "1209",
      district_id: "115",
      name_uz: "Yangirabod",
      name_oz: "ßíăčđŕáîä",
      name_ru: "ßíăčđŕáîä",
    },
    {
      id: "1210",
      district_id: "116",
      name_uz: "Jarquduq",
      name_oz: "Ćŕđ?óäó?",
      name_ru: "Ćŕđ?óäó?",
    },
    {
      id: "1211",
      district_id: "116",
      name_uz: "Jom",
      name_oz: "Ćîě",
      name_ru: "Ćîě",
    },
    {
      id: "1212",
      district_id: "116",
      name_uz: "Nurbuloq",
      name_oz: "Íóđáóëî?",
      name_ru: "Íóđáóëî?",
    },
    {
      id: "1213",
      district_id: "116",
      name_uz: "Nurbuloq",
      name_oz: "Íóđáóëî?",
      name_ru: "Íóđáóëî?",
    },
    {
      id: "1214",
      district_id: "116",
      name_uz: "Nurobod",
      name_oz: "Íóđîáîä",
      name_ru: "Íóđîáîä",
    },
    {
      id: "1215",
      district_id: "116",
      name_uz: "Sazog'on",
      name_oz: "Ńŕçî?îí",
      name_ru: "Ńŕçî?îí",
    },
    {
      id: "1216",
      district_id: "116",
      name_uz: "Tim",
      name_oz: "Ňčě",
      name_ru: "Ňčě",
    },
    {
      id: "1217",
      district_id: "116",
      name_uz: "Tutli",
      name_oz: "Ňóňëč",
      name_ru: "Ňóňëč",
    },
    {
      id: "1218",
      district_id: "116",
      name_uz: "Ulus",
      name_oz: "Óëóń",
      name_ru: "Óëóń",
    },
    {
      id: "1219",
      district_id: "117",
      name_uz: "A.Navoiy",
      name_oz: "A.Navoiy",
      name_ru: "A.Navoiy",
    },
    {
      id: "1220",
      district_id: "117",
      name_uz: "Avazali",
      name_oz: "Ŕâŕçŕëč",
      name_ru: "Ŕâŕçŕëč",
    },
    {
      id: "1221",
      district_id: "117",
      name_uz: "Bolta",
      name_oz: "Áîëňŕ",
      name_ru: "Áîëňŕ",
    },
    {
      id: "1222",
      district_id: "117",
      name_uz: "Dahbed",
      name_oz: "Äŕ?áĺä",
      name_ru: "Äŕ?áĺä",
    },
    {
      id: "1223",
      district_id: "117",
      name_uz: "Kumushkent",
      name_oz: "Ęóěóřęĺíň",
      name_ru: "Ęóěóřęĺíň",
    },
    {
      id: "1224",
      district_id: "117",
      name_uz: "Loyish",
      name_oz: "Ëîéčř",
      name_ru: "Ëîéčř",
    },
    {
      id: "1225",
      district_id: "117",
      name_uz: "Oqdaryo",
      name_oz: "Î?äŕđ¸",
      name_ru: "Î?äŕđ¸",
    },
    {
      id: "1226",
      district_id: "117",
      name_uz: "Oytamg'ali",
      name_oz: "Îéňŕě?ŕëč",
      name_ru: "Îéňŕě?ŕëč",
    },
    {
      id: "1227",
      district_id: "117",
      name_uz: "Primkent",
      name_oz: "Ďđčěęĺíň",
      name_ru: "Ďđčěęĺíň",
    },
    {
      id: "1228",
      district_id: "117",
      name_uz: "Qirqdarxon",
      name_oz: "?čđ?äŕđőîí",
      name_ru: "?čđ?äŕđőîí",
    },
    {
      id: "1229",
      district_id: "117",
      name_uz: "Qorateri",
      name_oz: "?îđŕňĺđč",
      name_ru: "?îđŕňĺđč",
    },
    {
      id: "1230",
      district_id: "117",
      name_uz: "Yangikent",
      name_oz: "ßíăčęĺíň",
      name_ru: "ßíăčęĺíň",
    },
    {
      id: "1231",
      district_id: "117",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1232",
      district_id: "117",
      name_uz: "Yangiqo'rg'on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăč?˘đ?îí",
    },
    {
      id: "1233",
      district_id: "117",
      name_uz: "Yangiqo'rg'on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăč?˘đ?îí",
    },
    {
      id: "1234",
      district_id: "117",
      name_uz: "Zarafshon",
      name_oz: "Çŕđŕôřîí",
      name_ru: "Çŕđŕôřîí",
    },
    {
      id: "1235",
      district_id: "119",
      name_uz: "Agron",
      name_oz: "Ŕăđîí",
      name_ru: "Ŕăđîí",
    },
    {
      id: "1236",
      district_id: "119",
      name_uz: "Anxor",
      name_oz: "Ŕíőîđ",
      name_ru: "Ŕíőîđ",
    },
    {
      id: "1237",
      district_id: "119",
      name_uz: "Arabxona",
      name_oz: "Ŕđŕáőîíŕ",
      name_ru: "Ŕđŕáőîíŕ",
    },
    {
      id: "1238",
      district_id: "119",
      name_uz: "Balhiyon",
      name_oz: "Áŕë?č¸í",
      name_ru: "Áŕë?č¸í",
    },
    {
      id: "1239",
      district_id: "119",
      name_uz: "Besh qahramon",
      name_oz: "Áĺř ?ŕ?đŕěîí",
      name_ru: "Áĺř ?ŕ?đŕěîí",
    },
    {
      id: "1240",
      district_id: "119",
      name_uz: "Bolatosh",
      name_oz: "Áîëŕňîř",
      name_ru: "Áîëŕňîř",
    },
    {
      id: "1241",
      district_id: "119",
      name_uz: "Charxin",
      name_oz: "×ŕđőčí",
      name_ru: "×ŕđőčí",
    },
    {
      id: "1242",
      district_id: "119",
      name_uz: "Chimboy",
      name_oz: "×čěáîé",
      name_ru: "×čěáîé",
    },
    {
      id: "1243",
      district_id: "119",
      name_uz: "Chortut",
      name_oz: "×îđňóň",
      name_ru: "×îđňóň",
    },
    {
      id: "1244",
      district_id: "119",
      name_uz: "Dimishkibolo",
      name_oz: "Äčěčřęčáîëî",
      name_ru: "Äčěčřęčáîëî",
    },
    {
      id: "1245",
      district_id: "119",
      name_uz: "Do'rmontepa",
      name_oz: "Ä˘đěîíňĺďŕ",
      name_ru: "Ä˘đěîíňĺďŕ",
    },
    {
      id: "1246",
      district_id: "119",
      name_uz: "Go'zalkent",
      name_oz: "Ă˘çŕëęĺíň",
      name_ru: "Ă˘çŕëęĺíň",
    },
    {
      id: "1247",
      district_id: "119",
      name_uz: "Go'zalkent",
      name_oz: "Ă˘çŕëęĺíň",
      name_ru: "Ă˘çŕëęĺíň",
    },
    {
      id: "1248",
      district_id: "119",
      name_uz: "Hindiboyi",
      name_oz: "?číäčáîéč",
      name_ru: "?číäčáîéč",
    },
    {
      id: "1249",
      district_id: "119",
      name_uz: "Iskandari",
      name_oz: "Čńęŕíäŕđč",
      name_ru: "Čńęŕíäŕđč",
    },
    {
      id: "1250",
      district_id: "119",
      name_uz: "Jag'alboyli",
      name_oz: "Ćŕ?ŕëáîéëč",
      name_ru: "Ćŕ?ŕëáîéëč",
    },
    {
      id: "1251",
      district_id: "119",
      name_uz: "Juma",
      name_oz: "Ćóěŕ",
      name_ru: "Ćóěŕ",
    },
    {
      id: "1252",
      district_id: "119",
      name_uz: "Mehnat",
      name_oz: "Ěĺ?íŕň",
      name_ru: "Ěĺ?íŕň",
    },
    {
      id: "1253",
      district_id: "119",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "1254",
      district_id: "119",
      name_uz: "Nayman",
      name_oz: "Íŕéěŕí",
      name_ru: "Íŕéěŕí",
    },
    {
      id: "1255",
      district_id: "119",
      name_uz: "O'rta Charxin",
      name_oz: "ˇđňŕ ×ŕđőčí",
      name_ru: "ˇđňŕ ×ŕđőčí",
    },
    {
      id: "1256",
      district_id: "119",
      name_uz: "Po'latchi",
      name_oz: "Ď˘ëŕň÷č",
      name_ru: "Ď˘ëŕň÷č",
    },
    {
      id: "1257",
      district_id: "119",
      name_uz: "Sanchikul",
      name_oz: "Ńŕí÷čęóë",
      name_ru: "Ńŕí÷čęóë",
    },
    {
      id: "1258",
      district_id: "119",
      name_uz: "Saribosh",
      name_oz: "Ńŕđčáîř",
      name_ru: "Ńŕđčáîř",
    },
    {
      id: "1259",
      district_id: "119",
      name_uz: "Saribosh",
      name_oz: "Ńŕđčáîř",
      name_ru: "Ńŕđčáîř",
    },
    {
      id: "1260",
      district_id: "119",
      name_uz: "Torariq",
      name_oz: "Ňîđŕđč?",
      name_ru: "Ňîđŕđč?",
    },
    {
      id: "1261",
      district_id: "120",
      name_uz: "Do'stobod",
      name_oz: "Ä˘ńňîáîä",
      name_ru: "Ä˘ńňîáîä",
    },
    {
      id: "1262",
      district_id: "120",
      name_uz: "Karnab",
      name_oz: "Ęŕđíŕá",
      name_ru: "Ęŕđíŕá",
    },
    {
      id: "1263",
      district_id: "120",
      name_uz: "Misit",
      name_oz: "Ěčńčň",
      name_ru: "Ěčńčň",
    },
    {
      id: "1264",
      district_id: "120",
      name_uz: "Past Burkut",
      name_oz: "Ďŕńň Áóđęóň",
      name_ru: "Ďŕńň Áóđęóň",
    },
    {
      id: "1265",
      district_id: "120",
      name_uz: "Qodirist",
      name_oz: "?îäčđčńň",
      name_ru: "?îäčđčńň",
    },
    {
      id: "1266",
      district_id: "120",
      name_uz: "Quyiboq",
      name_oz: "?óéčáî?",
      name_ru: "?óéčáî?",
    },
    {
      id: "1267",
      district_id: "120",
      name_uz: "Sanchiqul",
      name_oz: "Ńŕí÷č?óë",
      name_ru: "Ńŕí÷č?óë",
    },
    {
      id: "1268",
      district_id: "120",
      name_uz: "Sultonobod",
      name_oz: "Ńóëňîíîáîä",
      name_ru: "Ńóëňîíîáîä",
    },
    {
      id: "1269",
      district_id: "120",
      name_uz: "Suluvqo'rg'on",
      name_oz: "Ńóëóâ?˘đ?îí",
      name_ru: "Ńóëóâ?˘đ?îí",
    },
    {
      id: "1270",
      district_id: "120",
      name_uz: "Urgich",
      name_oz: "Óđăč÷",
      name_ru: "Óđăč÷",
    },
    {
      id: "1271",
      district_id: "120",
      name_uz: "Xayrobod",
      name_oz: "Őŕéđîáîä",
      name_ru: "Őŕéđîáîä",
    },
    {
      id: "1272",
      district_id: "120",
      name_uz: "Xumor",
      name_oz: "Őóěîđ",
      name_ru: "Őóěîđ",
    },
    {
      id: "1273",
      district_id: "120",
      name_uz: "Xumor",
      name_oz: "Őóěîđ",
      name_ru: "Őóěîđ",
    },
    {
      id: "1274",
      district_id: "120",
      name_uz: "Yuqori Po'latchi",
      name_oz: "Ţ?îđč Ď˘ëŕň÷č",
      name_ru: "Ţ?îđč Ď˘ëŕň÷č",
    },
    {
      id: "1275",
      district_id: "120",
      name_uz: "Ziyovuddin",
      name_oz: "Çč¸âóääčí",
      name_ru: "Çč¸âóääčí",
    },
    {
      id: "1276",
      district_id: "118",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "1277",
      district_id: "118",
      name_uz: "Chelak",
      name_oz: "×ĺëŕę",
      name_ru: "×ĺëŕę",
    },
    {
      id: "1278",
      district_id: "118",
      name_uz: "Choparoshli",
      name_oz: "×îďŕđîřëč",
      name_ru: "×îďŕđîřëč",
    },
    {
      id: "1279",
      district_id: "118",
      name_uz: "Choshtepa",
      name_oz: "×îřňĺďŕ",
      name_ru: "×îřňĺďŕ",
    },
    {
      id: "1280",
      district_id: "118",
      name_uz: "Do'stlarobod",
      name_oz: "Ä˘ńňëŕđîáîä",
      name_ru: "Ä˘ńňëŕđîáîä",
    },
    {
      id: "1281",
      district_id: "118",
      name_uz: "Do'stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1282",
      district_id: "118",
      name_uz: "G'ujumsoy",
      name_oz: "?óćóěńîé",
      name_ru: "?óćóěńîé",
    },
    {
      id: "1283",
      district_id: "118",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1284",
      district_id: "118",
      name_uz: "Ko'kdala",
      name_oz: "Ę˘ęäŕëŕ",
      name_ru: "Ę˘ęäŕëŕ",
    },
    {
      id: "1285",
      district_id: "118",
      name_uz: "Ko'ksaroy",
      name_oz: "Ę˘ęńŕđîé",
      name_ru: "Ę˘ęńŕđîé",
    },
    {
      id: "1286",
      district_id: "118",
      name_uz: "Ko'lto'sin",
      name_oz: "Ę˘ëň˘ńčí",
      name_ru: "Ę˘ëň˘ńčí",
    },
    {
      id: "1287",
      district_id: "118",
      name_uz: "O'rta saydov",
      name_oz: "ˇđňŕ ńŕéäîâ",
      name_ru: "ˇđňŕ ńŕéäîâ",
    },
    {
      id: "1288",
      district_id: "118",
      name_uz: "Oqqo'rg'on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1289",
      district_id: "118",
      name_uz: "Oqqo'rg'on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1290",
      district_id: "118",
      name_uz: "Oytamg'ali",
      name_oz: "Îéňŕě?ŕëč",
      name_ru: "Îéňŕě?ŕëč",
    },
    {
      id: "1291",
      district_id: "118",
      name_uz: "Payariq",
      name_oz: "Ďŕéŕđč?",
      name_ru: "Ďŕéŕđč?",
    },
    {
      id: "1292",
      district_id: "118",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1293",
      district_id: "118",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1294",
      district_id: "118",
      name_uz: "Sanoat",
      name_oz: "Ńŕíîŕň",
      name_ru: "Ńŕíîŕň",
    },
    {
      id: "1295",
      district_id: "118",
      name_uz: "Tomoyrat",
      name_oz: "Ňîěîéđŕň",
      name_ru: "Ňîěîéđŕň",
    },
    {
      id: "1296",
      district_id: "118",
      name_uz: "Tupolos",
      name_oz: "Ňóďîëîń",
      name_ru: "Ňóďîëîń",
    },
    {
      id: "1297",
      district_id: "118",
      name_uz: "Xo'ja Ismoil",
      name_oz: "Ő˘ćŕ Čńěîčë",
      name_ru: "Ő˘ćŕ Čńěîčë",
    },
    {
      id: "1298",
      district_id: "114",
      name_uz: "Jush",
      name_oz: "Ćóř",
      name_ru: "Ćóř",
    },
    {
      id: "1299",
      district_id: "114",
      name_uz: "Oqtepa",
      name_oz: "Î?ňĺďŕ",
      name_ru: "Î?ňĺďŕ",
    },
    {
      id: "1300",
      district_id: "114",
      name_uz: "Oxunboboyev nomli",
      name_oz: "Îőóíáîáîĺâ íîěëč",
      name_ru: "Îőóíáîáîĺâ íîěëč",
    },
    {
      id: "1301",
      district_id: "114",
      name_uz: "Pichat",
      name_oz: "Ďč÷ŕň",
      name_ru: "Ďč÷ŕň",
    },
    {
      id: "1302",
      district_id: "114",
      name_uz: "Qo'shrabot",
      name_oz: "?˘řđŕáîň",
      name_ru: "?˘řđŕáîň",
    },
    {
      id: "1303",
      district_id: "114",
      name_uz: "Qo'shrabot",
      name_oz: "?˘řđŕáîň",
      name_ru: "?˘řđŕáîň",
    },
    {
      id: "1304",
      district_id: "114",
      name_uz: "Urgandji",
      name_oz: "Óđăŕíäćč",
      name_ru: "Óđăŕíäćč",
    },
    {
      id: "1305",
      district_id: "114",
      name_uz: "Zarkent",
      name_oz: "Çŕđęĺíň",
      name_ru: "Çŕđęĺíň",
    },
    {
      id: "1306",
      district_id: "114",
      name_uz: "Zarmitan",
      name_oz: "Çŕđěčňŕí",
      name_ru: "Çŕđěčňŕí",
    },
    {
      id: "1307",
      district_id: "122",
      name_uz: "Farxod",
      name_oz: "Ôŕđőîä",
      name_ru: "Ôŕđőîä",
    },
    {
      id: "1308",
      district_id: "122",
      name_uz: "Kimyogar",
      name_oz: "Ęčě¸ăŕđ",
      name_ru: "Ęčě¸ăŕđ",
    },
    {
      id: "1309",
      district_id: "122",
      name_uz: "Xishrav",
      name_oz: "Őčřđŕâ",
      name_ru: "Őčřđŕâ",
    },
    {
      id: "1310",
      district_id: "121",
      name_uz: "Bog'ibaland",
      name_oz: "Áî?čáŕëŕíä",
      name_ru: "Áî?čáŕëŕíä",
    },
    {
      id: "1311",
      district_id: "121",
      name_uz: "Dashtakibolo",
      name_oz: "Äŕřňŕęčáîëî",
      name_ru: "Äŕřňŕęčáîëî",
    },
    {
      id: "1312",
      district_id: "121",
      name_uz: "Gulobod",
      name_oz: "Ăóëîáîä",
      name_ru: "Ăóëîáîä",
    },
    {
      id: "1313",
      district_id: "121",
      name_uz: "Kattaqo'rg'onariq",
      name_oz: "Ęŕňňŕ?˘đ?îíŕđč?",
      name_ru: "Ęŕňňŕ?˘đ?îíŕđč?",
    },
    {
      id: "1314",
      district_id: "121",
      name_uz: "Kulbaipoyon",
      name_oz: "Ęóëáŕčďî¸í",
      name_ru: "Ęóëáŕčďî¸í",
    },
    {
      id: "1315",
      district_id: "121",
      name_uz: "Ohalik",
      name_oz: "Î?ŕëčę",
      name_ru: "Î?ŕëčę",
    },
    {
      id: "1316",
      district_id: "121",
      name_uz: "Qaynama",
      name_oz: "?ŕéíŕěŕ",
      name_ru: "?ŕéíŕěŕ",
    },
    {
      id: "1317",
      district_id: "121",
      name_uz: "Qo'shtamg'ali",
      name_oz: "?˘řňŕě?ŕëč",
      name_ru: "?˘řňŕě?ŕëč",
    },
    {
      id: "1318",
      district_id: "121",
      name_uz: "Ulug'bek",
      name_oz: "Óëó?áĺę",
      name_ru: "Óëó?áĺę",
    },
    {
      id: "1319",
      district_id: "121",
      name_uz: "Xo'ja Ahrori Vali",
      name_oz: "Ő˘ćŕ Ŕ?đîđč Âŕëč",
      name_ru: "Ő˘ćŕ Ŕ?đîđč Âŕëč",
    },
    {
      id: "1320",
      district_id: "123",
      name_uz: "Adas",
      name_oz: "Ŕäŕń",
      name_ru: "Ŕäŕń",
    },
    {
      id: "1321",
      district_id: "123",
      name_uz: "Adas",
      name_oz: "Ŕäŕń",
      name_ru: "Ŕäŕń",
    },
    {
      id: "1322",
      district_id: "123",
      name_uz: "Bog'izag'on",
      name_oz: "Áî?čçŕ?îí",
      name_ru: "Áî?čçŕ?îí",
    },
    {
      id: "1323",
      district_id: "123",
      name_uz: "Bog'izag'on",
      name_oz: "Áî?čçŕ?îí",
      name_ru: "Áî?čçŕ?îí",
    },
    {
      id: "1324",
      district_id: "123",
      name_uz: "G'o'lba",
      name_oz: "?˘ëáŕ",
      name_ru: "?˘ëáŕ",
    },
    {
      id: "1325",
      district_id: "123",
      name_uz: "Jumabozor",
      name_oz: "Ćóěŕáîçîđ",
      name_ru: "Ćóěŕáîçîđ",
    },
    {
      id: "1326",
      district_id: "123",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "1327",
      district_id: "123",
      name_uz: "Qo'rg'oncha",
      name_oz: "?˘đ?îí÷ŕ",
      name_ru: "?˘đ?îí÷ŕ",
    },
    {
      id: "1328",
      district_id: "123",
      name_uz: "Sochakibolo",
      name_oz: "Ńî÷ŕęčáîëî",
      name_ru: "Ńî÷ŕęčáîëî",
    },
    {
      id: "1329",
      district_id: "123",
      name_uz: "Tepaqishloq",
      name_oz: "Ňĺďŕ?čřëî?",
      name_ru: "Ňĺďŕ?čřëî?",
    },
    {
      id: "1330",
      district_id: "123",
      name_uz: "Toyloq",
      name_oz: "Ňîéëî?",
      name_ru: "Ňîéëî?",
    },
    {
      id: "1331",
      district_id: "123",
      name_uz: "Toyloq",
      name_oz: "Ňîéëî?",
      name_ru: "Ňîéëî?",
    },
    {
      id: "1332",
      district_id: "124",
      name_uz: "Baxrin",
      name_oz: "Áŕőđčí",
      name_ru: "Áŕőđčí",
    },
    {
      id: "1333",
      district_id: "124",
      name_uz: "Beshbuloq",
      name_oz: "Áĺřáóëî?",
      name_ru: "Áĺřáóëî?",
    },
    {
      id: "1334",
      district_id: "124",
      name_uz: "Buloqboshi",
      name_oz: "Áóëî?áîřč",
      name_ru: "Áóëî?áîřč",
    },
    {
      id: "1335",
      district_id: "124",
      name_uz: "G'o's",
      name_oz: "?˘ń",
      name_ru: "?˘ń",
    },
    {
      id: "1336",
      district_id: "124",
      name_uz: "G'o's",
      name_oz: "?˘ń",
      name_ru: "?˘ń",
    },
    {
      id: "1337",
      district_id: "124",
      name_uz: "Ilonli",
      name_oz: "Čëîíëč",
      name_ru: "Čëîíëč",
    },
    {
      id: "1338",
      district_id: "124",
      name_uz: "Ispanza",
      name_oz: "Čńďŕíçŕ",
      name_ru: "Čńďŕíçŕ",
    },
    {
      id: "1339",
      district_id: "124",
      name_uz: "Ispanza",
      name_oz: "Čńďŕíçŕ",
      name_ru: "Čńďŕíçŕ",
    },
    {
      id: "1340",
      district_id: "124",
      name_uz: "Jartepa",
      name_oz: "Ćŕđňĺďŕ",
      name_ru: "Ćŕđňĺďŕ",
    },
    {
      id: "1341",
      district_id: "124",
      name_uz: "Jartepa",
      name_oz: "Ćŕđňĺďŕ",
      name_ru: "Ćŕđňĺďŕ",
    },
    {
      id: "1342",
      district_id: "124",
      name_uz: "Kamangaron",
      name_oz: "Ęŕěŕíăŕđîí",
      name_ru: "Ęŕěŕíăŕđîí",
    },
    {
      id: "1343",
      district_id: "124",
      name_uz: "Kenagas",
      name_oz: "Ęĺíŕăŕń",
      name_ru: "Ęĺíŕăŕń",
    },
    {
      id: "1344",
      district_id: "124",
      name_uz: "Mirzaqishloq",
      name_oz: "Ěčđçŕ?čřëî?",
      name_ru: "Ěčđçŕ?čřëî?",
    },
    {
      id: "1345",
      district_id: "124",
      name_uz: "Pochvon",
      name_oz: "Ďî÷âîí",
      name_ru: "Ďî÷âîí",
    },
    {
      id: "1346",
      district_id: "124",
      name_uz: "Pochvon",
      name_oz: "Ďî÷âîí",
      name_ru: "Ďî÷âîí",
    },
    {
      id: "1347",
      district_id: "124",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "?îđŕňĺďŕ",
    },
    {
      id: "1348",
      district_id: "124",
      name_uz: "Uramas",
      name_oz: "Óđŕěŕń",
      name_ru: "Óđŕěŕń",
    },
    {
      id: "1349",
      district_id: "124",
      name_uz: "Uramas",
      name_oz: "Óđŕěŕń",
      name_ru: "Óđŕěŕń",
    },
    {
      id: "1350",
      district_id: "124",
      name_uz: "Urgut",
      name_oz: "Óđăóň",
      name_ru: "Óđăóň",
    },
    {
      id: "1351",
      district_id: "124",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđč?",
    },
    {
      id: "1352",
      district_id: "125",
      name_uz: "Angor (mavjud)",
      name_oz: "Ŕíăîđ (ěŕâćóä)",
      name_ru: "Ŕíăîđ (ěŕâćóä)",
    },
    {
      id: "1353",
      district_id: "125",
      name_uz: "Angor (yangi)",
      name_oz: "Ŕíăîđ (˙íăč)",
      name_ru: "Ŕíăîđ (˙íăč)",
    },
    {
      id: "1354",
      district_id: "125",
      name_uz: "Gilambob",
      name_oz: "Ăčëŕěáîá",
      name_ru: "Ăčëŕěáîá",
    },
    {
      id: "1355",
      district_id: "125",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1356",
      district_id: "125",
      name_uz: "Zang",
      name_oz: "Çŕíă",
      name_ru: "Çŕíă",
    },
    {
      id: "1357",
      district_id: "125",
      name_uz: "Zartepa",
      name_oz: "Çŕđňĺďŕ",
      name_ru: "Çŕđňĺďŕ",
    },
    {
      id: "1358",
      district_id: "125",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "1359",
      district_id: "125",
      name_uz: "Kayran",
      name_oz: "Ęŕéđŕí",
      name_ru: "Ęŕéđŕí",
    },
    {
      id: "1360",
      district_id: "125",
      name_uz: "Kayran",
      name_oz: "Ęŕéđŕí",
      name_ru: "Ęŕéđŕí",
    },
    {
      id: "1361",
      district_id: "125",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1362",
      district_id: "125",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1363",
      district_id: "125",
      name_uz: "Navoiy",
      name_oz: "Íŕâîčé",
      name_ru: "Íŕâîčé",
    },
    {
      id: "1364",
      district_id: "125",
      name_uz: "Novshahar",
      name_oz: "Íîâřŕ?ŕđ",
      name_ru: "Íîâřŕ?ŕđ",
    },
    {
      id: "1365",
      district_id: "125",
      name_uz: "Tallimaron",
      name_oz: "Ňŕëëčěŕđîí",
      name_ru: "Ňŕëëčěŕđîí",
    },
    {
      id: "1366",
      district_id: "125",
      name_uz: "Tallimaron",
      name_oz: "Ňŕëëčěŕđîí",
      name_ru: "Ňŕëëčěŕđîí",
    },
    {
      id: "1367",
      district_id: "125",
      name_uz: "Talloshqon",
      name_oz: "Ňŕëëîř?îí",
      name_ru: "Ňŕëëîř?îí",
    },
    {
      id: "1368",
      district_id: "125",
      name_uz: "Xomkon",
      name_oz: "Őîěęîí",
      name_ru: "Őîěęîí",
    },
    {
      id: "1369",
      district_id: "125",
      name_uz: "Yangi turmush",
      name_oz: "ßíăč ňóđěóř",
      name_ru: "ßíăč ňóđěóř",
    },
    {
      id: "1370",
      district_id: "125",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1371",
      district_id: "211",
      name_uz: "Bandixon",
      name_oz: "Áŕíäčőîí",
      name_ru: "Áŕíäčőîí",
    },
    {
      id: "1372",
      district_id: "126",
      name_uz: "Boysun",
      name_oz: "Áîéńóí",
      name_ru: "Áîéńóí",
    },
    {
      id: "1373",
      district_id: "126",
      name_uz: "Ketmonchi",
      name_oz: "Ęĺňěîí÷č",
      name_ru: "Ęĺňěîí÷č",
    },
    {
      id: "1374",
      district_id: "126",
      name_uz: "Kofrun",
      name_oz: "Ęîôđóí",
      name_ru: "Ęîôđóí",
    },
    {
      id: "1375",
      district_id: "126",
      name_uz: "Qorabo‘yin",
      name_oz: "?îđŕá˘éčí",
      name_ru: "?îđŕá˘éčí",
    },
    {
      id: "1376",
      district_id: "126",
      name_uz: "Qo‘ng‘irot",
      name_oz: "?˘í?čđîň",
      name_ru: "?˘í?čđîň",
    },
    {
      id: "1377",
      district_id: "126",
      name_uz: "Qo‘rg‘oncha",
      name_oz: "?˘đ?îí÷ŕ",
      name_ru: "?˘đ?îí÷ŕ",
    },
    {
      id: "1378",
      district_id: "126",
      name_uz: "Machay",
      name_oz: "Ěŕ÷ŕé",
      name_ru: "Ěŕ÷ŕé",
    },
    {
      id: "1379",
      district_id: "126",
      name_uz: "Pasurxi",
      name_oz: "Ďŕńóđőč",
      name_ru: "Ďŕńóđőč",
    },
    {
      id: "1380",
      district_id: "126",
      name_uz: "Poyonqo‘rg‘on",
      name_oz: "Ďî¸í?˘đ?îí",
      name_ru: "Ďî¸í?˘đ?îí",
    },
    {
      id: "1381",
      district_id: "126",
      name_uz: "Rabot",
      name_oz: "Đŕáîň",
      name_ru: "Đŕáîň",
    },
    {
      id: "1382",
      district_id: "126",
      name_uz: "Tangimush",
      name_oz: "Ňŕíăčěóř",
      name_ru: "Ňŕíăčěóř",
    },
    {
      id: "1383",
      district_id: "126",
      name_uz: "Temir darvoza",
      name_oz: "Ňĺěčđ äŕđâîçŕ",
      name_ru: "Ňĺěčđ äŕđâîçŕ",
    },
    {
      id: "1384",
      district_id: "126",
      name_uz: "Chinorli",
      name_oz: "×číîđëč",
      name_ru: "×číîđëč",
    },
    {
      id: "1385",
      district_id: "127",
      name_uz: "Anbarsoy",
      name_oz: "Ŕíáŕđńîé",
      name_ru: "Ŕíáŕđńîé",
    },
    {
      id: "1386",
      district_id: "127",
      name_uz: "Binokor",
      name_oz: "Áčíîęîđ",
      name_ru: "Áčíîęîđ",
    },
    {
      id: "1387",
      district_id: "127",
      name_uz: "Dahana",
      name_oz: "Äŕ?ŕíŕ",
      name_ru: "Äŕ?ŕíŕ",
    },
    {
      id: "1388",
      district_id: "127",
      name_uz: "Dahana",
      name_oz: "Äŕ?ŕíŕ",
      name_ru: "Äŕ?ŕíŕ",
    },
    {
      id: "1389",
      district_id: "127",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "1390",
      district_id: "127",
      name_uz: "Denov",
      name_oz: "Äĺíîâ",
      name_ru: "Äĺíîâ",
    },
    {
      id: "1391",
      district_id: "127",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1392",
      district_id: "127",
      name_uz: "Jamatak",
      name_oz: "Ćŕěŕňŕę",
      name_ru: "Ćŕěŕňŕę",
    },
    {
      id: "1393",
      district_id: "127",
      name_uz: "Kenagas",
      name_oz: "Ęĺíŕăŕń",
      name_ru: "Ęĺíŕăŕń",
    },
    {
      id: "1394",
      district_id: "127",
      name_uz: "Qiziljar",
      name_oz: "?čçčëćŕđ",
      name_ru: "?čçčëćŕđ",
    },
    {
      id: "1395",
      district_id: "127",
      name_uz: "Qiziljar",
      name_oz: "?čçčëćŕđ",
      name_ru: "?čçčëćŕđ",
    },
    {
      id: "1396",
      district_id: "127",
      name_uz: "Namozgoh",
      name_oz: "Íŕěîçăî?",
      name_ru: "Íŕěîçăî?",
    },
    {
      id: "1397",
      district_id: "127",
      name_uz: "Paxtakurash",
      name_oz: "Ďŕőňŕęóđŕř",
      name_ru: "Ďŕőňŕęóđŕř",
    },
    {
      id: "1398",
      district_id: "127",
      name_uz: "Pistamozor",
      name_oz: "Ďčńňŕěîçîđ",
      name_ru: "Ďčńňŕěîçîđ",
    },
    {
      id: "1399",
      district_id: "127",
      name_uz: "Sina",
      name_oz: "Ńčíŕ",
      name_ru: "Ńčíŕ",
    },
    {
      id: "1400",
      district_id: "127",
      name_uz: "Tortuvli",
      name_oz: "Ňîđňóâëč",
      name_ru: "Ňîđňóâëč",
    },
    {
      id: "1401",
      district_id: "127",
      name_uz: "Farg‘ona",
      name_oz: "Ôŕđ?îíŕ",
      name_ru: "Ôŕđ?îíŕ",
    },
    {
      id: "1402",
      district_id: "127",
      name_uz: "Xayrabot",
      name_oz: "Őŕéđŕáîň",
      name_ru: "Őŕéđŕáîň",
    },
    {
      id: "1403",
      district_id: "127",
      name_uz: "Xitoyan",
      name_oz: "Őčňî˙í",
      name_ru: "Őčňî˙í",
    },
    {
      id: "1404",
      district_id: "127",
      name_uz: "Xolchayon",
      name_oz: "Őîë÷ŕ¸í",
      name_ru: "Őîë÷ŕ¸í",
    },
    {
      id: "1405",
      district_id: "127",
      name_uz: "Xolchayon",
      name_oz: "Őîë÷ŕ¸í",
      name_ru: "Őîë÷ŕ¸í",
    },
    {
      id: "1406",
      district_id: "127",
      name_uz: "Hazarbog‘",
      name_oz: "?ŕçŕđáî?",
      name_ru: "?ŕçŕđáî?",
    },
    {
      id: "1407",
      district_id: "127",
      name_uz: "Yurchi",
      name_oz: "Ţđ÷č",
      name_ru: "Ţđ÷č",
    },
    {
      id: "1408",
      district_id: "127",
      name_uz: "Yurchi",
      name_oz: "Ţđ÷č",
      name_ru: "Ţđ÷č",
    },
    {
      id: "1409",
      district_id: "127",
      name_uz: "Yangi Hazorbog‘",
      name_oz: "ßíăč ?ŕçîđáî?",
      name_ru: "ßíăč ?ŕçîđáî?",
    },
    {
      id: "1410",
      district_id: "127",
      name_uz: "Yangibog‘",
      name_oz: "ßíăčáî?",
      name_ru: "ßíăčáî?",
    },
    {
      id: "1411",
      district_id: "127",
      name_uz: "Yangibog‘",
      name_oz: "ßíăčáî?",
      name_ru: "ßíăčáî?",
    },
    {
      id: "1412",
      district_id: "127",
      name_uz: "Yangizamon",
      name_oz: "ßíăčçŕěîí",
      name_ru: "ßíăčçŕěîí",
    },
    {
      id: "1413",
      district_id: "127",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1414",
      district_id: "127",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1415",
      district_id: "128",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "1416",
      district_id: "128",
      name_uz: "Jarqo‘rg‘on",
      name_oz: "Ćŕđ?˘đ?îí",
      name_ru: "Ćŕđ?˘đ?îí",
    },
    {
      id: "1417",
      district_id: "128",
      name_uz: "Jarqo‘rg‘on",
      name_oz: "Ćŕđ?˘đ?îí",
      name_ru: "Ćŕđ?˘đ?îí",
    },
    {
      id: "1418",
      district_id: "128",
      name_uz: "Kakaydi",
      name_oz: "Ęŕęŕéäč",
      name_ru: "Ęŕęŕéäč",
    },
    {
      id: "1419",
      district_id: "128",
      name_uz: "Kafrun",
      name_oz: "Ęŕôđóí",
      name_ru: "Ęŕôđóí",
    },
    {
      id: "1420",
      district_id: "128",
      name_uz: "Qoraqursoq",
      name_oz: "?îđŕ?óđńî?",
      name_ru: "?îđŕ?óđńî?",
    },
    {
      id: "1421",
      district_id: "128",
      name_uz: "Markaziy Surxon",
      name_oz: "Ěŕđęŕçčé Ńóđőîí",
      name_ru: "Ěŕđęŕçčé Ńóđőîí",
    },
    {
      id: "1422",
      district_id: "128",
      name_uz: "Minor",
      name_oz: "Ěčíîđ",
      name_ru: "Ěčíîđ",
    },
    {
      id: "1423",
      district_id: "128",
      name_uz: "Minor",
      name_oz: "Ěčíîđ",
      name_ru: "Ěčíîđ",
    },
    {
      id: "1424",
      district_id: "128",
      name_uz: "Oqqo‘rg‘on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1425",
      district_id: "128",
      name_uz: "Surxon",
      name_oz: "Ńóđőîí",
      name_ru: "Ńóđőîí",
    },
    {
      id: "1426",
      district_id: "128",
      name_uz: "Chorjo‘y",
      name_oz: "×îđć˘é",
      name_ru: "×îđć˘é",
    },
    {
      id: "1427",
      district_id: "128",
      name_uz: "Sharq yulduzi",
      name_oz: "Řŕđ? ţëäóçč",
      name_ru: "Řŕđ? ţëäóçč",
    },
    {
      id: "1428",
      district_id: "129",
      name_uz: "Bandixon",
      name_oz: "Áŕíäčőîí",
      name_ru: "Áŕíäčőîí",
    },
    {
      id: "1429",
      district_id: "129",
      name_uz: "Zarkamar",
      name_oz: "Çŕđęŕěŕđ",
      name_ru: "Çŕđęŕěŕđ",
    },
    {
      id: "1430",
      district_id: "129",
      name_uz: "Istara",
      name_oz: "Čńňŕđŕ",
      name_ru: "Čńňŕđŕ",
    },
    {
      id: "1431",
      district_id: "129",
      name_uz: "Karmaki",
      name_oz: "Ęŕđěŕęč",
      name_ru: "Ęŕđěŕęč",
    },
    {
      id: "1432",
      district_id: "129",
      name_uz: "Kirshak",
      name_oz: "Ęčđřŕę",
      name_ru: "Ęčđřŕę",
    },
    {
      id: "1433",
      district_id: "129",
      name_uz: "Kunchiqish",
      name_oz: "Ęóí÷č?čř",
      name_ru: "Ęóí÷č?čř",
    },
    {
      id: "1434",
      district_id: "129",
      name_uz: "Qiziriq",
      name_oz: "?čçčđč?",
      name_ru: "?čçčđč?",
    },
    {
      id: "1435",
      district_id: "129",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "1436",
      district_id: "129",
      name_uz: "Olmazor",
      name_oz: "Îëěŕçîđ",
      name_ru: "Îëěŕçîđ",
    },
    {
      id: "1437",
      district_id: "129",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "1438",
      district_id: "129",
      name_uz: "Sariq",
      name_oz: "Ńŕđč?",
      name_ru: "Ńŕđč?",
    },
    {
      id: "1439",
      district_id: "129",
      name_uz: "Chorvador",
      name_oz: "×îđâŕäîđ",
      name_ru: "×îđâŕäîđ",
    },
    {
      id: "1440",
      district_id: "129",
      name_uz: "Sharq Istara",
      name_oz: "Řŕđ? Čńňŕđŕ",
      name_ru: "Řŕđ? Čńňŕđŕ",
    },
    {
      id: "1441",
      district_id: "129",
      name_uz: "Yangi yo‘l",
      name_oz: "ßíăč é˘ë",
      name_ru: "ßíăč é˘ë",
    },
    {
      id: "1442",
      district_id: "129",
      name_uz: "Yangi hayot",
      name_oz: "ßíăč ?ŕ¸ň",
      name_ru: "ßíăč ?ŕ¸ň",
    },
    {
      id: "1443",
      district_id: "130",
      name_uz: "Azlarsoy",
      name_oz: "Ŕçëŕđńîé",
      name_ru: "Ŕçëŕđńîé",
    },
    {
      id: "1444",
      district_id: "130",
      name_uz: "Arslonboyli",
      name_oz: "Ŕđńëîíáîéëč",
      name_ru: "Ŕđńëîíáîéëč",
    },
    {
      id: "1445",
      district_id: "130",
      name_uz: "Bog‘ora",
      name_oz: "Áî?îđŕ",
      name_ru: "Áî?îđŕ",
    },
    {
      id: "1446",
      district_id: "130",
      name_uz: "Jaloir",
      name_oz: "Ćŕëîčđ",
      name_ru: "Ćŕëîčđ",
    },
    {
      id: "1447",
      district_id: "130",
      name_uz: "Jaloir Qo‘rg‘oni",
      name_oz: "Ćŕëîčđ ?˘đ?îíč",
      name_ru: "Ćŕëîčđ ?˘đ?îíč",
    },
    {
      id: "1448",
      district_id: "130",
      name_uz: "Jiydali",
      name_oz: "Ćčéäŕëč",
      name_ru: "Ćčéäŕëč",
    },
    {
      id: "1449",
      district_id: "130",
      name_uz: "Ketmon",
      name_oz: "Ęĺňěîí",
      name_ru: "Ęĺňěîí",
    },
    {
      id: "1450",
      district_id: "130",
      name_uz: "Qarsoqli",
      name_oz: "?ŕđńî?ëč",
      name_ru: "?ŕđńî?ëč",
    },
    {
      id: "1451",
      district_id: "130",
      name_uz: "Qumqo‘rg‘on",
      name_oz: "?óě?˘đ?îí",
      name_ru: "?óě?˘đ?îí",
    },
    {
      id: "1452",
      district_id: "130",
      name_uz: "Qumqo‘rg‘on",
      name_oz: "?óě?˘đ?îí",
      name_ru: "?óě?˘đ?îí",
    },
    {
      id: "1453",
      district_id: "130",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "1454",
      district_id: "130",
      name_uz: "Oqjar",
      name_oz: "Î?ćŕđ",
      name_ru: "Î?ćŕđ",
    },
    {
      id: "1455",
      district_id: "130",
      name_uz: "Oqqapchig‘ay",
      name_oz: "Î??ŕď÷č?ŕé",
      name_ru: "Î??ŕď÷č?ŕé",
    },
    {
      id: "1456",
      district_id: "130",
      name_uz: "Oqsoy",
      name_oz: "Î?ńîé",
      name_ru: "Î?ńîé",
    },
    {
      id: "1457",
      district_id: "130",
      name_uz: "Hurriyat",
      name_oz: "?óđđč˙ň",
      name_ru: "?óđđč˙ň",
    },
    {
      id: "1458",
      district_id: "130",
      name_uz: "Sheroziy",
      name_oz: "Řĺđîçčé",
      name_ru: "Řĺđîçčé",
    },
    {
      id: "1459",
      district_id: "130",
      name_uz: "Elbayon",
      name_oz: "Ýëáŕ¸í",
      name_ru: "Ýëáŕ¸í",
    },
    {
      id: "1460",
      district_id: "130",
      name_uz: "Elobod",
      name_oz: "Ýëîáîä",
      name_ru: "Ýëîáîä",
    },
    {
      id: "1461",
      district_id: "130",
      name_uz: "Yuqori Kakaydi",
      name_oz: "Ţ?îđč Ęŕęŕéäč",
      name_ru: "Ţ?îđč Ęŕęŕéäč",
    },
    {
      id: "1462",
      district_id: "130",
      name_uz: "Yangiyer",
      name_oz: "ßíăčĺđ",
      name_ru: "ßíăčĺđ",
    },
    {
      id: "1463",
      district_id: "131",
      name_uz: "Baynalmilal",
      name_oz: "Áŕéíŕëěčëŕë",
      name_ru: "Áŕéíŕëěčëŕë",
    },
    {
      id: "1464",
      district_id: "131",
      name_uz: "Baxt",
      name_oz: "Áŕőň",
      name_ru: "Áŕőň",
    },
    {
      id: "1465",
      district_id: "131",
      name_uz: "Beshqo‘ton",
      name_oz: "Áĺř?˘ňîí",
      name_ru: "Áĺř?˘ňîí",
    },
    {
      id: "1466",
      district_id: "131",
      name_uz: "Boldir",
      name_oz: "Áîëäčđ",
      name_ru: "Áîëäčđ",
    },
    {
      id: "1467",
      district_id: "131",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1468",
      district_id: "131",
      name_uz: "Iftixor",
      name_oz: "Čôňčőîđ",
      name_ru: "Čôňčőîđ",
    },
    {
      id: "1469",
      district_id: "131",
      name_uz: "Qozoyoqli",
      name_oz: "?îçî¸?ëč",
      name_ru: "?îçî¸?ëč",
    },
    {
      id: "1470",
      district_id: "131",
      name_uz: "Qorakamar",
      name_oz: "?îđŕęŕěŕđ",
      name_ru: "?îđŕęŕěŕđ",
    },
    {
      id: "1471",
      district_id: "131",
      name_uz: "Muzrabot",
      name_oz: "Ěóçđŕáîň",
      name_ru: "Ěóçđŕáîň",
    },
    {
      id: "1472",
      district_id: "131",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "1473",
      district_id: "131",
      name_uz: "Obodon",
      name_oz: "Îáîäîí",
      name_ru: "Îáîäîí",
    },
    {
      id: "1474",
      district_id: "131",
      name_uz: "Ozod Vatan",
      name_oz: "Îçîä Âŕňŕí",
      name_ru: "Îçîä Âŕňŕí",
    },
    {
      id: "1475",
      district_id: "131",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "1476",
      district_id: "131",
      name_uz: "Sarhad",
      name_oz: "Ńŕđ?ŕä",
      name_ru: "Ńŕđ?ŕä",
    },
    {
      id: "1477",
      district_id: "131",
      name_uz: "Taskent",
      name_oz: "Ňŕńęĺíň",
      name_ru: "Ňŕńęĺíň",
    },
    {
      id: "1478",
      district_id: "131",
      name_uz: "Xalqobod",
      name_oz: "Őŕë?îáîä",
      name_ru: "Őŕë?îáîä",
    },
    {
      id: "1479",
      district_id: "131",
      name_uz: "Chegarachi",
      name_oz: "×ĺăŕđŕ÷č",
      name_ru: "×ĺăŕđŕ÷č",
    },
    {
      id: "1480",
      district_id: "131",
      name_uz: "Sharq yulduzi",
      name_oz: "Řŕđ? ţëäóçč",
      name_ru: "Řŕđ? ţëäóçč",
    },
    {
      id: "1481",
      district_id: "131",
      name_uz: "Sho‘rob",
      name_oz: "Ř˘đîá",
      name_ru: "Ř˘đîá",
    },
    {
      id: "1482",
      district_id: "132",
      name_uz: "Botosh",
      name_oz: "Áîňîř",
      name_ru: "Áîňîř",
    },
    {
      id: "1483",
      district_id: "132",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "1484",
      district_id: "132",
      name_uz: "Vaxshivor",
      name_oz: "Âŕőřčâîđ",
      name_ru: "Âŕőřčâîđ",
    },
    {
      id: "1485",
      district_id: "132",
      name_uz: "Gulobod",
      name_oz: "Ăóëîáîä",
      name_ru: "Ăóëîáîä",
    },
    {
      id: "1486",
      district_id: "132",
      name_uz: "Dug‘oba",
      name_oz: "Äó?îáŕ",
      name_ru: "Äó?îáŕ",
    },
    {
      id: "1487",
      district_id: "132",
      name_uz: "Jobu",
      name_oz: "Ćîáó",
      name_ru: "Ćîáó",
    },
    {
      id: "1488",
      district_id: "132",
      name_uz: "Ipoq",
      name_oz: "Čďî?",
      name_ru: "Čďî?",
    },
    {
      id: "1489",
      district_id: "132",
      name_uz: "Qorliq",
      name_oz: "?îđëč?",
      name_ru: "?îđëč?",
    },
    {
      id: "1490",
      district_id: "132",
      name_uz: "Qorliq",
      name_oz: "?îđëč?",
      name_ru: "?îđëč?",
    },
    {
      id: "1491",
      district_id: "132",
      name_uz: "Qurama",
      name_oz: "?óđŕěŕ",
      name_ru: "?óđŕěŕ",
    },
    {
      id: "1492",
      district_id: "132",
      name_uz: "Lutfiy",
      name_oz: "Ëóňôčé",
      name_ru: "Ëóňôčé",
    },
    {
      id: "1493",
      district_id: "132",
      name_uz: "Mirshodi",
      name_oz: "Ěčđřîäč",
      name_ru: "Ěčđřîäč",
    },
    {
      id: "1494",
      district_id: "132",
      name_uz: "Mormin",
      name_oz: "Ěîđěčí",
      name_ru: "Ěîđěčí",
    },
    {
      id: "1495",
      district_id: "132",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Î? îëňčí",
    },
    {
      id: "1496",
      district_id: "132",
      name_uz: "Oqarbuloq",
      name_oz: "Î?ŕđáóëî?",
      name_ru: "Î?ŕđáóëî?",
    },
    {
      id: "1497",
      district_id: "132",
      name_uz: "Oltinsoy",
      name_oz: "Îëňčíńîé",
      name_ru: "Îëňčíńîé",
    },
    {
      id: "1498",
      district_id: "132",
      name_uz: "Uzumzor",
      name_oz: "Óçóěçîđ",
      name_ru: "Óçóěçîđ",
    },
    {
      id: "1499",
      district_id: "132",
      name_uz: "Xayrandara",
      name_oz: "Őŕéđŕíäŕđŕ",
      name_ru: "Őŕéđŕíäŕđŕ",
    },
    {
      id: "1500",
      district_id: "132",
      name_uz: "Xo‘jasoat",
      name_oz: "Ő˘ćŕńîŕň",
      name_ru: "Ő˘ćŕńîŕň",
    },
    {
      id: "1501",
      district_id: "132",
      name_uz: "Chep",
      name_oz: "×ĺď",
      name_ru: "×ĺď",
    },
    {
      id: "1502",
      district_id: "132",
      name_uz: "Shakarqamish",
      name_oz: "Řŕęŕđ?ŕěčř",
      name_ru: "Řŕęŕđ?ŕěčř",
    },
    {
      id: "1503",
      district_id: "132",
      name_uz: "Ekraz",
      name_oz: "Ýęđŕç",
      name_ru: "Ýęđŕç",
    },
    {
      id: "1504",
      district_id: "132",
      name_uz: "Yangiqurilish",
      name_oz: "ßíăč?óđčëčř",
      name_ru: "ßíăč?óđčëčř",
    },
    {
      id: "1505",
      district_id: "133",
      name_uz: "Bog‘i iram",
      name_oz: "Áî?č čđŕě",
      name_ru: "Áî?č čđŕě",
    },
    {
      id: "1506",
      district_id: "133",
      name_uz: "Bo‘yropo‘sht",
      name_oz: "Á˘éđîď˘řň",
      name_ru: "Á˘éđîď˘řň",
    },
    {
      id: "1507",
      district_id: "133",
      name_uz: "Buyuk kelajak",
      name_oz: "Áóţę ęĺëŕćŕę",
      name_ru: "Áóţę ęĺëŕćŕę",
    },
    {
      id: "1508",
      district_id: "133",
      name_uz: "Dashnobod",
      name_oz: "Äŕříîáîä",
      name_ru: "Äŕříîáîä",
    },
    {
      id: "1509",
      district_id: "133",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "1510",
      district_id: "133",
      name_uz: "Sangardak",
      name_oz: "Ńŕíăŕđäŕę",
      name_ru: "Ńŕíăŕđäŕę",
    },
    {
      id: "1511",
      district_id: "133",
      name_uz: "Sariosiyo",
      name_oz: "Ńŕđčîńč¸",
      name_ru: "Ńŕđčîńč¸",
    },
    {
      id: "1512",
      district_id: "133",
      name_uz: "So‘fiyon",
      name_oz: "Ń˘ôč¸í",
      name_ru: "Ń˘ôč¸í",
    },
    {
      id: "1513",
      district_id: "133",
      name_uz: "Toqchiyon",
      name_oz: "Ňî?÷č¸í",
      name_ru: "Ňî?÷č¸í",
    },
    {
      id: "1514",
      district_id: "133",
      name_uz: "Tortuli",
      name_oz: "Ňîđňóëč",
      name_ru: "Ňîđňóëč",
    },
    {
      id: "1515",
      district_id: "133",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "1516",
      district_id: "133",
      name_uz: "Xufar",
      name_oz: "Őóôŕđ",
      name_ru: "Őóôŕđ",
    },
    {
      id: "1517",
      district_id: "133",
      name_uz: "Sharg‘un",
      name_oz: "Řŕđ?óí",
      name_ru: "Řŕđ?óí",
    },
    {
      id: "1518",
      district_id: "133",
      name_uz: "Yangihayot",
      name_oz: "ßíăč?ŕ¸ň",
      name_ru: "ßíăč?ŕ¸ň",
    },
    {
      id: "1519",
      district_id: "135",
      name_uz: "At-Termiziy",
      name_oz: "Ŕň-Ňĺđěčçčé",
      name_ru: "Ŕň-Ňĺđěčçčé",
    },
    {
      id: "1520",
      district_id: "135",
      name_uz: "Kokildorota",
      name_oz: "Ęîęčëäîđîňŕ",
      name_ru: "Ęîęčëäîđîňŕ",
    },
    {
      id: "1521",
      district_id: "135",
      name_uz: "Qizilboy",
      name_oz: "?čçčëáîé",
      name_ru: "?čçčëáîé",
    },
    {
      id: "1522",
      district_id: "135",
      name_uz: "Limonchi",
      name_oz: "Ëčěîí÷č",
      name_ru: "Ëčěîí÷č",
    },
    {
      id: "1523",
      district_id: "135",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "1524",
      district_id: "135",
      name_uz: "Mustaqillik",
      name_oz: "Ěóńňŕ?čëëčę",
      name_ru: "Ěóńňŕ?čëëčę",
    },
    {
      id: "1525",
      district_id: "135",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "1526",
      district_id: "135",
      name_uz: "Pattakesar",
      name_oz: "Ďŕňňŕęĺńŕđ",
      name_ru: "Ďŕňňŕęĺńŕđ",
    },
    {
      id: "1527",
      district_id: "135",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "1528",
      district_id: "135",
      name_uz: "Tajribakor",
      name_oz: "Ňŕćđčáŕęîđ",
      name_ru: "Ňŕćđčáŕęîđ",
    },
    {
      id: "1529",
      district_id: "135",
      name_uz: "Uchqizil",
      name_oz: "Ó÷?čçčë",
      name_ru: "Ó÷?čçčë",
    },
    {
      id: "1530",
      district_id: "135",
      name_uz: "Uchqizil",
      name_oz: "Ó÷?čçčë",
      name_ru: "Ó÷?čçčë",
    },
    {
      id: "1531",
      district_id: "135",
      name_uz: "Chegarachi",
      name_oz: "×ĺăŕđŕ÷č",
      name_ru: "×ĺăŕđŕ÷č",
    },
    {
      id: "1532",
      district_id: "135",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđč?",
    },
    {
      id: "1533",
      district_id: "136",
      name_uz: "Bobotog‘",
      name_oz: "Áîáîňî?",
      name_ru: "Áîáîňî?",
    },
    {
      id: "1534",
      district_id: "136",
      name_uz: "Jonchekka",
      name_oz: "Ćîí÷ĺęęŕ",
      name_ru: "Ćîí÷ĺęęŕ",
    },
    {
      id: "1535",
      district_id: "136",
      name_uz: "Jonchekka",
      name_oz: "Ćîí÷ĺęęŕ",
      name_ru: "Ćîí÷ĺęęŕ",
    },
    {
      id: "1536",
      district_id: "136",
      name_uz: "Qarashiq",
      name_oz: "?ŕđŕřč?",
      name_ru: "?ŕđŕřč?",
    },
    {
      id: "1537",
      district_id: "136",
      name_uz: "Malandiyon",
      name_oz: "Ěŕëŕíäč¸í",
      name_ru: "Ěŕëŕíäč¸í",
    },
    {
      id: "1538",
      district_id: "136",
      name_uz: "Mehnat",
      name_oz: "Ěĺ?íŕň",
      name_ru: "Ěĺ?íŕň",
    },
    {
      id: "1539",
      district_id: "136",
      name_uz: "Oq Ostona",
      name_oz: "Î? Îńňîíŕ",
      name_ru: "Î? Îńňîíŕ",
    },
    {
      id: "1540",
      district_id: "136",
      name_uz: "Telpakchinor",
      name_oz: "Ňĺëďŕę÷číîđ",
      name_ru: "Ňĺëďŕę÷číîđ",
    },
    {
      id: "1541",
      district_id: "136",
      name_uz: "Uzun",
      name_oz: "Óçóí",
      name_ru: "Óçóí",
    },
    {
      id: "1542",
      district_id: "136",
      name_uz: "Uzun",
      name_oz: "Óçóí",
      name_ru: "Óçóí",
    },
    {
      id: "1543",
      district_id: "136",
      name_uz: "Ulanqul",
      name_oz: "Óëŕí?óë",
      name_ru: "Óëŕí?óë",
    },
    {
      id: "1544",
      district_id: "136",
      name_uz: "Fayzova",
      name_oz: "Ôŕéçîâŕ",
      name_ru: "Ôŕéçîâŕ",
    },
    {
      id: "1545",
      district_id: "136",
      name_uz: "Xonjiza",
      name_oz: "Őîíćčçŕ",
      name_ru: "Őîíćčçŕ",
    },
    {
      id: "1546",
      district_id: "136",
      name_uz: "Chinor",
      name_oz: "×číîđ",
      name_ru: "×číîđ",
    },
    {
      id: "1547",
      district_id: "136",
      name_uz: "Yangi kuch",
      name_oz: "ßíăč ęó÷",
      name_ru: "ßíăč ęó÷",
    },
    {
      id: "1548",
      district_id: "136",
      name_uz: "Yangi ro‘zg‘or",
      name_oz: "ßíăč đ˘ç?îđ",
      name_ru: "ßíăč đ˘ç?îđ",
    },
    {
      id: "1549",
      district_id: "137",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "1550",
      district_id: "137",
      name_uz: "Zarabog‘",
      name_oz: "Çŕđŕáî?",
      name_ru: "Çŕđŕáî?",
    },
    {
      id: "1551",
      district_id: "137",
      name_uz: "Kilkon",
      name_oz: "Ęčëęîí",
      name_ru: "Ęčëęîí",
    },
    {
      id: "1552",
      district_id: "137",
      name_uz: "Ko‘hitang",
      name_oz: "Ę˘?čňŕíă",
      name_ru: "Ę˘?čňŕíă",
    },
    {
      id: "1553",
      district_id: "137",
      name_uz: "Navbog‘",
      name_oz: "Íŕâáî?",
      name_ru: "Íŕâáî?",
    },
    {
      id: "1554",
      district_id: "137",
      name_uz: "Oqqo‘rg‘on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1555",
      district_id: "137",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "1556",
      district_id: "137",
      name_uz: "Rabatak",
      name_oz: "Đŕáŕňŕę",
      name_ru: "Đŕáŕňŕę",
    },
    {
      id: "1557",
      district_id: "137",
      name_uz: "Sariqamish",
      name_oz: "Ńŕđč?ŕěčř",
      name_ru: "Ńŕđč?ŕěčř",
    },
    {
      id: "1558",
      district_id: "137",
      name_uz: "Sariqamish",
      name_oz: "Ńŕđč?ŕěčř",
      name_ru: "Ńŕđč?ŕěčř",
    },
    {
      id: "1559",
      district_id: "137",
      name_uz: "Seplon",
      name_oz: "Ńĺďëîí",
      name_ru: "Ńĺďëîí",
    },
    {
      id: "1560",
      district_id: "137",
      name_uz: "Talloshqon",
      name_oz: "Ňŕëëîř?îí",
      name_ru: "Ňŕëëîř?îí",
    },
    {
      id: "1561",
      district_id: "137",
      name_uz: "Chinobod",
      name_oz: "×číîáîä",
      name_ru: "×číîáîä",
    },
    {
      id: "1562",
      district_id: "137",
      name_uz: "Cho‘yinchi",
      name_oz: "×˘éčí÷č",
      name_ru: "×˘éčí÷č",
    },
    {
      id: "1563",
      district_id: "137",
      name_uz: "Sherobod",
      name_oz: "Řĺđîáîä",
      name_ru: "Řĺđîáîä",
    },
    {
      id: "1564",
      district_id: "137",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđč?",
    },
    {
      id: "1565",
      district_id: "137",
      name_uz: "Yangiturmush",
      name_oz: "ßíăčňóđěóř",
      name_ru: "ßíăčňóđěóř",
    },
    {
      id: "1566",
      district_id: "138",
      name_uz: "Alpomish",
      name_oz: "Ŕëďîěčř",
      name_ru: "Ŕëďîěčř",
    },
    {
      id: "1567",
      district_id: "138",
      name_uz: "Baxtlitepa",
      name_oz: "Áŕőňëčňĺďŕ",
      name_ru: "Áŕőňëčňĺďŕ",
    },
    {
      id: "1568",
      district_id: "138",
      name_uz: "G‘armaqo‘rg‘on",
      name_oz: "?ŕđěŕ?˘đ?îí",
      name_ru: "?ŕđěŕ?˘đ?îí",
    },
    {
      id: "1569",
      district_id: "138",
      name_uz: "Dalvarzin",
      name_oz: "Äŕëâŕđçčí",
      name_ru: "Äŕëâŕđçčí",
    },
    {
      id: "1570",
      district_id: "138",
      name_uz: "Jaloir",
      name_oz: "Ćŕëîčđ",
      name_ru: "Ćŕëîčđ",
    },
    {
      id: "1571",
      district_id: "138",
      name_uz: "Jarqishloq",
      name_oz: "Ćŕđ?čřëî?",
      name_ru: "Ćŕđ?čřëî?",
    },
    {
      id: "1572",
      district_id: "138",
      name_uz: "Joyilma",
      name_oz: "Ćîéčëěŕ",
      name_ru: "Ćîéčëěŕ",
    },
    {
      id: "1573",
      district_id: "138",
      name_uz: "Karvon",
      name_oz: "Ęŕđâîí",
      name_ru: "Ęŕđâîí",
    },
    {
      id: "1574",
      district_id: "138",
      name_uz: "Kattasovur",
      name_oz: "Ęŕňňŕńîâóđ",
      name_ru: "Ęŕňňŕńîâóđ",
    },
    {
      id: "1575",
      district_id: "138",
      name_uz: "Qo‘ldosh",
      name_oz: "?˘ëäîř",
      name_ru: "?˘ëäîř",
    },
    {
      id: "1576",
      district_id: "138",
      name_uz: "Qo‘shtegirmon",
      name_oz: "?˘řňĺăčđěîí",
      name_ru: "?˘řňĺăčđěîí",
    },
    {
      id: "1577",
      district_id: "138",
      name_uz: "Savur",
      name_oz: "Ńŕâóđ",
      name_ru: "Ńŕâóđ",
    },
    {
      id: "1578",
      district_id: "138",
      name_uz: "Sohibkor",
      name_oz: "Ńî?čáęîđ",
      name_ru: "Ńî?čáęîđ",
    },
    {
      id: "1579",
      district_id: "138",
      name_uz: "To‘la",
      name_oz: "Ň˘ëŕ",
      name_ru: "Ň˘ëŕ",
    },
    {
      id: "1580",
      district_id: "138",
      name_uz: "Xushchekka",
      name_oz: "Őóř÷ĺęęŕ",
      name_ru: "Őóř÷ĺęęŕ",
    },
    {
      id: "1581",
      district_id: "138",
      name_uz: "Sho‘rchi",
      name_oz: "Ř˘đ÷č",
      name_ru: "Ř˘đ÷č",
    },
    {
      id: "1582",
      district_id: "138",
      name_uz: "Sho‘rchi",
      name_oz: "Ř˘đ÷č",
      name_ru: "Ř˘đ÷č",
    },
    {
      id: "1583",
      district_id: "138",
      name_uz: "Elbayon",
      name_oz: "Ýëáŕ¸í",
      name_ru: "Ýëáŕ¸í",
    },
    {
      id: "1584",
      district_id: "138",
      name_uz: "Elobod",
      name_oz: "Ýëîáîä",
      name_ru: "Ýëîáîä",
    },
    {
      id: "1585",
      district_id: "138",
      name_uz: "Yalti",
      name_oz: "ßëňč",
      name_ru: "ßëňč",
    },
    {
      id: "1586",
      district_id: "138",
      name_uz: "Yangibozor",
      name_oz: "ßíăčáîçîđ",
      name_ru: "ßíăčáîçîđ",
    },
    {
      id: "1587",
      district_id: "139",
      name_uz: "Bekat",
      name_oz: "Áĺęŕň",
      name_ru: "Áĺęŕň",
    },
    {
      id: "1588",
      district_id: "139",
      name_uz: "Boyovut",
      name_oz: "Áî¸âóň",
      name_ru: "Áî¸âóň",
    },
    {
      id: "1589",
      district_id: "139",
      name_uz: "Boyovut",
      name_oz: "Áî¸âóň",
      name_ru: "Áî¸âóň",
    },
    {
      id: "1590",
      district_id: "139",
      name_uz: "Buyuk yurt",
      name_oz: "Áóţę ţđň",
      name_ru: "Áóţę ţđň",
    },
    {
      id: "1591",
      district_id: "139",
      name_uz: "G‘allakor",
      name_oz: "?ŕëëŕęîđ",
      name_ru: "?ŕëëŕęîđ",
    },
    {
      id: "1592",
      district_id: "139",
      name_uz: "Darvazakir",
      name_oz: "Äŕđâŕçŕęčđ",
      name_ru: "Äŕđâŕçŕęčđ",
    },
    {
      id: "1593",
      district_id: "139",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "1594",
      district_id: "139",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1595",
      district_id: "139",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1596",
      district_id: "139",
      name_uz: "Laylakko‘l",
      name_oz: "Ëŕéëŕęę˘ë",
      name_ru: "Ëŕéëŕęę˘ë",
    },
    {
      id: "1597",
      district_id: "139",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "1598",
      district_id: "139",
      name_uz: "Markaz",
      name_oz: "Ěŕđęŕç",
      name_ru: "Ěŕđęŕç",
    },
    {
      id: "1599",
      district_id: "139",
      name_uz: "Mingchinor",
      name_oz: "Ěčíă÷číîđ",
      name_ru: "Ěčíă÷číîđ",
    },
    {
      id: "1600",
      district_id: "139",
      name_uz: "Olmazor",
      name_oz: "Îëěŕçîđ",
      name_ru: "Îëěŕçîđ",
    },
    {
      id: "1601",
      district_id: "139",
      name_uz: "Tabarruk",
      name_oz: "Ňŕáŕđđóę",
      name_ru: "Ňŕáŕđđóę",
    },
    {
      id: "1602",
      district_id: "139",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "1603",
      district_id: "141",
      name_uz: "Bahor",
      name_oz: "Áŕ?îđ",
      name_ru: "Áŕ?îđ",
    },
    {
      id: "1604",
      district_id: "141",
      name_uz: "Do'stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1605",
      district_id: "141",
      name_uz: "Ulug'obod",
      name_oz: "Óëó?îáîä",
      name_ru: "Óëó?îáîä",
    },
    {
      id: "1606",
      district_id: "140",
      name_uz: "Beshbuloq",
      name_oz: "Áĺřáóëî?",
      name_ru: "Áĺřáóëî?",
    },
    {
      id: "1607",
      district_id: "140",
      name_uz: "Beshbuloq",
      name_oz: "Áĺřáóëî?",
      name_ru: "Áĺřáóëî?",
    },
    {
      id: "1608",
      district_id: "140",
      name_uz: "Dehqonobod",
      name_oz: "Äĺ??îíîáîä",
      name_ru: "Äĺ??îíîáîä",
    },
    {
      id: "1609",
      district_id: "140",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "1610",
      district_id: "140",
      name_uz: "Kunchi",
      name_oz: "Ęóí÷č",
      name_ru: "Ęóí÷č",
    },
    {
      id: "1611",
      district_id: "140",
      name_uz: "Oltin O‘rda",
      name_oz: "Îëňčí ˇđäŕ",
      name_ru: "Îëňčí ˇđäŕ",
    },
    {
      id: "1612",
      district_id: "140",
      name_uz: "Oltintepa",
      name_oz: "Îëňčíňĺďŕ",
      name_ru: "Îëňčíňĺďŕ",
    },
    {
      id: "1613",
      district_id: "140",
      name_uz: "Soyibobod",
      name_oz: "Ńîéčáîáîä",
      name_ru: "Ńîéčáîáîä",
    },
    {
      id: "1614",
      district_id: "140",
      name_uz: "Soxilobod",
      name_oz: "Ńîőčëîáîä",
      name_ru: "Ńîőčëîáîä",
    },
    {
      id: "1615",
      district_id: "140",
      name_uz: "Ulug‘bek",
      name_oz: "Óëó?áĺę",
      name_ru: "Óëó?áĺę",
    },
    {
      id: "1616",
      district_id: "140",
      name_uz: "Xalqako‘l",
      name_oz: "Őŕë?ŕę˘ë",
      name_ru: "Őŕë?ŕę˘ë",
    },
    {
      id: "1617",
      district_id: "140",
      name_uz: "Xumo",
      name_oz: "Őóěî",
      name_ru: "Őóěî",
    },
    {
      id: "1618",
      district_id: "140",
      name_uz: "Hulkar",
      name_oz: "?óëęŕđ",
      name_ru: "?óëęŕđ",
    },
    {
      id: "1619",
      district_id: "140",
      name_uz: "Chortoq",
      name_oz: "×îđňî?",
      name_ru: "×îđňî?",
    },
    {
      id: "1620",
      district_id: "142",
      name_uz: "Bahoriston",
      name_oz: "Áŕ?îđčńňîí",
      name_ru: "Áŕ?îđčńňîí",
    },
    {
      id: "1621",
      district_id: "142",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "1622",
      district_id: "142",
      name_uz: "Yo‘ldoshobod",
      name_oz: "É˘ëäîřîáîä",
      name_ru: "É˘ëäîřîáîä",
    },
    {
      id: "1623",
      district_id: "142",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "1624",
      district_id: "142",
      name_uz: "Mirzacho‘l",
      name_oz: "Ěčđçŕ÷˘ë",
      name_ru: "Ěčđçŕ÷˘ë",
    },
    {
      id: "1625",
      district_id: "142",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "1626",
      district_id: "142",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "1627",
      district_id: "142",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "1628",
      district_id: "142",
      name_uz: "Oqoltin",
      name_oz: "Î?îëňčí",
      name_ru: "Î?îëňčí",
    },
    {
      id: "1629",
      district_id: "142",
      name_uz: "Oqoltin",
      name_oz: "Î?îëňčí",
      name_ru: "Î?îëňčí",
    },
    {
      id: "1630",
      district_id: "142",
      name_uz: "Toshkent",
      name_oz: "Ňîřęĺíň",
      name_ru: "Ňîřęĺíň",
    },
    {
      id: "1631",
      district_id: "143",
      name_uz: "Andijon",
      name_oz: "Ŕíäčćîí",
      name_ru: "Ŕíäčćîí",
    },
    {
      id: "1632",
      district_id: "143",
      name_uz: "Ahillik",
      name_oz: "Ŕ?čëëčę",
      name_ru: "Ŕ?čëëčę",
    },
    {
      id: "1633",
      district_id: "143",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "1634",
      district_id: "143",
      name_uz: "Sardoba",
      name_oz: "Ńŕđäîáŕ",
      name_ru: "Ńŕđäîáŕ",
    },
    {
      id: "1635",
      district_id: "143",
      name_uz: "Farg‘ona",
      name_oz: "Ôŕđ?îíŕ",
      name_ru: "Ôŕđ?îíŕ",
    },
    {
      id: "1636",
      district_id: "143",
      name_uz: "Shodlik",
      name_oz: "Řîäëčę",
      name_ru: "Řîäëčę",
    },
    {
      id: "1637",
      district_id: "144",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1638",
      district_id: "144",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "1639",
      district_id: "144",
      name_uz: "Ittifak",
      name_oz: "Čňňčôŕę",
      name_ru: "Čňňčôŕę",
    },
    {
      id: "1640",
      district_id: "144",
      name_uz: "Nurota",
      name_oz: "Íóđîňŕ",
      name_ru: "Íóđîňŕ",
    },
    {
      id: "1641",
      district_id: "144",
      name_uz: "Paxtakon",
      name_oz: "Ďŕőňŕęîí",
      name_ru: "Ďŕőňŕęîí",
    },
    {
      id: "1642",
      district_id: "144",
      name_uz: "Sayxun",
      name_oz: "Ńŕéőóí",
      name_ru: "Ńŕéőóí",
    },
    {
      id: "1643",
      district_id: "144",
      name_uz: "Sohil",
      name_oz: "Ńî?čë",
      name_ru: "Ńî?čë",
    },
    {
      id: "1644",
      district_id: "144",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "1645",
      district_id: "144",
      name_uz: "Sho‘ro‘zak",
      name_oz: "Ř˘đ˘çŕę",
      name_ru: "Ř˘đ˘çŕę",
    },
    {
      id: "1646",
      district_id: "144",
      name_uz: "Sho‘ro‘zak",
      name_oz: "Ř˘đ˘çŕę",
      name_ru: "Ř˘đ˘çŕę",
    },
    {
      id: "1647",
      district_id: "144",
      name_uz: "Yangi hayot",
      name_oz: "ßíăč ?ŕ¸ň",
      name_ru: "ßíăč ?ŕ¸ň",
    },
    {
      id: "1648",
      district_id: "145",
      name_uz: "Gulzor",
      name_oz: "Ăóëçîđ",
      name_ru: "Ăóëçîđ",
    },
    {
      id: "1649",
      district_id: "145",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1650",
      district_id: "145",
      name_uz: "Qo‘rg‘ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "?˘đ?îíňĺďŕ",
    },
    {
      id: "1651",
      district_id: "145",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "1652",
      district_id: "145",
      name_uz: "Cho‘lQuvar",
      name_oz: "×˘ë?óâŕđ",
      name_ru: "×˘ë?óâŕđ",
    },
    {
      id: "1653",
      district_id: "145",
      name_uz: "Yangiqishloq",
      name_oz: "ßíăč?čřëî?",
      name_ru: "ßíăč?čřëî?",
    },
    {
      id: "1654",
      district_id: "145",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1655",
      district_id: "146",
      name_uz: "Baxt",
      name_oz: "Áŕőň",
      name_ru: "Áŕőň",
    },
    {
      id: "1656",
      district_id: "146",
      name_uz: "J.Mamanov",
      name_oz: "Ć.Ěŕěŕíîâ",
      name_ru: "Ć.Ěŕěŕíîâ",
    },
    {
      id: "1657",
      district_id: "146",
      name_uz: "Ziyokor",
      name_oz: "Çč¸ęîđ",
      name_ru: "Çč¸ęîđ",
    },
    {
      id: "1658",
      district_id: "146",
      name_uz: "Quyosh",
      name_oz: "?ó¸ř",
      name_ru: "?ó¸ř",
    },
    {
      id: "1659",
      district_id: "146",
      name_uz: "Malik",
      name_oz: "Ěŕëčę",
      name_ru: "Ěŕëčę",
    },
    {
      id: "1660",
      district_id: "146",
      name_uz: "Malik",
      name_oz: "Ěŕëčę",
      name_ru: "Ěŕëčę",
    },
    {
      id: "1661",
      district_id: "146",
      name_uz: "Oydin",
      name_oz: "Îéäčí",
      name_ru: "Îéäčí",
    },
    {
      id: "1662",
      district_id: "146",
      name_uz: "Paxtazor",
      name_oz: "Ďŕőňŕçîđ",
      name_ru: "Ďŕőňŕçîđ",
    },
    {
      id: "1663",
      district_id: "146",
      name_uz: "Sirdaryo",
      name_oz: "Ńčđäŕđ¸",
      name_ru: "Ńčđäŕđ¸",
    },
    {
      id: "1664",
      district_id: "146",
      name_uz: "Sirdaryo",
      name_oz: "Ńčđäŕđ¸",
      name_ru: "Ńčđäŕđ¸",
    },
    {
      id: "1665",
      district_id: "146",
      name_uz: "Turon",
      name_oz: "Ňóđîí",
      name_ru: "Ňóđîí",
    },
    {
      id: "1666",
      district_id: "146",
      name_uz: "Xaqiqat",
      name_oz: "Őŕ?č?ŕň",
      name_ru: "Őŕ?č?ŕň",
    },
    {
      id: "1667",
      district_id: "146",
      name_uz: "Xalqobod",
      name_oz: "Őŕë?îáîä",
      name_ru: "Őŕë?îáîä",
    },
    {
      id: "1668",
      district_id: "146",
      name_uz: "Cho‘lto‘qay",
      name_oz: "×˘ëň˘?ŕé",
      name_ru: "×˘ëň˘?ŕé",
    },
    {
      id: "1669",
      district_id: "146",
      name_uz: "Sholikor",
      name_oz: "Řîëčęîđ",
      name_ru: "Řîëčęîđ",
    },
    {
      id: "1670",
      district_id: "147",
      name_uz: "Binokor",
      name_oz: "Áčíîęîđ",
      name_ru: "Áčíîęîđ",
    },
    {
      id: "1671",
      district_id: "147",
      name_uz: "Gulbahor",
      name_oz: "Ăóëáŕ?îđ",
      name_ru: "Ăóëáŕ?îđ",
    },
    {
      id: "1672",
      district_id: "147",
      name_uz: "Gulbahor",
      name_oz: "Ăóëáŕ?îđ",
      name_ru: "Ăóëáŕ?îđ",
    },
    {
      id: "1673",
      district_id: "147",
      name_uz: "Zafarobod",
      name_oz: "Çŕôŕđîáîä",
      name_ru: "Çŕôŕđîáîä",
    },
    {
      id: "1674",
      district_id: "147",
      name_uz: "Qahramon",
      name_oz: "?ŕ?đŕěîí",
      name_ru: "?ŕ?đŕěîí",
    },
    {
      id: "1675",
      district_id: "147",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "1676",
      district_id: "147",
      name_uz: "Soxibkor",
      name_oz: "Ńîőčáęîđ",
      name_ru: "Ńîőčáęîđ",
    },
    {
      id: "1677",
      district_id: "147",
      name_uz: "Turkiston",
      name_oz: "Ňóđęčńňîí",
      name_ru: "Ňóđęčńňîí",
    },
    {
      id: "1678",
      district_id: "147",
      name_uz: "Farxod",
      name_oz: "Ôŕđőîä",
      name_ru: "Ôŕđőîä",
    },
    {
      id: "1679",
      district_id: "147",
      name_uz: "Xovos",
      name_oz: "Őîâîń",
      name_ru: "Őîâîń",
    },
    {
      id: "1680",
      district_id: "147",
      name_uz: "Xovotog‘",
      name_oz: "Őîâîňî?",
      name_ru: "Őîâîňî?",
    },
    {
      id: "1681",
      district_id: "147",
      name_uz: "Xusnobod",
      name_oz: "Őóńíîáîä",
      name_ru: "Őóńíîáîä",
    },
    {
      id: "1682",
      district_id: "147",
      name_uz: "Chamanzor",
      name_oz: "×ŕěŕíçîđ",
      name_ru: "×ŕěŕíçîđ",
    },
    {
      id: "1683",
      district_id: "199",
      name_uz: "Ulug'bek",
      name_oz: "Óëó?áĺę",
      name_ru: "Óëóăáĺę",
    },
    {
      id: "1684",
      district_id: "150",
      name_uz: "Krasnogorsk",
      name_oz: "Ęđŕńíîăîđńę",
      name_ru: "Ęđŕńíîăîđńę",
    },
    {
      id: "1685",
      district_id: "150",
      name_uz: "Nurobod",
      name_oz: "Íóđîáîä",
      name_ru: "Íóđîáîä",
    },
    {
      id: "1686",
      district_id: "150",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1687",
      district_id: "151",
      name_uz: "Bahoriston",
      name_oz: "Áŕ?îđčńňîí",
      name_ru: "Áŕ?îđčńňîí",
    },
    {
      id: "1688",
      district_id: "151",
      name_uz: "Bekobod",
      name_oz: "Áĺęîáîä",
      name_ru: "Áĺęîáîä",
    },
    {
      id: "1689",
      district_id: "151",
      name_uz: "Bobur",
      name_oz: "Áîáóđ",
      name_ru: "Áîáóđ",
    },
    {
      id: "1690",
      district_id: "151",
      name_uz: "Gulzor",
      name_oz: "Ăóëçîđ",
      name_ru: "Ăóëçîđ",
    },
    {
      id: "1691",
      district_id: "151",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1692",
      district_id: "151",
      name_uz: "Dalvarzin",
      name_oz: "Äŕëâŕđçčí",
      name_ru: "Äŕëâŕđçčí",
    },
    {
      id: "1693",
      district_id: "151",
      name_uz: "Jumabozor",
      name_oz: "Ćóěŕáîçîđ",
      name_ru: "Ćóěŕáîçîđ",
    },
    {
      id: "1694",
      district_id: "151",
      name_uz: "Zafar",
      name_oz: "Çŕôŕđ",
      name_ru: "Çŕôŕđ",
    },
    {
      id: "1695",
      district_id: "151",
      name_uz: "Ko‘rkam",
      name_oz: "Ę˘đęŕě",
      name_ru: "Ę˘đęŕě",
    },
    {
      id: "1696",
      district_id: "151",
      name_uz: "Qiyot",
      name_oz: "?č¸ň",
      name_ru: "?č¸ň",
    },
    {
      id: "1697",
      district_id: "151",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "1698",
      district_id: "151",
      name_uz: "Mehnatobod",
      name_oz: "Ěĺ?íŕňîáîä",
      name_ru: "Ěĺ?íŕňîáîä",
    },
    {
      id: "1699",
      district_id: "151",
      name_uz: "Mo‘yinqum",
      name_oz: "Ě˘éčí?óě",
      name_ru: "Ě˘éčí?óě",
    },
    {
      id: "1700",
      district_id: "151",
      name_uz: "Xos",
      name_oz: "Őîń",
      name_ru: "Őîń",
    },
    {
      id: "1701",
      district_id: "151",
      name_uz: "Chanoq",
      name_oz: "×ŕíî?",
      name_ru: "×ŕíî?",
    },
    {
      id: "1702",
      district_id: "151",
      name_uz: "Yangi hayot",
      name_oz: "ßíăč ?ŕ¸ň",
      name_ru: "ßíăč ?ŕ¸ň",
    },
    {
      id: "1703",
      district_id: "151",
      name_uz: "Yangiqo‘rg‘on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăč?˘đ?îí",
    },
    {
      id: "1704",
      district_id: "153",
      name_uz: "Bo‘ka",
      name_oz: "Á˘ęŕ",
      name_ru: "Á˘ęŕ",
    },
    {
      id: "1705",
      district_id: "153",
      name_uz: "Iftixor",
      name_oz: "Čôňčőîđ",
      name_ru: "Čôňčőîđ",
    },
    {
      id: "1706",
      district_id: "153",
      name_uz: "Ko‘korol",
      name_oz: "Ę˘ęîđîë",
      name_ru: "Ę˘ęîđîë",
    },
    {
      id: "1707",
      district_id: "153",
      name_uz: "Qoraqo‘yli",
      name_oz: "?îđŕ?˘éëč",
      name_ru: "?îđŕ?˘éëč",
    },
    {
      id: "1708",
      district_id: "153",
      name_uz: "Qo‘shtepa",
      name_oz: "?˘řňĺďŕ",
      name_ru: "?˘řňĺďŕ",
    },
    {
      id: "1709",
      district_id: "153",
      name_uz: "Namuna",
      name_oz: "Íŕěóíŕ",
      name_ru: "Íŕěóíŕ",
    },
    {
      id: "1710",
      district_id: "153",
      name_uz: "Rovot",
      name_oz: "Đîâîň",
      name_ru: "Đîâîň",
    },
    {
      id: "1711",
      district_id: "153",
      name_uz: "Turon",
      name_oz: "Ňóđîí",
      name_ru: "Ňóđîí",
    },
    {
      id: "1712",
      district_id: "153",
      name_uz: "Chig‘atoy",
      name_oz: "×č?ŕňîé",
      name_ru: "×č?ŕňîé",
    },
    {
      id: "1713",
      district_id: "154",
      name_uz: "Azadbash",
      name_oz: "Ŕçŕäáŕř",
      name_ru: "Ŕçŕäáŕř",
    },
    {
      id: "1714",
      district_id: "154",
      name_uz: "Bog‘iston",
      name_oz: "Áî?čńňîí",
      name_ru: "Áî?čńňîí",
    },
    {
      id: "1715",
      district_id: "154",
      name_uz: "Burchmullo",
      name_oz: "Áóđ÷ěóëëî",
      name_ru: "Áóđ÷ěóëëî",
    },
    {
      id: "1716",
      district_id: "154",
      name_uz: "Bo‘stonliq",
      name_oz: "Á˘ńňîíëč?",
      name_ru: "Á˘ńňîíëč?",
    },
    {
      id: "1717",
      district_id: "154",
      name_uz: "G‘azalkent",
      name_oz: "?ŕçŕëęĺíň",
      name_ru: "?ŕçŕëęĺíň",
    },
    {
      id: "1718",
      district_id: "154",
      name_uz: "G‘azalkent",
      name_oz: "?ŕçŕëęĺíň",
      name_ru: "?ŕçŕëęĺíň",
    },
    {
      id: "1719",
      district_id: "154",
      name_uz: "Dumaloq",
      name_oz: "Äóěŕëî?",
      name_ru: "Äóěŕëî?",
    },
    {
      id: "1720",
      district_id: "154",
      name_uz: "Iskandar",
      name_oz: "Čńęŕíäŕđ",
      name_ru: "Čńęŕíäŕđ",
    },
    {
      id: "1721",
      district_id: "154",
      name_uz: "Qoramanas",
      name_oz: "?îđŕěŕíŕń",
      name_ru: "?îđŕěŕíŕń",
    },
    {
      id: "1722",
      district_id: "154",
      name_uz: "Qoronqul",
      name_oz: "?îđîí?óë",
      name_ru: "?îđîí?óë",
    },
    {
      id: "1723",
      district_id: "154",
      name_uz: "Qurbonov nomli",
      name_oz: "?óđáîíîâ íîěëč",
      name_ru: "?óđáîíîâ íîěëč",
    },
    {
      id: "1724",
      district_id: "154",
      name_uz: "Qo‘shqo‘rg‘on",
      name_oz: "?˘ř?˘đ?îí",
      name_ru: "?˘ř?˘đ?îí",
    },
    {
      id: "1725",
      district_id: "154",
      name_uz: "Qo‘shqo‘rg‘on",
      name_oz: "?˘ř?˘đ?îí",
      name_ru: "?˘ř?˘đ?îí",
    },
    {
      id: "1726",
      district_id: "154",
      name_uz: "Nanay",
      name_oz: "Íŕíŕé",
      name_ru: "Íŕíŕé",
    },
    {
      id: "1727",
      district_id: "154",
      name_uz: "Pargos",
      name_oz: "Ďŕđăîń",
      name_ru: "Ďŕđăîń",
    },
    {
      id: "1728",
      district_id: "154",
      name_uz: "Pargos",
      name_oz: "Ďŕđăîń",
      name_ru: "Ďŕđăîń",
    },
    {
      id: "1729",
      district_id: "154",
      name_uz: "Pskom",
      name_oz: "Ďńęîě",
      name_ru: "Ďńęîě",
    },
    {
      id: "1730",
      district_id: "154",
      name_uz: "Sari qanli",
      name_oz: "Ńŕđč ?ŕíëč",
      name_ru: "Ńŕđč ?ŕíëč",
    },
    {
      id: "1731",
      district_id: "154",
      name_uz: "Sijjak",
      name_oz: "Ńčććŕę",
      name_ru: "Ńčććŕę",
    },
    {
      id: "1732",
      district_id: "154",
      name_uz: "Sobir Raximov",
      name_oz: "Ńîáčđ Đŕőčěîâ",
      name_ru: "Ńîáčđ Đŕőčěîâ",
    },
    {
      id: "1733",
      district_id: "154",
      name_uz: "Soyliq",
      name_oz: "Ńîéëč?",
      name_ru: "Ńîéëč?",
    },
    {
      id: "1734",
      district_id: "154",
      name_uz: "Soyliq",
      name_oz: "Ńîéëč?",
      name_ru: "Ńîéëč?",
    },
    {
      id: "1735",
      district_id: "154",
      name_uz: "Talpin",
      name_oz: "Ňŕëďčí",
      name_ru: "Ňŕëďčí",
    },
    {
      id: "1736",
      district_id: "154",
      name_uz: "Tulabe",
      name_oz: "Ňóëŕáĺ",
      name_ru: "Ňóëŕáĺ",
    },
    {
      id: "1737",
      district_id: "154",
      name_uz: "Uenqulsoy",
      name_oz: "Óĺí?óëńîé",
      name_ru: "Óĺí?óëńîé",
    },
    {
      id: "1738",
      district_id: "154",
      name_uz: "Xondoyliq",
      name_oz: "Őîíäîéëč?",
      name_ru: "Őîíäîéëč?",
    },
    {
      id: "1739",
      district_id: "154",
      name_uz: "Xo‘jakent",
      name_oz: "Ő˘ćŕęĺíň",
      name_ru: "Ő˘ćŕęĺíň",
    },
    {
      id: "1740",
      district_id: "154",
      name_uz: "Xo‘jakent",
      name_oz: "Ő˘ćŕęĺíň",
      name_ru: "Ő˘ćŕęĺíň",
    },
    {
      id: "1741",
      district_id: "154",
      name_uz: "Xumsan",
      name_oz: "Őóěńŕí",
      name_ru: "Őóěńŕí",
    },
    {
      id: "1742",
      district_id: "154",
      name_uz: "Xumsan",
      name_oz: "Őóěńŕí",
      name_ru: "Őóěńŕí",
    },
    {
      id: "1743",
      district_id: "154",
      name_uz: "Ho‘ja",
      name_oz: "?˘ćŕ",
      name_ru: "?˘ćŕ",
    },
    {
      id: "1744",
      district_id: "154",
      name_uz: "Chimboyliq",
      name_oz: "×čěáîéëč?",
      name_ru: "×čěáîéëč?",
    },
    {
      id: "1745",
      district_id: "154",
      name_uz: "Chimgan",
      name_oz: "×čěăŕí",
      name_ru: "×čěăŕí",
    },
    {
      id: "1746",
      district_id: "154",
      name_uz: "Chinor",
      name_oz: "×číîđ",
      name_ru: "×číîđ",
    },
    {
      id: "1747",
      district_id: "154",
      name_uz: "Chorvoq",
      name_oz: "×îđâî?",
      name_ru: "×îđâî?",
    },
    {
      id: "1748",
      district_id: "154",
      name_uz: "Yangi ovul",
      name_oz: "ßíăč îâóë",
      name_ru: "ßíăč îâóë",
    },
    {
      id: "1749",
      district_id: "155",
      name_uz: "Axmad Yassaviy",
      name_oz: "Ŕőěŕä ßńńŕâčé",
      name_ru: "Ŕőěŕä ßńńŕâčé",
    },
    {
      id: "1750",
      district_id: "155",
      name_uz: "Boz-su",
      name_oz: "Áîç-ńó",
      name_ru: "Áîç-ńó",
    },
    {
      id: "1751",
      district_id: "155",
      name_uz: "Daliguzar",
      name_oz: "Äŕëčăóçŕđ",
      name_ru: "Äŕëčăóçŕđ",
    },
    {
      id: "1752",
      district_id: "155",
      name_uz: "Zangiota",
      name_oz: "Çŕíăčîňŕ",
      name_ru: "Çŕíăčîňŕ",
    },
    {
      id: "1753",
      district_id: "155",
      name_uz: "Zangiota",
      name_oz: "Çŕíăčîňŕ",
      name_ru: "Çŕíăčîňŕ",
    },
    {
      id: "1754",
      district_id: "155",
      name_uz: "Qatortol",
      name_oz: "?ŕňîđňîë",
      name_ru: "?ŕňîđňîë",
    },
    {
      id: "1755",
      district_id: "155",
      name_uz: "Quyoshli",
      name_oz: "?ó¸řëč",
      name_ru: "?ó¸řëč",
    },
    {
      id: "1756",
      district_id: "155",
      name_uz: "Nazarbek",
      name_oz: "Íŕçŕđáĺę",
      name_ru: "Íŕçŕđáĺę",
    },
    {
      id: "1757",
      district_id: "155",
      name_uz: "Nazarbek",
      name_oz: "Íŕçŕđáĺę",
      name_ru: "Íŕçŕđáĺę",
    },
    {
      id: "1758",
      district_id: "155",
      name_uz: "Pastdarxon",
      name_oz: "Ďŕńňäŕđőîí",
      name_ru: "Ďŕńňäŕđőîí",
    },
    {
      id: "1759",
      district_id: "155",
      name_uz: "Tarnov",
      name_oz: "Ňŕđíîâ",
      name_ru: "Ňŕđíîâ",
    },
    {
      id: "1760",
      district_id: "155",
      name_uz: "Turkiston",
      name_oz: "Ňóđęčńňîí",
      name_ru: "Ňóđęčńňîí",
    },
    {
      id: "1761",
      district_id: "155",
      name_uz: "O‘zgarish",
      name_oz: "ˇçăŕđčř",
      name_ru: "ˇçăŕđčř",
    },
    {
      id: "1762",
      district_id: "155",
      name_uz: "Ulug‘bek",
      name_oz: "Óëó?áĺę",
      name_ru: "Óëó?áĺę",
    },
    {
      id: "1763",
      district_id: "155",
      name_uz: "O‘rtaovul",
      name_oz: "ˇđňŕîâóë",
      name_ru: "ˇđňŕîâóë",
    },
    {
      id: "1764",
      district_id: "155",
      name_uz: "Xonabod",
      name_oz: "Őîíŕáîä",
      name_ru: "Őîíŕáîä",
    },
    {
      id: "1765",
      district_id: "155",
      name_uz: "Honobod",
      name_oz: "?îíîáîä",
      name_ru: "?îíîáîä",
    },
    {
      id: "1766",
      district_id: "155",
      name_uz: "Chig‘atoy-Oqtepa",
      name_oz: "×č?ŕňîé-Î?ňĺďŕ",
      name_ru: "×č?ŕňîé-Î?ňĺďŕ",
    },
    {
      id: "1767",
      district_id: "155",
      name_uz: "Erkin",
      name_oz: "Ýđęčí",
      name_ru: "Ýđęčí",
    },
    {
      id: "1768",
      district_id: "155",
      name_uz: "Erkin",
      name_oz: "Ýđęčí",
      name_ru: "Ýđęčí",
    },
    {
      id: "1769",
      district_id: "155",
      name_uz: "Eshonguzar",
      name_oz: "Ýřîíăóçŕđ",
      name_ru: "Ýřîíăóçŕđ",
    },
    {
      id: "1770",
      district_id: "156",
      name_uz: "Alisherobod",
      name_oz: "Ŕëčřĺđîáîä",
      name_ru: "Ŕëčřĺđîáîä",
    },
    {
      id: "1771",
      district_id: "156",
      name_uz: "Argin",
      name_oz: "Ŕđăčí",
      name_ru: "Ŕđăčí",
    },
    {
      id: "1772",
      district_id: "156",
      name_uz: "Baytqo‘rg‘on",
      name_oz: "Áŕéň?˘đ?îí",
      name_ru: "Áŕéň?˘đ?îí",
    },
    {
      id: "1773",
      district_id: "156",
      name_uz: "Geofizika",
      name_oz: "Ăĺîôčçčęŕ",
      name_ru: "Ăĺîôčçčęŕ",
    },
    {
      id: "1774",
      district_id: "156",
      name_uz: "Do‘rmon",
      name_oz: "Ä˘đěîí",
      name_ru: "Ä˘đěîí",
    },
    {
      id: "1775",
      district_id: "156",
      name_uz: "Do‘rmon",
      name_oz: "Ä˘đěîí",
      name_ru: "Ä˘đěîí",
    },
    {
      id: "1776",
      district_id: "156",
      name_uz: "Yon-ariq",
      name_oz: "¨í-ŕđč?",
      name_ru: "¨í-ŕđč?",
    },
    {
      id: "1777",
      district_id: "156",
      name_uz: "Yoshlik",
      name_oz: "¨řëčę",
      name_ru: "¨řëčę",
    },
    {
      id: "1778",
      district_id: "156",
      name_uz: "Zafarobod",
      name_oz: "Çŕôŕđîáîä",
      name_ru: "Çŕôŕđîáîä",
    },
    {
      id: "1779",
      district_id: "156",
      name_uz: "Ko‘prik boshi",
      name_oz: "Ę˘ďđčę áîřč",
      name_ru: "Ę˘ďđčę áîřč",
    },
    {
      id: "1780",
      district_id: "156",
      name_uz: "Qibray",
      name_oz: "?čáđŕé",
      name_ru: "?čáđŕé",
    },
    {
      id: "1781",
      district_id: "156",
      name_uz: "Qipchoq",
      name_oz: "?čď÷î?",
      name_ru: "?čď÷î?",
    },
    {
      id: "1782",
      district_id: "156",
      name_uz: "Madaniyat",
      name_oz: "Ěŕäŕíč˙ň",
      name_ru: "Ěŕäŕíč˙ň",
    },
    {
      id: "1783",
      district_id: "156",
      name_uz: "Mustaqillik",
      name_oz: "Ěóńňŕ?čëëčę",
      name_ru: "Ěóńňŕ?čëëčę",
    },
    {
      id: "1784",
      district_id: "156",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "1785",
      district_id: "156",
      name_uz: "Oq-qovoq",
      name_oz: "Î?-?îâî?",
      name_ru: "Î?-?îâî?",
    },
    {
      id: "1786",
      district_id: "156",
      name_uz: "Salar",
      name_oz: "Ńŕëŕđ",
      name_ru: "Ńŕëŕđ",
    },
    {
      id: "1787",
      district_id: "156",
      name_uz: "TaraqQiyot",
      name_oz: "Ňŕđŕ??č¸ň",
      name_ru: "Ňŕđŕ??č¸ň",
    },
    {
      id: "1788",
      district_id: "156",
      name_uz: "Tuzel",
      name_oz: "Ňóçĺë",
      name_ru: "Ňóçĺë",
    },
    {
      id: "1789",
      district_id: "156",
      name_uz: "Uymaut",
      name_oz: "Óéěŕóň",
      name_ru: "Óéěŕóň",
    },
    {
      id: "1790",
      district_id: "156",
      name_uz: "Unqo‘rg‘on",
      name_oz: "Óí?˘đ?îí",
      name_ru: "Óí?˘đ?îí",
    },
    {
      id: "1791",
      district_id: "156",
      name_uz: "Unqo‘rg‘on-1",
      name_oz: "Óí?˘đ?îí-1",
      name_ru: "Óí?˘đ?îí-1",
    },
    {
      id: "1792",
      district_id: "156",
      name_uz: "O‘tkir",
      name_oz: "ˇňęčđ",
      name_ru: "ˇňęčđ",
    },
    {
      id: "1793",
      district_id: "156",
      name_uz: "X.Amirov",
      name_oz: "Ő.Ŕěčđîâ",
      name_ru: "Ő.Ŕěčđîâ",
    },
    {
      id: "1794",
      district_id: "156",
      name_uz: "Chinobod",
      name_oz: "×číîáîä",
      name_ru: "×číîáîä",
    },
    {
      id: "1795",
      district_id: "156",
      name_uz: "Yangiobod",
      name_oz: "ßíăčîáîä",
      name_ru: "ßíăčîáîä",
    },
    {
      id: "1796",
      district_id: "157",
      name_uz: "Gul",
      name_oz: "Ăóë",
      name_ru: "Ăóë",
    },
    {
      id: "1797",
      district_id: "157",
      name_uz: "Do‘stobod",
      name_oz: "Ä˘ńňîáîä",
      name_ru: "Ä˘ńňîáîä",
    },
    {
      id: "1798",
      district_id: "157",
      name_uz: "Ketmontepa",
      name_oz: "Ęĺňěîíňĺďŕ",
      name_ru: "Ęĺňěîíňĺďŕ",
    },
    {
      id: "1799",
      district_id: "157",
      name_uz: "Qo‘rg‘oncha",
      name_oz: "?˘đ?îí÷ŕ",
      name_ru: "?˘đ?îí÷ŕ",
    },
    {
      id: "1800",
      district_id: "157",
      name_uz: "Qo‘rg‘oncha",
      name_oz: "?˘đ?îí÷ŕ",
      name_ru: "?˘đ?îí÷ŕ",
    },
    {
      id: "1801",
      district_id: "157",
      name_uz: "Maydontol",
      name_oz: "Ěŕéäîíňîë",
      name_ru: "Ěŕéäîíňîë",
    },
    {
      id: "1802",
      district_id: "157",
      name_uz: "Ma?naviyat",
      name_oz: "Ěŕúíŕâč˙ň",
      name_ru: "Ěŕúíŕâč˙ň",
    },
    {
      id: "1803",
      district_id: "157",
      name_uz: "Ma?rifat",
      name_oz: "Ěŕúđčôŕň",
      name_ru: "Ěŕúđčôŕň",
    },
    {
      id: "1804",
      district_id: "157",
      name_uz: "Paxtazor",
      name_oz: "Ďŕőňŕçîđ",
      name_ru: "Ďŕőňŕçîđ",
    },
    {
      id: "1805",
      district_id: "157",
      name_uz: "Toshloq",
      name_oz: "Ňîřëî?",
      name_ru: "Ňîřëî?",
    },
    {
      id: "1806",
      district_id: "157",
      name_uz: "Toshovul",
      name_oz: "Ňîřîâóë",
      name_ru: "Ňîřîâóë",
    },
    {
      id: "1807",
      district_id: "157",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "1808",
      district_id: "158",
      name_uz: "Achchi",
      name_oz: "Ŕ÷÷č",
      name_ru: "Ŕ÷÷č",
    },
    {
      id: "1809",
      district_id: "158",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1810",
      district_id: "158",
      name_uz: "Zarbdor",
      name_oz: "Çŕđáäîđ",
      name_ru: "Çŕđáäîđ",
    },
    {
      id: "1811",
      district_id: "158",
      name_uz: "Zafar",
      name_oz: "Çŕôŕđ",
      name_ru: "Çŕôŕđ",
    },
    {
      id: "1812",
      district_id: "158",
      name_uz: "Oytamg‘ali",
      name_oz: "Îéňŕě?ŕëč",
      name_ru: "Îéňŕě?ŕëč",
    },
    {
      id: "1813",
      district_id: "158",
      name_uz: "Oqqo‘rg‘on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1814",
      district_id: "158",
      name_uz: "Oqqo‘rg‘on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Î??˘đ?îí",
    },
    {
      id: "1815",
      district_id: "158",
      name_uz: "Olimkent",
      name_oz: "Îëčěęĺíň",
      name_ru: "Îëčěęĺíň",
    },
    {
      id: "1816",
      district_id: "158",
      name_uz: "Toshto‘g‘on",
      name_oz: "Ňîřň˘?îí",
      name_ru: "Ňîřň˘?îí",
    },
    {
      id: "1817",
      district_id: "158",
      name_uz: "Hamzaobod",
      name_oz: "?ŕěçŕîáîä",
      name_ru: "?ŕěçŕîáîä",
    },
    {
      id: "1818",
      district_id: "158",
      name_uz: "Shoxruxiya",
      name_oz: "Řîőđóőč˙",
      name_ru: "Řîőđóőč˙",
    },
    {
      id: "1819",
      district_id: "158",
      name_uz: "Eltamg‘ali",
      name_oz: "Ýëňŕě?ŕëč",
      name_ru: "Ýëňŕě?ŕëč",
    },
    {
      id: "1820",
      district_id: "158",
      name_uz: "Erkinlik",
      name_oz: "Ýđęčíëčę",
      name_ru: "Ýđęčíëčę",
    },
    {
      id: "1821",
      district_id: "160",
      name_uz: "Birlik",
      name_oz: "Áčđëčę",
      name_ru: "Áčđëčę",
    },
    {
      id: "1822",
      district_id: "160",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1823",
      district_id: "160",
      name_uz: "Yon-ariq",
      name_oz: "¨í-ŕđč?",
      name_ru: "¨í-ŕđč?",
    },
    {
      id: "1824",
      district_id: "160",
      name_uz: "Qora xitoy",
      name_oz: "?îđŕ őčňîé",
      name_ru: "?îđŕ őčňîé",
    },
    {
      id: "1825",
      district_id: "160",
      name_uz: "Qora Xitoy",
      name_oz: "?îđŕ Őčňîé",
      name_ru: "?îđŕ Őčňîé",
    },
    {
      id: "1826",
      district_id: "160",
      name_uz: "Qurama",
      name_oz: "?óđŕěŕ",
      name_ru: "?óđŕěŕ",
    },
    {
      id: "1827",
      district_id: "160",
      name_uz: "Ozodlik",
      name_oz: "Îçîäëčę",
      name_ru: "Îçîäëčę",
    },
    {
      id: "1828",
      district_id: "160",
      name_uz: "Susam",
      name_oz: "Ńóńŕě",
      name_ru: "Ńóńŕě",
    },
    {
      id: "1829",
      district_id: "160",
      name_uz: "Telov",
      name_oz: "Ňĺëîâ",
      name_ru: "Ňĺëîâ",
    },
    {
      id: "1830",
      district_id: "160",
      name_uz: "Telov",
      name_oz: "Ňĺëîâ",
      name_ru: "Ňĺëîâ",
    },
    {
      id: "1831",
      district_id: "160",
      name_uz: "Uvaq",
      name_oz: "Óâŕ?",
      name_ru: "Óâŕ?",
    },
    {
      id: "1832",
      district_id: "160",
      name_uz: "Eyvalek",
      name_oz: "Ýéâŕëĺę",
      name_ru: "Ýéâŕëĺę",
    },
    {
      id: "1833",
      district_id: "161",
      name_uz: "Boshqizilsoy",
      name_oz: "Áîř?čçčëńîé",
      name_ru: "Áîř?čçčëńîé",
    },
    {
      id: "1834",
      district_id: "161",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Á˘ńňîí",
    },
    {
      id: "1835",
      district_id: "161",
      name_uz: "Zarkent",
      name_oz: "Çŕđęĺíň",
      name_ru: "Çŕđęĺíň",
    },
    {
      id: "1836",
      district_id: "161",
      name_uz: "Qoraqalpoq",
      name_oz: "?îđŕ?ŕëďî?",
      name_ru: "?îđŕ?ŕëďî?",
    },
    {
      id: "1837",
      district_id: "161",
      name_uz: "Quyosh",
      name_oz: "?ó¸ř",
      name_ru: "?ó¸ř",
    },
    {
      id: "1838",
      district_id: "161",
      name_uz: "Qo‘rg‘ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "?˘đ?îíňĺďŕ",
    },
    {
      id: "1839",
      district_id: "161",
      name_uz: "Nomdanak",
      name_oz: "Íîěäŕíŕę",
      name_ru: "Íîěäŕíŕę",
    },
    {
      id: "1840",
      district_id: "161",
      name_uz: "Parkent",
      name_oz: "Ďŕđęĺíň",
      name_ru: "Ďŕđęĺíň",
    },
    {
      id: "1841",
      district_id: "161",
      name_uz: "Parkent",
      name_oz: "Ďŕđęĺíň",
      name_ru: "Ďŕđęĺíň",
    },
    {
      id: "1842",
      district_id: "161",
      name_uz: "So‘qoq",
      name_oz: "Ń˘?î?",
      name_ru: "Ń˘?î?",
    },
    {
      id: "1843",
      district_id: "161",
      name_uz: "Xisarak",
      name_oz: "Őčńŕđŕę",
      name_ru: "Őčńŕđŕę",
    },
    {
      id: "1844",
      district_id: "161",
      name_uz: "Changi",
      name_oz: "×ŕíăč",
      name_ru: "×ŕíăč",
    },
    {
      id: "1845",
      district_id: "161",
      name_uz: "Chinorli",
      name_oz: "×číîđëč",
      name_ru: "×číîđëč",
    },
    {
      id: "1846",
      district_id: "162",
      name_uz: "Dungqo‘rg‘on",
      name_oz: "Äóíă?˘đ?îí",
      name_ru: "Äóíă?˘đ?îí",
    },
    {
      id: "1847",
      district_id: "162",
      name_uz: "Kelovchi",
      name_oz: "Ęĺëîâ÷č",
      name_ru: "Ęĺëîâ÷č",
    },
    {
      id: "1848",
      district_id: "162",
      name_uz: "Koriz",
      name_oz: "Ęîđčç",
      name_ru: "Ęîđčç",
    },
    {
      id: "1849",
      district_id: "162",
      name_uz: "Muratali",
      name_oz: "Ěóđŕňŕëč",
      name_ru: "Ěóđŕňŕëč",
    },
    {
      id: "1850",
      district_id: "162",
      name_uz: "Murotali",
      name_oz: "Ěóđîňŕëč",
      name_ru: "Ěóđîňŕëč",
    },
    {
      id: "1851",
      district_id: "162",
      name_uz: "Oq tepa",
      name_oz: "Î? ňĺďŕ",
      name_ru: "Î? ňĺďŕ",
    },
    {
      id: "1852",
      district_id: "162",
      name_uz: "Pskent",
      name_oz: "Ďńęĺíň",
      name_ru: "Ďńęĺíň",
    },
    {
      id: "1853",
      district_id: "162",
      name_uz: "Said",
      name_oz: "Ńŕčä",
      name_ru: "Ńŕčä",
    },
    {
      id: "1854",
      district_id: "162",
      name_uz: "Said",
      name_oz: "Ńŕčä",
      name_ru: "Ńŕčä",
    },
    {
      id: "1855",
      district_id: "215",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "1856",
      district_id: "215",
      name_uz: "Z.Jalilov",
      name_oz: "Ç.Ćŕëčëîâ",
      name_ru: "Ç.Ćŕëčëîâ",
    },
    {
      id: "1857",
      district_id: "215",
      name_uz: "Keles",
      name_oz: "Ęĺëĺń",
      name_ru: "Ęĺëĺń",
    },
    {
      id: "1858",
      district_id: "215",
      name_uz: "Kensoy",
      name_oz: "Ęĺíńîé",
      name_ru: "Ęĺíńîé",
    },
    {
      id: "1859",
      district_id: "215",
      name_uz: "Ko‘k saroy",
      name_oz: "Ę˘ę ńŕđîé",
      name_ru: "Ę˘ę ńŕđîé",
    },
    {
      id: "1860",
      district_id: "215",
      name_uz: "Ko‘k Saroy",
      name_oz: "Ę˘ę Ńŕđîé",
      name_ru: "Ę˘ę Ńŕđîé",
    },
    {
      id: "1861",
      district_id: "215",
      name_uz: "Ko‘k-terak",
      name_oz: "Ę˘ę-ňĺđŕę",
      name_ru: "Ę˘ę-ňĺđŕę",
    },
    {
      id: "1862",
      district_id: "215",
      name_uz: "Qashqarlik",
      name_oz: "?ŕř?ŕđëčę",
      name_ru: "?ŕř?ŕđëčę",
    },
    {
      id: "1863",
      district_id: "215",
      name_uz: "Qizg‘aldoq",
      name_oz: "?čç?ŕëäî?",
      name_ru: "?čç?ŕëäî?",
    },
    {
      id: "1864",
      district_id: "215",
      name_uz: "M.Fozilov",
      name_oz: "Ě.Ôîçčëîâ",
      name_ru: "Ě.Ôîçčëîâ",
    },
    {
      id: "1865",
      district_id: "215",
      name_uz: "Masalboy",
      name_oz: "Ěŕńŕëáîé",
      name_ru: "Ěŕńŕëáîé",
    },
    {
      id: "1866",
      district_id: "215",
      name_uz: "Sabzavot",
      name_oz: "Ńŕáçŕâîň",
      name_ru: "Ńŕáçŕâîň",
    },
    {
      id: "1867",
      district_id: "215",
      name_uz: "Hasanboy",
      name_oz: "?ŕńŕíáîé",
      name_ru: "?ŕńŕíáîé",
    },
    {
      id: "1868",
      district_id: "215",
      name_uz: "Hasanboy",
      name_oz: "?ŕńŕíáîé",
      name_ru: "?ŕńŕíáîé",
    },
    {
      id: "1869",
      district_id: "215",
      name_uz: "Chig‘atoy",
      name_oz: "×č?ŕňîé",
      name_ru: "×č?ŕňîé",
    },
    {
      id: "1870",
      district_id: "215",
      name_uz: "Choshtepa",
      name_oz: "×îřňĺďŕ",
      name_ru: "×îřňĺďŕ",
    },
    {
      id: "1871",
      district_id: "215",
      name_uz: "Chuvalachi",
      name_oz: "×óâŕëŕ÷č",
      name_ru: "×óâŕëŕ÷č",
    },
    {
      id: "1872",
      district_id: "215",
      name_uz: "Shamsiobod",
      name_oz: "Řŕěńčîáîä",
      name_ru: "Řŕěńčîáîä",
    },
    {
      id: "1873",
      district_id: "215",
      name_uz: "Yunusobod",
      name_oz: "Ţíóńîáîä",
      name_ru: "Ţíóńîáîä",
    },
    {
      id: "1874",
      district_id: "163",
      name_uz: "Angor",
      name_oz: "Ŕíăîđ",
      name_ru: "Ŕíăîđ",
    },
    {
      id: "1875",
      district_id: "163",
      name_uz: "Istiqbol",
      name_oz: "Čńňč?áîë",
      name_ru: "Čńňč?áîë",
    },
    {
      id: "1876",
      district_id: "163",
      name_uz: "Yo‘ng‘ichqala",
      name_oz: "É˘í?č÷?ŕëŕ",
      name_ru: "É˘í?č÷?ŕëŕ",
    },
    {
      id: "1877",
      district_id: "163",
      name_uz: "Kuchluk",
      name_oz: "Ęó÷ëóę",
      name_ru: "Ęó÷ëóę",
    },
    {
      id: "1878",
      district_id: "163",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1879",
      district_id: "163",
      name_uz: "Qorasuv",
      name_oz: "?îđŕńóâ",
      name_ru: "?îđŕńóâ",
    },
    {
      id: "1880",
      district_id: "163",
      name_uz: "Qumovul",
      name_oz: "?óěîâóë",
      name_ru: "?óěîâóë",
    },
    {
      id: "1881",
      district_id: "163",
      name_uz: "Navoiy nomli",
      name_oz: "Íŕâîčé íîěëč",
      name_ru: "Íŕâîčé íîěëč",
    },
    {
      id: "1882",
      district_id: "163",
      name_uz: "Oq ota",
      name_oz: "Î? îňŕ",
      name_ru: "Î? îňŕ",
    },
    {
      id: "1883",
      district_id: "163",
      name_uz: "Paxtakor",
      name_oz: "Ďŕőňŕęîđ",
      name_ru: "Ďŕőňŕęîđ",
    },
    {
      id: "1884",
      district_id: "163",
      name_uz: "Paxtaobod",
      name_oz: "Ďŕőňŕîáîä",
      name_ru: "Ďŕőňŕîáîä",
    },
    {
      id: "1885",
      district_id: "163",
      name_uz: "Tuyabo‘g‘iz",
      name_oz: "Ňó˙á˘?čç",
      name_ru: "Ňó˙á˘?čç",
    },
    {
      id: "1886",
      district_id: "163",
      name_uz: "Uyshun",
      name_oz: "Óéřóí",
      name_ru: "Óéřóí",
    },
    {
      id: "1887",
      district_id: "163",
      name_uz: "O‘rtasaroy",
      name_oz: "ˇđňŕńŕđîé",
      name_ru: "ˇđňŕńŕđîé",
    },
    {
      id: "1888",
      district_id: "163",
      name_uz: "Haqiqat",
      name_oz: "?ŕ?č?ŕň",
      name_ru: "?ŕ?č?ŕň",
    },
    {
      id: "1889",
      district_id: "163",
      name_uz: "Sholikor",
      name_oz: "Řîëčęîđ",
      name_ru: "Řîëčęîđ",
    },
    {
      id: "1890",
      district_id: "163",
      name_uz: "Yangi turmush",
      name_oz: "ßíăč ňóđěóř",
      name_ru: "ßíăč ňóđěóř",
    },
    {
      id: "1891",
      district_id: "164",
      name_uz: "A.Temur",
      name_oz: "Ŕ.Ňĺěóđ",
      name_ru: "Ŕ.Ňĺěóđ",
    },
    {
      id: "1892",
      district_id: "164",
      name_uz: "Birlik",
      name_oz: "Áčđëčę",
      name_ru: "Áčđëčę",
    },
    {
      id: "1893",
      district_id: "164",
      name_uz: "Gulzorobod",
      name_oz: "Ăóëçîđîáîä",
      name_ru: "Ăóëçîđîáîä",
    },
    {
      id: "1894",
      district_id: "164",
      name_uz: "Do‘stlik",
      name_oz: "Ä˘ńňëčę",
      name_ru: "Ä˘ńňëčę",
    },
    {
      id: "1895",
      district_id: "164",
      name_uz: "Yollama",
      name_oz: "¨ëëŕěŕ",
      name_ru: "¨ëëŕěŕ",
    },
    {
      id: "1896",
      district_id: "164",
      name_uz: "Isloxat",
      name_oz: "Čńëîőŕň",
      name_ru: "Čńëîőŕň",
    },
    {
      id: "1897",
      district_id: "164",
      name_uz: "Kir",
      name_oz: "Ęčđ",
      name_ru: "Ęčđ",
    },
    {
      id: "1898",
      district_id: "164",
      name_uz: "Ko‘tarma",
      name_oz: "Ę˘ňŕđěŕ",
      name_ru: "Ę˘ňŕđěŕ",
    },
    {
      id: "1899",
      district_id: "164",
      name_uz: "Olmazor",
      name_oz: "Îëěŕçîđ",
      name_ru: "Îëěŕçîđ",
    },
    {
      id: "1900",
      district_id: "164",
      name_uz: "Paxta",
      name_oz: "Ďŕőňŕ",
      name_ru: "Ďŕőňŕ",
    },
    {
      id: "1901",
      district_id: "164",
      name_uz: "Turkiston",
      name_oz: "Ňóđęčńňîí",
      name_ru: "Ňóđęčńňîí",
    },
    {
      id: "1902",
      district_id: "164",
      name_uz: "O‘zbekiston",
      name_oz: "ˇçáĺęčńňîí",
      name_ru: "ˇçáĺęčńňîí",
    },
    {
      id: "1903",
      district_id: "164",
      name_uz: "Chinoz",
      name_oz: "×číîç",
      name_ru: "×číîç",
    },
    {
      id: "1904",
      district_id: "164",
      name_uz: "Chinoz",
      name_oz: "×číîç",
      name_ru: "×číîç",
    },
    {
      id: "1905",
      district_id: "164",
      name_uz: "Chorvador",
      name_oz: "×îđâŕäîđ",
      name_ru: "×îđâŕäîđ",
    },
    {
      id: "1906",
      district_id: "164",
      name_uz: "Eski Toshkent",
      name_oz: "Ýńęč Ňîřęĺíň",
      name_ru: "Ýńęč Ňîřęĺíň",
    },
    {
      id: "1907",
      district_id: "164",
      name_uz: "Eshonobod",
      name_oz: "Ýřîíîáîä",
      name_ru: "Ýřîíîáîä",
    },
    {
      id: "1908",
      district_id: "164",
      name_uz: "Yangi Chinoz",
      name_oz: "ßíăč ×číîç",
      name_ru: "ßíăč ×číîç",
    },
    {
      id: "1909",
      district_id: "166",
      name_uz: "Arganchi",
      name_oz: "Ŕđăŕí÷č",
      name_ru: "Ŕđăŕí÷č",
    },
    {
      id: "1910",
      district_id: "166",
      name_uz: "Bordonko‘l",
      name_oz: "Áîđäîíę˘ë",
      name_ru: "Áîđäîíę˘ë",
    },
    {
      id: "1911",
      district_id: "166",
      name_uz: "Jambul",
      name_oz: "Ćŕěáóë",
      name_ru: "Ćŕěáóë",
    },
    {
      id: "1912",
      district_id: "166",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňč?ëîë",
    },
    {
      id: "1913",
      district_id: "166",
      name_uz: "Mirobod",
      name_oz: "Ěčđîáîä",
      name_ru: "Ěčđîáîä",
    },
    {
      id: "1914",
      district_id: "166",
      name_uz: "Navro‘z",
      name_oz: "Íŕâđ˘ç",
      name_ru: "Íŕâđ˘ç",
    },
    {
      id: "1915",
      district_id: "166",
      name_uz: "Oqovul",
      name_oz: "Î?îâóë",
      name_ru: "Î?îâóë",
    },
    {
      id: "1916",
      district_id: "166",
      name_uz: "Sakson ota",
      name_oz: "Ńŕęńîí îňŕ",
      name_ru: "Ńŕęńîí îňŕ",
    },
    {
      id: "1917",
      district_id: "166",
      name_uz: "Surnkent",
      name_oz: "Ńóđíęĺíň",
      name_ru: "Ńóđíęĺíň",
    },
    {
      id: "1918",
      district_id: "166",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "1919",
      district_id: "166",
      name_uz: "Xitoy Tepa",
      name_oz: "Őčňîé Ňĺďŕ",
      name_ru: "Őčňîé Ňĺďŕ",
    },
    {
      id: "1920",
      district_id: "166",
      name_uz: "Yangibozor",
      name_oz: "ßíăčáîçîđ",
      name_ru: "ßíăčáîçîđ",
    },
    {
      id: "1921",
      district_id: "167",
      name_uz: "Bozsu",
      name_oz: "Áîçńó",
      name_ru: "Áîçńó",
    },
    {
      id: "1922",
      district_id: "167",
      name_uz: "Gulbahor",
      name_oz: "Ăóëáŕ?îđ",
      name_ru: "Ăóëáŕ?îđ",
    },
    {
      id: "1923",
      district_id: "167",
      name_uz: "Yo‘g‘ontepa",
      name_oz: "É˘?îíňĺďŕ",
      name_ru: "É˘?îíňĺďŕ",
    },
    {
      id: "1924",
      district_id: "167",
      name_uz: "Kirsadoq",
      name_oz: "Ęčđńŕäî?",
      name_ru: "Ęčđńŕäî?",
    },
    {
      id: "1925",
      district_id: "167",
      name_uz: "Qovunchi",
      name_oz: "?îâóí÷č",
      name_ru: "?îâóí÷č",
    },
    {
      id: "1926",
      district_id: "167",
      name_uz: "Qo‘sh yog‘och",
      name_oz: "?˘ř ¸?î÷",
      name_ru: "?˘ř ¸?î÷",
    },
    {
      id: "1927",
      district_id: "167",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕ?îđ",
    },
    {
      id: "1928",
      district_id: "167",
      name_uz: "Niyozbosh",
      name_oz: "Íč¸çáîř",
      name_ru: "Íč¸çáîř",
    },
    {
      id: "1929",
      district_id: "167",
      name_uz: "Nov",
      name_oz: "Íîâ",
      name_ru: "Íîâ",
    },
    {
      id: "1930",
      district_id: "167",
      name_uz: "Xonqo‘rg‘on",
      name_oz: "Őîí?˘đ?îí",
      name_ru: "Őîí?˘đ?îí",
    },
    {
      id: "1931",
      district_id: "167",
      name_uz: "Halqobod",
      name_oz: "?ŕë?îáîä",
      name_ru: "?ŕë?îáîä",
    },
    {
      id: "1932",
      district_id: "167",
      name_uz: "Sho‘ralisoy",
      name_oz: "Ř˘đŕëčńîé",
      name_ru: "Ř˘đŕëčńîé",
    },
    {
      id: "1933",
      district_id: "167",
      name_uz: "Eski Qovunchi",
      name_oz: "Ýńęč ?îâóí÷č",
      name_ru: "Ýńęč ?îâóí÷č",
    },
    {
      id: "1934",
      district_id: "168",
      name_uz: "Beshariq",
      name_oz: "Áĺřŕđč?",
      name_ru: "Áĺřŕđűę",
    },
    {
      id: "1935",
      district_id: "168",
      name_uz: "Nafosat",
      name_oz: "Íŕôîńŕň",
      name_ru: "Íŕôîńŕň",
    },
    {
      id: "1936",
      district_id: "168",
      name_uz: "Zarqaynar",
      name_oz: "Çŕđ?ŕéíŕđ",
      name_ru: "Çŕđęŕéíŕđ",
    },
    {
      id: "1937",
      district_id: "168",
      name_uz: "Kapayangi",
      name_oz: "Ęŕďŕ˙íăč",
      name_ru: "Ęŕďŕ˙íăč",
    },
    {
      id: "1938",
      district_id: "168",
      name_uz: "Qumqishloq",
      name_oz: "?óě?čřëî?",
      name_ru: "Ęóěęčřëŕę",
    },
    {
      id: "1939",
      district_id: "168",
      name_uz: "Oqtovuq",
      name_oz: "Î?ňîâó?",
      name_ru: "Ŕęňîâóę",
    },
    {
      id: "1940",
      district_id: "168",
      name_uz: "Rapqon",
      name_oz: "Đŕď?îí",
      name_ru: "Đŕďęŕí",
    },
    {
      id: "1941",
      district_id: "168",
      name_uz: "Tovul",
      name_oz: "Ňîâóë",
      name_ru: "Ňîâóë",
    },
    {
      id: "1942",
      district_id: "168",
      name_uz: "Uzun",
      name_oz: "Óçóí",
      name_ru: "Óçóí",
    },
    {
      id: "1943",
      district_id: "168",
      name_uz: "Chimboy",
      name_oz: "×čěáîé",
      name_ru: "×čěáŕé",
    },
    {
      id: "1944",
      district_id: "168",
      name_uz: "Manguobod",
      name_oz: "Ěŕíăóîáîä",
      name_ru: "Ěŕíăóîáîä",
    },
    {
      id: "1945",
      district_id: "168",
      name_uz: "Beshariq tumanining qishloq fuqarolar yig‘inlari",
      name_oz: "Áĺřŕđč?  ňóěŕíčíčíă ?čřëî? ôó?ŕđîëŕđ éč?číëŕđč",
      name_ru: "Ńĺëüńęčĺ ńőîäű ăđŕćäŕí Áĺřŕđűęńęîăî đŕéîíŕ",
    },
    {
      id: "1946",
      district_id: "168",
      name_uz: "Andarxon",
      name_oz: "Ŕíäŕđőîí",
      name_ru: "Ŕíäŕđőŕí",
    },
    {
      id: "1947",
      district_id: "168",
      name_uz: "Beshariq",
      name_oz: "Áĺřŕđč?",
      name_ru: "Áĺřŕđűę",
    },
    {
      id: "1948",
      district_id: "168",
      name_uz: "Vatan",
      name_oz: "Âŕňŕí",
      name_ru: "Âŕňŕí",
    },
    {
      id: "1949",
      district_id: "168",
      name_uz: "Qorajiyda",
      name_oz: "?îđŕćčéäŕ",
      name_ru: "Ęŕđŕćčéäŕ",
    },
    {
      id: "1950",
      district_id: "168",
      name_uz: "Qashqar",
      name_oz: "?ŕř?ŕđ",
      name_ru: "Ęŕřęŕđ",
    },
    {
      id: "1951",
      district_id: "168",
      name_uz: "Beshsari",
      name_oz: "Áĺřńŕđč",
      name_ru: "Áĺřńŕđč",
    },
    {
      id: "1952",
      district_id: "168",
      name_uz: "Rapqon",
      name_oz: "Đŕď?îí",
      name_ru: "Đŕďęŕí",
    },
    {
      id: "1953",
      district_id: "168",
      name_uz: "Tovul",
      name_oz: "Ňîâóë",
      name_ru: "Ňŕâóë",
    },
    {
      id: "1954",
      district_id: "168",
      name_uz: "Yakkatut",
      name_oz: "ßęęŕňóň",
      name_ru: "ßęęŕňóň",
    },
    {
      id: "1955",
      district_id: "170",
      name_uz: "Ibrat",
      name_oz: "Čáđŕň",
      name_ru: "Čáđŕň",
    },
    {
      id: "1956",
      district_id: "170",
      name_uz: "Yuqori Bachqir",
      name_oz: "Ţ?îđč Áŕ÷?čđ",
      name_ru: "Ţęîđč Áŕ÷ęčđ",
    },
    {
      id: "1957",
      district_id: "170",
      name_uz: "Quyi Bachqir",
      name_oz: "?óéč Áŕ÷?čđ",
      name_ru: "Ęóéč Áŕ÷ęčđ",
    },
    {
      id: "1958",
      district_id: "170",
      name_uz: "Chinobod",
      name_oz: "×číîáîä",
      name_ru: "×číŕáŕä",
    },
    {
      id: "1959",
      district_id: "170",
      name_uz: "Buvayda",
      name_oz: "Áóâŕéäŕ",
      name_ru: "Áóâŕéäŕ",
    },
    {
      id: "1960",
      district_id: "170",
      name_uz: "Zarbuloq",
      name_oz: "Çŕđáóëî?",
      name_ru: "Çŕđáóëîę",
    },
    {
      id: "1961",
      district_id: "170",
      name_uz: "Qum",
      name_oz: "?óě",
      name_ru: "Ęóě",
    },
    {
      id: "1962",
      district_id: "170",
      name_uz: "Yuqori Nayman",
      name_oz: "Ţ?îđč Íŕéěŕí",
      name_ru: "Ţęîđč Íŕéěŕí",
    },
    {
      id: "1963",
      district_id: "170",
      name_uz: "Oqqo‘rg‘on",
      name_oz: "Î??˘đ?îí",
      name_ru: "Ŕęęóđăŕí",
    },
    {
      id: "1964",
      district_id: "170",
      name_uz: "Quyi Urganji",
      name_oz: "?óéč Óđăŕíćč",
      name_ru: "Ęóéč Óđăŕíćč",
    },
    {
      id: "1965",
      district_id: "169",
      name_uz: "Bog‘dod",
      name_oz: "Áî?äîä",
      name_ru: "Áŕăäŕä",
    },
    {
      id: "1966",
      district_id: "169",
      name_uz: "Amirobod",
      name_oz: "Ŕěčđîáîä",
      name_ru: "Ŕěčđîáîä",
    },
    {
      id: "1967",
      district_id: "169",
      name_uz: "Maylavoy",
      name_oz: "Ěŕéëŕâîé",
      name_ru: "Ěŕéëŕâîé",
    },
    {
      id: "1968",
      district_id: "169",
      name_uz: "Oltin vodiy",
      name_oz: "Îëňčí âîäčé",
      name_ru: "Îëňčí âîäčé",
    },
    {
      id: "1969",
      district_id: "169",
      name_uz: "Bog‘ishamol",
      name_oz: "Áî?čřŕěîë",
      name_ru: "Áîăčřŕěîë",
    },
    {
      id: "1970",
      district_id: "169",
      name_uz: "Bordon",
      name_oz: "Áîđäîí",
      name_ru: "Áîđäîí",
    },
    {
      id: "1971",
      district_id: "169",
      name_uz: "Do‘rmoncha",
      name_oz: "Ä˘đěîí÷ŕ",
      name_ru: "Äóđěŕí÷ŕ",
    },
    {
      id: "1972",
      district_id: "169",
      name_uz: "Irgali",
      name_oz: "Čđăŕëč",
      name_ru: "Čđăŕëč",
    },
    {
      id: "1973",
      district_id: "169",
      name_uz: "Qaroqchitol",
      name_oz: "?ŕđî?÷čňîë",
      name_ru: "Ęŕđŕę÷čňîë",
    },
    {
      id: "1974",
      district_id: "169",
      name_uz: "Kaxat",
      name_oz: "Ęŕőŕň",
      name_ru: "Ęŕőŕň",
    },
    {
      id: "1975",
      district_id: "169",
      name_uz: "Qirqboldi",
      name_oz: "?čđ?áîëäč",
      name_ru: "Ęčđęáîëäű",
    },
    {
      id: "1976",
      district_id: "169",
      name_uz: "Konizar",
      name_oz: "Ęîíčçŕđ",
      name_ru: "Ęîíčçŕđ",
    },
    {
      id: "1977",
      district_id: "169",
      name_uz: "Qo‘shtegirmon",
      name_oz: "?˘řňĺăčđěîí",
      name_ru: "Ęóřňĺăčđěîí",
    },
    {
      id: "1978",
      district_id: "169",
      name_uz: "Matqulobod",
      name_oz: "Ěŕň?óëîáîä",
      name_ru: "Ěŕňęóëŕáŕä",
    },
    {
      id: "1979",
      district_id: "169",
      name_uz: "Mirzaobod",
      name_oz: "Ěčđçŕîáîä",
      name_ru: "Ěčđçŕŕáŕä",
    },
    {
      id: "1980",
      district_id: "169",
      name_uz: "Samandarak",
      name_oz: "Ńŕěŕíäŕđŕę",
      name_ru: "Ńŕěŕíäŕđŕę",
    },
    {
      id: "1981",
      district_id: "169",
      name_uz: "Samarqand",
      name_oz: "Ńŕěŕđ?ŕíä",
      name_ru: "Ńŕěŕđęŕíä",
    },
    {
      id: "1982",
      district_id: "169",
      name_uz: "O‘ltarma",
      name_oz: "ˇëňŕđěŕ",
      name_ru: "Óëňŕđěŕ",
    },
    {
      id: "1983",
      district_id: "169",
      name_uz: "Xusnobod",
      name_oz: "Őóńíîáîä",
      name_ru: "Őóńíŕáŕä",
    },
    {
      id: "1984",
      district_id: "169",
      name_uz: "Chekmirzaobod",
      name_oz: "×ĺęěčđçŕîáîä",
      name_ru: "×ĺęěčđçŕŕáŕä",
    },
    {
      id: "1985",
      district_id: "169",
      name_uz: "Churindi",
      name_oz: "×óđčíäč",
      name_ru: "×óđčíäč",
    },
    {
      id: "1986",
      district_id: "176",
      name_uz: "Boltako‘l",
      name_oz: "Áîëňŕę˘ë",
      name_ru: "Áîëňŕęóë",
    },
    {
      id: "1987",
      district_id: "176",
      name_uz: "Gishtmon",
      name_oz: "Ăčřňěîí",
      name_ru: "Ăčřňěîí",
    },
    {
      id: "1988",
      district_id: "176",
      name_uz: "Do‘rmon",
      name_oz: "Ä˘đěîí",
      name_ru: "Äóđěîí",
    },
    {
      id: "1989",
      district_id: "176",
      name_uz: "Katta Beshkapa",
      name_oz: "Ęŕňňŕ Áĺřęŕďŕ",
      name_ru: "Ęŕňňŕ áĺřęŕďŕ",
    },
    {
      id: "1990",
      district_id: "176",
      name_uz: "Qizil ariq",
      name_oz: "?čçčë ŕđč?",
      name_ru: "Ęčçčëŕđčę",
    },
    {
      id: "1991",
      district_id: "176",
      name_uz: "Qorajiyda",
      name_oz: "?îđŕćčéäŕ",
      name_ru: "Ęŕđŕćčéäŕ",
    },
    {
      id: "1992",
      district_id: "176",
      name_uz: "Qorakaltak",
      name_oz: "?îđŕęŕëňŕę",
      name_ru: "Ęŕđŕęŕëňŕę",
    },
    {
      id: "1993",
      district_id: "176",
      name_uz: "Qumtepa",
      name_oz: "?óěňĺďŕ",
      name_ru: "Ęóěňĺďŕ",
    },
    {
      id: "1994",
      district_id: "176",
      name_uz: "Quyi Oqtepa",
      name_oz: "?óéč Î?ňĺďŕ",
      name_ru: "Ęóéč Îęňĺďŕ",
    },
    {
      id: "1995",
      district_id: "176",
      name_uz: "Sarmozor",
      name_oz: "Ńŕđěîçîđ",
      name_ru: "Ńŕđěîçîđ",
    },
    {
      id: "1996",
      district_id: "176",
      name_uz: "Xotinariq",
      name_oz: "Őîňčíŕđč?",
      name_ru: "Őîňčíŕđűę",
    },
    {
      id: "1997",
      district_id: "176",
      name_uz: "Shahartepa",
      name_oz: "Řŕ?ŕđňĺďŕ",
      name_ru: "Řŕőŕđňĺďŕ",
    },
    {
      id: "1998",
      district_id: "176",
      name_uz: "Eshonguzar",
      name_oz: "Ýřîíăóçŕđ",
      name_ru: "Ýřîíăóçŕđ",
    },
    {
      id: "1999",
      district_id: "176",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđűę",
    },
    {
      id: "2000",
      district_id: "178",
      name_uz: "Oltiariq",
      name_oz: "Îëňčŕđč?",
      name_ru: "Ŕëňűŕđűę",
    },
    {
      id: "2001",
      district_id: "178",
      name_uz: "Chinor",
      name_oz: "×číîđ",
      name_ru: "×číîđ",
    },
    {
      id: "2002",
      district_id: "178",
      name_uz: "Azimobod",
      name_oz: "Ŕçčěîáîä",
      name_ru: "Ŕçčěŕáŕä",
    },
    {
      id: "2003",
      district_id: "178",
      name_uz: "Bo‘rbaliq",
      name_oz: "Á˘đáŕëč?",
      name_ru: "Áóđáŕëčę",
    },
    {
      id: "2004",
      district_id: "178",
      name_uz: "Djurek",
      name_oz: "Äćóđĺę",
      name_ru: "Äćóđĺę",
    },
    {
      id: "2005",
      district_id: "178",
      name_uz: "Zilxa",
      name_oz: "Çčëőŕ",
      name_ru: "Çčëőŕ",
    },
    {
      id: "2006",
      district_id: "178",
      name_uz: "Katput",
      name_oz: "Ęŕňďóň",
      name_ru: "Ęŕňďóň",
    },
    {
      id: "2007",
      district_id: "178",
      name_uz: "Oqbo‘yra",
      name_oz: "Î?á˘éđŕ",
      name_ru: "Îęáóéđŕ",
    },
    {
      id: "2008",
      district_id: "178",
      name_uz: "Povulg‘on",
      name_oz: "Ďîâóë?îí",
      name_ru: "Ďîâóëăŕí",
    },
    {
      id: "2009",
      district_id: "178",
      name_uz: "Poloson",
      name_oz: "Ďîëîńîí",
      name_ru: "Ďŕëŕńŕí",
    },
    {
      id: "2010",
      district_id: "178",
      name_uz: "Chordara",
      name_oz: "×îđäŕđŕ",
      name_ru: "×îđäŕđŕ",
    },
    {
      id: "2011",
      district_id: "178",
      name_uz: "Eskiarab",
      name_oz: "Ýńęčŕđŕá",
      name_ru: "Ýńęčŕđŕá",
    },
    {
      id: "2012",
      district_id: "178",
      name_uz: "Yangiarab",
      name_oz: "ßíăčŕđŕá",
      name_ru: "ßíăčŕđŕá",
    },
    {
      id: "2013",
      district_id: "178",
      name_uz: "Yangiqo‘rg‘on",
      name_oz: "ßíăč?˘đ?îí",
      name_ru: "ßíăčęóđăŕí",
    },
    {
      id: "2014",
      district_id: "178",
      name_uz: "Yangiyo‘l tumanining qishloq fuqarolar yig‘inlari",
      name_oz: "ßíăčé˘ë ňóěŕíčíčíă ?čřëî? ôó?ŕđîëŕđ éč?číëŕđč",
      name_ru: "Ńĺëüńęčĺ ńőîäű ăđŕćäŕí Ŕëňűŕđűęńęîăî đŕéîíŕ",
    },
    {
      id: "2015",
      district_id: "178",
      name_uz: "Oqbo‘yra",
      name_oz: "Î?á˘éđŕ",
      name_ru: "Ŕęáóéđŕ",
    },
    {
      id: "2016",
      district_id: "178",
      name_uz: "Oltiariq",
      name_oz: "Îëňčŕđč?",
      name_ru: "Ŕëňűŕđűę",
    },
    {
      id: "2017",
      district_id: "178",
      name_uz: "Yangiarab",
      name_oz: "ßíăčŕđŕá",
      name_ru: "ßíăčŕđŕá",
    },
    {
      id: "2018",
      district_id: "178",
      name_uz: "Bo‘rbaliq",
      name_oz: "Á˘đáŕëč?",
      name_ru: "Áóđáŕëűę",
    },
    {
      id: "2019",
      district_id: "178",
      name_uz: "G‘ayrat",
      name_oz: "?ŕéđŕň",
      name_ru: "Ăŕéđŕň",
    },
    {
      id: "2020",
      district_id: "178",
      name_uz: "Djurek",
      name_oz: "Äćóđĺę",
      name_ru: "Äćóđĺę",
    },
    {
      id: "2021",
      district_id: "178",
      name_uz: "Qiziltepa",
      name_oz: "?čçčëňĺďŕ",
      name_ru: "Ęűçűëňĺďŕ",
    },
    {
      id: "2022",
      district_id: "178",
      name_uz: "Poloson",
      name_oz: "Ďîëîńîí",
      name_ru: "Ďŕëŕńŕí",
    },
    {
      id: "2023",
      district_id: "178",
      name_uz: "Fayziobod",
      name_oz: "Ôŕéçčîáîä",
      name_ru: "Ôŕéçčŕáŕä",
    },
    {
      id: "2024",
      district_id: "178",
      name_uz: "Toshqo‘rg‘on",
      name_oz: "Ňîř?˘đ?îí",
      name_ru: "Ňîřęóđăîí",
    },
    {
      id: "2025",
      district_id: "178",
      name_uz: "Kapchugay",
      name_oz: "Ęŕď÷óăŕé",
      name_ru: "Ęŕď÷óăŕé",
    },
    {
      id: "2026",
      district_id: "178",
      name_uz: "Povulg‘on",
      name_oz: "Ďîâóë?îí",
      name_ru: "Ďŕâóëăŕí",
    },
    {
      id: "2027",
      district_id: "178",
      name_uz: "Katput",
      name_oz: "Ęŕňďóň",
      name_ru: "Ęŕňďóň",
    },
    {
      id: "2028",
      district_id: "178",
      name_uz: "Zilxa",
      name_oz: "Çčëőŕ",
      name_ru: "Çčëőŕ",
    },
    {
      id: "2029",
      district_id: "178",
      name_uz: "Azimobod",
      name_oz: "Ŕçčěîáîä",
      name_ru: "Ŕçčěŕáŕä",
    },
    {
      id: "2030",
      district_id: "178",
      name_uz: "Tinchlik",
      name_oz: "Ňčí÷ëčę",
      name_ru: "Ňčí÷ëčę",
    },
    {
      id: "2031",
      district_id: "173",
      name_uz: "Quva",
      name_oz: "?óâŕ",
      name_ru: "Ęóâŕ",
    },
    {
      id: "2032",
      district_id: "173",
      name_uz: "Sanoatchilar",
      name_oz: "Ńŕíîŕň÷čëŕđ",
      name_ru: "Ńŕíîŕň÷čëŕđ",
    },
    {
      id: "2033",
      district_id: "173",
      name_uz: "Guliston",
      name_oz: "Ăóëčńňîí",
      name_ru: "Ăóëčńňîí",
    },
    {
      id: "2034",
      district_id: "173",
      name_uz: "Damariq",
      name_oz: "Äŕěŕđč?",
      name_ru: "Äŕěŕđčę",
    },
    {
      id: "2035",
      district_id: "173",
      name_uz: "Jalayer",
      name_oz: "Ćŕëŕĺđ",
      name_ru: "Äćŕëŕĺđ",
    },
    {
      id: "2036",
      district_id: "173",
      name_uz: "Qayirma",
      name_oz: "?ŕéčđěŕ",
      name_ru: "Ęŕéčđěŕ",
    },
    {
      id: "2037",
      district_id: "173",
      name_uz: "Qaqir",
      name_oz: "?ŕ?čđ",
      name_ru: "Ęŕęčđ",
    },
    {
      id: "2038",
      district_id: "173",
      name_uz: "Qandabuloq",
      name_oz: "?ŕíäŕáóëî?",
      name_ru: "Ęŕíäŕáóëîę",
    },
    {
      id: "2039",
      district_id: "173",
      name_uz: "Qorashox",
      name_oz: "?îđŕřîő",
      name_ru: "Ęŕđŕřîő",
    },
    {
      id: "2040",
      district_id: "173",
      name_uz: "Mustaqillik",
      name_oz: "Ěóńňŕ?čëëčę",
      name_ru: "Ěóńňŕęčëëčę",
    },
    {
      id: "2041",
      district_id: "173",
      name_uz: "Oltinariq",
      name_oz: "Îëňčíŕđč?",
      name_ru: "Îëňčíŕđčę",
    },
    {
      id: "2042",
      district_id: "173",
      name_uz: "Pastki Xo‘ja Xasan",
      name_oz: "Ďŕńňęč Ő˘ćŕ Őŕńŕí",
      name_ru: "Ďŕńňęč Őóćŕ Őŕńŕí",
    },
    {
      id: "2043",
      district_id: "173",
      name_uz: "Tolmozor",
      name_oz: "Ňîëěîçîđ",
      name_ru: "Ňîëěŕçîđ",
    },
    {
      id: "2044",
      district_id: "173",
      name_uz: "Turk",
      name_oz: "Ňóđę",
      name_ru: "Ňóđę",
    },
    {
      id: "2045",
      district_id: "173",
      name_uz: "O‘zbek",
      name_oz: "ˇçáĺę",
      name_ru: "Óçáĺę",
    },
    {
      id: "2046",
      district_id: "173",
      name_uz: "Yuziya",
      name_oz: "Ţçč˙",
      name_ru: "Ţçč˙",
    },
    {
      id: "2047",
      district_id: "182",
      name_uz: "Uchko‘prik",
      name_oz: "Ó÷ę˘ďđčę",
      name_ru: "Ó÷ęóďđčę",
    },
    {
      id: "2048",
      district_id: "182",
      name_uz: "Begobod",
      name_oz: "Áĺăîáîä",
      name_ru: "Áĺăŕáŕä",
    },
    {
      id: "2049",
      district_id: "182",
      name_uz: "G‘ijdan",
      name_oz: "?čćäŕí",
      name_ru: "Ăčćäŕí",
    },
    {
      id: "2050",
      district_id: "182",
      name_uz: "Katta Qashqar",
      name_oz: "Ęŕňňŕ ?ŕř?ŕđ",
      name_ru: "Ęŕňňŕ ęŕřęŕđ",
    },
    {
      id: "2051",
      district_id: "182",
      name_uz: "Qumariqobod",
      name_oz: "?óěŕđč?îáîä",
      name_ru: "Ęóěŕđčęîáîä",
    },
    {
      id: "2052",
      district_id: "182",
      name_uz: "Bog‘ibo‘ston",
      name_oz: "Áî?čá˘ńňîí",
      name_ru: "Áîăčáóńňîí",
    },
    {
      id: "2053",
      district_id: "182",
      name_uz: "Mirzaxo‘ja",
      name_oz: "Ěčđçŕő˘ćŕ",
      name_ru: "Ěčđçŕőóćŕ",
    },
    {
      id: "2054",
      district_id: "182",
      name_uz: "Palaxon",
      name_oz: "Ďŕëŕőîí",
      name_ru: "Ďŕëŕőîí",
    },
    {
      id: "2055",
      district_id: "182",
      name_uz: "Sobirjon",
      name_oz: "Ńîáčđćîí",
      name_ru: "Ńîáčđćŕí",
    },
    {
      id: "2056",
      district_id: "182",
      name_uz: "Turg‘oq",
      name_oz: "Ňóđ?î?",
      name_ru: "Ňóđăîę",
    },
    {
      id: "2057",
      district_id: "182",
      name_uz: "Yangiqishloq",
      name_oz: "ßíăč?čřëî?",
      name_ru: "ßíăčęčřëîę",
    },
    {
      id: "2058",
      district_id: "179",
      name_uz: "Rishton",
      name_oz: "Đčřňîí",
      name_ru: "Đčřňŕí",
    },
    {
      id: "2059",
      district_id: "179",
      name_uz: "Avazboy",
      name_oz: "Ŕâŕçáîé",
      name_ru: "Ŕâŕçáŕé",
    },
    {
      id: "2060",
      district_id: "179",
      name_uz: "Beshkapa",
      name_oz: "Áĺřęŕďŕ",
      name_ru: "Áĺřęŕďŕ",
    },
    {
      id: "2061",
      district_id: "179",
      name_uz: "Bujay",
      name_oz: "Áóćŕé",
      name_ru: "Áóćŕé",
    },
    {
      id: "2062",
      district_id: "179",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Áóńňŕí",
    },
    {
      id: "2063",
      district_id: "179",
      name_uz: "Do‘tir",
      name_oz: "Ä˘ňčđ",
      name_ru: "Äóňčđ",
    },
    {
      id: "2064",
      district_id: "179",
      name_uz: "Saxovat",
      name_oz: "Ńŕőîâŕň",
      name_ru: "Ńŕőîâŕň",
    },
    {
      id: "2065",
      district_id: "179",
      name_uz: "Zoxidon",
      name_oz: "Çîőčäîí",
      name_ru: "Çîőčäŕí",
    },
    {
      id: "2066",
      district_id: "179",
      name_uz: "Qayrag‘och",
      name_oz: "?ŕéđŕ?î÷",
      name_ru: "Ęŕéđŕăŕ÷",
    },
    {
      id: "2067",
      district_id: "179",
      name_uz: "Oq-yer",
      name_oz: "Î?-ĺđ",
      name_ru: "Ŕę-ĺđ",
    },
    {
      id: "2068",
      district_id: "179",
      name_uz: "Pandigon",
      name_oz: "Ďŕíäčăîí",
      name_ru: "Ďŕíäčăŕí",
    },
    {
      id: "2069",
      district_id: "179",
      name_uz: "To‘da",
      name_oz: "Ň˘äŕ",
      name_ru: "Ňóäŕ",
    },
    {
      id: "2070",
      district_id: "179",
      name_uz: "O‘yrat",
      name_oz: "ˇéđŕň",
      name_ru: "Óéđŕň",
    },
    {
      id: "2071",
      district_id: "179",
      name_uz: "Xurramobod",
      name_oz: "Őóđđŕěîáîä",
      name_ru: "Őóđđŕěŕáŕä",
    },
    {
      id: "2072",
      district_id: "180",
      name_uz: "Ravon",
      name_oz: "Đŕâîí",
      name_ru: "Đŕâîí",
    },
    {
      id: "2073",
      district_id: "180",
      name_uz: "Qal’a",
      name_oz: "?ŕëúŕ",
      name_ru: "Ęŕëúŕ",
    },
    {
      id: "2074",
      district_id: "180",
      name_uz: "Sarikanda",
      name_oz: "Ńŕđčęŕíäŕ",
      name_ru: "Ńŕđčęŕíäŕ",
    },
    {
      id: "2075",
      district_id: "180",
      name_uz: "So‘x",
      name_oz: "Ń˘ő",
      name_ru: "Ńîő",
    },
    {
      id: "2076",
      district_id: "180",
      name_uz: "Tul",
      name_oz: "Ňóë",
      name_ru: "Ňóë",
    },
    {
      id: "2077",
      district_id: "180",
      name_uz: "Hushyor",
      name_oz: "?óř¸đ",
      name_ru: "Őóřĺđ",
    },
    {
      id: "2078",
      district_id: "180",
      name_uz: "Tarovatli",
      name_oz: "Ňŕđîâŕňëč",
      name_ru: "Ňŕđîâŕňëč",
    },
    {
      id: "2079",
      district_id: "181",
      name_uz: "Toshloq",
      name_oz: "Ňîřëî?",
      name_ru: "Ňŕřëŕę",
    },
    {
      id: "2080",
      district_id: "181",
      name_uz: "Arabmozor",
      name_oz: "Ŕđŕáěîçîđ",
      name_ru: "Ŕđŕáěîçîđ",
    },
    {
      id: "2081",
      district_id: "181",
      name_uz: "Axshak",
      name_oz: "Ŕőřŕę",
      name_ru: "Ŕőřŕę",
    },
    {
      id: "2082",
      district_id: "181",
      name_uz: "Varzak",
      name_oz: "Âŕđçŕę",
      name_ru: "Âŕđçŕę",
    },
    {
      id: "2083",
      district_id: "181",
      name_uz: "Zarkent",
      name_oz: "Çŕđęĺíň",
      name_ru: "Çŕđęĺíň",
    },
    {
      id: "2084",
      district_id: "181",
      name_uz: "Qumariq",
      name_oz: "?óěŕđč?",
      name_ru: "Ęóěŕđčę",
    },
    {
      id: "2085",
      district_id: "181",
      name_uz: "Quyi Nayman",
      name_oz: "?óéč Íŕéěŕí",
      name_ru: "Ęóéč Íŕéěŕí",
    },
    {
      id: "2086",
      district_id: "181",
      name_uz: "Sadda",
      name_oz: "Ńŕääŕ",
      name_ru: "Ńŕääŕ",
    },
    {
      id: "2087",
      district_id: "181",
      name_uz: "Turvat",
      name_oz: "Ňóđâŕň",
      name_ru: "Ňóđâŕň",
    },
    {
      id: "2088",
      district_id: "181",
      name_uz: "Yakkatut",
      name_oz: "ßęęŕňóň",
      name_ru: "ßęęŕňóň",
    },
    {
      id: "2089",
      district_id: "183",
      name_uz: "Yaypan",
      name_oz: "ßéďŕí",
      name_ru: "ßéďŕí",
    },
    {
      id: "2090",
      district_id: "183",
      name_uz: "Sho‘rsuv",
      name_oz: "Ř˘đńóâ",
      name_ru: "Řîđńó",
    },
    {
      id: "2091",
      district_id: "183",
      name_uz: "Avg‘on",
      name_oz: "Ŕâ?îí",
      name_ru: "Ŕâăîí",
    },
    {
      id: "2092",
      district_id: "183",
      name_uz: "Dahana Qaqir",
      name_oz: "Äŕ?ŕíŕ ?ŕ?čđ",
      name_ru: "Äŕőŕíŕ ęŕęčđ",
    },
    {
      id: "2093",
      district_id: "183",
      name_uz: "Islom",
      name_oz: "Čńëîě",
      name_ru: "Čńëŕě",
    },
    {
      id: "2094",
      district_id: "183",
      name_uz: "Katta Tagob",
      name_oz: "Ęŕňňŕ Ňŕăîá",
      name_ru: "Ęŕňňŕ Ňŕăŕá",
    },
    {
      id: "2095",
      district_id: "183",
      name_uz: "Qizil Qaqir",
      name_oz: "?čçčë ?ŕ?čđ",
      name_ru: "Ęčçčë ęŕęčđ",
    },
    {
      id: "2096",
      district_id: "183",
      name_uz: "Kichik Tagob",
      name_oz: "Ęč÷čę Ňŕăîá",
      name_ru: "Ęč÷čę Ňŕăŕá",
    },
    {
      id: "2097",
      district_id: "183",
      name_uz: "Sardoba",
      name_oz: "Ńŕđäîáŕ",
      name_ru: "Ńŕđäîáŕ",
    },
    {
      id: "2098",
      district_id: "183",
      name_uz: "Kudash",
      name_oz: "Ęóäŕř",
      name_ru: "Ęóäŕř",
    },
    {
      id: "2099",
      district_id: "183",
      name_uz: "Kul elash",
      name_oz: "Ęóë ýëŕř",
      name_ru: "Ęóë ýëŕř",
    },
    {
      id: "2100",
      district_id: "183",
      name_uz: "Qulibek",
      name_oz: "?óëčáĺę",
      name_ru: "Ęóëčáĺę",
    },
    {
      id: "2101",
      district_id: "183",
      name_uz: "Qumbosti",
      name_oz: "?óěáîńňč",
      name_ru: "Ęóěáîńäč",
    },
    {
      id: "2102",
      district_id: "183",
      name_uz: "Qo‘shqo‘noq",
      name_oz: "?˘ř?˘íî?",
      name_ru: "Ęóřęóíŕę",
    },
    {
      id: "2103",
      district_id: "183",
      name_uz: "Qo‘rg‘oncha",
      name_oz: "?˘đ?îí÷ŕ",
      name_ru: "Ęóđăŕí÷ŕ",
    },
    {
      id: "2104",
      district_id: "183",
      name_uz: "Nursux",
      name_oz: "Íóđńóő",
      name_ru: "Íóđńóő",
    },
    {
      id: "2105",
      district_id: "183",
      name_uz: "Ovchi",
      name_oz: "Îâ÷č",
      name_ru: "Îâ÷č",
    },
    {
      id: "2106",
      district_id: "183",
      name_uz: "Oyimcha Qaqir",
      name_oz: "Îéčě÷ŕ ?ŕ?čđ",
      name_ru: "Ŕéčě÷ŕ ęŕęčđ",
    },
    {
      id: "2107",
      district_id: "183",
      name_uz: "Oqmachit",
      name_oz: "Î?ěŕ÷čň",
      name_ru: "Ŕęěŕ÷čň",
    },
    {
      id: "2108",
      district_id: "183",
      name_uz: "Oxta Tagob",
      name_oz: "Îőňŕ Ňŕăîá",
      name_ru: "Ŕőňŕ Ňŕăŕá",
    },
    {
      id: "2109",
      district_id: "183",
      name_uz: "O‘qchi Dasht",
      name_oz: "ˇ?÷č Äŕřň",
      name_ru: "Óę÷č Äŕřň",
    },
    {
      id: "2110",
      district_id: "183",
      name_uz: "O‘qchi Rajabgardi",
      name_oz: "ˇ?÷č Đŕćŕáăŕđäč",
      name_ru: "Óę÷č Đŕćŕáăŕđäč",
    },
    {
      id: "2111",
      district_id: "183",
      name_uz: "Iftixor",
      name_oz: "Čôňčőîđ",
      name_ru: "Čôňčőîđ",
    },
    {
      id: "2112",
      district_id: "184",
      name_uz: "Chimyon ( mavjud)",
      name_oz: "×čě¸í  ( ěŕâćóä)",
      name_ru: "×čěčîí",
    },
    {
      id: "2113",
      district_id: "184",
      name_uz: "Avval",
      name_oz: "Ŕââŕë",
      name_ru: "Ŕââŕë",
    },
    {
      id: "2114",
      district_id: "184",
      name_uz: "Archa",
      name_oz: "Ŕđ÷ŕ",
      name_ru: "Ŕđ÷ŕ",
    },
    {
      id: "2115",
      district_id: "184",
      name_uz: "Vodil",
      name_oz: "Âîäčë",
      name_ru: "Âîäčë",
    },
    {
      id: "2116",
      district_id: "184",
      name_uz: "Yuqori Vodil",
      name_oz: "Ţ?îđč Âîäčë",
      name_ru: "Ţęîđč Âîäčë",
    },
    {
      id: "2117",
      district_id: "184",
      name_uz: "Damko‘l",
      name_oz: "Äŕěę˘ë",
      name_ru: "Äŕěęóë",
    },
    {
      id: "2118",
      district_id: "184",
      name_uz: "Yoshlarobod",
      name_oz: "¨řëŕđîáîä",
      name_ru: "Ĺřëŕđŕáŕä",
    },
    {
      id: "2119",
      district_id: "184",
      name_uz: "Qo‘rg‘ontepa",
      name_oz: "?˘đ?îíňĺďŕ",
      name_ru: "Ęóđăîíňĺďŕ",
    },
    {
      id: "2120",
      district_id: "184",
      name_uz: "Langar",
      name_oz: "Ëŕíăŕđ",
      name_ru: "Ëŕíăŕđ",
    },
    {
      id: "2121",
      district_id: "184",
      name_uz: "Log‘on",
      name_oz: "Ëî?îí",
      name_ru: "Ëŕăŕí",
    },
    {
      id: "2122",
      district_id: "184",
      name_uz: "Mindon",
      name_oz: "Ěčíäîí",
      name_ru: "Ěčíäîí",
    },
    {
      id: "2123",
      district_id: "184",
      name_uz: "Novkent",
      name_oz: "Íîâęĺíň",
      name_ru: "Íîâęĺíň",
    },
    {
      id: "2124",
      district_id: "184",
      name_uz: "Yuqori Oqtepa",
      name_oz: "Ţ?îđč Î?ňĺďŕ",
      name_ru: "Ţęîđč Îęňĺďŕ",
    },
    {
      id: "2125",
      district_id: "184",
      name_uz: "Parvoz",
      name_oz: "Ďŕđâîç",
      name_ru: "Ďŕđâîç",
    },
    {
      id: "2126",
      district_id: "184",
      name_uz: "Yuqori Soybo‘yi",
      name_oz: "Ţ?îđč Ńîéá˘éč",
      name_ru: "Ţęîđč Ńîéáóéč",
    },
    {
      id: "2127",
      district_id: "184",
      name_uz: "Bahor",
      name_oz: "Áŕ?îđ",
      name_ru: "Áŕőîđ",
    },
    {
      id: "2128",
      district_id: "184",
      name_uz: "Xonqiz",
      name_oz: "Őîí?čç",
      name_ru: "Őîíęčç",
    },
    {
      id: "2129",
      district_id: "184",
      name_uz: "Xo‘roba",
      name_oz: "Ő˘đîáŕ",
      name_ru: "Őóđîáŕ",
    },
    {
      id: "2130",
      district_id: "184",
      name_uz: "Neftchilar",
      name_oz: "Íĺôň÷čëŕđ",
      name_ru: "Íĺôň÷čëŕđ",
    },
    {
      id: "2131",
      district_id: "184",
      name_uz: "Shoximardonobod",
      name_oz: "Řîőčěŕđäîíîáîä",
      name_ru: "Řîőčěŕđäîíŕáŕä",
    },
    {
      id: "2132",
      district_id: "184",
      name_uz: "Yuqori Mindon",
      name_oz: "Ţ?îđč Ěčíäîí",
      name_ru: "Ţęîđč ěčíäŕí",
    },
    {
      id: "2133",
      district_id: "171",
      name_uz: "Dang‘ara",
      name_oz: "Äŕí?ŕđŕ",
      name_ru: "Äŕíăŕđŕ",
    },
    {
      id: "2134",
      district_id: "171",
      name_uz: "Doimobod",
      name_oz: "Äîčěîáîä",
      name_ru: "Äîčěŕáŕä",
    },
    {
      id: "2135",
      district_id: "171",
      name_uz: "Katta Ganjiravon",
      name_oz: "Ęŕňňŕ Ăŕíćčđŕâîí",
      name_ru: "Ęŕňňŕ ăŕíćčđŕâîí",
    },
    {
      id: "2136",
      district_id: "171",
      name_uz: "Katta Turk",
      name_oz: "Ęŕňňŕ Ňóđę",
      name_ru: "Ęŕňňŕ ňóđę",
    },
    {
      id: "2137",
      district_id: "171",
      name_uz: "Qum Qiyali",
      name_oz: "?óě ?č˙ëč",
      name_ru: "Ęóě ęč˙ëč",
    },
    {
      id: "2138",
      district_id: "171",
      name_uz: "Toptiqsaroy",
      name_oz: "Ňîďňč?ńŕđîé",
      name_ru: "Ňîďňčęńŕđŕé",
    },
    {
      id: "2139",
      district_id: "171",
      name_uz: "Tumor",
      name_oz: "Ňóěîđ",
      name_ru: "Ňóěîđ",
    },
    {
      id: "2140",
      district_id: "171",
      name_uz: "Yuqori Urganji",
      name_oz: "Ţ?îđč Óđăŕíćč",
      name_ru: "Ţęîđč Óđăŕíćč",
    },
    {
      id: "2141",
      district_id: "171",
      name_uz: "Yangi zamon",
      name_oz: "ßíăč çŕěîí",
      name_ru: "ßíăč çŕěîí",
    },
    {
      id: "2142",
      district_id: "186",
      name_uz: "Navbahor",
      name_oz: "Íŕâáŕ?îđ",
      name_ru: "Íŕâáŕőîđ",
    },
    {
      id: "2143",
      district_id: "186",
      name_uz: "Kaldo‘shan",
      name_oz: "Ęŕëä˘řŕí",
      name_ru: "Ęŕëäóřŕí",
    },
    {
      id: "2144",
      district_id: "186",
      name_uz: "Qo‘qonboy",
      name_oz: "?˘?îíáîé",
      name_ru: "Ęóęîíáŕé",
    },
    {
      id: "2145",
      district_id: "186",
      name_uz: "Tomosha",
      name_oz: "Ňîěîřŕ",
      name_ru: "Ňîěîřŕ",
    },
    {
      id: "2146",
      district_id: "186",
      name_uz: "Chek chuvaldak",
      name_oz: "×ĺę ÷óâŕëäŕę",
      name_ru: "×ĺę ÷óâŕëäŕę",
    },
    {
      id: "2147",
      district_id: "186",
      name_uz: "Shoyinbek",
      name_oz: "Řîéčíáĺę",
      name_ru: "Řîéčíáĺę",
    },
    {
      id: "2148",
      district_id: "186",
      name_uz: "Eski",
      name_oz: "Ýńęč",
      name_ru: "Ýńęč",
    },
    {
      id: "2149",
      district_id: "186",
      name_uz: "Eshon",
      name_oz: "Ýřîí",
      name_ru: "Ýřîí",
    },
    {
      id: "2150",
      district_id: "172",
      name_uz: "Yozyovon",
      name_oz: "¨ç¸âîí",
      name_ru: "ßçú˙âŕí",
    },
    {
      id: "2151",
      district_id: "172",
      name_uz: "Yozyovon chek",
      name_oz: "¨ç¸âîí ÷ĺę",
      name_ru: "Ĺçĺâîí ÷ĺę",
    },
    {
      id: "2152",
      district_id: "172",
      name_uz: "Yo‘ldoshobod",
      name_oz: "É˘ëäîřîáîä",
      name_ru: "Éóëäîřŕáŕä",
    },
    {
      id: "2153",
      district_id: "172",
      name_uz: "Qorasoqol",
      name_oz: "?îđŕńî?îë",
      name_ru: "Ęŕđŕńŕęŕë",
    },
    {
      id: "2154",
      district_id: "172",
      name_uz: "Qoratepa",
      name_oz: "?îđŕňĺďŕ",
      name_ru: "Ęîđŕňĺďŕ",
    },
    {
      id: "2155",
      district_id: "172",
      name_uz: "Kelajak",
      name_oz: "Ęĺëŕćŕę",
      name_ru: "Ęĺëŕćŕę",
    },
    {
      id: "2156",
      district_id: "172",
      name_uz: "Quyi Soybo‘yi",
      name_oz: "?óéč Ńîéá˘éč",
      name_ru: "Ęóéč Ńîéáóéč",
    },
    {
      id: "2157",
      district_id: "172",
      name_uz: "Toshxovuz",
      name_oz: "Ňîřőîâóç",
      name_ru: "Ňîřőîâóç",
    },
    {
      id: "2158",
      district_id: "172",
      name_uz: "Xonobod",
      name_oz: "Őîíîáîä",
      name_ru: "Őîíŕáŕä",
    },
    {
      id: "2159",
      district_id: "175",
      name_uz: "Muqimiy",
      name_oz: "Ěó?čěčé",
      name_ru: "Ěóęčěč",
    },
    {
      id: "2160",
      district_id: "174",
      name_uz: "Arsif",
      name_oz: "Ŕđńčô",
      name_ru: "Ŕđńčâ",
    },
    {
      id: "2161",
      district_id: "174",
      name_uz: "Valik",
      name_oz: "Âŕëčę",
      name_ru: "Âŕëčę",
    },
    {
      id: "2162",
      district_id: "174",
      name_uz: "Qo‘chqorchi",
      name_oz: "?˘÷?îđ÷č",
      name_ru: "Ęó÷ęŕp÷č",
    },
    {
      id: "2163",
      district_id: "174",
      name_uz: "Muyon",
      name_oz: "Ěó¸í",
      name_ru: "Ěó˙í",
    },
    {
      id: "2164",
      district_id: "174",
      name_uz: "Isfayramsoy",
      name_oz: "Čńôŕéđŕěńîé",
      name_ru: "Čńôŕéđŕěńîé",
    },
    {
      id: "2165",
      district_id: "174",
      name_uz: "So‘fon",
      name_oz: "Ń˘ôîí",
      name_ru: "Ńóôŕí",
    },
    {
      id: "2166",
      district_id: "177",
      name_uz: "Yangi Marg‘ilon",
      name_oz: "ßíăč Ěŕđ?čëîí",
      name_ru: "ßíăč Ěŕpăčëŕí",
    },
    {
      id: "2167",
      district_id: "187",
      name_uz: "Bog‘ot",
      name_oz: "Áî?îň",
      name_ru: "Áŕăŕň",
    },
    {
      id: "2168",
      district_id: "187",
      name_uz: "Nurafshon",
      name_oz: "Íóđŕôřîí",
      name_ru: "Íóđŕôřîí",
    },
    {
      id: "2169",
      district_id: "187",
      name_uz: "Oltinqum",
      name_oz: "Îëňčí?óě",
      name_ru: "Îëňčíęóě",
    },
    {
      id: "2170",
      district_id: "187",
      name_uz: "Uzumzor",
      name_oz: "Óçóěçîđ",
      name_ru: "Óçóěçîđ",
    },
    {
      id: "2171",
      district_id: "187",
      name_uz: "Yangiqadam",
      name_oz: "ßíăč?ŕäŕě",
      name_ru: "ßíăčęŕäŕě",
    },
    {
      id: "2172",
      district_id: "188",
      name_uz: "Gurlan",
      name_oz: "Ăóđëŕí",
      name_ru: "Ăóđëĺí",
    },
    {
      id: "2173",
      district_id: "188",
      name_uz: "Chakkalar",
      name_oz: "×ŕęęŕëŕđ",
      name_ru: "×ŕęęŕëŕđ",
    },
    {
      id: "2174",
      district_id: "188",
      name_uz: "Bo‘zqal’a",
      name_oz: "Á˘ç?ŕëúŕ",
      name_ru: "Áóçęŕëúŕ",
    },
    {
      id: "2175",
      district_id: "188",
      name_uz: "Nurzamin",
      name_oz: "Íóđçŕěčí",
      name_ru: "Íóđçŕěčí",
    },
    {
      id: "2176",
      district_id: "188",
      name_uz: "Nukus yop",
      name_oz: "Íóęóń ¸ď",
      name_ru: "Íóęóń ĺď",
    },
    {
      id: "2177",
      district_id: "188",
      name_uz: "Markaziy Guliston",
      name_oz: "Ěŕđęŕçčé Ăóëčńňîí",
      name_ru: "Ěŕđęŕçčé Ăóëčńňŕí",
    },
    {
      id: "2178",
      district_id: "188",
      name_uz: "Do‘simbiy",
      name_oz: "Ä˘ńčěáčé",
      name_ru: "Äóńčěáčé",
    },
    {
      id: "2179",
      district_id: "188",
      name_uz: "Taxtako‘pir",
      name_oz: "Ňŕőňŕę˘ďčđ",
      name_ru: "Ňŕőňŕęóďčđ",
    },
    {
      id: "2180",
      district_id: "188",
      name_uz: "Yormish",
      name_oz: "¨đěčř",
      name_ru: "Ĺđěčř",
    },
    {
      id: "2181",
      district_id: "189",
      name_uz: "Qo‘shko‘pir",
      name_oz: "?˘řę˘ďčđ",
      name_ru: "Ęîřęóďűđ",
    },
    {
      id: "2182",
      district_id: "189",
      name_uz: "Qoromon",
      name_oz: "?îđîěîí",
      name_ru: "Ęŕđŕěŕí",
    },
    {
      id: "2183",
      district_id: "189",
      name_uz: "O‘rta qishloq",
      name_oz: "ˇđňŕ ?čřëî?",
      name_ru: "Óđňŕ ęčřëŕę",
    },
    {
      id: "2184",
      district_id: "189",
      name_uz: "Xonbod",
      name_oz: "Őîíáîä",
      name_ru: "Őîíŕáŕä",
    },
    {
      id: "2185",
      district_id: "189",
      name_uz: "Shixmashhad",
      name_oz: "Řčőěŕř?ŕä",
      name_ru: "Řčőěŕřőŕä",
    },
    {
      id: "2186",
      district_id: "189",
      name_uz: "Sherobod",
      name_oz: "Řĺđîáîä",
      name_ru: "Řĺđŕáŕä",
    },
    {
      id: "2187",
      district_id: "190",
      name_uz: "Cholish",
      name_oz: "×îëčř",
      name_ru: "×ŕëűř",
    },
    {
      id: "2188",
      district_id: "190",
      name_uz: "Oq oltin",
      name_oz: "Î? îëňčí",
      name_ru: "Ŕę ŕëňčí",
    },
    {
      id: "2189",
      district_id: "190",
      name_uz: "Chandir",
      name_oz: "×ŕíäčđ",
      name_ru: "×îíäčđ",
    },
    {
      id: "2190",
      district_id: "190",
      name_uz: "Ko‘palik",
      name_oz: "Ę˘ďŕëčę",
      name_ru: "Ęóďŕëčę",
    },
    {
      id: "2191",
      district_id: "190",
      name_uz: "Gardonlar",
      name_oz: "Ăŕđäîíëŕđ",
      name_ru: "Ăŕđäîíëŕđ",
    },
    {
      id: "2192",
      district_id: "193",
      name_uz: "Xazorasp",
      name_oz: "Őŕçîđŕńď",
      name_ru: "Őŕçŕđŕńď",
    },
    {
      id: "2193",
      district_id: "193",
      name_uz: "Oq yop",
      name_oz: "Î? ¸ď",
      name_ru: "Ŕę ĺď",
    },
    {
      id: "2194",
      district_id: "193",
      name_uz: "Oyok ovvo",
      name_oz: "Î¸ę îââî",
      name_ru: "Ŕĺę-ŕââŕ",
    },
    {
      id: "2195",
      district_id: "193",
      name_uz: "Nurxayot",
      name_oz: "Íóđőŕ¸ň",
      name_ru: "Íóđőŕ¸ň",
    },
    {
      id: "2196",
      district_id: "219",
      name_uz: "Pitnak",
      name_oz: "Ďčňíŕę",
      name_ru: "Ďčňíŕę",
    },
    {
      id: "2197",
      district_id: "194",
      name_uz: "Xonqa",
      name_oz: "Őîí?ŕ",
      name_ru: "Őŕíęŕ",
    },
    {
      id: "2198",
      district_id: "194",
      name_uz: "Istiqlol",
      name_oz: "Čńňč?ëîë",
      name_ru: "Čńňčęëîë",
    },
    {
      id: "2199",
      district_id: "194",
      name_uz: "Madaniy yer",
      name_oz: "Ěŕäŕíčé ĺđ",
      name_ru: "Ěŕäŕíčé ĺđ",
    },
    {
      id: "2200",
      district_id: "194",
      name_uz: "Birlashgan",
      name_oz: "Áčđëŕřăŕí",
      name_ru: "Áčđëŕřăŕí",
    },
    {
      id: "2201",
      district_id: "194",
      name_uz: "Yosh kuch",
      name_oz: "¨ř ęó÷",
      name_ru: "Ĺř ęó÷",
    },
    {
      id: "2202",
      district_id: "192",
      name_uz: "Gullanbog‘",
      name_oz: "Ăóëëŕíáî?",
      name_ru: "Ăóëëŕíáŕă",
    },
    {
      id: "2203",
      district_id: "192",
      name_uz: "Parchanxos",
      name_oz: "Ďŕđ÷ŕíőîń",
      name_ru: "Ďŕđ÷ŕíőŕń",
    },
    {
      id: "2204",
      district_id: "192",
      name_uz: "Iftixor",
      name_oz: "Čôňčőîđ",
      name_ru: "Čôňčőîđ",
    },
    {
      id: "2205",
      district_id: "192",
      name_uz: "Sho‘r-Qal’a",
      name_oz: "Ř˘đ-?ŕëúŕ",
      name_ru: "Řóđęŕëúŕ",
    },
    {
      id: "2206",
      district_id: "192",
      name_uz: "Yuqori qo‘m",
      name_oz: "Ţ?îđč ?˘ě",
      name_ru: "Ţęîđčęîě",
    },
    {
      id: "2207",
      district_id: "192",
      name_uz: "Hamkor",
      name_oz: "?ŕěęîđ",
      name_ru: "Őŕěęîđ",
    },
    {
      id: "2208",
      district_id: "195",
      name_uz: "Shovot",
      name_oz: "Řîâîň",
      name_ru: "Řŕâŕň",
    },
    {
      id: "2209",
      district_id: "195",
      name_uz: "Bo‘yrochi",
      name_oz: "Á˘éđî÷č",
      name_ru: "Áóéđŕ÷č",
    },
    {
      id: "2210",
      district_id: "195",
      name_uz: "Ipakchi",
      name_oz: "Čďŕę÷č",
      name_ru: "Čďŕę÷č",
    },
    {
      id: "2211",
      district_id: "195",
      name_uz: "Kangli",
      name_oz: "Ęŕíăëč",
      name_ru: "Ęŕíăëč",
    },
    {
      id: "2212",
      district_id: "195",
      name_uz: "Qat-qal’a",
      name_oz: "?ŕň-?ŕëúŕ",
      name_ru: "Ęŕň-ęŕëúŕ",
    },
    {
      id: "2213",
      district_id: "195",
      name_uz: "Monoq",
      name_oz: "Ěîíî?",
      name_ru: "Ěîíŕę",
    },
    {
      id: "2214",
      district_id: "195",
      name_uz: "Chig‘atoy",
      name_oz: "×č?ŕňîé",
      name_ru: "×čăŕňŕé",
    },
    {
      id: "2215",
      district_id: "196",
      name_uz: "Yangiariq",
      name_oz: "ßíăčŕđč?",
      name_ru: "ßíăčŕđűę",
    },
    {
      id: "2216",
      district_id: "196",
      name_uz: "Gulbog‘",
      name_oz: "Ăóëáî?",
      name_ru: "Ăóëáîă",
    },
    {
      id: "2217",
      district_id: "196",
      name_uz: "Soburzon",
      name_oz: "Ńîáóđçîí",
      name_ru: "Ńîáóđçŕí",
    },
    {
      id: "2218",
      district_id: "196",
      name_uz: "Suvgan",
      name_oz: "Ńóâăŕí",
      name_ru: "Ńóâăŕí",
    },
    {
      id: "2219",
      district_id: "196",
      name_uz: "Tagan",
      name_oz: "Ňŕăŕí",
      name_ru: "Ňŕăŕí",
    },
    {
      id: "2220",
      district_id: "196",
      name_uz: "Qo‘shloq",
      name_oz: "?˘řëî?",
      name_ru: "Ęóřëîę",
    },
    {
      id: "2221",
      district_id: "197",
      name_uz: "Yangibozor",
      name_oz: "ßíăčáîçîđ",
      name_ru: "ßíăčáŕçŕđ",
    },
    {
      id: "2222",
      district_id: "197",
      name_uz: "Yangi yop",
      name_oz: "ßíăč ¸ď",
      name_ru: "ßíăč-ĺď",
    },
    {
      id: "2223",
      district_id: "197",
      name_uz: "Mangitlar",
      name_oz: "Ěŕíăčňëŕđ",
      name_ru: "Ěŕíăčňëŕđ",
    },
    {
      id: "2224",
      district_id: "15",
      name_uz: "Jumurtov",
      name_oz: "Ćóěóđňîâ",
      name_ru: "Äćóěóđňŕó",
    },
    {
      id: "2225",
      district_id: "15",
      name_uz: "Kipshak",
      name_oz: "Ęčďřŕę",
      name_ru: "Ęčď÷îę",
    },
    {
      id: "2226",
      district_id: "15",
      name_uz: "Kilichboy",
      name_oz: "Ęčëč÷áîé",
      name_ru: "Ęčëč÷áŕé",
    },
    {
      id: "2227",
      district_id: "15",
      name_uz: "Xitoy",
      name_oz: "Őčňîé",
      name_ru: "Ęčňŕé",
    },
    {
      id: "2228",
      district_id: "16",
      name_uz: "Beruniy",
      name_oz: "Áĺđóíčé",
      name_ru: "Áĺđóíč",
    },
    {
      id: "2229",
      district_id: "16",
      name_uz: "Bulish",
      name_oz: "Áóëčř",
      name_ru: "Áóëčř",
    },
    {
      id: "2230",
      district_id: "222",
      name_uz: "Aspantay",
      name_oz: "Ŕńďŕíňŕé",
      name_ru: "Ŕńďŕíňŕé",
    },
    {
      id: "2231",
      district_id: "222",
      name_uz: "Yerkindarya",
      name_oz: "Ĺđęčíäŕđ˙",
      name_ru: "Ĺđęčíäŕđü˙",
    },
    {
      id: "2232",
      district_id: "222",
      name_uz: "Ko‘k-suv",
      name_oz: "Ę˘ę-ńóâ",
      name_ru: "Ęóę-ńó",
    },
    {
      id: "2233",
      district_id: "222",
      name_uz: "Qusqanatov",
      name_oz: "?óń?ŕíŕňîâ",
      name_ru: "Ęóńęŕíŕňŕó",
    },
    {
      id: "2234",
      district_id: "19",
      name_uz: "Qorao‘zak",
      name_oz: "?îđŕ˘çŕę",
      name_ru: "Ęŕđŕóç˙ę",
    },
    {
      id: "2235",
      district_id: "17",
      name_uz: "Xalqobod",
      name_oz: "Őŕë?îáîä",
      name_ru: "Őŕëęŕáŕä",
    },
    {
      id: "2236",
      district_id: "17",
      name_uz: "Kegeyli",
      name_oz: "Ęĺăĺéëč",
      name_ru: "Ęĺăĺéëč",
    },
    {
      id: "2237",
      district_id: "19",
      name_uz: "Qo‘ng‘irot",
      name_oz: "?˘í?čđîň",
      name_ru: "Ęóíăđŕä",
    },
    {
      id: "2238",
      district_id: "19",
      name_uz: "Jasliq",
      name_oz: "Ćŕńëč?",
      name_ru: "Ćŕńëűę",
    },
    {
      id: "2239",
      district_id: "19",
      name_uz: "Qaraqalpaqstan",
      name_oz: "?ŕđŕ?ŕëďŕ?ńňŕí",
      name_ru: "Ęŕđŕęŕëďŕęńňŕí",
    },
    {
      id: "2240",
      district_id: "19",
      name_uz: "Qiriqqiz",
      name_oz: "?čđč??čç",
      name_ru: "Ęűđűęęűç",
    },
    {
      id: "2241",
      district_id: "19",
      name_uz: "Oltinko‘l",
      name_oz: "Îëňčíę˘ë",
      name_ru: "Ŕëňűíęóëü",
    },
    {
      id: "2242",
      district_id: "19",
      name_uz: "Yelabad",
      name_oz: "Ĺëŕáŕä",
      name_ru: "Ĺëŕáŕä",
    },
    {
      id: "2243",
      district_id: "18",
      name_uz: "Qanliko‘l",
      name_oz: "?ŕíëčę˘ë",
      name_ru: "Ęŕíëűęóëü",
    },
    {
      id: "2244",
      district_id: "21",
      name_uz: "Mo‘ynoq",
      name_oz: "Ě˘éíî?",
      name_ru: "Ěóéíŕę",
    },
    {
      id: "2245",
      district_id: "22",
      name_uz: "Oqmang‘it",
      name_oz: "Î?ěŕí?čň",
      name_ru: "Ŕęěŕíăčň",
    },
    {
      id: "2246",
      district_id: "209",
      name_uz: "Taxiatosh",
      name_oz: "Ňŕőčŕňîř ",
      name_ru: "Ňŕőčŕňŕř",
    },
    {
      id: "2247",
      district_id: "209",
      name_uz: "Naymanko‘l",
      name_oz: "Íŕéěŕíę˘ë",
      name_ru: "Íŕéěŕíęóë",
    },
    {
      id: "2248",
      district_id: "24",
      name_uz: "Taxtako‘pir",
      name_oz: "Ňŕőňŕę˘ďčđ",
      name_ru: "Ňŕőňŕęóďűđ",
    },
    {
      id: "2249",
      district_id: "25",
      name_uz: "To‘rtko‘l",
      name_oz: "Ň˘đňę˘ë",
      name_ru: "Ňóđňęóëü",
    },
    {
      id: "2250",
      district_id: "25",
      name_uz: "Miskin",
      name_oz: "Ěčńęčí",
      name_ru: "Ěčńęčí",
    },
    {
      id: "2251",
      district_id: "25",
      name_uz: "Turkmankuli",
      name_oz: "Ňóđęěŕíęóëč",
      name_ru: "Ňóđęěŕíęóëč",
    },
    {
      id: "2252",
      district_id: "25",
      name_uz: "Tozabog‘",
      name_oz: "Ňîçŕáî?",
      name_ru: "Ňîçŕáîă",
    },
    {
      id: "2253",
      district_id: "25",
      name_uz: "Nurli yo‘l",
      name_oz: "Íóđëč é˘ë",
      name_ru: "Íóđëč-éóë",
    },
    {
      id: "2254",
      district_id: "25",
      name_uz: "Amirobod",
      name_oz: "Ŕěčđîáîä",
      name_ru: "Ŕěčđŕáŕä",
    },
    {
      id: "2255",
      district_id: "26",
      name_uz: "Xo‘jayli",
      name_oz: "Ő˘ćŕéëč",
      name_ru: "Őîäćĺéëč",
    },
    {
      id: "2256",
      district_id: "26",
      name_uz: "Vodnik",
      name_oz: "Âîäíčę",
      name_ru: "Âîäíčę",
    },
    {
      id: "2257",
      district_id: "27",
      name_uz: "Chimboy",
      name_oz: "×čěáîé",
      name_ru: "×čěáŕé",
    },
    {
      id: "2258",
      district_id: "27",
      name_uz: "Ayteke",
      name_oz: "Ŕéňĺęĺ",
      name_ru: "Ŕéňĺęĺ",
    },
    {
      id: "2259",
      district_id: "28",
      name_uz: "Shumanay",
      name_oz: "Řóěŕíŕé",
      name_ru: "Řóěŕíŕé",
    },
    {
      id: "2260",
      district_id: "29",
      name_uz: "Bo‘ston",
      name_oz: "Á˘ńňîí",
      name_ru: "Áóńňŕí",
    },
    {
      id: "2261",
      district_id: "29",
      name_uz: "Saxtiyon",
      name_oz: "Ńŕőňč¸í",
      name_ru: "Ńŕőňčĺí",
    },
    {
      id: "2262",
      district_id: "23",
      name_uz: "Karatau",
      name_oz: "Ęŕđŕňŕó",
      name_ru: "Ęŕđŕňŕó",
    },
    {
      id: "2263",
      district_id: "225",
      name_uz: "Yettitom",
      name_oz: "Ĺňňčňîě",
      name_ru: "Ĺňňčňîě",
    },
    {
      id: "2264",
      district_id: "225",
      name_uz: "Chiyal",
      name_oz: "×č˙ë",
      name_ru: "×č˙ë",
    },
    {
      id: "2265",
      district_id: "225",
      name_uz: "Ko‘kdala",
      name_oz: "Ę˘ęäŕëŕ",
      name_ru: "Ęóęäŕëŕ",
    },
    {
      id: "2266",
      district_id: "225",
      name_uz: "Ayritom",
      name_oz: "Ŕéđčňîě",
      name_ru: "Ŕéđčňîě",
    },
  ];